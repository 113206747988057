<template>
  <div class="container">
    <div class="text-container">
      <p class="header">Payment successful</p>
      <p class="text">We're delighted to inform you that your payment has been processed successfully! You're now a step closer to experiencing our exceptional service</p>

      <p class="header">Set your password</p>
      <p class="text">we've sent an email to your email address with instructions on setting up your password. This step is crucial for accessing your account and managing your bookings.</p>
      <p class="header">Booking confirmation</p>
      <p class="text">
        Your shoot is officially booked. We're excited to bring your vision to life. Once you sign in, you'll have full access to all your booked shoots, including this recent one. You can view
        details, track progress, and manage your shoots effortlessly.
      </p>
      <BaseButton icon="user" :icon_style="iconStyle" :custom_style="buttonStyle" :label="$t('Sign in')" @click.native="$router.replace({ name: 'home' })" />
    </div>
    <div class="img-container">
      <img src="https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/credit-card.svg" alt="Credit card" />
    </div>
  </div>
</template>

<script>
import helpers from '@utils/helpers';
import BaseButton from '../components/base/BaseButton.vue';

export default {
  name: 'StripeSuccess',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonStyle: {
        'box-shadow': `0 3px 14px ${helpers.getCssVariable('--main-btn-color-shadow')}`,
        'background-color': helpers.getCssVariable('--main-btn-color'),
        color: helpers.getCssVariable('--main-text-color'),
        width: '300px'
      },
      iconStyle: {
        fill: helpers.getCssVariable('--main-text-color'),
        stroke: helpers.getCssVariable('--main-text-color')
      }
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: row; /* Default layout is row */
  position: relative;
}
.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 50%;
}

.text-container {
  display: flex;
  flex-direction: column;
  /* background-color: lightgrey; */
  position: relative;
  padding: 5rem 4rem;
  margin-top: auto;
  margin-bottom: auto;
  flex: 50%;
}
.header {
  font-size: 30px;
  font-weight: bold;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
}

.text {
  font-size: 20px;
  font-weight: 200;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
}
@include responsive($max: mobileL) {
  .container {
    flex-direction: column !important;
    height: auto !important;
    padding: 24px;
  }
  .img-container {
    position: relative;
    flex: auto;
    display: none;
  }

  .text-container {
    flex: auto;
    margin: 0;
    padding: 0;
  }

  .text {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 16px !important;
  }
}
</style>
