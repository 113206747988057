<template>
  <div class="baseContentBlock">
    <h2 class="baseContentBlock__title" v-html="title">{{ title }}</h2>
    <h3 v-if="subtitle" class="baseContentBlock__subtitle">{{ subtitle }}</h3>
    <div class="baseContentBlock__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
/**
 * BaseContent component.
 *
 * @displayName BaseContentBlock
 */

export default {
  name: 'BaseContentBlock',
  props: {
    /**
     * Use this prop to pass the title of the block
     */
    title: { type: String, required: true },
    /**
     * Use this prop to pass the title of the block
     */
    subtitle: { type: String, default: '' }
  }
};
</script>
<style lang="scss" scoped>
.baseContentBlock {
  &__content {
    @include inter-font($size: 14px, $color: var(--main-text-color));
  }
  &__subtitle {
    @include inter-font($size: 14px, $color: var(--main-text-color));
    line-height: calculateRem(24px);
    margin-top: 0;
  }
  &__title {
    @include antonio-font($size: 23px, $uppercase: true, $color: var(--main-text-color));
    line-height: calculateRem(35px);
    margin-top: 0;
  }
}
</style>
