<template>
  <div :data-type="type" class="baseColumnLineChart">
    <apexchart type="line" :options="chartOptions" :series="series" style="width: 100%"></apexchart>
  </div>
</template>

<script>
/**
 * Component used to display a mixed chart with columns and line
 */
export default {
  name: 'BaseColumnLineChart',
  props: {
    /**
     * Data colors (columns)
     */
    colors: { default: () => [], type: Array },
    /**
     * Chart labels
     */
    labels: { default: () => [], type: Array },
    /**
     * Series data to be shown
     */
    series: { default: () => [], type: Array },
    /**
     * Column chart type (dark or light)
     */
    type: { default: 'dark', type: String }
  },

  computed: {
    chartOptions() {
      return {
        xaxis: {
          categories: this.labels,
          labels: {
            rotate: 0,
            style: {
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisBorder: {
            show: true,
            color: this.type === 'light' ? '#151515' : '#fff'
          },
          axisTicks: {
            show: false
          }
        },
        grid: {
          borderColor: this.getCssVariable('--graph-grid-color'),
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        colors: this.colors,
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {}
          },
          background: 'transparent',
          height: '100%',
          redrawOnParentResize: true,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          type: 'line'
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [2]
        },
        labels: this.labels,
        legend: {
          show: false
        },
        noData: {
          align: 'center',
          text: 'No data available at the moment',
          verticalAlign: 'middle'
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            horizontal: false
          }
        },
        stroke: {
          curve: 'smooth',
          width: 0
        },
        tooltip: {
          enabled: false
        },
        yaxis: {
          show: true,
          showAlways: true,
          axisBorder: {
            show: true,
            color: this.type === 'light' ? '#151515' : '#fff',
            offsetX: 3,
            offsetY: -1
          }
        }
      };
    }
  },
  watch: {
    labels() {
      this.chartOptions.xaxis.categories = this.labels;
    }
  }
};
</script>

<style lang="scss" scoped>
.baseColumnLineChart {
  display: flex;
  height: 100%;
  width: 100%;
}
</style>

<style lang="scss">
.baseColumnLineChart[data-type='light'] {
  .apexcharts-xaxis-label {
    fill: #151515;
  }
}

.apexcharts-xaxis-label {
  @include inter-font($size: 10px);
  fill: #fff;
}
</style>
