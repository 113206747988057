<template>
  <div :class="['categoryCard', { 'categoryCard--has-border': noBackgroundImage }]">
    <slot>
      <div class="categoryCard__title">
        <p>{{ service.name }}</p>
      </div>
    </slot>
    <BaseImage v-if="!noBackgroundImage" class="categoryCard__image" :src="getServiceImage(service.id)" />
  </div>
</template>

<script>
// Base Components
import BaseImage from '@base/BaseImage.vue';

/**
 * Component used to show a card with service background and a slot for its content
 *
 * @displayName CategoryCard
 */
export default {
  name: 'CategoryCard',
  components: {
    BaseImage
  },
  props: {
    /**
     * Set to true if you want to hide the background image
     */
    noBackgroundImage: { default: false, type: Boolean },
    /**
     * Service (category) to  be shown
     */
    service: { default: () => ({}), type: Object }
  }
};
</script>

<style lang="scss" scoped>
.categoryCard {
  $category-height: 76px;
  $category-width: 240px;

  border-radius: 10px;
  cursor: pointer;
  height: $category-height;
  overflow: hidden;
  min-width: $category-width;
  position: relative;

  .baseCheckbox {
    position: relative;
    z-index: 2;
  }

  &__title {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 2;
    width: 100%;

    > p {
      @include antonio-font($size: 14px, $color: #fff);
      margin: 0;
    }
  }

  & ~ .packages__category {
    margin-left: $category-spacing;
  }

  &__image {
    height: 100%;
    object-fit: cover;
    left: 0;
    min-width: 240px;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}
</style>
