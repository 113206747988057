<template>
  <BaseCard :custom_style="card_style">
    <template #header v-if="!shouldShowGallery">
      <div class="cardShoot__header">
        <span v-if="!hideShootId" class="cardShoot__shoot-id">{{ $t('SHOOT.SHOOT_ID', { id: shoot.id }) }}</span>
        <span v-if="!hideShootId && shoot.outlet_name && !isPhotographer" class="cardShoot__shoot-headerInfo">{{ $t('SHOOT.OUTLET_NAME') }} {{ shoot.outlet_name }} </span>
        <span v-if="!hideShootId && shoot.outlet_code && !isPhotographer" class="cardShoot__shoot-headerInfo">{{ $t('SHOOT.OUTLET_CODE') }} {{ shoot.outlet_code }} </span>
        <span v-if="!hideShootId && isAdmin" class="cardShoot__shoot-headerInfo">{{ $t('SHOOT.CLIENT_ID') }} {{ shoot.client_id }} </span>
        <BaseIcon class="cardShoot__close" icon="close" stroke="#151515" @click.native="$emit('close', false)" />
      </div>
    </template>
    <template #body>
      <div class="cardShoot__body">
        <slot name="body" />
      </div>
      <!-- Gallery [Images] Grid -->
      <div class="gallery" v-if="shouldShowGallery">
        <div class="folder" v-for="group in groupedImages" :key="group.folderName">
          <!-- Folder Header -->
          <div class="folder-header">
            <q-icon name="folder" size="lg" :style="{ color: '#FECB30', marginRight: '12px' }" />
            <q-item-label :style="{ fontSize: '18px' }">{{ group.folderName }}</q-item-label>
          </div>
          <div class="gallery-container">
            <div class="img-container" v-for="img in group.images" :key="img.id" @click="handleImageClick(img)">
              <v-lazy-image :src="img.preview_url" :src-placeholder="img.placeholder_url" class="img" />
              <div class="status-container">
                <BaseIcon :icon="getStatusIcon(img.status, localShowRawsPreview)" class="status-img" />
                <p class="status-text">{{ getStatusText(img.status, localShowRawsPreview) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Images Status Form -->
      <div class="gallery" v-if="showForm">
        <div class="form-container">
          <div class="form-img-container">
            <v-lazy-image
              :src="selectedImg.preview_url"
              :src-placeholder="selectedImg.placeholder_url"
              class="img form-img"
              :style="{ height: '100%', padding: '0px', margin: '0px' }"
              :alt="selectedImg.url"
            />
          </div>
          <div>
            <p class="feedback-title mb-0">{{ feedbackTitle }}</p>
            <BaseInput v-model="textFeedback" class="feedback" type="textarea" :label="feedbackLabel" />
          </div>
        </div>
        <template>
          <div class="cta-container">
            <p class="feedback-title" :style="{ marginTop: '14px', fontSize: '14px' }">
              {{
                localShowRawsPreview
                  ? $t('Once you select your preferred images, we will proceed to edit them.')
                  : $t('Once all your images are approved, the download link will be enabled, allowing you to download the full-resolution images.')
              }}
            </p>
            <div class="btns-container">
              <BaseButton
                :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                :label="localShowRawsPreview ? $t('Select for editing') : $t('Approve')"
                :disabled="
                  selectedImg.status === 'approved' || selectedImg.status === 'resubmitted' || selectedImg.status === 'selected' || loading || shoot.raws_preview ? !anyRawsPending : !anyImagesPending
                "
                :tooltip="selectedImg.status === 'approved' || selectedImg.status === 'resubmitted' || selectedImg.status === 'selected' ? $t('The image has been submitted') : ''"
                :loading="approveImageLoading"
                icon="edit"
                type="default"
                @click.native="shoot.raws_preview ? handleRawImageStatus('selected', textFeedback === '' ? null : textFeedback) : handleImgStatus('approved', null)"
                :ripple="true"
              />
              <BaseButton
                :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                :label="localShowRawsPreview ? $t('Skip editing') : $t('Resubmit')"
                :disabled="
                  selectedImg.status === 'resubmitted' ||
                  selectedImg.status === 'not-selected' ||
                  (shoot.images_preview && textFeedback === '') ||
                  (shoot.raws_preview && hasReachedMaxNotSelected) ||
                  loading
                "
                :loading="resubmitImageLoading"
                :icon="localShowRawsPreview ? 'close' : 'edit'"
                type="default"
                :style="{ marginLeft: '8px' }"
                :ripple="true"
                @click.native="shoot.raws_preview ? handleRawImageStatus('not-selected', null) : handleImgStatus('resubmitted', textFeedback)"
              />
              <a :href="selectedImg.url" target="_blank" :style="{ textDecoration: 'none' }">
                <BaseButton
                  :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                  :label="$t('View image in high quality')"
                  icon="image"
                  type="default"
                  :style="{ marginLeft: '8px' }"
                  :disabled="loading"
                />
              </a>
              <div class="counters" v-if="localShowRawsPreview">
                <q-chip outline color="primary" text-color="white" icon="filter_none"> Total: {{ totalRaws }} </q-chip>
                <q-chip outline color="blue" text-color="white" icon="filter_center_focus"> Max Selectable: {{ maxSelectableRaws }} </q-chip>
                <q-chip outline color="green" text-color="white" icon="check_circle"> Selected: {{ selectedRaws }} </q-chip>
                <q-chip outline color="red" text-color="white" icon="cancel"> Not Selected: {{ notSelectedRaws }} </q-chip>
                <q-chip outline color="orange" text-color="white" icon="hourglass_empty"> Pending: {{ pendingRaws }} </q-chip>
              </div>

              <BaseButton
                icon="arrow-left"
                :icon_style="{ fill: '#151515', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                :label="$t('Go back')"
                type="default"
                :style="{ position: 'absolute', bottom: '40px', right: localShowRawsPreview ? '200px' : '240px' }"
                @click.native="handleGoBack"
                :disabled="loading"
              />
              <BaseButton
                v-if="shoot.raws_preview"
                icon="check"
                :icon_style="{}"
                :label="$t('Submit RAWs')"
                type="default"
                :style="{ position: 'absolute', bottom: '40px', right: '36px' }"
                @click.native="submitRaws"
                :disabled="anyRawsPending || loading"
                :loading="submitRawsLoading"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
    <template #footer v-if="!localShowRawsPreview">
      <div class="cardShoot__footer">
        <!-- TODO -->
        <div v-if="isClient && isShootRebookable" class="cardShoot__rebook" @click="rebookShoot">
          <div class="cardShoot__rebook-icons">
            <BaseIcon
              icon="plus"
              :custom_style="{ height: '12px', left: '50%', position: 'absolute', stroke: '#fff', 'stroke-width': '4px', top: '50%', transform: 'translate(-50%, -50%)', width: '12px' }"
              class="cardShoot__rebook-icons-plus"
            />
            <BaseIcon
              icon="rotate-cw"
              :custom_style="{ stroke: '#fff', width: '23px', height: '23px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }"
              class="cardShoot__rebook-icons-rotate"
            />
          </div>
          <p>{{ getRebookLabel }}</p>
        </div>

        <span class="cardShoot__go-back" @click="$emit('close', false)">{{ $t('GO_BACK') }}</span>
        <slot name="actions" />
      </div>
    </template>
  </BaseCard>
</template>

<script>
import text from '@/i18n';
// Base Components
import BaseCard from '@base/BaseCard.vue';
import BaseIcon from '@base/BaseIcon.vue';
import VLazyImage from 'v-lazy-image/v2';
import BaseInput from '@base/BaseInput.vue';
import BaseButton from '@base/BaseButton.vue';
import { ShootsApi } from '@api/index';

// Utils
// Mixins
import checkBreakpoint from '@utils/mixins/check-breakpoint.js';
import { reactive, toRefs, computed, watch } from '@vue/composition-api';

import { useQueryClient, useMutation } from '@tanstack/vue-query';

/**
 * Shoot card component, it's the wrapper for the wizard used to accept a job and also for the client to see shoot info
 *
 * @displayName CardShoot
 */
export default {
  name: 'CardShoot',
  mixins: [checkBreakpoint],
  components: {
    BaseCard,
    BaseIcon,
    VLazyImage,
    BaseInput,
    BaseButton
  },
  props: {
    /**
     * Use this prop to pass shoot info
     */
    shoot: { default: () => ({}), type: Object },
    /**
     * Use this prop to hide the shoot id from the header
     */
    hideShootId: { default: false, type: Boolean },
    /**
     * Use this prop to hide/show the images preview
     */
    showRawsPreview: { default: false, type: Boolean }
  },
  setup(props, { root, emit }) {
    const store = root.$store;
    const router = root.$router;
    const queryClient = useQueryClient();

    const state = reactive({
      card_style: {
        display: 'flex',
        'flex-direction': 'column',
        height: '557px',
        padding: '36px 40px 30px 40px',
        'max-width': '750px',
        width: '100%'
      },
      showForm: false,
      localShowRawsPreview: props.showRawsPreview, // Create a local copy of showPreview
      selectedImg: null,
      feedback: '',
      notes: '',
      uploadUrl: `http://localhost/v1/shoot/${props.shoot.id}/action/upload-processed`,
      headers: {},
      isRaw: false,
      loading: false,
      approveImageLoading: false,
      resubmitImageLoading: false,
      submitRawsLoading: false,
      bulkActionLoading: false
    });

    // Vuex getters
    const isAdmin = computed(() => store.getters['user/isAdmin']);
    const isClient = computed(() => store.getters['user/isClient']);
    const isPhotographer = computed(() => store.getters['user/isPhotographer']);

    // Computed properties
    const hasResubmittedImages = computed(() => props.shoot.images.some(img => img.status === 'resubmitted'));

    const approveButtonLabel = computed(() => (hasResubmittedImages.value ? 'Approve remaining' : 'Approve all images')); // Localize this in your template

    const isShootRebookable = computed(() => {
      const { shoot } = props;
      return shoot.type === 'express' && ['uploaded', 'ready', 'completed'].includes(shoot.status); // Replace with your constants
    });

    const getRebookLabel = computed(() => (isResponsive.value ? 'REBOOK' : 'REBOOK_SHOOT')); // Localize this in your template

    const isResponsive = computed(() => checkBreakpoint({ upperBoundary: 543 })); // checkBreakpoint function needs to be defined

    const shouldShowGallery = computed(() => state.localShowRawsPreview);

    const currentImages = computed(() => {
      if (state.localShowRawsPreview) {
        return props.shoot.raws;
      }
      return [];
    });

    const groupedImages = computed(() => {
      const folderMap = currentImages.value.reduce((acc, img) => {
        if (!acc[img.folder]) {
          acc[img.folder] = [];
        }
        acc[img.folder].push(img);
        return acc;
      }, {});

      const groups = Object.keys(folderMap).map(folderName => {
        const sortedImages = folderMap[folderName].sort((a, b) => {
          // Assuming `aspect_ratio` is a number; sort in ascending order
          return a.aspect_ratio - b.aspect_ratio;
        });

        return {
          folderName,
          images: sortedImages
        };
      });

      return groups;
    });
    const feedbackTitle = computed(() => {
      return state.localShowRawsPreview
        ? text.t('If you have any preferences or notes for the images yet to be edited, please specify below.')
        : text.t('If you have any specific changes or requests, click the "Re-edit" button below. We want to make sure the images perfectly match your vision.');
    });
    const feedbackLabel = computed(() => {
      return state.localShowRawsPreview ? text.t('Notes') : text.t('Feedback');
    });

    const textFeedback = computed({
      get: () => {
        return state.localShowRawsPreview ? state.notes : state.feedback;
      },
      set: value => {
        if (state.localShowRawsPreview) {
          state.notes = value;
        } else {
          state.feedback = value;
        }
      }
    });
    const totalRaws = computed(() => {
      return props.shoot.raws.length;
    });
    const selectedRaws = computed(() => {
      return props.shoot.raws.filter(img => img.status === 'selected').length;
    });
    const notSelectedRaws = computed(() => {
      return props.shoot.raws.filter(img => img.status === 'not-selected').length;
    });
    const pendingRaws = computed(() => {
      return props.shoot.raws.filter(img => img.status === 'pending').length;
    });
    const maxSelectableRaws = computed(() => {
      // Increase the picture_number by 10% and round up to the nearest integer
      return Math.ceil(props.shoot.picture_number * 1.1);
    });
    const maxNotSelectedRaws = computed(() => {
      // Calculate the maximum number of raw images that can be "not-selected"
      return totalRaws.value - maxSelectableRaws.value;
    });
    const hasReachedMaxNotSelected = computed(() => {
      return notSelectedRaws.value >= maxNotSelectedRaws.value;
    });

    const totalImages = computed(() => props.shoot.images.length);
    const approvedImages = computed(() => props.shoot.images.filter(img => img.status === 'approved').length);
    const resubmittedImages = computed(() => props.shoot.images.filter(img => img.status === 'resubmitted').length);
    const pendingImages = computed(() => props.shoot.images.filter(img => img.status === 'pending').length);

    const anyPending = computed(() => {
      if (state.isRaw) {
        const noPendingForRaws = selectedRaws.value === totalRaws.value || selectedRaws.value === maxSelectableRaws.value;
        return !noPendingForRaws;
      } else {
        const anyPendingForImages = props.shoot.images.some(img => img.status === 'pending');
        return anyPendingForImages;
      }
    });
    const anyRawsPending = computed(() => {
      const noPendingForRaws = selectedRaws.value === totalRaws.value || selectedRaws.value === maxSelectableRaws.value;
      return !noPendingForRaws;
    });

    const anyImagesPending = computed(() => {
      const anyPendingForImages = props.shoot.images.some(img => img.status === 'pending');
      return anyPendingForImages;
    });

    // Mutation to update the processed image status
    const updateImageStatusMutation = useMutation({
      mutationFn: ({ id, payload }) => {
        return ShootsApi.updateImageStatus({ id, payload });
      },
      onSuccess: async (data, variables) => {
        if (data.statusCode === 200) {
          await queryClient.refetchQueries(['shoots']);
          state.approveImageLoading = false;
          state.resubmitImageLoading = false;
          state.loading = false;
          state.notes = '';
          textFeedback.value = '';
          state.showForm = false;
          state.selectedImg.status = variables.payload.newStatus;
          if (variables.payload.newStatus === 'approved') {
            store.dispatch('notification/addSuccessNotification', 'Image has been approved');
          } else {
            store.dispatch('notification/addSuccessNotification', 'Image has been resubmitted');
          }
        } else {
          store.dispatch('notification/addFailureNotification', 'Failed to update the image status');
        }
      },
      onError: (error, variables) => {
        state.approveImageLoading = false;
        state.resubmitImageLoading = false;
        state.loading = false;
        state.notes = '';
        state.showForm = false;
        console.error(`Error updating image #${variables.id} status:`, error);
        store.dispatch('notification/addFailureNotification', 'Failed to update the image status');
      }
    });

    // Mutation to update the raw image status
    const updateRawStatusMutation = useMutation({
      mutationFn: ({ id, payload }) => {
        return ShootsApi.updateRawImageStatus({ id, payload });
      },
      onSuccess: async (data, variables) => {
        if (data.statusCode === 200) {
          await queryClient.refetchQueries(['shoots']);
          if (variables.payload.newStatus === 'selected') {
            store.dispatch('notification/addSuccessNotification', 'Image has been chosen for the editing process');
          } else {
            store.dispatch('notification/addSuccessNotification', 'Image excluded from the editing process');
          }
        } else {
          store.dispatch('notification/addFailureNotification', `Failed to update status for RAW image #${variables.id}`);
        }
      },
      onError: (error, variables) => {
        console.error(`Error updating RAW image #${variables.id} status:`, error);
        store.dispatch('notification/addFailureNotification', `Failed to update status for RAW image #${variables.id}`);
      },
      onSettled: variables => {}
    });

    const triggerPreviewMutation = useMutation({
      mutationFn: payload => ShootsApi.updatePreview(payload),
      onMutate: () => {},
      onSuccess: () => {
        queryClient.refetchQueries(['shoots']);
        store.dispatch('notification/addSuccessNotification', 'images are ready to download');
        emit('close', false);
      },
      onError: error => {
        console.error('Error updating images_preview:', error);
        emit('close', false);
        store.dispatch('notification/addFailureNotification', 'Failed to update JPEG preview');
      }
    });

    const triggerRawsPreviewMutation = useMutation({
      mutationFn: payload => ShootsApi.updateRawsPreview(payload),
      onMutate: variables => {},
      onSuccess: (data, variables) => {
        if (data.statusCode === 200) {
          state.localShowRawsPreview = false;
          queryClient.refetchQueries(['shoots']);
          store.dispatch('notification/addSuccessNotification', 'Images have been submitted');
          emit('close', false);
        }
      },
      onError: error => {
        console.error('Error updating raws_preview:', error);
        state.localShowRawsPreview = false;
        store.dispatch('notification/addFailureNotification', 'Failed to update RAWs preview');
      }
      // Optionally, you can also add onSettled if needed
    });

    const bulkUpdateImageStatusMutation = useMutation({
      mutationFn: ({ shootId, hasResubmittedImages }) => {
        if (hasResubmittedImages) {
          return ShootsApi.approveRemainingImages({ payload: { shootId } });
        } else {
          return ShootsApi.bulkApproveImages({ payload: { shootId } });
        }
      },
      onSuccess: async data => {
        if (data.statusCode === 200) {
          queryClient.refetchQueries(['shoots']);
          state.loading = false;
          state.bulkActionLoading = false;
          store.dispatch('notification/addSuccessNotification', 'The images have been approved successfully');
          // You may need to update the local state to reflect these changes
          if (hasResubmittedImages.value === false) {
            triggerPreviewMutation.mutate({
              id: props.shoot.id,
              payload: {
                imagesPreview: !props.shoot.images_preview
              }
            });
          } else {
            emit('close', false);
          }
        }
      },
      onError: (error, variables) => {
        console.error(`Error performing bulk action for Shoot #${variables.shootId}:`, error);
        store.dispatch('notification/addFailureNotification', 'Failed to approve images');
        state.loading = false;
        state.bulkActionLoading = false;
        emit('close', false);
      }
    });

    /* Methods */
    // Method to dispatch the updateShoot action
    function updateShoot(payload) {
      store.dispatch('bookShoot/updateShoot', payload);
    }

    function rebookShoot() {
      const { address } = props.shoot;

      const shoot = {
        packages: props.shoot.services.map(service => ({
          service_id: service.service_id,
          pictures_number: service.pictures_number,
          name: service.package.name,
          package_id: service.package.id
        })),
        photographer_id: null,
        client_id: null,
        is_payed: false,
        location: {
          ...address
        },
        name: props.shoot.name,
        total_price: props.shoot.price,
        time: {
          duration: props.shoot.duration
        },
        type: 'express',
        content: props.shoot.content
      };

      updateShoot(shoot);
      router.push({ name: 'bookShoot', query: { type: 'express' } });
    }

    // Go from images preview feedback form to the images preview gallery
    function handleGoBack() {
      textFeedback.value = '';
      state.showForm = false;
    }

    function handleImageClick(img) {
      // Set the selectedImg to the clicked image metadata
      state.selectedImg = img;
      // Toggle the showForm variable to show the form
      state.showForm = true;
    }
    function getStatusText(status, isRaw) {
      if (isRaw) {
        if (status === 'pending') {
          return 'Pending';
        } else if (status === 'selected') {
          return 'Selected';
        } else if (status === 'not-selected') {
          return 'Not Selected';
        } else {
          return 'Unknown Status';
        }
      } else {
        if (status === 'pending') {
          return 'Waiting approval';
        } else if (status === 'approved') {
          return 'Approved';
        } else {
          return 'Revisions requested';
        }
      }
    }
    function getStatusIcon(status, isRaw) {
      if (isRaw) {
        if (status === 'pending') {
          return 'pending';
        } else if (status === 'selected') {
          return 'check';
        } else if (status === 'not-selected') {
          return 'close';
        } else {
          return 'pending'; // Or whatever you'd like for unknown statuses
        }
      } else {
        if (status === 'pending') {
          return 'pending';
        } else if (status === 'approved') {
          return 'check';
        } else {
          return 'pending';
        }
      }
    }

    async function handleImgStatus(newStatus, feedback) {
      state.loading = true;
      if (newStatus === 'approved') {
        state.approveImageLoading = true;
      } else {
        state.resubmitImageLoading = true;
      }
      const mutationVariables = {
        id: state.selectedImg.id,
        payload: { newStatus, feedback }
      };

      updateImageStatusMutation.mutate(mutationVariables, {
        onSuccess: () => {
          if (!anyImagesPending.value && !hasResubmittedImages) {
            state.selectedImg.status = 'approved';
            triggerPreviewMutation.mutate({
              id: props.shoot.id,
              payload: {
                images_preview: !props.shoot.images_preview
              }
            });
          }
        }
      });
    }
    async function handleRawImageStatus(newStatus, notes) {
      state.loading = true;
      if (newStatus === 'selected') {
        state.approveImageLoading = true;
      } else {
        state.resubmitImageLoading = true;
      }
      const mutationVariables = {
        id: state.selectedImg.id,
        payload: { newStatus, notes }
      };
      updateRawStatusMutation.mutate(mutationVariables, {
        onSuccess: () => {
          state.approveImageLoading = false;
          state.resubmitImageLoading = false;
          state.loading = false;
          state.notes = '';
          if (!anyRawsPending.value) {
            state.selectedImg.status = 'selected';
            store.dispatch('notification/addSuccessNotification', 'Review your selections and click "Submit RAWs" to proceed.');
            return;
          }
          state.showForm = false;
        }
      });
    }

    function submitRaws() {
      state.loading = true;
      state.submitRawsLoading = true;
      triggerRawsPreviewMutation.mutate(
        {
          id: props.shoot.id,
          payload: {
            rawsPreview: !props.shoot.raws_preview
          }
        },
        {
          onSuccess() {
            state.submitRawsLoading = false;
            state.loading = false;
          }
        }
      );
    }

    async function handleBulkAction(shootId) {
      state.loading = true;
      state.bulkActionLoading = true;
      bulkUpdateImageStatusMutation.mutate({ shootId, hasResubmittedImages: hasResubmittedImages.value });
    }

    // Watcher for showRawsPreview
    watch(
      () => props.showRawsPreview,
      newValue => {
        state.localShowRawsPreview = newValue;
      },
      { immediate: true }
    );

    return {
      ...toRefs(state),
      isAdmin,
      isClient,
      isPhotographer,
      hasResubmittedImages,
      approveButtonLabel,
      isShootRebookable,
      getRebookLabel,
      textFeedback,
      isResponsive,
      shouldShowGallery,
      currentImages,
      groupedImages,
      feedbackTitle,
      feedbackLabel,
      totalRaws,
      selectedRaws,
      notSelectedRaws,
      pendingRaws,
      maxSelectableRaws,
      maxNotSelectedRaws,
      hasReachedMaxNotSelected,
      totalImages,
      approvedImages,
      resubmittedImages,
      pendingImages,
      anyPending,
      anyRawsPending,
      anyImagesPending,
      handleGoBack,
      handleImageClick,
      rebookShoot,
      getStatusIcon,
      getStatusText,
      handleImgStatus,
      handleRawImageStatus,
      handleBulkAction,
      updateShoot,
      submitRaws
    };
  }
};
</script>

<style lang="scss" scoped>
.counters {
  margin-top: 12px;
}
.gallery {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  padding: 36px 40px 30px 40px;
  z-index: 100;
  background-color: white;
  overflow-y: scroll; // Add this line
}

.folder {
  margin-bottom: 12px;
}
.folder-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.gallery-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
  min-height: 100%;
}
.form-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}
.cta-container {
  width: 100%;
}
.gallery-container::-webkit-scrollbar {
  display: none;
}

.img {
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
}

.form-img-container {
  position: relative;
  height: 225px;
  max-height: 225px;
}

.form-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.img-container {
  position: relative;
}

.status-container {
  display: flex;
  position: relative;
  align-items: center;
}
.status-img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.status-text {
  font-size: 12px;
  font-family: inter;
  position: relative;
  top: 50%;
  margin: 0;
  padding: 0;
}

.img:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.005);
}

.feedback {
  width: 100%;
  border-radius: 5px;
}

.feedback-title {
  font-family: $inter-regular;
  font-size: calculateRem(12px);
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.6rem;
}

.cardShoot {
  $border: 2px solid var(--separator-color);

  &__body {
    border-bottom: $border;
    border-top: $border;
    flex: 1;
    min-height: 389px;
    padding: 24.5px 0;
    position: relative;
    width: 100%;
  }

  &__close {
    cursor: pointer;
    margin-left: auto;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 19.5px;
    position: relative;
    z-index: 150;
  }

  &__rebook {
    align-items: center;
    cursor: pointer;
    display: flex;
    left: 0;
    position: absolute;

    &:hover {
      > p {
        visibility: visible;
      }
    }

    > p {
      visibility: hidden;
      background: #ffedb7;
      border-radius: 10px;
      font-size: 10px;
      margin-bottom: 0;
      margin-left: 10px;
      padding: 0px 5px;
    }

    &-icons {
      background-color: var(--main-btn-color);
      border-radius: 50%;
      height: 34px;
      position: relative;
      width: 34px;
    }
  }

  &__go-back {
    display: none;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    z-index: 5;
    background-color: white;
  }

  &__shoot-id,
  &__shoot-headerInfo {
    @include inter-font($size: 12px, $bolded: false, $color: var(--main-text-color));
    padding-right: 10px;
  }
  &__shoot-headerInfo {
    border-left: 2px solid var(--light-grey-hr);
    padding-left: 10px;
  }
}

@include responsive($max: md) {
  $header-height: 44px;
  $footer-height: 76px;

  .cardShoot {
    $border: 2px solid var(--separator-color);

    &__body {
      border-top: unset;
      min-height: unset;
      overflow: scroll;
      padding-top: 0;
      padding: unset;
    }

    &__close {
      display: none;
    }

    &__footer {
      height: $footer-height;
      padding-top: 13.5px;
    }

    &__go-back {
      display: block;
      @include inter-font($size: 14px, $color: var(--main-text-color));
      margin-right: auto;
      padding: 0 15px;
    }
    &__header {
      justify-content: flex-start;
    }
    &__rebook {
      position: relative;
      > p {
        margin-left: 40px;
        width: 50px;
      }
      &-icons {
        height: 30px;
        position: absolute;
        width: 30px;
        &-plus {
          height: 10px !important;
          width: 10px !important;
        }
        &-rotate {
          height: 18px !important;
          width: 18px !important;
        }
      }
    }
    &__rebook {
      position: relative;
      > p {
        margin-left: 40px;
        width: 50px;
      }
      &-icons {
        height: 30px;
        position: absolute;
        width: 30px;
        &-plus {
          height: 10px !important;
          width: 10px !important;
        }
        &-rotate {
          height: 18px !important;
          width: 18px !important;
        }
      }
    }

    &__header {
      justify-content: flex-start;
    }
  }
}
</style>
