<template>
  <q-list class="userDropdown" v-if="!isLoading">
    <template v-for="(element, index) in dropDownElements">
      <q-item v-if="!element.isDisabled" :key="index" class="userDropdown__box">
        <q-item-section class="userDropdown__item" @click="handleAction(element.action)" :clickable="false">
          <BaseIcon
            v-if="element.icon"
            :custom_style="{ display: 'inline-block', fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px', marginRight: '10px' }"
            :icon="element.icon"
          />
          <span class="element-name">{{ element.name }}</span>
        </q-item-section>

        <BaseTooltip v-if="element.isTooltipEnabled === true" :tooltip="tooltip" />
      </q-item>
    </template>
  </q-list>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseIcon from '@base/BaseIcon.vue';
import BaseTooltip from '@base/BaseTooltip.vue';

/**
 * Component used to handle the user dropdown content
 *
 * @displayName UserDropdown
 */
export default {
  name: 'ActionsDropdown',
  components: {
    BaseIcon,
    BaseTooltip
  },
  props: {
    downloadProcessedPhotos: Function,
    downloadRawPhotos: Function,
    handleEditShoot: Function,
    handleCancelShoot: Function,
    handleDeclineShoot: Function,
    handleAcceptShoot: Function,
    handleAssignPhotographer: Function,
    handleTriggerRawsPreview: Function,
    previewRawImages: Function,
    selectedPhotographer: Object,
    isDownloadDisabled: Boolean,
    actionsComponent: Array,
    assignLabel: String,
    shoot: Object,
    isLoading: Boolean
  },
  data() {
    return {
      tooltip: this.$t('COMING_SOON'),
      disabled: false
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isEditor: 'user/isEditor',
      isClient: 'user/isClient',
      isPhotographer: 'user/isPhotographer',
      isSubClient: 'user/isSubClient',
      user: 'user/getUser'
    }),
    dropDownElements() {
      return [
        {
          name: this.$t('Download content'),
          action: 'DOWNLOAD_CONTENT',
          icon: 'image',
          isDisabled: !this.actionsComponent.includes('DOWNLOAD') || (this.isDownloadDisabled && this.isClient)
        },
        {
          name: this.$t('Download RAWs'),
          action: 'DOWNLOAD_RAWS',
          icon: 'camera',
          isDisabled: !this.actionsComponent.includes('DOWNLOAD_RAWS') || this.isDownloadDisabled
        },

        {
          name: this.$t('Preview RAWs'),
          action: 'PREVIEW_RAWS',
          icon: 'image',
          isDisabled: !this.actionsComponent.includes('PREVIEW_RAWS') || !this.shoot.raws_preview
        },
        {
          name: this.isClient ? this.$t('Edit request') : this.$t('Edit shoot'),
          action: 'EDIT_SHOOT',
          icon: 'pencil',
          isDisabled: !this.actionsComponent.includes('EDIT')
        },
        {
          name: this.isClient ? this.$t('Cancellation request') : this.$t('Cancel shoot'),
          action: 'CANCEL_SHOOT',
          icon: 'trash',
          isDisabled: !this.actionsComponent.includes('CANCEL')
        },
        {
          name: this.$t(this.assignLabel),
          action: 'ASSIGN_PH',
          icon: 'camera',
          isDisabled: !this.actionsComponent.includes('ASSIGN_PH') || !this.selectedPhotographer
        },
        {
          name: this.isAdmin ? this.$t('Reassign photographer') : this.$t('Decline shoot'),
          action: 'DECLINE_SHOOT',
          icon: this.isAdmin ? 'camera' : 'close',
          isDisabled: !this.actionsComponent.includes('DECLINE')
        },
        {
          name: this.$t('Accept shoot'),
          action: 'ACCEPT_SHOOT',
          icon: 'check',
          isDisabled: !this.actionsComponent.includes('ACCEPT')
        },
        {
          name: this.shoot.raws_preview ? this.$t('Disable RAWs preview') : this.$t('Enable RAWs preview'),
          action: 'TRIGGER_RAWS_PREVIEW',
          icon: this.shoot.raws_preview ? 'lock' : 'unlock',
          isDisabled: !this.actionsComponent.includes('TRIGGER_RAWS_PREVIEW')
        }
      ];
    }
  },
  methods: {
    /**
     * Method used to perform a specific action based on the link clicked from the dropdown
     */
    handleAction(action) {
      switch (action) {
        case 'DOWNLOAD_CONTENT':
          this.downloadProcessedPhotos();
          break;
        case 'DOWNLOAD_RAWS':
          this.downloadRawPhotos();
          break;
        case 'PREVIEW_RAWS':
          this.previewRawImages();
          break;
        case 'EDIT_SHOOT':
          this.handleEditShoot('edit');
          break;
        case 'CANCEL_SHOOT':
          this.handleCancelShoot('cancel');
          break;
        case 'ASSIGN_PH':
          this.handleAssignPhotographer();
          break;
        case 'ACCEPT_SHOOT':
          this.handleAcceptShoot();
          break;
        case 'DECLINE_SHOOT':
          this.handleDeclineShoot();
          break;
        case 'TRIGGER_RAWS_PREVIEW':
          this.handleTriggerRawsPreview();
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.addBorders {
  border-radius: 10px;
}

.userDropdown {
  * {
    box-sizing: border-box !important;
  }
  cursor: pointer;

  @include inter-font($size: 14px);
  background: #fff;
  border: 1px solid #979797;
  border-radius: 10px;

  &__box {
    align-items: flex-end;
    flex-direction: column;
  }
  &__box:hover {
    background-color: var(--main-btn-color);
    font-weight: 600;
  }

  .q-item {
    min-height: auto;
    /* margin: 8px 0px; */
    margin: 0px !important;
    position: relative;
  }

  &__item {
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 8px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
.userDropdown__box-disabled {
  opacity: 0.5; /* You can adjust the visual appearance of disabled items */
  pointer-events: none; /* This prevents the disabled item from receiving click events */
}
</style>
