import { render, staticRenderFns } from "./BusinessBook.vue?vue&type=template&id=50d80555&scoped=true&"
import script from "./BusinessBook.vue?vue&type=script&lang=js&"
export * from "./BusinessBook.vue?vue&type=script&lang=js&"
import style0 from "./BusinessBook.vue?vue&type=style&index=0&id=50d80555&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d80555",
  null
  
)

export default component.exports