import { render, staticRenderFns } from "./EditorShoots.vue?vue&type=template&id=01882790&scoped=true&"
import script from "./EditorShoots.vue?vue&type=script&lang=js&"
export * from "./EditorShoots.vue?vue&type=script&lang=js&"
import style0 from "./EditorShoots.vue?vue&type=style&index=0&id=01882790&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01882790",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QBtnToggle,QInput,QDate,QSelect,QPagination});
