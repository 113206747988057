import { render, staticRenderFns } from "./BaseAlert.vue?vue&type=template&id=5d951515&scoped=true&"
import script from "./BaseAlert.vue?vue&type=script&lang=js&"
export * from "./BaseAlert.vue?vue&type=script&lang=js&"
import style0 from "./BaseAlert.vue?vue&type=style&index=0&id=5d951515&lang=scss&scoped=true&"
import style1 from "./BaseAlert.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d951515",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard});
