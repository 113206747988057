<template>
  <div class="pt-40">
    <section class="align-middle mb-30">
      <div class="userReportLayout__switches">
        <div
          v-for="(item, index) of views"
          :key="`${index}_switch`"
          data-type="view"
          :class="['userReportLayout__switch', { 'userReportLayout__switch--is-active': currentView === item }]"
          @click="currentView = item"
        >
          {{ item }}
        </div>
        <div data-type="percentage" :class="{ 'userReportLayout__percentage--is-active': isPercentageShown }" @click="isPercentageShown = !isPercentageShown">%</div>
      </div>
      <BaseInput v-model="idName" class="userReportLayout__search pa-0 mr-10" :label="$t('SEARCH_BY_ID_NAME')" />
      <BaseCalendarInput v-model="dates" range />
      <BaseButton
        class="ml-10"
        icon="download"
        type="default"
        :custom_style="{ height: '44px', width: '44px' }"
        :icon_style="{ 'min-height': '17px', 'min-width': '17px' }"
        @click.native="downloadCsv"
      />
    </section>
    <div class="align-middle ph-10 pv-22">
      <div :class="['userReportLayout__field', { 'userReportLayout__field--asc': isAscSorted(field) }]" v-for="(field, index) of fields" :key="index" @click="sortField(field)">
        {{ field.label }}
        <BaseIcon v-if="isFieldSorted(field)" class="ml-10" icon="arrow-filter" fill="#8F8F8F" :custom_style="{ height: '7px', 'min-height': '7px', 'min-width': '10px', width: '10px' }" />
      </div>
    </div>
    <hr data-theme="medium" class="hr mb-12 mt-0" />
    <BaseTable hide-button infinite-scroll-disabled type="borderless" :items="items" :items-fields="fields.map(({ value }) => value)" :isClient="type === 'client'">
      <template #id="{ item }"
        ><p class="userReportLayout__data">{{ item.user_photographer_id || item.user_client_id }}</p></template
      >
      <template #name="{ item }"
        ><p class="userReportLayout__data">{{ item.user_name }}</p></template
      >
      <template #created="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'created', item }) }}</p></template
      >
      <template #confirmed="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'confirmed', item }) }}</p></template
      >

      <template #declined="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'declined', item }) }}</p></template
      >

      <template #canceled="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'canceled', item }) }}</p></template
      >

      <template #uploaded="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'uploaded', item }) }}</p></template
      >

      <template #completed="{ item }"
        ><p class="userReportLayout__data">{{ itemData({ field: 'completed', item }) }}</p></template
      >
      <template #sub-client="{ item }">
        <div
          class="baseTable__shoot userReportLayout__subclient"
          v-for="(itm, index) in item.subclients"
          :class="{ userReportLayout__lastclient: index == item.subclients.length - 1 }"
          :key="`field_${index}`"
        >
          <template v-for="(field, id) in fields.map(({ value }) => value)">
            <div v-if="['id', 'name'].includes(field)" class="baseTable__shoot-slot" :key="`field_id_${id}`">
              <p class="userReportLayout__data">{{ field === 'id' ? itm.target_client_user_id : '' }}</p>
            </div>
            <div v-else-if="typeof field === 'string'" class="baseTable__shoot-slot" :key="`field_name_${id}`">
              <p class="userReportLayout__data">{{ itemData({ field, item: itm }) }}</p>
            </div>
          </template>
        </div>
      </template>
    </BaseTable>
  </div>
</template>

<script>
// Api
import { ReportApi } from '@api/index';

// Base components
import BaseButton from '@base/BaseButton.vue';
import BaseCalendarInput from '@base/BaseCalendarInput.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseTable from '@base/BaseTable.vue';

import helpers from '@utils/helpers';

/**
 * Component used to create the layout of the user report
 */
export default {
  name: 'UserReportLayout',
  components: {
    BaseButton,
    BaseCalendarInput,
    BaseIcon,
    BaseInput,
    BaseTable
  },
  props: {
    /**
     * Type of user
     */
    type: { default: '', type: String }
  },
  data() {
    return {
      currentView: 'operations',
      dates: {},
      idName: '',
      isPercentageShown: false,
      items: [],
      nameTimeout: null,
      payload: {
        filters: {
          time: {}
        },
        pagination: {},
        sorting: {}
      },
      views: ['operations', 'revenues']
    };
  },
  computed: {
    fields() {
      if (this.type === 'photographer')
        return [
          { label: 'Photographer ID', value: 'id' },
          { label: 'Name', value: 'name' },
          { label: 'Total confirmed', value: 'confirmed', sortField: () => (!this.isRevenuesView && 'confirmed_count') || 'confirmed_revenue' },
          { label: 'Total declined', value: 'declined', sortField: () => (!this.isRevenuesView && 'declined_count') || 'declined_revenue' },
          { label: 'Total canceled', value: 'canceled', sortField: () => (!this.isRevenuesView && 'canceled_count') || 'canceled_revenue' },
          { label: 'Total uploaded', value: 'uploaded', sortField: () => (!this.isRevenuesView && 'uploaded_count') || 'uploaded_revenue' },
          { label: 'Total complete', value: 'completed', sortField: () => (!this.isRevenuesView && 'completed_count') || 'completed_revenue' }
        ];

      if (this.type === 'client')
        return [
          { label: 'Client ID', value: 'id' },
          { label: 'Name', value: 'name' },
          { label: 'Total created', value: 'created', sortField: () => (!this.isRevenuesView && 'created_count') || 'created_revenue' },
          { label: 'Total canceled', value: 'canceled', sortField: () => (!this.isRevenuesView && 'canceled_count') || 'canceled_revenue' },
          { label: 'Total complete', value: 'completed', sortField: () => (!this.isRevenuesView && 'completed_count') || 'completed_revenue' }
        ];

      return [];
    },
    isRevenuesView() {
      return this.currentView === 'revenues';
    }
  },
  watch: {
    /**
     * Updating payload name/id filter when user types something in the input field
     */
    idName() {
      clearTimeout(this.nameTimeout);

      this.nameTimeout = setTimeout(() => {
        this.$set(this.payload.filters, 'name', this.idName || undefined);
      }, 1000);
    },
    /**
     * Updating payload datetime selection when user types something
     */
    dates() {
      this.$set(this.payload.filters, 'time', this.dates);
    },
    /**
     * Everytime the payload changes, trigger api call
     */
    payload: {
      deep: true,
      handler: function () {
        this.retrieveReport();
      }
    }
  },
  created() {
    this.$set(this.payload.filters, 'userType', this.type);

    // Retrieving current week range (mon-sun dates) to inizialize the dates of the date picker
    const { weekFirstDay, weekLastDay } = helpers.getLastWeekRange();

    this.$set(this.payload.filters.time, 'from', weekFirstDay);
    this.$set(this.payload.filters.time, 'to', weekLastDay);
    this.$set(this.dates, 'from', weekFirstDay);
    this.$set(this.dates, 'to', weekLastDay);

    this.retrieveReport();
  },
  methods: {
    /**
     * Downloading csv file with information in the selected time range
     */
    async downloadCsv() {
      await ReportApi.downloadReport({ ...this.dates, type: this.type });
    },
    /**
     * Returns true if field is sorted ascending
     */
    isAscSorted(field) {
      return this.isFieldSorted(field) && this.payload.sorting.direction === 'asc';
    },
    /**
     * Check if the field is sorted (asc/desc)
     */
    isFieldSorted(_field) {
      const { sorting = {} } = this.payload;
      const { field } = sorting;

      return _field.sortField && field === _field.sortField();
    },
    /**
     * Return total counter for a given user
     */
    itemTotal(item) {
      const keys = Object.keys(item);
      let tot = 0;
      const suffix = this.isRevenuesView ? '_revenue' : '_count';

      for (const key of keys) {
        if (key.indexOf(suffix) !== -1) tot += item[key] ? item[key] : 0;
      }

      return tot !== 0 ? tot : 1;
    },
    /**
     * Method that returns the item data
     */
    itemData({ field, item }) {
      const suffix = this.isRevenuesView ? '_revenue' : '_count';
      let count = item[`${field}${suffix}`];

      if (count == null) count = 0;

      if (this.isPercentageShown) {
        return `${(count / this.itemTotal(item)) * 100}%`;
      }

      return count;
    },
    /**
     * Method used to retrieve users report
     */
    async retrieveReport() {
      const userReport = await ReportApi.usersReport(this.payload);

      if (userReport.statusCode === 200) {
        this.items = userReport.data.reports;
      }
    },
    /**
     * Updating payload sort
     */
    sortField(field) {
      const isFieldSorted = this.isFieldSorted(field);
      const { sorting = {} } = this.payload;
      const { direction = 'asc' } = sorting;

      if (isFieldSorted) {
        this.$set(this.payload.sorting, 'direction', direction === 'asc' ? 'desc' : 'asc');
        return;
      }

      this.$set(this.payload, 'sorting', { field: field.sortField(), direction: 'asc' });
    }
  }
};
</script>

<style lang="scss">
.userReportLayout {
  &__data {
    @include inter-font($size: 12px);
    flex: 1;
    margin: 0;
  }

  &__field {
    @include inter-font($size: 12px, $color: var(--secondary-text-color));
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0 10px;
    text-align: left;

    &--asc {
      .baseIcon {
        transform: rotate(180deg);
      }
    }
  }

  &__percentage--is-active {
    background-color: var(--main-text-color) !important;
    color: var(--terziary-text-color);
  }

  &__search {
    .q-field__label {
      font-size: calculateRem(10px);
    }
  }

  &__switch {
    &--is-active {
      background-color: var(--view-bg-active) !important;
    }
  }

  &__switches {
    align-items: center;
    display: flex;
    margin-right: auto;

    > div {
      cursor: pointer;

      &[data-type='percentage'] {
        align-items: center;
        background-color: var(--secondary-bg-color);
        border: 1px solid var(--view-bg-active);
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-left: 15px;
        width: 30px;
      }

      &[data-type='view'] {
        @include inter-font($size: 10px, $color: var(--main-text-color), $bolded: true);
        background-color: var(--secondary-bg-color);
        border: 1px solid var(--view-bg-active);
        cursor: pointer;
        height: 30px;
        padding: 8px;
        text-transform: uppercase;

        &:first-child {
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }

        &:nth-child(2) {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }

  &__subclient {
    background: #dddddd;
    border-radius: unset;
    margin-bottom: 0;
  }

  &__lastclient {
    border-radius: 0 0 10px 10px;
    margin-bottom: 10px;
  }
}
</style>
