<template>
  <div>
    <component :is="orders" :photographer_id="null" />
    <BaseAlert :show="successModalOpen" icon="check-circle" :title="alert.header" @close-alert="closeAlert"><div v-html="$t(alert.primaryText)"></div></BaseAlert>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Components
import BaseAlert from '@components/base/BaseAlert.vue';
import Orders from '@views/admin/Orders.vue';
import BusinessShoots from '@views/client/BusinessShoots.vue';
import PhotographerShoots from '@views/photographer/PhotographerShoots.vue';
import EditorShoots from '@views/editor/EditorShoots.vue';

/**
 * Component used to build the Orders for users.
 *
 * @dispalyName Orders
 */
export default {
  name: 'Orders',
  components: {
    BaseAlert
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      successModalOpen: 'shoot/getIsSuccessAlertOpen',
      alert: 'alert/getAlert'
    }),
    /**
     * Computed used to retrieve the orders based on the user role
     */
    orders() {
      if (this.isAdmin) return Orders;

      if (this.isClient) return BusinessShoots;

      if (this.isEditor) return EditorShoots;

      if (this.isPhotographer) return PhotographerShoots;

      return null;
    }
  },
  methods: {
    ...mapActions({
      closeAlert: 'shoot/handleCloseSuccessAlert'
    })
  }
};
</script>
