<template>
  <div class="cardShootInfo">
    <div class="cardShootInfo__icon">
      <BaseIcon fill="transparent" :icon="icon" stroke="#151515" :custom_style="{ height: '13px', 'stroke-width': '3px', 'margin-top': '4px', width: '13px' }" />
    </div>
    <span>
      <p class="cardShootInfo__title">{{ title || '-' }}</p>
      <template v-if="subtitles.length > 0">
        <p v-for="(subtitle, index) in subtitles" :key="index" class="cardShootInfo__subtitle">{{ subtitle || '-' }}</p>
      </template>
      <span v-if="action" class="cardShootInfo__action" @click="getRoute">{{ action }}</span>
    </span>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseIcon from '@base/BaseIcon.vue';

export default {
  name: 'CardShootInfo',
  components: {
    BaseIcon
  },
  props: {
    /**
     * Box info action label
     */
    action: { default: '', type: String },
    /**
     * Box info icon
     */
    icon: { default: '', type: String },
    shootId: { default: null, type: Number },
    /**
     * Box info subtitle
     */

    subtitles: { default: () => [], type: Array },
    /**
     * Box info title
     */
    title: { default: '', type: String }
  },

  /**
   * Computed used to retrieve the user role
   */
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isPhotographer: 'user/isPhotographer'
    })
  },

  methods: {
    getRoute() {
      if (this.isAdmin) return this.$router.push({ name: 'orders', query: { view: 'calendar', id: this.shootId } });

      if (this.isClient || this.isPhotographer) return this.$router.push({ name: 'orders', query: { view: 'calendar', id: this.shootId } });
    }
  }
};
</script>

<style lang="scss" scoped>
.cardShootInfo {
  display: flex;

  span {
    margin-left: 13px;
  }

  &__title,
  &__subtitle,
  &__action {
    margin: 0;
  }

  &__action {
    cursor: pointer;
    @include inter-font($size: 12px, $color: var(--main-text-color));
    font-style: italic;
    margin-left: 0 !important;
    text-decoration: underline;
  }

  &__title {
    font-weight: bold;
  }

  &__title,
  &__subtitle {
    @include inter-font($size: 14px, $color: var(--main-text-color));
    text-align: left;
  }

  &__icon {
    display: flex;
  }
}

@include responsive($max: tablet) {
  .cardShootInfo {
    span {
      margin-left: 8px;
    }

    &__action {
      font-size: calculateRem(10px);
    }

    &__title,
    &__subtitle {
      font-size: calculateRem(12px);
    }
  }
}
</style>
