<template>
  <vue-numeric-input
    class="number_input"
    controls-type="plusminus"
    :disabled="disabled"
    :max="maxi"
    :min="min"
    size="normal"
    :step="step"
    :value="value"
    @input="$emit('update:value', $event)"
  ></vue-numeric-input>
</template>
<script>
import VueNumericInput from 'vue-numeric-input';

export default {
  name: 'BaseNumberInput',
  model: {
    event: 'update:value',
    prop: 'value'
  },
  components: {
    VueNumericInput
  },
  props: {
    /**
     * Set to true to disable the input
     */
    disabled: { default: false, type: Boolean },
    /**
     * Set the max of the numeric input
     */
    maxi: { default: Infinity, type: Number },
    /**
     * Set the min of the numeric input
     */
    min: { default: 0, type: Number },
    /**
     * Set the step of the numeric input
     */
    step: { default: 1, type: Number },
    /**
     * Set the value of the numeric input
     */
    value: { default: 0, type: [Number, String] }
  },
  data() {
    return {
      isError: false
    };
  }
};
</script>

<style lang="scss">
.number_input {
  border: 1px solid var(--secondary-text-color);
  border-radius: 10px;
  height: 45px !important;
  text-align: center;
  transition: all 0.5s linear;
  input {
    text-align: center !important;
    border: 0 !important;
    height: 100%;
    background: none;
  }

  .btn-increment,
  .btn-decrement {
    background-color: var(--box-bg-color);
    border-radius: 4px;
    box-shadow: unset;
    height: 18px !important;
    margin: auto 0;
    width: 18px !important;

    &:hover {
      background: var(--box-bg-color);
    }
  }
  .btn-increment {
    margin-right: 14px;
    .btn-icon::before {
      background-image: url('~@/assets/svg/plus_white.svg');
      background-position: 55% 55%;
    }
  }
  .btn-decrement {
    margin-left: 14px;
    .btn-icon::before {
      background-image: url('~@/assets/svg/minus_white.svg');
      background-position: 55% 55%;
    }
  }
}
</style>
