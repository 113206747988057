<template>
  <div class="downloads-manager">
    <div v-if="hasActiveDownloads" class="downloads-wrapper" :class="{ 'is-collapsed': !isExpanded }">
      <div class="downloads-header">
        <h3 class="downloads-title">Downloads</h3>
        <div class="header-actions">
          <span v-if="activeDownloadsCount" class="active-count">{{ activeDownloadsCount }} active</span>
          <q-btn flat round dense :icon="isExpanded ? 'expand_more' : 'expand_less'" @click="toggleExpanded" size="18px" />
        </div>
      </div>

      <transition-group name="download-list" tag="div" class="downloads-list" v-show="isExpanded">
        <div v-for="download in sortedDownloads" :key="download.id" class="download-item" :class="[download.status, { 'is-active': ['pending', 'processing'].includes(download.status) }]">
          <!-- Active Download Layout (Pending/Processing) -->
          <template v-if="['pending', 'processing'].includes(download.status)">
            <div class="download-header active">
              <q-icon :name="getStatusIcon(download.status)" class="status-icon" :class="{ rotating: download.status === 'processing' }" />
              <div class="download-content">
                <span class="download-name">{{ download.shootName }}</span>
                <span class="download-message">{{ download.message || getDefaultMessage(download.status) }}</span>
                <div class="progress-section">
                  <q-linear-progress :value="download.progress / 100" class="progress-bar" rounded size="xs" :indeterminate="download.status === 'pending'" />
                  <span v-if="download.status === 'processing'" class="progress-text"> {{ download.progress }}% </span>
                </div>
              </div>
            </div>
          </template>

          <!-- Completed Download Layout -->
          <template v-else>
            <div class="download-header completed">
              <div class="download-content">
                <span class="download-name">{{ download.shootName }}</span>
                <span class="download-message">{{ download.message || getDefaultMessage(download.status) }}</span>
              </div>
              <div class="download-actions">
                <template v-if="download.downloadUrl">
                  <q-btn flat dense icon="download" size="sm" @click="getFile(download)">
                    <q-tooltip>Download</q-tooltip>
                  </q-btn>
                  <q-btn flat dense icon="content_copy" size="sm" @click="copyToClipboard(download.downloadUrl)">
                    <q-tooltip>Copy Link</q-tooltip>
                  </q-btn>
                </template>
                <q-btn v-if="canRemoveDownload(download)" flat dense icon="close" size="sm" @click="removeDownload(download.id)">
                  <q-tooltip>Remove</q-tooltip>
                </q-btn>
              </div>
            </div>
          </template>

          <!-- Error Message -->
          <div v-if="download.error" class="error-message">
            {{ download.error }}
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';

export default {
  name: 'DownloadManager',
  props: {
    updateStatusMutation: {
      required: true
    },
    userRole: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const isExpanded = ref(true);

    // Computed properties using root.$store
    const hasActiveDownloads = computed(() => root.$store.getters['downloads/hasActiveDownloads']);
    const activeDownloadsCount = computed(() => root.$store.getters['downloads/getActiveDownloads'].length);
    const downloads = computed(() => root.$store.getters['downloads/getAllDownloads']);

    const sortedDownloads = computed(() => {
      return [...downloads.value].sort((a, b) => {
        const statusPriority = {
          processing: 0,
          pending: 1,
          completed: 2,
          error: 3
        };
        if (statusPriority[a.status] !== statusPriority[b.status]) {
          return statusPriority[a.status] - statusPriority[b.status];
        }
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    });

    const getStatusIcon = status => {
      const icons = {
        pending: 'hourglass_empty',
        processing: 'sync',
        completed: 'check_circle',
        error: 'error'
      };
      return icons[status] || 'help';
    };

    const getDefaultMessage = status => {
      const messages = {
        pending: 'Waiting in queue...',
        processing: 'Preparing download...',
        completed: 'Download ready',
        error: 'Download failed'
      };
      return messages[status] || 'Unknown status';
    };

    const canRemoveDownload = download => {
      return ['completed', 'error'].includes(download.status);
    };

    const getFile = download => {
      console.log('calling downloadfile with url', download.downloadUrl);
      try {
        window.open(download.downloadUrl, '_blank');
      } catch (error) {
        console.error('Download failed:', error);
        root.$store.dispatch('notification/addFailureNotification', 'Failed to download file');
      }
    };

    // Add this watch to track download status changes
    watch(downloads, async (newDownloads, oldDownloads) => {
      if (!oldDownloads.length) return;

      for (const download of newDownloads) {
        const oldDownload = oldDownloads.find(d => d.id === download.id);

        // Check if status just changed to completed and has downloadUrl
        if (oldDownload && oldDownload.status !== 'completed' && download.status === 'completed' && download.downloadUrl) {
          console.log('Download completed, auto-starting download:', download.shootName);
          getFile(download);

          // Update shoot status if user is client and mutation is provided
          if (props.userRole === 'client' && props.updateStatusMutation) {
            try {
              const statusDetails = { id: download.shootId, payload: { targetStatus: 'completed' } };
              props.updateStatusMutation.mutate(statusDetails);
            } catch (error) {
              console.error('Failed to update shoot status:', error);
              root.$store.dispatch('notification/addFailureNotification', 'Failed to update shoot status');
            }
          }
        }
      }
    });

    const copyToClipboard = async url => {
      try {
        await navigator.clipboard.writeText(url);
        root.$store.dispatch('notification/addSuccessNotification', 'Download link copied to clipboard');
      } catch (error) {
        root.$store.dispatch('notification/addFailureNotification', 'Failed to copy download link');
      }
    };

    const removeDownload = downloadId => {
      root.$store.dispatch('downloads/removeDownload', downloadId);
    };

    const toggleExpanded = () => {
      isExpanded.value = !isExpanded.value;
    };

    return {
      isExpanded,
      hasActiveDownloads,
      activeDownloadsCount,
      sortedDownloads,
      getStatusIcon,
      getDefaultMessage,
      canRemoveDownload,
      getFile,
      copyToClipboard,
      removeDownload,
      toggleExpanded
    };
  }
};
</script>

<style scoped>
.downloads-manager {
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 1000;
  width: 360px;
}

.downloads-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.downloads-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  height: 64px;
}

.downloads-title {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
  color: #1a1a1a;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.active-count {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.5);
}

.downloads-list {
  overflow-y: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 400px;
}

.download-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s ease;
}

.download-item:hover {
  background: #f3f4f6;
}

.download-item.error {
  background: #fff5f5;
  border: 1px solid rgba(220, 53, 69, 0.1);
}

.download-header {
  display: flex;
  gap: 12px;
}

.download-header.active {
  align-items: flex-start;
}

.download-header.completed {
  justify-content: space-between;
  align-items: center;
}

.status-icon {
  margin-top: 4px;
  font-size: 1.2em;
  color: #666;
}

.status-icon.rotating {
  animation: rotate 1.5s linear infinite;
}

.download-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.download-name {
  font-weight: 500;
  color: #1a1a1a;
  font-size: 0.95em;
}

.download-message {
  font-size: 0.85em;
  color: rgba(0, 0, 0, 0.6);
}

.download-actions {
  display: flex;
  gap: 4px;
}

.progress-section {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.progress-bar {
  flex: 1;
}

.progress-text {
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  min-width: 40px;
  text-align: right;
}

.error-message {
  margin-top: 8px;
  font-size: 0.85em;
  color: #dc3545;
  padding: 8px;
  background: rgba(220, 53, 69, 0.05);
  border-radius: 4px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Transitions */
.download-list-enter-active,
.download-list-leave-active {
  transition: all 0.3s ease;
}

.download-list-enter,
.download-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
