<template>
  <section ref="scrollTargetRef" class="packagesView">
    <!-- Loading overlay -->
    <div v-if="isLoading || isRefetching" class="packagesView__overlay">
      <q-spinner-facebook color="primary" size="100px" />
    </div>

    <template v-if="pkgs.length === 0">
      <p class="packagesView__no-shoots">{{ $t('NO_SHOOTS') }}</p>
    </template>
    <template v-else>
      <div class="packagesView__packages">
        <PackageCard v-for="pkg in pkgs" :key="pkg.id" :pkg="pkg" @click.native="handlePackageSelection(pkg.id)" />
      </div>
    </template>
    <hr class="packagesView__hr" />
    <PackagesCarousel :items="pkgs" @pkg-id="handlePackageSelection($event)" />
  </section>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Components
import PackageCard from '@/components/PackageCard.vue';
import PackagesCarousel from '@/components/PackagesCarousel.vue';

/**
 * This component will handle the card packages view toggling from grid to slider
 *
 * @displayName PackagesView
 */
export default {
  name: 'PackagesView',
  components: {
    PackageCard,
    PackagesCarousel
  },
  props: {
    /**
     * List of packages
     */
    pkgs: { default: () => [], type: Array },
    isRefetching: Boolean,
    isLoading: Boolean
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isSubClient: 'user/isSubClient',
      isPhotographer: 'user/isPhotographer'
    })
  },
  mounted() {},
  methods: {
    ...mapActions({
      setSelectedPackage: 'shoot/saveOpenedShoot'
    }),
    handlePackageSelection(id) {
      this.setSelectedPackage(id);
      this.$emit('open-pkg');
    }
  }
};
</script>

<style lang="scss" scoped>
.packagesView__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white overlay */
  z-index: 10; /* Ensure it covers other content */
}
.packagesView {
  display: flex;
  flex-direction: column; /* Ensures that the contents are laid out vertically */
  height: calc(100vh - 230px); /* Adjust 120px to the combined height of other components */
  position: relative;
  overflow-y: scroll;

  &__packages {
    $gap: 8px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    column-gap: 12px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    row-gap: 12px;
  }

  &__hr {
    margin-left: 0;
    width: 93%;
    background-color: var(--grey-hr);
    border: unset;
    height: 1px;
  }

  &__section-title {
    position: relative;
    text-align: left;

    &[data-type='to_pay'] {
      color: var(--to-pay-color);
    }

    &[data-type='payed'] {
      color: var(--payed-color);
    }

    &::after {
      content: '';
      background-color: currentColor;
      display: flex;
      height: 1px;
      position: absolute;
      top: 50%;
      width: 100%;
    }

    &::before {
      background-color: var(--main-bg-color);
      content: attr(data-title);
      padding-right: 10px;
      position: relative;
      z-index: 1;
    }
  }
}

*::-webkit-scrollbar-thumb {
  background: var(--terziary-btn-color) !important;
  &:hover {
    background: var(--terziary-btn-color) !important;
  }
}

@include responsive($max: mobileL) {
  .packagesView {
    &__packages {
      display: none;
    }
  }
}
</style>
