<template>
  <section class="full-height">
    <EditPackage v-if="showEditPackage" :service="selectedPackage" :servicesArr="servicesArr" @packages:refresh="retrievePackages" @go-back="handleGoBack" />
    <PageLayout v-if="!showEditPackage" :sections="sections" :class="{ 'packages__pageLayout ': showMoveCategory || showEditCategory }">
      <template #categories>
        <div class="packages__categories">
          <template v-if="!showMoveCategory && !showEditCategory">
            <div class="packages__add-category" @click="showEditCategory = true">
              <BaseIcon :custom_style="{ height: '24px', width: '24px' }" stroke="#fff" icon="plus" />
              <p>{{ $t('ADD_NEW_CATEGORY') }}</p>
            </div>
            <CategoryCard v-for="(service, index) of servicesArr" :key="index" :service="service" class="packages__service">
              <CategoryHandler
                :category="service"
                :packages="packages"
                @category:edit="handleEditCategory"
                @category:move="handleCategoryMove"
                @category:selected="handleCategoryFilter"
                @refresh:services="retrieveServices"
              />
            </CategoryCard>
          </template>
          <MoveCategory v-else-if="showMoveCategory" :service="selectedCategory" @packages:refresh="retrievePackages" @go-back="handleGoBack" @refresh:services="retrieveServices" />
          <EditCategory v-else-if="showEditCategory" :service="selectedCategory" @packages:refresh="retrievePackages" @go-back="handleGoBack" @refresh:services="retrieveServices" />
        </div>
      </template>
      <template #filters>
        <div class="packages__filters row">
          <div class="packages__category-wrapper mr-35">
            <div class="packages__category-filter">
              <BaseIcon :custom_style="{ height: '18px', width: '16px' }" stroke="#fff" icon="tag" />
              <p>{{ $tc('CATEGORY', 1) | firstUppercase }}</p>
            </div>
          </div>
          <div class="row">
            <RangeFilter
              :disabled="showMoveCategory || showEditCategory"
              class="mt-13"
              icon="dollar-sign"
              :label="$t('PRICE_LABEL')"
              :range="filters.price"
              :range-value="priceRange"
              @range:updated="handlePriceFilter"
            />
            <SelectFilter
              :disabled="showMoveCategory || showEditCategory"
              class="mt-13"
              icon="clock"
              :label="$t('DURATION')"
              :options="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
              :selected-options="filters.duration"
              @selected:updated="handleDurationFilter"
            />
            <RangeFilter
              :disabled="showMoveCategory || showEditCategory"
              class="mt-13"
              icon="hashtag"
              :label="$t('QUANTITY')"
              :range="filters.quantity"
              :range-value="quantityRange"
              @range:updated="handleQuantityFilter"
            />
            <SelectFilter
              :disabled="showMoveCategory || showEditCategory"
              class="mt-13"
              icon="diamond_simple"
              :label="$t('TYPE')"
              :options="['Pro', 'Base']"
              :selected-options="filters.type"
              @selected:updated="handleTypeFilter"
            />
          </div>
          <p v-if="showClearFilters" class="packages__clear mt-18 mb-0" @click="filters = {}">{{ $t('CLEAR_ALL_FILTERS') }}</p>
        </div>
      </template>
      <template #packages>
        <div class="packages__packages">
          <div class="packages__new-package" @click="showEditPackage = true">
            <BaseIcon :custom_style="{ height: '24px', width: '24px' }" icon="plus" />
          </div>
          <PackageCard v-for="(_package, index) of packages" :key="index" :pkg="_package" @package:delete="handelPackageDelete" @package:update="handlePackageUpdate(_package)" />
        </div>
        <PackagesCarousel :items="packages" @handle-package-update="handlePackageUpdate($event)" @handle-package-delete="handlePackageDelete($event)" @show-edit-package="showEditPackage = true" />
      </template>
    </PageLayout>
  </section>
</template>

<script>
import { PackagesApi, ServiceApi } from '@api/index';

// Base components
import BaseIcon from '@base/BaseIcon.vue';

// Components
import CategoryCard from '@components/package/CategoryCard.vue';
import CategoryHandler from '@components/package/CategoryHandler.vue';
import EditCategory from '@components/package/EditCategory.vue';
import EditPackage from '@components/package/EditPackage.vue';
import MoveCategory from '@components/package/MoveCategory.vue';
import PackageCard from '@components/package/PackageCard.vue';
import PackagesCarousel from '@/components/package/PackagesCarousel.vue';
import PageLayout from '@components/layout/PageLayout.vue';

// Filters components
import RangeFilter from '@filters/RangeFilter.vue';
import SelectFilter from '@filters/SelectFilter.vue';

export default {
  name: 'Packages',
  components: {
    BaseIcon,
    CategoryCard,
    CategoryHandler,
    EditCategory,
    EditPackage,
    MoveCategory,
    PackageCard,
    PackagesCarousel,
    PageLayout,
    RangeFilter,
    SelectFilter
  },
  data() {
    return {
      allPackages: [],
      filters: {},
      movingCategory: {},
      selectedCategory: {},
      packages: [],
      payload: {
        sorting: {
          field: 'last_update',
          direction: 'asc'
        },
        filters: {}
      },
      sections: [
        {
          name: 'categories',
          style: {
            height: 'auto',
            'min-height': '136px'
          }
        },
        {
          name: 'filters',
          style: {
            height: 'auto',
            minHeight: '77px'
          }
        },
        {
          name: 'packages',
          style: {
            flex: 1,
            'max-height': '100%',
            'overflow-y': 'auto'
          }
        }
      ],
      selectedPackage: {},
      selectedServices: [],
      servicesArr: [],
      showEditCategory: false,
      showEditPackage: false,
      showMoveCategory: false,
      show_navigator: false,
      selectedPkgIndex: null
    };
  },
  computed: {
    /**
     * Returning the range of prices based on the packages
     */
    priceRange() {
      const prices = this.allPackages.map(({ price }) => price);

      return this.arrayMinMax(prices);
    },
    /**
     * Returning the range of quantity (of picture) based on the packages
     */
    quantityRange() {
      const quantity = this.allPackages.map(({ picture_number }) => picture_number);

      return this.arrayMinMax(quantity);
    },
    /**
     * Returns true if at least one filter is active. When true the action 'clear all filters' is shown
     */
    showClearFilters() {
      return Object.keys(this.filters).length > 0;
    }
  },
  watch: {
    /**
     * Watching filters variable to update the packages based on the filters values (price, service, ecc...)
     */
    filters: {
      deep: true,
      handler: function () {
        this.packages = this.allPackages.filter(_package => {
          const { duration = [], price = {}, quantity = {}, services = [], type = [] } = this.filters;

          const durationFilter = duration.length > 0 ? duration.includes(_package.duration) : true;
          const priceFilter = Object.keys(price).length > 0 ? _package.price <= price.max && _package.price >= price.min : true;
          const quantityFilter = Object.keys(quantity).length > 0 ? _package.picture_number <= quantity.max && _package.picture_number >= quantity.min : true;
          const serviceFilter = services.length > 0 ? services.includes(_package.service_id) : true;

          let typeFilter = true;
          if (type.length === 1) {
            typeFilter = (type.includes('Pro') && _package.is_plus) || (type.includes('Base') && !_package.is_plus);
          }

          return durationFilter && priceFilter && quantityFilter && serviceFilter && typeFilter;
        });
      }
    }
  },
  created() {
    this.retrievePackages();
    this.retrieveServices();
  },
  methods: {
    /**
     * Method used to filter the BE packages by category
     */
    handleCategoryFilter(category) {
      this.selectedServices = this.updateArray({ array: this.selectedServices, element: category, param: 'id' });
      const selectedServicesIds = this.selectedServices.map(({ id }) => id);

      this.$set(this.filters, 'services', selectedServicesIds);
    },
    /**
     * This method is used to show the move category view
     */
    handleCategoryMove(category) {
      this.selectedCategory = { ...category };
      this.showMoveCategory = true;
    },
    /**
     * Filtering packages based on duration
     */
    handleDurationFilter($event) {
      this.$set(this.filters, 'duration', $event);
    },
    /**
     * This method will reset the page default view
     */
    handleGoBack() {
      this.showEditCategory = false;
      this.showMoveCategory = false;
      this.showEditPackage = false;

      this.selectedPackage = {};
      this.selectedCategory = {};
    },
    /**
     * Removing the delete a package from the array of packages
     */
    handelPackageDelete(pack) {
      this.packages = this.updateArray({ array: this.packages, element: pack, param: 'id' });
      this.allPackages = this.updateArray({ array: this.allPackages, element: pack, param: 'id' });
    },
    /**
     * Method used to edit a selected package
     */
    handlePackageUpdate(_package) {
      this.selectedPackage = { ..._package };
      this.showEditPackage = true;
    },
    /**
     * Method used to filter the packages base on price range
     */
    handlePriceFilter($event) {
      this.$set(this.filters, 'price', $event);
    },
    /**
     * Method used to filter the packages base on number of pictures
     */
    handleQuantityFilter($event) {
      this.$set(this.filters, 'quantity', $event);
    },
    /**
     * Method used to handle the package type filter
     */
    handleTypeFilter($event) {
      this.$set(this.filters, 'type', $event);
    },
    /**
     * Method used to retrieve packages
     * at first set the filter and the selectedServices to empty;
     * than retrive packages
     */
    async retrievePackages() {
      this.resetFilters();
      const packages = await PackagesApi.search({ payload: this.payload });

      if (packages.statusCode === 200) {
        this.packages = packages.data.packages;
        /*
         * set the order of packages by the last update of the service.
         */
        this.orderLastFirst(this.packages);
        this.orderByPictureNumberAndPlus(this.packages);
        this.allPackages = this.packages;
      }
    },
    /**
     * This method will handle the edit of a category, showing the editor
     */
    handleEditCategory(category) {
      this.selectedCategory = { ...category };
      this.showEditCategory = true;
      this.filters = {};
    },
    /**
     * Method used to retrive the available services
     */
    async retrieveServices() {
      // Reset the default view
      this.handleGoBack();

      const services = await ServiceApi.search({
        sorting: {
          field: 'last_update',
          direction: 'desc'
        }
      });
      if (services.statusCode === 200) {
        this.servicesArr = services.data.services;
        /*
         * TODO
         * set the order of service by the last update of the service cause' sorting is not yet enabled.
         */
        this.servicesArr.sort(function (a, b) {
          var updateA = a.last_update;
          var updateB = b.last_update;
          if (updateA < updateB) return 1;
          if (updateA > updateB) return -1;
          return 0;
        });
        this.$store.dispatch('services/updateServices', this.servicesArr);
      }
    },
    handlePackages(index) {
      this.show_navigator = true;
      this.selectedPkgIndex = index;
    },
    /**
     * to set all filters empty
     */
    resetFilters() {
      this.filters = {};
      this.selectedServices = [];
      this.selectedPackage = {};
      this.selectedPkgIndex = null;
    }
  }
};
</script>

<style lang="scss">
.packages {
  $page-padding: 171px;
  $category-padding: 24px;
  $category-spacing: 20px;

  &__add-category {
    align-items: center;
    border: 1px dotted var(--terziary-text-color);
    border-radius: 10px;
    color: var(--terziary-text-color);
    cursor: pointer;
    display: flex;
    height: $category-height;
    justify-content: flex-start;
    margin-right: $category-spacing;
    min-width: $category-width;
    padding: 0 $category-padding;

    > p {
      @include inter-font($size: 12px, $color: #fff);
      font-weight: 800;
      margin: 0;
      margin-left: 14px;
    }
  }

  &__categories {
    background-color: var(--packages-pg-bg);
    display: flex;
    padding: 30px 10px 30px $page-padding;
    overflow-y: hidden;
    overflow-x: auto;
    @include responsive($max: md) {
      padding: 31px;
    }
  }

  &__categories {
    height: 100%;
  }

  &__category-filter {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 30px;
    width: fit-content;

    > p {
      @include inter-font($size: 14px, $color: #fff);
      margin: 0;
      margin-left: calculateRem(5px);
      @include responsive($max: md) {
        display: none;
      }
    }
  }

  &__category-wrapper {
    align-items: center;
    background-color: var(--packages-pg-bg);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 0 8px;
    width: fit-content;
    @include responsive($max: md) {
      margin-right: 10px;
      padding: 17px;
    }
  }

  &__clear {
    @include inter-font($size: 14px);
    cursor: pointer;
    text-decoration: underline;
  }

  &__new-package {
    align-items: center;
    border: 1px dotted var(--new-package-border);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 290px;
    justify-content: center;
    width: 240px;
  }

  &__packages {
    $gap: 20px;

    column-gap: $gap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 240px));
    max-height: 100%;
    overflow-y: auto;
    padding-top: 20px;
    row-gap: $gap;
  }
  &__pageLayout {
    > section:first-of-type {
      @include responsive($max: md) {
        min-height: 100% !important;
      }
    }
  }

  &__service {
    & ~ .packages__service {
      margin-left: $category-spacing;
    }
  }

  &__filters,
  &__packages {
    padding: 0 $page-padding;
    @include responsive($max: md) {
      padding: 0 31px;
    }
  }

  &__packages {
    @include responsive($max: mobileL) {
      display: none;
    }
  }
}
</style>
