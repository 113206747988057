<template>
  <div class="baseRange">
    <div class="baseRange__header">
      <p>{{ title }} {{ $t('SELECTION') | lowercase }}</p>
    </div>
    <div class="baseRange__body">
      <q-range class="baseRange__slider" color="yellow" label :min="min" :max="max" :step="step" :value="range" @input="newValue = $event" />
      <div class="baseRange__actions row items-center justify-end q-gutter-sm">
        <BaseButton :custom_style="button_style" :label="$t('CANCEL')" @click.native="handleCancel" />
        <BaseButton :custom_style="{ ...button_style, 'background-color': getCssVariable('--main-btn-color') }" :disabled="isConfirmDisabled" :label="$t('CONFIRM')" @click.native="handleConfirm" />
      </div>
    </div>
  </div>
</template>

<script>
// Base components
import BaseButton from '@base/BaseButton.vue';
/**
 * Base components to show a box with a range selector
 *
 * @displayName BaseRange
 */
export default {
  name: 'BaseRange',
  components: {
    BaseButton
  },
  props: {
    /**
     * Max range value
     */
    max: { default: 1000, type: Number },
    /**
     * Min range value
     */
    min: { default: 0, type: Number },
    /**
     * Range of values
     */
    range: {
      default: function () {
        return {
          min: this.min,
          max: this.max
        };
      },
      type: Object
    },
    /**
     * Step amount
     */
    step: { default: 1, type: Number },
    // Box title
    title: { default: '', type: String }
  },
  data() {
    return {
      button_style: {
        'border-radius': '5px !important',
        height: '23px !important'
      },
      newValue: this.range
    };
  },
  computed: {
    /**
     * If the old range value is different from the new range value, the confirm button is enabled.
     * This way, the user can update the range value only if it has changed the range
     */
    isConfirmDisabled() {
      return JSON.stringify(this.newValue) === JSON.stringify(this.range);
    }
  },
  methods: {
    /**
     * Reset range values
     */
    handleCancel() {
      this.$emit('value:update', { min: this.min, max: this.max });
    },
    /**
     * Emits the new range value
     */
    handleConfirm() {
      this.$emit('value:update', this.newValue);
    }
  }
};
</script>

<style lang="scss">
.baseRange {
  $border-radius: 10px;
  border-radius: $border-radius;
  height: 170px;
  width: 222px;
  @include responsive($max: md) {
    width: 200px;
  }

  &__actions {
    padding: 0 16px 16px 16px;
  }

  &__body {
    background-color: var(--bg-color);
    padding-top: 20px;
  }

  &__header {
    align-items: center;
    background-color: var(--header-bg-color);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    display: flex;
    height: 50px;
    justify-content: center;

    > p {
      @include inter-font($size: 10px, $color: var(--header-color));
      margin: 0;
    }
  }

  &__slider {
    margin: 0 auto 20px auto;
    width: 80%;

    .q-slider__track-container {
      background-color: --track-color;
    }

    .q-slider__track {
      background-color: --track-active-color;
    }
  }
}
</style>
