<template>
  <div class="container">
    <div v-if="isLoading || isRefetching" class="overlay">
      <q-spinner-facebook color="primary" size="100px" />
    </div>
    <q-table :data="shoots" :columns="tableColumns" row-key="id" :loading="isLoading || isRefetching" class="table" :rows-per-page-options="[50]" hide-bottom flat>
      <template v-slot:body="props">
        <q-tr :props="props" @click="handleShootSelection(props.row)">
          <q-td v-for="field in shootsFields" :key="field" :props="props">
            <template v-if="field === 'date'">
              <div :data-type="getStatus(props.row.status)" class="shootsTab__bg shootsTab__bg--mobile"></div>
              {{ fromDateTimeToDateAndTimeRange({ from: props.row.datetime, duration: props.row.duration }).date || '--' }}
            </template>
            <template v-else-if="field === 'id'">
              {{ props.row.id }}
            </template>
            <template v-else-if="field === 'customer'">
              {{ props.row.customer_name }}
            </template>
            <template v-else-if="field === 'location'">
              {{ props.row.address && props.row.address.city }}
            </template>
            <template v-else-if="field === 'category'">
              {{ getCategory(props.row.services) }}
            </template>
            <template v-else-if="field === 'price'">
              {{ `${getPrice(props.row)} ${$t('AED')}` }}
            </template>
            <template v-else-if="field === 'status'">
              <div class="status-container">
                <span class="status-indicator" :style="{ backgroundColor: getStatusColor(props.row.status) }"></span>
                {{ getStatus(props.row.status) | firstUppercase }}
              </div>
            </template>
            <template v-else>
              {{ props.row[field] }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShootsTable',
  props: {
    shoots: {
      type: Array,
      default: () => []
    },
    shootsFields: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: () => 'UPCOMING_SHOOT'
    },
    isLoading: Boolean,
    isRefetching: Boolean,
    getCategory: Function,
    getPrice: Function,
    getStatus: Function,
    getStatusColor: Function,
    showNavigator: Function
  },
  computed: {
    tableColumns() {
      return this.shootsFields.map(field => ({
        name: field,
        required: true,
        label: field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' '),
        align: 'left',
        field: row => row[field],
        sortable: true
      }));
    },
    ...mapGetters({
      services: 'services/getServices'
    })
  },
  methods: {
    ...mapActions({
      setSelectedShoot: 'shoot/saveOpenedShoot'
    }),
    handleShootSelection(row) {
      if (row.redeemable_total !== 0) {
        this.setSelectedShoot(row.id);
        this.$emit('open-pkg');
      }
    }
  }
};
</script>
<style scoped lang="scss">
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white overlay */
  z-index: 10; /* Ensure it covers other content */
}
.container {
  display: flex;
  flex-direction: column; /* Ensures that the contents are laid out vertically */
  height: calc(100vh - 230px); /* Adjust 120px to the combined height of other components */
  position: relative;
}

.table {
  height: 100%;
  width: 100%;
}
.status-container {
  display: flex;
  align-items: center;
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 12px;
  display: inline-block;
}
</style>
