<template>
  <div class="baseCircularProgress">
    <q-circular-progress show-value :angle="270" font-size="18px" :value="value" size="100px" :thickness="0.05" color="black" track-color="grey-4" class="q-ma-md">
      <span class="baseCircularProgress__value">{{ value }}%</span>
    </q-circular-progress>
  </div>
</template>
<script>
/**
 * BaseCircularProgress component. It loads a circle showing the progress percentage passed in "value" prop.
 *
 * @displayName BaseCircularProgress
 */
export default {
  name: 'BaseCircularProgress',
  props: {
    /**
     * Use this prop to pass the value of the progress
     */
    value: { default: 0, type: Number },
    /**
     * Use this prop to pass the progress icon size
     */
    size: { default: 100, type: Number }
  }
};
</script>
<style lang="scss" scoped>
.baseCircularProgress {
  display: flex;
  justify-content: center;

  &__value {
    @include antonio-font(18px);
  }
}
</style>
