<template>
  <section class="standardPackage">
    <div>
      <p class="ma-0 text-left">{{ $t('SELECT_CATEGORY') }}:</p>
      <ShootSelectType class="mt-20" @updated:categories="updateSelectedCategories" />
    </div>
    <div>
      <p v-if="packages.length > 0" class="ma-0 text-left">{{ $t('SELECT_PACKAGE') }}:</p>
      <div ref="packages" class="standardPackage__packages pb-20">
        <PackageCard
          v-for="(_package, index) of packages"
          :key="index"
          :pkg="_package"
          :class="['standardPackage__package mt-20', { 'standardPackage__package--is-selected': selectedPackagesIds.includes(_package.id) }]"
          hide-actions
          size="small"
          @click.native="handlePackageSelection({ pkg: _package })"
        />
      </div>
    </div>
  </section>
</template>

<script>
// Store
import { mapGetters } from 'vuex';

// Api
import { PackagesApi } from '@api/index';

// Components
import PackageCard from '@components/package/PackageCard.vue';
import ShootSelectType from './ShootSelectType.vue';

// Helpers
import { sortPackagesByName } from '@utils/packagesHelpers';

/**
 * This component is used in the book a new shoot wizard for the creation of a express package
 */
export default {
  name: 'StandardPackage',
  components: {
    PackageCard,
    ShootSelectType
  },
  data() {
    return {
      packages: [],
      selectedPackages: []
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      shoot: 'bookShoot/getShoot'
    }),
    selectedPackagesIds() {
      return this.selectedPackages.map(({ id, package_id }) => id || package_id);
    }
  },
  mounted() {
    this.selectedPackages = this.shoot.packages;
  },
  methods: {
    async updateSelectedCategories($event) {
      const services = $event.map(({ id }) => id);
      const packagesResponse = await PackagesApi.search({
        payload: {
          filters: {
            services
          }
        }
      });

      if (packagesResponse.statusCode === 200) this.packages = sortPackagesByName(packagesResponse.data.packages);
    },
    /**
     * Setting the selected package and emitting the event
     */

    handlePackageSelection({ pkg }) {
      this.selectedPackages = [pkg];
      this.$emit('packages:updated', [pkg]);
    }
  }
};
</script>

<style lang="scss" scoped>
.standardPackage {
  column-gap: 62px;
  display: grid;
  grid-template-columns: 3fr 7fr;
  max-height: 'fit-content';
  overflow-y: auto;

  &__package {
    cursor: pointer;

    &--is-selected {
      border: 3px solid var(--main-text-color);
    }
  }

  &__packages {
    $gap: 20px;

    column-gap: $gap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 210px));
    row-gap: $gap;
  }
}
</style>
