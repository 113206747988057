<template>
  <!-- Check if there is only one item -->
  <div v-if="items.length === 1" class="packagesCarousel__single">
    <PackageCard :pkg="items[0]" @click.native="$emit('pkg-id', items[0].id)" />
  </div>
  <carousel-3d v-else class="packagesCarousel__carousel" :count="items.length" :display="display" :perspective="perspective" :space="space" :width="width" :border="0">
    <slide v-for="(item, index) in items" :index="index" :key="index" class="packagesCarousel__slide">
      <template slot-scope="{ isCurrent }">
        <PackageCard :class="{ 'packagesCarousel__slide--is-faded': !isCurrent }" :pkg="item" @click.native="$emit('pkg-id', item.id)" />
      </template>
    </slide>
  </carousel-3d>
</template>

<script>
// Carousel library
import { Carousel3d, Slide } from 'vue-carousel-3d';

// Components
import PackageCard from '@/components/PackageCard.vue';

/**
 * This component will handle the packages carousel shown on mobile
 *
 * @displayName PackagesCarousel
 */
export default {
  name: 'PackagesCarousel',
  components: {
    Carousel3d,
    PackageCard,
    Slide
  },
  props: {
    /**
     * Number of items displayed
     */
    display: { default: 3, type: Number },
    /**
     * List of carousel items
     */
    items: { default: () => [], type: Array },
    /**
     * Defines the perspective of the cards. Higher means more rotate
     */
    perspective: { default: 0, type: Number },
    /**
     * Space between displayed cards
     */
    space: { default: 100, type: Number },
    /**
     * Cards width
     */
    width: { default: 255, type: Number }
  }
};
</script>

<style lang="scss" scoped>
.packagesCarousel {
  &__carousel {
    display: none;
  }

  &__single {
    display: none;
  }

  &__slide {
    background-color: transparent;
    transition: transform 500ms ease 0s, opacity 500ms ease 50ms, visibility 500ms ease 0s !important;

    &--is-faded {
      opacity: 0.8;
      transition: transform 500ms ease 0s, opacity 500ms ease 50ms, visibility 500ms ease 0s !important;
    }
  }

  &__packages {
    $gap: 20px;

    column-gap: $gap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(188px, 1fr));
    row-gap: $gap;
  }
}

@include responsive($max: mobileL) {
  .packagesCarousel {
    &__carousel {
      display: block;
    }
    &__carousel,
    &__carousel .carousel-3d-slider,
    &__slide {
      height: 350px !important;
    }

    &__packages {
      display: none;
    }

    &__single {
      display: block;
      width: 255px !important;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
