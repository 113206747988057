<template>
  <BaseContentBlock :title="getTitle" :subtitle="getSubtitle" class="cancelEditShootDialog">
    <template v-if="isAdmin && selectedType === EDIT">
      <BaseInput clearable v-model="shootId" :value="shootId" @update:value="$emit('shootId:update', $event)" />
    </template>
  </BaseContentBlock>
</template>
<script>
// Base Components
import BaseContentBlock from '@base/BaseContentBlock.vue';
import BaseInput from '@base/BaseInput.vue';

import { CANCEL, EDIT } from '@utils/constants';

// Vuex
import { mapGetters } from 'vuex';

export default {
  name: 'CancelEditShootDialog',
  components: {
    BaseContentBlock,
    BaseInput
  },
  props: {
    /**
     * set the type of modal to show
     */
    selectedType: { default: CANCEL, type: String, validator: value => [CANCEL, EDIT].includes(value) }
  },
  data() {
    return {
      EDIT: EDIT,
      shootId: null
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient'
    }),
    /**
     * use to get the correct title to pass to the component
     */
    getTitle() {
      return this.$t('CLIENT_ACTION_TITLE', { action: this.selectedType });
    },
    /**
     * use to get the correct subtitle to pass to the component
     */
    getSubtitle() {
      const subtitle = this.isAdmin && this.selectedType === CANCEL ? this.$t('SHOOT.ADMIN_DECLINE') : this.$t('ENABLE_SHOOT_EDITING');
      return this.isClient ? this.$t('CLIENT_ACTION_DESCRIPTION', { action: this.selectedType }) : subtitle;
    }
  }
};
</script>
<style lang="scss" scoped>
.cancelEditShootDialog {
  height: 100%;
  position: relative;
  width: calculateRem(670px);

  &__btn {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .baseInput {
    width: 50%;
  }
}
</style>
