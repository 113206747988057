<template>
  <div class="selectQuantityOfPictures">
    <p class="selectQuantityOfPictures__category">{{ category }}</p>
    <BaseNumberInput class="selectQuantityOfPictures__quantity" :step="1" :min="0" :value="selectedQuantity" @update:value="$emit('update:quantity', $event)" />
  </div>
</template>

<script>
// Base Components
import BaseNumberInput from '@base/BaseNumberInput.vue';

/**
 * This component is used to select the quantity of available pictures for a given category
 */
export default {
  name: 'SelectQuantityOfPictures',
  model: {
    event: 'update:quantity',
    prop: 'selectedQuantity'
  },
  components: {
    BaseNumberInput
  },
  props: {
    /**
     * Name of the category
     */
    category: { default: '', type: String },
    /**
     * The selected option
     */
    selectedQuantity: { default: 0, type: [Number, String] }
  }
};
</script>

<style lang="scss" scoped>
.selectQuantityOfPictures {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 14.5px 0;

  &__category {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    font-weight: bold;
    margin: 0;
    max-width: 50%;
    overflow-x: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__quantity {
    padding-bottom: 0;
    min-width: 100px !important;
  }
}
</style>
