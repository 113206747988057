<template>
  <section class="moveCategory">
    <BaseButton class="moveCategory__button moveCategory__backButton" icon="angular-arrow" :label="$t('BACK')" @click.native="$emit('go-back')" />
    <section class="moveCategory__content">
      <slot />
    </section>
    <BaseButton
      class="moveCategory__button"
      :custom_style="{ 'background-color': getCssVariable('--replace-button-color'), color: getCssVariable('--main-text-color') }"
      :disabled="isConfirmDisabled"
      :label="$t('Confirm')"
      @click.native="$emit('confirm:action')"
    />
  </section>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';

/**
 * Component used to set the layout of category action section (like editing, creating or moving it)
 *
 * @displayName CategoryActionLayout
 */
export default {
  name: 'CategoryActionLayout',
  components: {
    BaseButton
  },
  props: {
    /**
     * Set to true if you need the button 'confirm' to be disabled
     */
    isConfirmDisabled: { default: true, type: Boolean }
  }
};
</script>

<style lang="scss" scoped></style>
