<template>
  <div class="selectBusinessAccount">
    <BaseSelectWithFilter v-model="selectedBusinessAccount" option-label="company_name" option-value="client_id" use-input :label="$t('COMPANY.NAME')" :options="filteredBusinessUsers" />
  </div>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';

export default {
  name: 'SelectBusinessAccount',
  components: {
    BaseSelectWithFilter
  },
  data() {
    return {
      businessUsers: [],
      filteredBusinessUsers: []
    };
  },
  computed: {
    ...mapGetters({
      shoot: 'bookShoot/getShoot'
    }),
    selectedBusinessAccount: {
      get() {
        const businessUser = this.businessUsers.find(user => this.shoot.client_id === user.client_id);
        return businessUser ? businessUser.company_name : null;
      },
      set(value) {
        this.$emit('client:selected', value);
      }
    }
  },
  created() {
    this.retrieveBusinessUsers();
  },
  methods: {
    async retrieveBusinessUsers() {
      const users = await UserApi.searchUsers({ payload: { filters: { role: 'client' } } });
      this.businessUsers = users.data.users.filter(({ status }) => status === 'active');
      this.filteredBusinessUsers = users.data.users.filter(({ status }) => status === 'active');
    }
  }
};
</script>

<style lang="scss" scoped>
.selectBusinessAccount {
  width: 300px;

  > p {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    margin: 0;
    margin-bottom: 20px;
    text-align: left;
  }
}
</style>
