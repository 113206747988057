<template>
  <section>
    <CategoryCard :no-background-image="isBgImageDisabled" :service="selectedService" @click.native="showOptions = !showOptions" />
    <div v-if="showOptions" class="categorySelect__cardOptions">
      <CategoryCard v-for="(service, index) of services" :key="index" class="categorySelect__option" :service="service" @click.native="handleServiceSelection(service)" />
    </div>
  </section>
</template>

<script>
// Api
import { ServiceApi } from '@api/index';

// Components
import CategoryCard from '@components/package/CategoryCard.vue';

/**
 * Component used to select from the list of categories
 *
 * @displayName CategorySelect
 */
export default {
  name: 'CategorySelect',
  components: {
    CategoryCard
  },
  data() {
    return {
      selectedService: {},
      showOptions: false,
      services: []
    };
  },
  computed: {
    /**
     * Returns true if no service is selected
     */
    isBgImageDisabled() {
      return Object.keys(this.selectedService).length === 0;
    }
  },
  created() {
    this.retrieveServices();
  },
  methods: {
    /**
     * Method used to set the selected category
     */
    handleServiceSelection($event) {
      this.selectedService = $event;
      this.$emit('category:selected', $event);
    },
    /**
     * Method used to retrieve services
     */
    async retrieveServices() {
      const services = await ServiceApi.search({
        sorting: {
          field: 'id',
          direction: 'desc'
        }
      });

      if (services.statusCode === 200) {
        this.services = services.data.services;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.categorySelect {
  &__cardOptions {
    height: 270px;
    overflow: auto;
  }
  &__option {
    margin-top: 10px;
  }
}
</style>
