<template>
  <section class="shootsFilter">
    <p class="shootsFilter__title">{{ groupLabel }}</p>
    <div class="shootsFilter__tags">
      <div v-for="(status, index) of statuses" :key="index" class="shootsFilter__status-tag">
        <div class="shootsFilter__status-color mr-10" :style="`background-color: ${status.color}`"></div>
        <p class="ma-0 mr-10">{{ status.label }}</p>
        <BaseIcon class="shootsFilter__list" :custom_style="{ stroke: '#fff', height: '12px', width: '13px' }" icon="list" @click.native="handleClick(status)" />
      </div>
    </div>
  </section>
</template>

<script>
// Base Components
import BaseIcon from '@base/BaseIcon.vue';

import { statuses, CONFIRMED, COMPLETED, READY, SCHEDULED, UPLOADED, ASSIGNED, READY_FOR_UPLOAD, CANCELED } from '@/utils/shootsHelpers';

const UI_COMPLETED = 'COMPLETED';
const IN_PROGRESS = 'IN_PROGRESS';
const UPCOMING = 'UPCOMING';

/**
 * Component used to show shoots statuses based on macro fronend status.
 * ex.: UPCOMING SHOOTS ---> pending, confirmed
 */
export default {
  name: 'ShootsFilter',
  components: {
    BaseIcon
  },
  props: {
    /**
     * Pass one of the group status (i.e.: UPCAOMING SHOOTS)
     */
    group: { default: '', type: String }
  },
  data() {
    return {
      COMPLETED: {
        label: 'Completed shoots',
        value: UI_COMPLETED
      },
      PROGRESS: {
        label: 'Shoots in process',
        value: IN_PROGRESS
      },
      UPCOMING: {
        label: 'Upcoming shoots',
        value: UPCOMING
      }
    };
  },
  computed: {
    /**
     * Returning the group label
     */
    groupLabel() {
      const groups = [this.COMPLETED, this.PROGRESS, this.UPCOMING];

      return groups.find(({ value }) => value === this.group).label || '';
    },
    statuses() {
      const _statuses = {
        [UPCOMING]: statuses.filter(status => status.value.includes(ASSIGNED) || status.value.includes(SCHEDULED) || status.value.includes(CONFIRMED)),
        [IN_PROGRESS]: statuses.filter(status => status.value.includes(READY_FOR_UPLOAD) || status.value.includes(UPLOADED)),
        [UI_COMPLETED]: statuses.filter(status => status.value.includes(READY) || status.value.includes(COMPLETED) || status.value.includes(CANCELED))
      };

      return _statuses[this.group];
    }
  },
  methods: {
    /**
     * Handle click on status filter
     */
    handleClick(status) {
      this.$router.push({
        name: 'orders',
        query: {
          statuses: status.value.join()
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.shootsFilter {
  border-left: 2px solid var(--border-filters);
  padding-left: 14px;

  &__list {
    cursor: pointer;
    margin-left: auto;
  }

  &__status-color {
    border-radius: 2px;
    height: 10px;
    width: 10px;
  }

  &__status-tag {
    @include inter-font($size: 12px, $color: #fff);
    align-items: center;
    background-color: var(--filters-tag-bg);
    border-radius: 10px;
    display: flex;
    height: 35px;
    padding: 13px;
    width: fit-content;
  }

  &__tags {
    column-gap: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 11px;
  }

  &__title {
    @include antonio-font($size: 18px, $color: #fff);
    text-align: left;
  }
}
</style>
