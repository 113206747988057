import { render, staticRenderFns } from "./BaseTable.vue?vue&type=template&id=26a6652a&"
import script from "./BaseTable.vue?vue&type=script&lang=js&"
export * from "./BaseTable.vue?vue&type=script&lang=js&"
import style0 from "./BaseTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInfiniteScroll,QSpinnerDots});
