<template>
  <apexchart :height="size" :width="size" type="donut" :options="options" :series="series"></apexchart>
</template>

<script>
export default {
  name: 'BaseDonut',
  props: {
    /**
     * Sections colors (the order of the colors should be paired with the order of the data in the series)
     */
    colors: { default: () => [], type: Array },
    /**
     * Labels of the dount chart sections (the order should be paired with the order of the data in the series)
     */
    labels: { default: () => [], type: Array },
    /**
     * Data to be shown
     */
    series: { default: () => [], type: Array },
    /**
     * Chart size
     */
    size: { default: 150, type: Number }
  },
  computed: {
    /**
     * Options
     */
    options() {
      return {
        colors: this.colors,
        dataLabels: {
          enabled: false
        },
        labels: this.labels,
        legend: {
          show: false
        },
        stroke: {
          show: false
        }
      };
    }
  }
};
</script>
