<template>
  <BaseFilter :disabled="disabled" :filter_label="label" :icon="icon">
    <div class="selecteFilter__content py-5">
      <BaseCheckbox v-for="(option, index) of options" :key="index" v-model="value" :class="{ 'mb-10': index < options.length - 1 }" :label="itemLabel && option[itemLabel]" :val="option" />
    </div>
  </BaseFilter>
</template>

<script>
// Base Components
import BaseFilter from '@base/BaseFilter.vue';
import BaseCheckbox from '@base/BaseCheckbox.vue';

export default {
  name: 'SelectFilter',
  components: {
    BaseFilter,
    BaseCheckbox
  },
  props: {
    /**
     * Set disabled filter
     */
    disabled: { default: false, type: Boolean },
    /**
     * Filter icon
     */
    icon: { default: '', type: String },
    /**
     * Filter default label
     */
    label: { default: '', type: String },
    /**
     * The list of the options to be displayed
     */
    options: { default: () => [], type: Array },
    /**
     * List of selected options
     */
    selectedOptions: { default: () => [], type: Array },
    itemLabel: { default: '', type: String }
  },
  computed: {
    value: {
      get() {
        return this.selectedOptions || [];
      },
      set(value) {
        this.$emit('selected:updated', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.selecteFilter {
  &__content {
    background-color: var(--secondary-bg-color);
    max-height: 170px;
    overflow-y: auto;
    padding: 5px;
  }
}
</style>
