<template>
  <q-toggle :data-label-position="labelPosition" class="baseToggle" :color="color" :value="value" @input="$emit('toggled', $event)">
    <slot />
  </q-toggle>
</template>

<script>
export default {
  name: 'BaseToggle',
  model: {
    event: 'toggled',
    prop: 'value'
  },
  props: {
    /**
     * Toggle color
     */
    color: { default: '#FECB30', type: String },
    /**
     * Set to left/right to show the label on that position
     */
    labelPosition: { default: 'right', type: String },
    /**
     * Toggle value
     */
    value: { default: false, type: Boolean }
  }
};
</script>

<style lang="scss">
.baseToggle {
  --circle-color: #fff;
  --track-color: #fecb30;
  justify-content: space-between;

  &[data-label-position='left'] {
    flex-direction: row-reverse;
  }

  &[aria-checked='true'] {
    .q-toggle__track {
      opacity: 1;
    }
  }

  .q-toggle__inner {
    align-items: center;
    display: flex;
    padding-right: 0;
  }

  .q-toggle__track {
    background-color: var(--track-color);
    border-radius: 15px;
    height: 23px;
    width: 35px;
  }

  .q-toggle__thumb {
    $size: 23px;

    height: $size;
    top: calc(50% - $size/2);
    width: $size;
    &::after {
      background-color: var(--circle-color) !important;
    }
  }
}
</style>
