<template>
  <div>
    <BaseDropdown v-if="!isDisabled && !hasSingleAction">
      <template #trigger>
        <BaseButton icon="arrow-down" :label="buttonLabel" :custom_style="buttonStyle" :icon_style="iconStyle" type="default" :loading="isLoading" />
      </template>
      <template #menu>
        <ActionsDropdown v-bind="$props" />
      </template>
    </BaseDropdown>

    <BaseButton
      v-if="!isDisabled && hasSingleAction"
      :icon="singleAction.icon"
      :label="singleAction.label"
      type="default"
      @click.native="singleAction.actionHandler"
      :disabled="singleAction.isDisabled"
    />
  </div>
</template>

<script>
import BaseButton from '@base/BaseButton.vue';
import BaseDropdown from '@base/BaseDropdown.vue';
import ActionsDropdown from './ActionsDropdown.vue';
import helpers from '@utils/helpers';

const AVAILABLE_ACTIONS = {
  DOWNLOAD: {
    label: 'Download content',
    icon: 'download',
    getHandler: props => props.downloadProcessedPhotos
  },
  DOWNLOAD_RAWS: {
    label: 'Download RAWs',
    icon: 'download',
    getHandler: props => props.downloadRawPhotos
  },
  CANCEL: {
    label: 'Cancel',
    icon: 'cancel',
    getHandler: props => props.handleCancelShoot
  },
  EDIT: {
    label: 'Edit',
    icon: 'edit',
    getHandler: props => props.handleEditShoot
  },
  ACCEPT: {
    label: 'Accept',
    icon: 'check',
    getHandler: props => props.handleAcceptShoot
  },
  DECLINE: {
    label: 'Decline',
    icon: 'close',
    getHandler: props => props.handleDeclineShoot
  },
  PREVIEW_RAWS: {
    label: 'Preview RAWs',
    icon: 'image',
    getHandler: props => props.previewRawImages,
    isDisabled: props => !props.actionsComponent.includes('PREVIEW_RAWS') || !props.shoot.raws_preview || !props.shoot.raws || props.shoot.raws.length === 0
  }
};

export default {
  name: 'ActionsButton',

  components: {
    BaseButton,
    BaseDropdown,
    ActionsDropdown
  },

  props: {
    downloadProcessedPhotos: Function,
    downloadRawPhotos: Function,
    handleEditShoot: Function,
    handleCancelShoot: Function,
    handleDeclineShoot: Function,
    handleAcceptShoot: Function,
    handleAssignPhotographer: Function,
    previewRawImages: Function,
    handleTriggerRawsPreview: Function,
    assignLabel: String,
    selectedPhotographer: Object,
    isDownloadDisabled: Boolean,
    actionsComponent: {
      type: Array,
      default: () => []
    },
    shoot: {
      type: Object,
      required: true
    },
    isLoading: Boolean
  },

  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  computed: {
    iconStyle() {
      return {
        fill: 'black',
        height: '14px',
        width: '14px',
        stroke: 'black'
      };
    },

    buttonStyle() {
      return {
        'background-color': helpers.getCssVariable('--terziary-btn-color'),
        color: helpers.getCssVariable('--primary-text-color'),
        'margin-left': '8px',
        'margin-right': '8px'
      };
    },

    buttonLabel() {
      if (this.windowWidth < 325) return '';
      if (this.windowWidth < 500) return 'Actions';
      return 'Shoot actions';
    },

    availableActions() {
      return Object.keys(AVAILABLE_ACTIONS).filter(action => this.actionsComponent.includes(action));
    },

    isDisabled() {
      return this.availableActions.length === 0;
    },

    hasSingleAction() {
      return this.availableActions.length === 1;
    },

    singleAction() {
      if (!this.hasSingleAction) return null;

      const actionName = this.availableActions[0];
      const action = AVAILABLE_ACTIONS[actionName];

      return {
        label: action.label,
        icon: action.icon,
        actionHandler: action.getHandler(this),
        isDisabled: action.isDisabled ? action.isDisabled(this) : false
      };
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>
