<template>
  <section>
    <div class="shootSelectType">
      <div class="shootSelectType__box" v-for="(type, index) in services" :key="index">
        <BaseImage class="shootSelectType__image" :src="getServiceImage(type.id)" />
        <div class="q-pa-lg">
          <!-- import the BaseRadioInpu component that already exists, with all the properties necessary for the radio, changing only the value that determines the label. This component issues an event with the same name and arguments as the one it receives from its child   -->
          <BaseRadioInput v-model="selectedValue" :colors="{ bg: '#fff0', 'bg-active': '#fff' }" type="radio" :label="type.name" :val="type" name="categories" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';

// Base Components
import BaseRadioInput from '@base/BaseRadioInput.vue';
import BaseImage from '@base/BaseImage.vue';

export default {
  name: 'ShootSelectType',
  components: {
    BaseRadioInput,
    BaseImage
  },
  data() {
    return {
      selectedValue: {}
    };
  },
  computed: {
    ...mapGetters({
      services: 'services/getServices',
      shoot: 'bookShoot/getShoot'
    })
  },
  watch: {
    selectedValue() {
      this.$emit('updated:categories', [this.selectedValue]);
    }
  },
  created() {
    if (this.shoot.packages.length > 0) {
      for (const pkg of this.shoot.packages) {
        const foundService = this.services.find(type => type.id === Number(pkg.service_id));
        if (foundService) this.selectedValue = foundService;
      }
    }
  }
};
</script>
<style lang="scss">
.shootSelectType {
  margin: auto;

  &__box {
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 76px;
    margin: 10px 0;
    max-width: 300px;
    overflow: hidden;
    position: relative;

    &::before {
      background-color: var(--overlay-bg-color);
      border-radius: 10px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .q-radio {
      height: 100%;
      left: 0;
      padding: 30px 25px;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .q-pa-lg {
      padding: 0;
    }

    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .baseRadioInput .q-radio__label {
    @include antonio-font($size: 14px, $color: var(--status-text-color));
    font-weight: 700;
    z-index: 9;
  }
}
</style>
