<template>
  <div>
    <input type="file" ref="fileInput" @change="handleFileSelect" webkitdirectory directory multiple id="file-upload" class="file-input" hidden />
    <label for="file-upload" class="file-label" :class="{ uploading: isUploading }">
      <q-icon name="cloud_upload" class="file-icon" />
      <span class="file-text">{{ getLabelText }}</span>
    </label>
  </div>
</template>
<!-- Redeploying -->

<script>
import { ref, computed } from '@vue/composition-api';
import { ShootsApi } from '@api/index';

const SUPPORTED_EXTENSIONS = {
  videography: ['.mp4'],
  photography: ['.jpg', '.jpeg']
};

const IGNORED_FILES = ['.ds_store', '._.ds_store', 'thumbs.db'];

export default {
  name: 'ContentUploader',
  props: {
    shootId: {
      type: Number,
      required: true
    },
    content: {
      type: String,
      required: true,
      validator: value => ['photography', 'videography'].includes(value)
    },
    updateStatusMutation: {
      required: true
    }
  },
  setup(props, { root }) {
    const store = root.$store;
    const fileInput = ref(null);
    const isProcessing = ref(false);
    const isUploading = ref(false);
    const overallProgress = ref(0);

    const userData = computed(() => root.$store.getters['user/getUser']);

    const getLabelText = computed(() => {
      if (isProcessing.value) return 'Processing files...';
      if (isUploading.value) return `Uploading: ${overallProgress.value.toFixed(0)}%`;
      return `Upload ${props.content === 'videography' ? 'videos' : 'photos'}`;
    });

    const filterFiles = files => {
      const supportedExtensions = SUPPORTED_EXTENSIONS[props.content];

      return files.filter(file => {
        const fileName = file.name.toLowerCase();
        const fileExtension = '.' + fileName.split('.').pop();

        // Skip system files and check file extension
        return !IGNORED_FILES.includes(fileName) && !fileName.startsWith('._') && supportedExtensions.includes(fileExtension);
      });
    };

    const getMediaDimensions = file => {
      return new Promise((resolve, reject) => {
        const objectUrl = URL.createObjectURL(file);

        if (file.type.startsWith('video/')) {
          const video = document.createElement('video');
          video.preload = 'metadata';

          video.onloadedmetadata = () => {
            URL.revokeObjectURL(objectUrl);
            resolve({
              width: video.videoWidth,
              height: video.videoHeight
            });
          };

          video.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error(`Failed to load video metadata for ${file.name}`));
          };

          video.src = objectUrl;
        } else if (file.type.startsWith('image/')) {
          const img = new Image();

          img.onload = () => {
            URL.revokeObjectURL(objectUrl);
            resolve({
              width: img.naturalWidth,
              height: img.naturalHeight
            });
          };

          img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error(`Failed to load image ${file.name}`));
          };

          img.src = objectUrl;
        } else {
          URL.revokeObjectURL(objectUrl);
          reject(new Error(`Unsupported file type: ${file.type}`));
        }
      });
    };

    const getVideoDuration = file => {
      if (!file.type.startsWith('video/')) return Promise.resolve(0);

      return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        const objectUrl = URL.createObjectURL(file);

        video.preload = 'metadata';

        video.onloadedmetadata = () => {
          URL.revokeObjectURL(objectUrl);
          const durationInSeconds = Math.round(video.duration);
          resolve(durationInSeconds);
        };

        video.onerror = () => {
          URL.revokeObjectURL(objectUrl);
          reject(new Error(`Failed to get video duration for ${file.name}`));
        };

        video.src = objectUrl;
      });
    };

    const prepareFileData = async file => {
      const baseData = {
        name: file.name,
        relativePath: file.webkitRelativePath || file.name,
        contentType: file.type
      };

      try {
        const dimensions = await getMediaDimensions(file);
        const aspectRatio = dimensions.width && dimensions.height ? parseFloat((dimensions.width / dimensions.height).toFixed(3)) : null;

        const mediaData = {
          ...baseData,
          aspectRatio
        };

        if (props.content === 'videography') {
          const duration = await getVideoDuration(file);
          return {
            ...mediaData,
            duration
          };
        }

        return mediaData;
      } catch (error) {
        console.error(`Error preparing file data for ${file.name}:`, error);
        throw error; // Rethrow to handle in the caller
      }
    };

    const uploadFile = async (file, presignedUrl, index, fileProgresses) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', presignedUrl, true);
        xhr.setRequestHeader('Content-Type', file.contentType);

        xhr.upload.onprogress = event => {
          if (event.lengthComputable) {
            fileProgresses[index] = event.loaded / event.total;
            updateOverallProgress(fileProgresses);
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error(`Upload failed with status: ${xhr.status}`));
          }
        };

        xhr.onerror = () => reject(new Error('Network error during upload'));
        xhr.send(file);
      });
    };

    const updateOverallProgress = fileProgresses => {
      const totalProgress = fileProgresses.reduce((sum, progress) => sum + progress, 0);
      overallProgress.value = (totalProgress / fileProgresses.length) * 100;
    };

    const handleFileSelect = async event => {
      const allFiles = Array.from(event.target.files);
      const files = filterFiles(allFiles);

      if (files.length === 0) {
        store.dispatch('notification/addFailure', 'No supported files found. Please check file types and try again.');
        return;
      }

      isProcessing.value = true;
      overallProgress.value = 0;

      try {
        store.dispatch('notification/addSuccessNotification', `Processing ${files.length} files...`);

        const filesData = await Promise.all(
          files.map(file =>
            prepareFileData(file).catch(error => {
              store.dispatch('notification/addFailureNotification', `Failed to process ${file.name}: ${error.message}`);
              return null;
            })
          )
        );

        const validFilesData = filesData.filter(Boolean);

        if (validFilesData.length === 0) {
          throw new Error('No valid files to upload after processing');
        }

        const response = await ShootsApi.getPresignedUrls({
          id: props.shootId,
          files: validFilesData
        });

        const { presignedUrls, imagesMetadata, videosMetadata, processedCount, skippedCount } = response.data;

        isProcessing.value = false;
        isUploading.value = true;

        store.dispatch('notification/addSuccessNotification', 'Starting file upload...');

        const fileProgresses = new Array(presignedUrls.length).fill(0);

        await Promise.all(
          presignedUrls.map((urlData, index) => {
            const file = files.find(f => f.name === urlData.fileName);
            if (!file) {
              console.warn(`File ${urlData.fileName} not found in selected files`);
              return Promise.resolve();
            }

            return uploadFile(file, urlData.presignedUrl, index, fileProgresses).catch(error => {
              store.dispatch('notification/addFailureNotification', `Failed to upload ${file.name}: ${error.message}`);
            });
          })
        );

        // Create batch after successful uploads
        if (props.content === 'videography') {
          await ShootsApi.createVideosBatch({
            id: props.shootId,
            payload: { userData: userData, videos: videosMetadata }
          });
        } else {
          await ShootsApi.createImagesBatch({
            id: props.shootId,
            payload: { userData: userData, images: imagesMetadata }
          });
        }

        const statusDetails = { id: props.shootId, payload: { targetStatus: 'ready' } };
        props.updateStatusMutation.mutate(statusDetails);
        const mediaType = props.content === 'videography' ? 'videos' : 'photos';
        store.dispatch('notification/addSuccessNotification', `Successfully uploaded ${processedCount} ${mediaType}. ${skippedCount} files skipped.`);
      } catch (error) {
        console.error('Upload process failed:', error);
        store.dispatch('notification/addFailureNotification', `Upload process failed: ${error.message}`);
      } finally {
        isProcessing.value = false;
        isUploading.value = false;
        if (fileInput.value) {
          fileInput.value.value = '';
        }
      }
    };

    return {
      fileInput,
      isProcessing,
      isUploading,
      getLabelText,
      handleFileSelect,
      overallProgress
    };
  }
};
</script>

<style scoped>
.file-label {
  cursor: pointer;
  height: 44px;
  border-radius: 10px;
  display: flex;
  padding: 12px;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--main-btn-color);
  color: var(--main-text-color);
  transition: background-color 0.3s ease;
}

.file-icon {
  margin-right: 10px;
  font-size: 1.2em;
}
</style>
