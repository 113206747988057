<template>
  <BaseTabs :active-tab-index="activeTabIndex" :tabs="tabs" @changed:tab="handleChangedTab">
    <template #filters>
      <div class="flex flex-center w-100 ml-auto">
        <p v-if="showFilters" class="shootsTab__clear ml-auto mr-0 mb-0 text-white" @click="updateShowFilters(false)">{{ $t('CLEAR_ALL_FILTERS') }}</p>
      </div>
    </template>
    <template #UPCOMING_SHOOT>
      <div class="orders-container">
        <div class="filters-container">
          <q-btn filled color="primary" text-color="black-1" icon-right="download" label="Export data" no-caps @click="triggerExport" class="export-btn" />
          <q-btn-toggle
            v-model="viewMode"
            no-caps
            spread
            :options="[
              { label: 'List', value: 'list', icon: 'view_list' },
              { label: 'Grid', value: 'grid', icon: 'view_module' }
            ]"
            style="margin-bottom: 12px"
            toggle-color="grey-6"
            toggle-text-color="white"
          />
          <q-input filled v-model="searchQuery" debounce="300" placeholder="Search..." @input="searchQueryChanged" @keypress="validateInput" style="margin-bottom: 12px" />

          <q-date v-model="selectedDateRange" range mask="YYYY-MM-DD" format="YYYY-MM-DD" @input="dateFilterChanged" today-btn :dense="true" :class="['date-filter']" minimal />
          <q-select v-model="selectedStatuses" :options="statusOptions" multiple use-chips @input="statusFilterChanged" class="status-select" label="Filter by status" />
          <q-select v-model="selectedServices" :options="serviceOptions" multiple use-chips @input="serviceFilterChanged" class="service-select" label="Filter by category" />
          <q-select v-model="selectedCities" :options="citiesOptions" multiple use-chips @input="cityFilterChanged" class="city-select" label="Filter by city" />
        </div>
        <div class="table-container">
          <ShootsTab :activeTab="activeTab" :loading="isLoading" :refetching="isRefetching" :shoots="shoots" />
          <div class="pagination-container">
            <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="15" color="black" />
            <span>{{ totalCount }} Shoots</span>
          </div>
        </div>
      </div>
    </template>
    <template #SHOOT_IN_PROGRESS>
      <div class="orders-container">
        <div class="filters-container">
          <q-btn filled color="primary" text-color="black-1" icon-right="download" label="Export data" no-caps @click="triggerExport" class="export-btn" />
          <q-btn-toggle
            v-model="viewMode"
            no-caps
            spread
            :options="[
              { label: 'List', value: 'list', icon: 'view_list' },
              { label: 'Grid', value: 'grid', icon: 'view_module' }
            ]"
            style="margin-bottom: 12px"
            toggle-color="grey-6"
            toggle-text-color="white"
          />
          <q-input filled v-model="searchQuery" debounce="300" placeholder="Search..." @input="searchQueryChanged" @keypress="validateInput" style="margin-bottom: 12px" />

          <q-date v-model="selectedDateRange" range mask="YYYY-MM-DD" format="YYYY-MM-DD" @input="dateFilterChanged" today-btn :dense="true" :class="['date-filter']" minimal />
          <q-select v-model="selectedStatuses" :options="statusOptions" multiple use-chips @input="statusFilterChanged" class="status-select" label="Filter by status" />
          <q-select v-model="selectedServices" :options="serviceOptions" multiple use-chips @input="serviceFilterChanged" class="service-select" label="Filter by category" />
          <q-select v-model="selectedCities" :options="citiesOptions" multiple use-chips @input="cityFilterChanged" class="city-select" label="Filter by city" />
        </div>
        <div class="table-container">
          <ShootsTab :activeTab="activeTab" :loading="isLoading" :refetching="isRefetching" :shoots="shoots" />
          <div class="pagination-container">
            <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="15" color="black" />
            <span>{{ totalCount }} Shoots</span>
          </div>
        </div>
      </div>
    </template>
    <template #DOWNLOAD_READY>
      <div class="orders-container">
        <div class="filters-container">
          <q-btn filled color="primary" text-color="black-1" icon-right="download" label="Export data" no-caps @click="triggerExport" class="export-btn" />
          <q-btn-toggle
            v-model="viewMode"
            no-caps
            spread
            :options="[
              { label: 'List', value: 'list', icon: 'view_list' },
              { label: 'Grid', value: 'grid', icon: 'view_module' }
            ]"
            style="margin-bottom: 12px"
            toggle-color="grey-6"
            toggle-text-color="white"
          />
          <q-input filled v-model="searchQuery" debounce="300" placeholder="Search..." @input="searchQueryChanged" @keypress="validateInput" style="margin-bottom: 12px" />

          <q-date v-model="selectedDateRange" range mask="YYYY-MM-DD" format="YYYY-MM-DD" @input="dateFilterChanged" today-btn :dense="true" :class="['date-filter']" minimal />
          <q-select v-model="selectedStatuses" :options="statusOptions" multiple use-chips @input="statusFilterChanged" class="status-select" label="Filter by status" />
          <q-select v-model="selectedServices" :options="serviceOptions" multiple use-chips @input="serviceFilterChanged" class="service-select" label="Filter by category" />
          <q-select v-model="selectedCities" :options="citiesOptions" multiple use-chips @input="cityFilterChanged" class="city-select" label="Filter by city" />
        </div>
        <div class="table-container">
          <ShootsTab :activeTab="activeTab" :loading="isLoading" :refetching="isRefetching" :shoots="shoots" />
          <div class="pagination-container">
            <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="15" color="black" />
            <span>{{ totalCount }} Shoots</span>
          </div>
        </div>
      </div>
    </template>
    <template #ARCHIVED_SHOOT>
      <div class="orders-container">
        <div class="filters-container">
          <q-btn filled color="primary" text-color="black-1" icon-right="download" label="Export data" no-caps @click="triggerExport" class="export-btn" />
          <q-btn-toggle
            v-model="viewMode"
            no-caps
            spread
            :options="[
              { label: 'List', value: 'list', icon: 'view_list' },
              { label: 'Grid', value: 'grid', icon: 'view_module' }
            ]"
            style="margin-bottom: 12px"
            toggle-color="grey-6"
            toggle-text-color="white"
          />
          <q-input filled v-model="searchQuery" debounce="300" placeholder="Search..." @input="searchQueryChanged" @keypress="validateInput" style="margin-bottom: 12px" />

          <q-date v-model="selectedDateRange" range mask="YYYY-MM-DD" format="YYYY-MM-DD" @input="dateFilterChanged" today-btn :dense="true" :class="['date-filter']" minimal />
          <q-select v-model="selectedStatuses" :options="statusOptions" multiple use-chips @input="statusFilterChanged" class="status-select" label="Filter by status" />
          <q-select v-model="selectedServices" :options="serviceOptions" multiple use-chips @input="serviceFilterChanged" class="service-select" label="Filter by category" />
          <q-select v-model="selectedCities" :options="citiesOptions" multiple use-chips @input="cityFilterChanged" class="city-select" label="Filter by city" />
        </div>
        <div class="table-container">
          <ShootsTab :activeTab="activeTab" :loading="isLoading" :refetching="isRefetching" :shoots="shoots" />
          <div class="pagination-container">
            <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="15" color="black" />
            <span>{{ totalCount }} Shoots</span>
          </div>
        </div>
      </div>
    </template>
  </BaseTabs>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api';
import { useQuery } from '@tanstack/vue-query';
import BaseTabs from '@base/BaseTabs.vue';
import ShootsTab from '@/components/shoot/ShootsTab.vue';
// import ShootToggleView from '@/components/shoot/ShootToggleView.vue';
import { ShootsApi } from '@api/index';
import helpers, { UPCOMING_SHOOT, SHOOT_IN_PROGRESS, DOWNLOAD_READY, ARCHIVED_SHOOT } from '@utils/shootsHelpers';
import { exportFile } from 'quasar';

const fetchShoots = async (page, filters, totalCount = null, isExport = false) => {
  // Simulate a delay before executing the API call
  const offset = (page - 1) * 50;
  let pagination;
  if (isExport) {
    pagination = {
      offset: 0,
      size: totalCount
    };
  } else {
    pagination = {
      offset,
      size: 50
    };
  }
  let payload = {
    filters,
    pagination
  };
  const response = await ShootsApi.searchShoots(payload);
  return {
    shoots: response.data.shoots,
    totalCount: response.data.total
  };
};

export default {
  name: 'Orders',
  components: {
    BaseTabs,
    ShootsTab
    // ShootToggleView
  },
  setup(props, { root }) {
    const store = root.$store;
    root.$router.replace({ query: { view: 'list' } });
    const activeTab = ref(UPCOMING_SHOOT);
    const activeTabIndex = ref(0);
    const page = ref(1);
    const searchQuery = ref(''); // Initialize the search query

    const viewMode = ref('list');

    const isPhotographer = computed(() => root.$store.getters['user/isPhotographer']);
    const toggleView = computed(() => root.$store.getters['shoot/getActiveView']);
    const showFilters = computed(() => root.$store.getters['shoot/getShowFilters']);

    const services = computed(() => store.getters['services/getServices']);
    const citiesOptions = ref([]);
    const selectedCities = ref([]);

    const fetchFilters = async () => {
      const response = await ShootsApi.getFilters();
      citiesOptions.value = response.data.cities.map(city => ({
        label: city,
        value: city
      }));
    };

    if (citiesOptions.value.length === 0) {
      fetchFilters();
    }

    const selectedDateRange = ref({
      from: null,
      to: null
    });

    const selectedServices = ref([]);
    const serviceOptions = computed(() =>
      services.value.map(service => ({
        label: service.name,
        value: service.id
      }))
    );

    const tabs = computed(() => [
      { label: root.$i18n.tc('STATUSES.UPCOMING_SHOOT', 0).toUpperCase(), value: UPCOMING_SHOOT },
      { label: root.$i18n.tc('STATUSES.SHOOT_IN_PROGRESS').toUpperCase(), value: SHOOT_IN_PROGRESS },
      { label: root.$i18n.tc('STATUSES.DOWNLOAD_READY', 0).toUpperCase(), value: DOWNLOAD_READY },
      { label: root.$i18n.tc('STATUSES.ARCHIVED_SHOOT', 0).toUpperCase(), value: ARCHIVED_SHOOT }
    ]);

    const filters = ref({
      statuses: helpers.retrieveBEStatuses({ isClient: true, status: UPCOMING_SHOOT })
    });

    const selectedStatuses = ref([
      { label: 'Pending', value: 'scheduled' },
      { label: 'Assigned', value: 'assigned' },
      { label: 'Confirmed', value: 'confirmed' }
    ]);
    // Compute status options based on the active tab
    const statusOptions = computed(() => {
      switch (activeTab.value) {
        case UPCOMING_SHOOT:
          return [
            { label: 'Pending', value: 'scheduled' },
            { label: 'Assigned', value: 'assigned' },
            { label: 'Confirmed', value: 'confirmed' }
          ];
        case SHOOT_IN_PROGRESS:
          return [{ label: 'Ready for editing', value: 'uploaded' }];
        case DOWNLOAD_READY:
          return [{ label: 'Ready for download', value: 'ready' }];
        case ARCHIVED_SHOOT:
          return [
            { label: 'Completed', value: 'completed' },
            { label: 'Canceled', value: 'canceled' }
          ];
        default:
          return [];
      }
    });

    const getPrice = shoot => {
      if (isPhotographer.value) return shoot.photographer_revenue;
      return shoot.price;
    };
    const getStatus = status => {
      return helpers.mapStatus(status);
    };
    const getCategory = rowServiceId => {
      const service = services.value.find(_service => _service.id === rowServiceId) || {};
      return service.name;
    };
    const formatDate = () => {
      const now = new Date();
      return now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, '0') + '-' + now.getDate().toString().padStart(2, '0');
    };

    const triggerExport = async () => {
      const { shoots } = await fetchShoots(1, filters.value, totalCount.value, true);

      const columns = [
        { label: 'Shoot ID', field: row => row.id },
        { label: 'Date', field: row => (row.datetime ? row.datetime.split('T')[0] : 'No Date') },
        { label: 'Customer', field: row => row.customer_name || 'No Customer' },
        { label: 'Location', field: row => (row.address && row.address.city ? row.address.city : 'No City') },
        { label: 'Category', field: row => getCategory(row.services[0].service_id) },
        { label: 'Price', field: row => `${getPrice(row)} AED` },
        { label: 'Status', field: row => getStatus(row.status) }
      ];

      const rows = shoots;

      // Prepare the CSV content
      const csvContent = [columns.map(col => `"${col.label}"`).join(',')]
        .concat(
          rows.map(row =>
            columns
              .map(col => {
                const value = col.field(row); // Accessing the function directly now
                return `"${String(value).replace(/"/g, '""')}"`;
              })
              .join(',')
          )
        )
        .join('\r\n');

      const fileName = `${activeTab.value}_${formatDate()}.csv`; // Append date stamp to filename
      const status = exportFile(fileName, csvContent, 'text/csv');

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        });
      }
    };

    const {
      data: shootData,
      isLoading,
      isRefetching
    } = useQuery({
      queryKey: ['shoots', page, activeTab, filters],
      queryFn: () => fetchShoots(page.value, filters.value),
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const shoots = computed(() => shootData.value?.shoots || []);
    const totalCount = computed(() => shootData.value?.totalCount || 0);
    const totalPages = computed(() => Math.ceil(totalCount.value / 50));

    watch(viewMode, newValue => {
      root.$router.replace({ query: { view: newValue } });
      // Optionally, handle additional logic when view mode changes
    });

    const handleChangedTab = async newValue => {
      activeTab.value = newValue;

      page.value = 1;
      searchQuery.value = '';

      const newFilters = { ...filters.value };
      newFilters.statuses = helpers.retrieveBEStatuses({ isClient: true, status: newValue });
      delete newFilters.services;
      delete newFilters.date;
      delete newFilters.searchQuery;

      searchQuery.value = '';
      filters.value = newFilters;

      selectedServices.value = [];
      selectedDateRange.value = { from: null, to: null };
      selectedStatuses.value = statusOptions.value;
    };

    // Watcher to update filters based on selected statuses
    const statusFilterChanged = () => {
      filters.value = { ...filters.value, statuses: selectedStatuses.value.map(status => status.value) };
    };

    const serviceFilterChanged = () => {
      if (selectedServices.value && selectedServices.value.length > 0) {
        filters.value = { ...filters.value, services: selectedServices.value.map(service => service.value) || [] };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.services;
        filters.value = newFilters;
      }
    };
    const searchQueryChanged = () => {
      if (searchQuery.value !== '') {
        filters.value = { ...filters.value, searchQuery: searchQuery.value };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.searchQuery; // Make sure this matches the property name used when adding to filters
        filters.value = newFilters;
      }
    };

    const validateInput = event => {
      // Regex to allow letters, numbers, and space
      const regex = /^[a-zA-Z0-9 ]+$/;

      // Get the character from the event
      const char = String.fromCharCode(event.charCode || event.keyCode);

      // Check if the character matches the regex
      if (!char.match(regex)) {
        event.preventDefault(); // Prevent the character from being input
      }
    };

    const cityFilterChanged = () => {
      filters.value = { ...filters.value, cities: selectedCities.value.map(city => city.label) };
    };

    const dateFilterChanged = () => {
      if (selectedDateRange.value.from && selectedDateRange.value.to) {
        filters.value = { ...filters.value, date: { from: selectedDateRange.value.from, to: selectedDateRange.value.to } };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.date;
        filters.value = newFilters;
      }
    };

    return {
      shoots,
      totalPages,
      totalCount,
      currentPage: page,
      isLoading,
      isRefetching,
      activeTab,
      activeTabIndex,
      tabs,
      toggleView,
      showFilters,
      handleChangedTab,
      searchQuery,
      selectedStatuses,
      statusOptions,
      statusFilterChanged,
      serviceOptions,
      selectedServices,
      serviceFilterChanged,
      cityFilterChanged,
      citiesOptions,
      selectedCities,
      dateFilterChanged,
      selectedDateRange,
      viewMode,
      searchQueryChanged,
      validateInput,
      triggerExport
    };
  }
};
</script>

<style scoped lang="scss">
.orders-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 12px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr; /* Collapse the grid to a single column */
  }
}

.filters-container {
  width: 300px;
  height: calc(100vh - 180px); /* Adjust 120px to the combined height of other components */
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 18px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1024px) {
    display: none; /* Hide filters container on small screens */
  }
}

.filter-label {
  @include inter-font($size: 12px, $bolded: false, $color: var(--primary-text-color));
  text-align: left;
  margin-bottom: 10px;
}

.pagination-container {
  padding: 12px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  & > span {
    @include inter-font($size: 14px, $bolded: false, $color: var(--primary-text-color));
    margin-left: auto;
  }
}

.q-date {
  min-width: unset !important;
  width: 100%;
  margin-bottom: 12px;
}

.export-btn {
  margin-bottom: 12px;
}
</style>
