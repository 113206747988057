<template>
  <BaseFilter :disabled="disabled" :filter_label="label" :icon="icon">
    <BaseRange :max="rangeValue.max" :min="rangeValue.min" :range="range" :title="label" @value:update="$emit('range:updated', $event)" />
  </BaseFilter>
</template>

<script>
// Base Components
import BaseFilter from '@base/BaseFilter.vue';
import BaseRange from '@base/BaseRange.vue';

export default {
  name: 'RangeFilter',
  props: {
    /**
     * Set disabled filter
     */
    disabled: { default: false, type: Boolean },
    /**
     * Filter icon
     */
    icon: { default: '', type: String },
    /**
     * Filter default label
     */
    label: { default: '', type: String },
    /**
     * Range of values
     */
    range: {
      default: undefined,
      type: Object
    },
    /**
     * Max and min of the range slider
     */
    rangeValue: {
      default: () => ({
        min: 0,
        max: 1000
      }),
      type: Object
    }
  },
  components: {
    BaseFilter,
    BaseRange
  }
};
</script>
