<template>
  <section>
    <div class="selectComunication">
      <template v-for="(typeCard, index) in typesCards">
        <div v-if="!typeCard.disabled" class="selectComunication__card" :key="index" @click="selectCard(index)" :class="{ 'selectComunication__card--selected': selectedCardIndex === index }">
          <h6 class="selectComunication__title">{{ typeCard.titleCard }}</h6>
          <p class="selectComunication__description">{{ typeCard.descriptionCard }}</p>
          <BaseTooltip v-if="typeCard.isTooltipEnabled === true" :tooltip="tooltip" />
        </div>
      </template>
    </div>
  </section>
</template>
<script>
// Store
import { mapGetters } from 'vuex';
import BaseTooltip from '@base/BaseTooltip.vue';

export default {
  name: 'SelectComunication',
  components: {
    BaseTooltip
  },

  data() {
    return {
      selectedCardIndex: null,
      tooltip: this.$t('COMING_SOON')
    };
  },
  computed: {
    ...mapGetters({
      shoot: 'bookShoot/getShoot'
    }),
    typesCards() {
      return [
        {
          titleCard: this.$t('COMMUNICATION.BRIEF.TITLE'),
          descriptionCard: this.$t('COMMUNICATION.BRIEF.DESCRIPTION')
        },
        {
          titleCard: this.$t('COMMUNICATION.TYPEFORM.TITLE'),
          descriptionCard: this.$t('COMMUNICATION.TYPEFORM.DESCRIPTION')
        },
        {
          titleCard: this.$t('COMMUNICATION.WHATSAPP.TITLE'),
          descriptionCard: this.$t('COMMUNICATION.WHATSAPP.DESCRIPTION')
        }
      ];
    }
  },
  methods: {
    // saves the index of the selected item in the SelectedCardIndex property
    selectCard(index) {
      this.selectedCardIndex = index;

      this.$emit('type:selected', this.typesCards[index]);
    }
  }
};
</script>

<style lang="scss" scoped>
.selectComunication {
  column-gap: 62px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  justify-content: center;
  margin-bottom: 20px;
  row-gap: 20px;

  &__card {
    background-color: var(--terziary-text-color);
    border: 3px solid transparent;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    font-family: $inter-regular;
    font-weight: 400;
    height: 200px;
    padding: 0 20px;
    text-align: center;
    width: 300px;

    &--selected {
      border: 3px solid var(--border-color) !important;
    }
  }

  &__title {
    font-family: $inter-regular;
    font-size: calculateRem(16px);
    margin: 0;
    padding-top: 60px;
  }

  &__description {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
  }
}
</style>
