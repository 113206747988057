<template>
  <BaseTabs :tabs="tabs">
    <template #ORDERS>
      <section class="report__cards">
        <StatusRecapCard v-for="(status, index) of cardStatuses" :key="index" :color="status.color" :counter="status.counter" :status="status.label" />
      </section>
      <section class="report__body justify-between mt-35 pb-20">
        <div data-column="left">
          <BaseColumnLineChart :colors="shootsColors" :labels="shootsLabels" :series="shootsSeries" type="light" />
        </div>
        <div data-column="right">
          <p class="report__date-title">{{ $t('SELECT_DATE') }}</p>
          <BaseCalendarInput v-model="dates" range />
          <div class="mv-56">
            <div class="align-middle">
              <p class="report__title">{{ $t('TOTAL') }}</p>
              <div class="report__data">
                <p class="ma-0">{{ totalShoots }}</p>
                {{ $tc('SHOOT.SHOOT', totalShoots) }}
              </div>
              <div class="report__data">
                <p class="ma-0">5000</p>
                {{ $tc('PHOTO', 5000) }}
              </div>
            </div>
            <hr data-theme="dark" class="hr mv-30" />
            <div class="align-middle">
              <p class="report__title">{{ $t('DAILY_AVERAGE') }}</p>
              <div class="report__data">
                <p class="ma-0">{{ dailyAverage }}</p>
                {{ $tc('SHOOT.SHOOT', dailyAverage) }}
              </div>
              <div class="report__data">
                <p class="ma-0">123</p>
                {{ $tc('PHOTO', 123) }}
              </div>
            </div>
          </div>
          <div class="report__actions">
            <BaseButton icon="download" type="default" :label="$t('DOWNLOAD_CSV')" @click.native="downloadCsv" />
          </div>
        </div>
      </section>
    </template>
    <template #PHOTOGRAPHER>
      <UserReportLayout type="photographer" />
    </template>
    <template #CUSTOMER>
      <UserReportLayout type="client" />
    </template>
  </BaseTabs>
</template>

<script>
import moment from 'moment';

// Api
import { ReportApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseCalendarInput from '@base/BaseCalendarInput.vue';
import BaseColumnLineChart from '@base/BaseColumnLineChart.vue';
import BaseTabs from '@base/BaseTabs.vue';

// Components
import StatusRecapCard from '@components/report/StatusRecapCard.vue';
import UserReportLayout from '@components/report/UserReportLayout.vue';

// Shoot helper
import { statuses } from '@utils/shootsHelpers';
import helpers from '@utils/helpers';

const CUSTOMER = 'CUSTOMER';
const ORDERS = 'ORDERS';
const PHOTOGRAPHER = 'PHOTOGRAPHER';

/**
 * View for reports
 */
export default {
  name: 'Report',
  components: {
    BaseButton,
    BaseCalendarInput,
    BaseColumnLineChart,
    BaseTabs,
    StatusRecapCard,
    UserReportLayout
  },
  data() {
    return {
      dates: {},
      payload: {
        filters: {
          time: {}
        }
      },
      reports: [],
      shootsColors: [],
      shootsLabels: [],
      shootsSeries: [],
      tabs: [
        { label: this.$i18n.t('ORDERS').toUpperCase(), value: ORDERS },
        { label: this.$i18n.tc('PHOTOGRAPHER', 0).toUpperCase(), value: PHOTOGRAPHER },
        { label: this.$i18n.tc('CUSTOMER', 0).toUpperCase(), value: CUSTOMER }
      ]
    };
  },
  computed: {
    /**
     * Return the cards statuses
     */
    cardStatuses() {
      const _statuses = [];
      for (const status of statuses) {
        let counter = 0;

        for (const _value of status.value) {
          counter += this.lastElementCounters[_value] ? this.lastElementCounters[_value].total : 0;
        }

        _statuses.push({
          ...status,
          counter
        });
      }

      return _statuses;
    },
    /**
     * Returning the daily average
     */
    dailyAverage() {
      return Math.round(this.totalShoots / this.numberOfDays);
    },
    /**
     * Last element counters
     */
    lastElementCounters() {
      const element = JSON.parse(JSON.stringify(this.reports)).reverse()[0];

      if (!element) return {};

      const key = Object.keys(element)[0];

      return element[key];
    },
    /**
     * Number of days
     */
    numberOfDays() {
      let counter = 0;
      const { from, to } = this.dates;

      if (!from || !to) return 1;

      let now = moment(from.substr(0, 10)).clone();

      while (now.isSameOrBefore(moment(to.substr(0, 10)))) {
        counter += 1;
        now.add(1, 'days');
      }

      return counter;
    },
    /**
     * Returning total shoots
     */
    totalShoots() {
      const shoots = this.lastElementCounters.shoots || {};
      return shoots.total || 0;
    }
  },
  watch: {
    dates: {
      deep: true,
      handler: function (value) {
        this.payload.filters.time = value;
        this.retrieveShootsReport();
      }
    }
  },
  created() {
    // Retrieving current week range (mon-sun dates) to inizialize the dates of the date picker
    const { weekFirstDay, weekLastDay } = helpers.getLastWeekRange();

    this.$set(this.dates, 'from', weekFirstDay);
    this.$set(this.dates, 'to', weekLastDay);
  },
  methods: {
    /**
     * Method used to download csv for shoots report
     */
    downloadCsv() {
      ReportApi.downloadReport({ ...this.dates, type: 'shoot' });
    },
    /**
     * Method used to formrat the shoots report to be displayed in the graph
     */
    formatShootData() {
      // Returning graph colors based on the status
      const colors = statuses.map(({ color }) => color);
      const labels = [];
      const series = [];

      if (this.reports.length === 0) {
        this.shootsLabels = labels;
        this.shootsColors = colors;
        this.shootsSeries = series;

        return;
      }

      // Retrieving the selected datepicker range dates
      const { from, to } = this.dates;
      // Cloning the starting date, we will use this to loop through the dates
      let now = moment(from.substr(0, 10)).clone();

      /**
       * For every day between from and we need to retrieve the reports
       */
      while (now.isSameOrBefore(moment(to.substr(0, 10)))) {
        // Pushing graph x-axis label for every date
        labels.push(`${now.format('ddd')}, ${now.format('DD')}`);

        // Formatting the current looped date to match the dates that backend returns in the report api
        const formattedCompleteDate = now.format('YYYY-MM-DD');

        // Retrieving the status counters for the current lopped date
        const dayCounters = this.reports.find(item => item[formattedCompleteDate] !== undefined) || {};

        // For every status, retrieving the counters. This data will be used in the series to be displayed in the graph
        for (const status of statuses) {
          let statusData = 0;
          const { label, value } = status;
          const counters = dayCounters[formattedCompleteDate] || {};

          for (const _value of value) {
            statusData += counters[_value] ? counters[_value].total : 0;
          }

          statusData = statusData > 0 ? statusData : null;

          const _series = series.find(serie => serie.name === label);

          if (_series) {
            _series.data.push(statusData);
            continue;
          }

          series.push({
            name: label,
            type: 'column',
            data: [statusData]
          });
        }

        now.add(1, 'days');
      }

      this.shootsLabels = labels;
      this.shootsColors = colors;
      this.shootsSeries = series;
    },
    /**
     * Retrieving shoots report
     */
    async retrieveShootsReport() {
      const shootsReport = await ReportApi.shootsReport(this.payload);

      if (shootsReport.statusCode === 200) {
        this.reports = shootsReport.data.reports;
      } else {
        this.reports = [];
      }

      this.formatShootData();
    }
  }
};
</script>

<style lang="scss" scoped>
.report {
  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  &__body {
    > div[data-column='left'] {
      flex: 1;
      margin-right: 10px;
    }

    > div[data-column='right'] {
      min-width: 285px;
    }
  }

  &__cards {
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(139px, 139px));
    row-gap: 10px;
  }

  &__data {
    @include inter-font($size: 14px);

    &:not(:first-child) {
      margin-left: 35px;
    }

    > p {
      @include inter-font($size: 18px, $bolded: true);
    }
  }

  &__date-title {
    @include inter-font($size: 12px);
  }

  &__title {
    @include antonio-font($size: 16px);
    margin-right: auto;
  }
}
</style>
