<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <section
    :class="[
      'locationAndTimeSelection',
      {
        'flex-container': type === `custom` && this.shoot.redeemable_total,
        'grid-container': this.shoot.redeemable_total === undefined || type === `redeem`
      }
    ]"
  >
    <div class="locationAndTimeSelection__location">
      <p class="locationAndTimeSelection__title">{{ $t('SELECT_LOCATION') }}: *</p>
      <BaseLocation v-model="location" mandatory splitted-address :placeholder="$t('WRITE_LOCATION')" />
      <div class="locationAndTimeSelection__info">
        <BaseIcon class="mr-10" icon="info" :custom_style="{ height: '13px', 'min-height': '13px', 'min-width': '13px', width: '13px' }" :stroke="getCssVariable('--secondary-text-color')" />
        {{ $t('ADDRESS_INFO') }}
      </div>
    </div>
    <div class="locationAndTimeSelection__date" v-show="this.shoot.redeemable_total === undefined || type === `express` || type === `redeem`">
      <p class="locationAndTimeSelection__title">{{ $t('SELECT_DAY') }}:</p>
      <BaseCalendar :showButton="false" ref="calendar" class="locationAndTimeSelection__calendar" flat size="small" :default-date="date" :options="filterPastDate" @new-date="date = $event" />
    </div>
    <div>
      <div v-show="type === `custom`">
        <p class="locationAndTimeSelection__title">{{ $t('SELECT_SHOOT_LENGTH') }}:</p>
        <p v-if="shoot.content === 'photography'" v-for="(service, index) in getPackages()" :key="index">
          <small>{{ service.service_name }} : {{ service.numberOfPicture || service.picture_number }} pictures</small>
        </p>
        <p v-if="shoot.content === 'videography'">
          <small>{{ shoot.packages[0].video_number }} videos</small>
        </p>
        <p v-if="shoot.content === 'videography'">
          <small>{{ shoot.packages[0].video_duration }} seconds each</small>
        </p>
        <div class="locationAndTimeSelection__time">
          <p>{{ $t('HOURS') }}</p>
          <BaseNumberInput v-model="hours" :step="0.5" class="locationAndTimeSelection__hours-input" :disabled="type === `express`" :min="1" />
        </div>
        <hr class="locationAndTimeSelection__hr" />
      </div>
      <div v-show="type === `redeem` || type === `express` || this.shoot.redeemable_total === undefined">
        <div class="locationAndTimeSelection__time">
          <p class="locationAndTimeSelection__title">{{ $t('SELECT_TIME') }}:</p>
          <p class="locationAndTimeSelection__hours" v-if="type === 'express' || isSubClient">{{ $tc('HOURS_COUNT', hours) }}</p>
        </div>
        <div class="locationAndTimeSelection__time">
          <p>{{ $t('FROM') }}</p>
          <BaseSelect v-model="time.from" :clearable="false" :options="getFromTimeStops" />
        </div>
        <div class="locationAndTimeSelection__time locationAndTimeSelection__time--to">
          <p>{{ $t('TO') }}</p>
          <p>{{ getTimeToStops() }}</p>
        </div>
      </div>
      <hr class="locationAndTimeSelection__hr" />
    </div>
    <p class="mandatory">{{ $t('MANDATORY_FIELD') }}</p>
  </section>
</template>

<script>
import { date } from 'quasar';

// Store
import { mapGetters } from 'vuex';

// Base Components
import BaseCalendar from '@base/BaseCalendar.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseLocation from '@base/BaseLocation.vue';
import BaseNumberInput from '@base/BaseNumberInput.vue';
import BaseSelect from '@base/BaseSelect.vue';
import moment from 'moment';

/**
 * Component used to handle new shoot registration datetime and location
 *
 * @display LocationAndTimeSelection
 */
export default {
  name: 'LocationAndTimeSelection',
  components: {
    BaseCalendar,
    BaseIcon,
    BaseLocation,
    BaseNumberInput,
    BaseSelect
  },
  props: {
    /**
     * use to set the edit mode of shoot
     */
    editMode: { default: false, type: Boolean },
    type: { default: '', type: String },
    /**
     * use to set the visibility of the date
     */
    visible: { default: true, type: Boolean }
  },
  data() {
    return {
      date: '',
      decideLater: false,
      location: {
        formatted_address: ''
      },
      time: {
        from: '08:00 am',
        to: ''
      },
      hours: 1,
      setDate: false
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isSubClient: 'user/isSubClient',
      services: 'services/getServices',
      shoot: 'bookShoot/getShoot'
    }),
    defaultDate() {
      const datetime = this.editMode ? this.shoot.datetime : this.shoot.time.from;
      if (this.editMode && this.shoot.time.from) return this.shoot.time.from;

      if (!this.editMode && !this.shoot.time.from) return '';

      const [year, month, day] = datetime.substr(0, 10).split('-');

      return `${day}/${month}/${year}`;
    },
    /**
     * Returns the available time range for the from select
     */
    getFromTimeStops() {
      return this.getTimeStops({ start: '00:00', end: '23:59' });
    },
    /**
     * Returns the correct label
     */
    getDateLabel() {
      return this.shoot.redeemable_total != undefined && !this.shoot.redeemable_counter ? this.$t('SET_DATE_FIRST_SHOOT') : this.$t('SET_DATE_SHOOT');
    },
    /**
     * Returns the available time range for the to select
     */
    getToTimeStops() {
      return this.getTimeToStops();
    },
    timeFrom() {
      return this.editMode ? this.fromDateTimeToDateAndTimeRange({ duration: this.shoot.time.duration, from: this.shoot.time.from || this.shoot.datetime }).fromTime : this.time.from;
    }
  },
  watch: {
    /**
     * Prevent cancelling time from (set it to the previous value)
     */
    timeFrom(val, oldVal) {
      if (!val) this.time.from = oldVal;
    },
    hours() {
      this.handeleDateTime();
    },
    date() {
      this.handeleDateTime();
    },
    /**
     * when the value of setDate changes, the value of 'visible' changes, and is output to the parent along with the duration
     */
    setDate() {
      // this.decideLater = !this.decideLater;
      // this.$emit('date:skip', this.decideLater);
      this.$emit('update:datetime', { duration: this.hours });
    },
    // decideLater() {
    //   this.$refs.calendar.handleCancel();
    //   this.$emit('date:skip', this.decideLater);
    //   this.$emit('update:datetime', { duration: this.hours });
    // },
    location(newVal, oldVal) {
      this.$emit('update:location', this.location);
    },
    time: {
      deep: true,
      handler: function () {
        this.handeleDateTime();
      }
    }
    // visible() {
    //   this.decideLater = this.visible;
    // }
  },
  created() {
    if (this.shoot.location && !this.editMode) this.location = this.shoot.location;
    if (this.shoot.location && this.editMode) this.location = this.shoot.location.value;

    if (this.shoot.time.from || this.shoot.datetime) {
      this.date = this.defaultDate;

      const timeStartToEdit = this.shoot.time.from
        ? this.fromDateTimeToDateAndTimeRange({ duration: this.shoot.time.duration, from: this.shoot.time.from }).fromTime
        : this.fromDateTimeToDateAndTimeRange({ duration: this.shoot.time.duration, from: this.shoot.datetime }).fromTime;

      this.editMode ? this.$set(this.time, 'from', timeStartToEdit) : this.$set(this.time, 'from', moment(this.shoot.time.from.substr(12, 5), ['HH:mm']).format('hh:mm A'));
    }

    this.hours = this.shoot.packages[0].duration || this.shoot.time.duration / 60;
    this.time.to = this.getTimeToStops();
    // this.decideLater = this.editMode ? !this.visible : this.visible;

    if (this.editMode && this.date) {
      this.setDate = true;
      // this.decideLater = true;
    }

    // if (this.isSubClient) this.decideLater = false;
  },
  methods: {
    /**
     * This method check if a date is in the past, if so its not selectable
     */
    filterPastDate(_date) {
      return this.isAdmin ? true : _date > date.formatDate(Date.now(), 'YYYY/MM/DD');
    },
    /**
     * get the packages
     */
    getPackages() {
      const pkgs = this.shoot.packages.map(pkg => {
        const s = this.services.find(_service => _service.id === Number(pkg.service_id)) || {};
        return {
          ...pkg,
          service_name: s.name
        };
      });
      return pkgs;
    },
    /**
     * Given a starting hour and an end hour, it returns an array of timeslot with 15 minutes range.
     * ex.: 08:00, 08:15, 08:30
     */
    getTimeStops({ end, start }) {
      var startTime = moment(start, 'HH:mm');
      var endTime = moment(end, 'HH:mm');

      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      var timeStops = [];

      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format('HH:mm a'));
        startTime.add(15, 'minutes');
      }
      return timeStops;
    },
    /**
     * Function to get the final time of the shoot
     */
    getTimeToStops() {
      const startTime = moment(this.time.from, 'HH:mm a');
      const stopTime = startTime.add(this.hours, 'hours').format('HH:mm a');

      return stopTime;
    },

    handeleDateTime() {
      if (!this.date) {
        this.$emit('update:datetime', { duration: this.hours });
        return;
      }

      if (this.date === moment.utc(this.date).format()) {
        const [year, month, day] = this.date.substr(0, 10).split('-');
        this.date = `${day}/${month}/${year}`;
      }

      const dateToFormat = moment.utc(this.date, 'DD/MM/YYYY').format('YYYY-MM-DD');

      const [year, month, day] = dateToFormat.substr(0, 10).split('-');

      const formattedFromTime = moment(this.time.from, ['h:mm A']).format('HH:mm');

      const formattedToTime = moment(this.time.from, ['h:mm A']).add(this.hours, 'hours').format('HH:mm');

      const [hoursFrom, minutesFrom] = formattedFromTime.split(':');

      const dateTimeFrom = moment.utc([Number(year), Number(month) - 1, Number(day), Number(hoursFrom), Number(minutesFrom)]).format();

      const [hours, minutes] = formattedToTime.split(':');
      const dateTimeTo = moment.utc([Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes)]).format();

      if (moment(dateTimeFrom).isValid() && moment(dateTimeTo).isValid()) this.$emit('update:datetime', { from: dateTimeFrom, duration: this.hours });
    }
  }
};
</script>

<style lang="scss">
.flex-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 550px !important;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 62px;
}

.mandatory {
  position: absolute;
  bottom: 24px;
}

.locationAndTimeSelection {
  position: relative;
  &__alert {
    display: inline-flex;
    margin-top: 20px;
    > p {
      line-height: 0.8rem;
    }
  }
  &__icon {
    margin-right: 10px;
  }
  &__calendar {
    margin-bottom: 30px;
  }

  &__date {
    text-align: left;
  }

  &__hours {
    @include inter-font($bolded: true, $size: 12px);
  }

  &__hours-input {
    width: 118px !important;
    height: 40px !important;
  }

  &__hr {
    background-color: var(--grey-hr);
    border: unset;
    height: 2px;
    margin: 0;
  }

  &__info {
    @include inter-font($size: 11px, $color: var(--secondary-text-color));
    align-items: flex-start;
    display: flex;
    margin-top: 20px;
  }

  &__checkbox {
    .q-checkbox__inner {
      font-size: calculateRem(12px) !important;
    }
    &__setDate {
      margin-bottom: 30px;
    }
  }

  &__location {
    text-align: left;
    margin: 0 !important;
    padding: 0 !important;
    flex: 0.25;
    margin-bottom: 24px;
  }
  p {
    text-align: left;
    > small {
      color: #8f8f8f;
    }
  }

  &__time {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 14.5px 0;

    > p {
      color: var(--main-text-color);
      margin: 0;
      max-width: 50%;
      overflow-x: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .baseSelect {
      padding-bottom: 0;
      width: max-content;
    }
  }

  &__title {
    @include inter-font($bolded: false, $size: 14px);
    margin: 0;
    margin-bottom: 20px;
    text-align: left;
    &--second {
      padding-top: 35px;
    }
  }
}
</style>
