import { render, staticRenderFns } from "./BaseRadioInput.vue?vue&type=template&id=c6ed709c&"
import script from "./BaseRadioInput.vue?vue&type=script&lang=js&"
export * from "./BaseRadioInput.vue?vue&type=script&lang=js&"
import style0 from "./BaseRadioInput.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QRadio,QColor});
