<template>
  <section class="book">
    <component
      class="book__stepper"
      :is="bookWizard"
      @validation="nextDisabled = $event"
      @stepper:completed="isStepperCompleted = $event"
      @hide-next="hideNext = $event"
      @update:query="queryType = $event"
    />
    <div class="book__footer">
      <BaseButton v-if="step > 1" data-type="back-button" icon="angular-arrow" label="Back" :icon_style="{ height: '23px', transform: 'rotate(180deg)', width: '16px' }" @click.native="handleBack" />
      <p v-if="emailSent" class="book__email-sent mb-0" @click="sendEmail">{{ $t('SEND_EMAIL_OPERATIONS') }}</p>
      <BaseButton
        v-if="!hideNext"
        class="book__right-button"
        :type="!nextDisabled ? 'default' : ''"
        icon-on-right
        :disabled="nextDisabled || (typeof validPhone === 'boolean' && !validPhone) || (typeof validEmail === 'boolean' && !validEmail)"
        :icon="rightButtonIcon"
        :label="rightButtonLabel"
        :icon_style="{ height: '23px', width: '16px' }"
        :loading="nextLoading"
        @click.native="handleNextStep"
      />
    </div>
    <a ref="mailto" href="mailto:operations@flashy.ae?subject=New shoot brief" style="display: none"></a>
  </section>
</template>

<script>
// Api
import { ShootsApi } from '@api/index';

// Vuex
import { mapActions, mapGetters } from 'vuex';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseStepper from '@base/BaseStepper.vue';

// Components
import AdminBook from './AdminBook.vue';
import BusinessBook from './BusinessBook.vue';

// Utils
import { REDEEMABLE } from '@utils/shootsHelpers';
// Helpers
import helpers from '@/utils/helpers';

/**
 * Component used as view for book new shoot wizard
 *
 * @displayName Book
 */
export default {
  name: 'Book',
  components: {
    BaseButton,
    BaseStepper
  },
  data() {
    return {
      dateSkip: false,
      emailSent: false,
      nextDisabled: true,
      nextLoading: false,
      hideNext: false,
      isStepperCompleted: false,
      queryType: '',
      showConfirm: false,
      steps: []
    };
  },
  computed: {
    ...mapGetters({
      activeSteps: 'stepper/getSteps',
      brief: 'bookShoot/getBrief',
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      shoot: 'bookShoot/getShoot',
      step: 'stepper/getStep',
      validPhone: 'bookShoot/getValidPhone',
      validEmail: 'bookShoot/getValidEmail'
    }),
    bookWizard() {
      if (this.isAdmin) return AdminBook;
      if (this.isClient) return BusinessBook;

      return null;
    },
    /**
     * Check if the shoot is redeemable
     */
    isRedeemable() {
      return this.shoot.status === REDEEMABLE;
    },
    /**
     * Check if the current step is the upload brief
     */
    isUploadBriefStep() {
      const { name } = this.activeSteps[this.step - 1] || {};
      return name === 'UPLOAD_BRIEF';
    },
    /**
     * Style of the right button
     */
    nextStepButtonStyle() {
      return {
        'background-color': helpers.getCssVariable('--terziary-btn-color'),
        height: '44px'
      };
    },
    /**
     * Right button action icon
     */
    rightButtonIcon() {
      const icon = {
        true: '',
        false: 'angular-arrow'
      };

      return icon[`${this.isStepperCompleted}`];
    },
    /**
     * Right button action label
     */
    rightButtonLabel() {
      if (this.isUploadBriefStep) return this.emailSent ? this.$tc('STATUSES.UPCOMING_SHOOT', 0) : this.$t('SEND_EMAIL');

      const label = {
        true: this.$t('CONFIRM'),
        false: this.$t('NEXT_STEP')
      };

      return label[`${this.isStepperCompleted}`];
    }
  },
  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      goToNextStep: 'stepper/goToNextStep',
      goToPrevStep: 'stepper/goToPrevStep',
      resetState: 'bookShoot/resetState',
      showSuccessModal: 'shoot/handleOpenSuccessAlert',
      showSuccessNotification: 'notification/addSuccessNotification'
    }),
    async createShoot() {
      this.nextDisabled = true;
      this.nextLoading = true;
      const shoot = await ShootsApi.createShoot({ payload: this.shoot });

      if (shoot.statusCode === 200 && this.brief) {
        const blob = await new Blob([this.brief]);
        if (shoot.data.shoot.redeemable_shoot_id) {
          ShootsApi.uploadBrief({ id: shoot.data.shoot.redeemable_shoot_id, blob });
        } else ShootsApi.uploadBrief({ id: shoot.data.shoot.id, blob });
      }

      this.$store.dispatch('alert/sendAlert', {
        type: this.shoot.type,
        isAdmin: this.isAdmin,
        redeemable: this.shoot.redeemable_total
      });
      this.$store.dispatch('shoot/handleOpenSuccessAlert');
      this.$router.push({ name: 'orders', query: { refetch: 'true' } });
      this.nextLoading = false;
    },
    /**
     * Handle back button click
     */
    handleBack() {
      this.goToPrevStep();
      this.emailSent = false;
    },
    handleNextStep() {
      if (this.isStepperCompleted && this.isRedeemable) {
        this.redeemShoot(this.shoot);
        if (this.shoot.redeemable_total > 0) this.shoot.redeemable_counter = this.shoot.redeemable_counter - 1;

        return;
      }
      if (this.isStepperCompleted && this.queryType != '') {
        this.editShoot(this.shoot);
        return;
      }

      if (this.isStepperCompleted) {
        if (this.isUploadBriefStep) {
          if (this.emailSent) {
            this.$router.push({ name: 'orders', query: { refetch: 'true' } });
            return;
          }

          this.sendEmail();
          this.emailSent = true;
          return;
        }

        this.createShoot();
        // this.showConfirm = true;
        return;
      }

      this.nextDisabled = true;
      this.goToNextStep();
    },
    /**
     * Method used to send email
     */
    sendEmail() {
      this.$refs.mailto.click();
    },

    /**
     * Redeeming shoot
     */
    async redeemShoot(item) {
      this.nextDisabled = true;
      this.nextLoading = true;
      const redeemShootResource = await ShootsApi.createShoot({
        payload: {
          location: {
            ...item.location
          },
          name: item.name,
          notes: item.notes,
          outlet_code: item.outlet_code,
          outlet_name: item.outlet_name,
          poc_name: (item.poc_name !== '' && item.poc_name) || null,
          poc_email: (item.poc_email !== '' && item.poc_email) || null,
          poc_phone: (item.poc_phone !== '' && item.poc_phone) || null,
          time: {
            duration: item.time.duration,
            from: item.time.from
          },
          redeemable_shoot_id: item.redeemable_shoot_id
        }
      });

      if (redeemShootResource.statusCode === 200) {
        this.showSuccessModal();
        this.$router.push({ name: 'orders', query: { refetch: 'true' } });
        this.nextLoading = false;
      }
    },
    /**
     * editing a shoot
     */
    async editShoot(item) {
      this.nextLoading = true;
      const editShootResource = await ShootsApi.editShoot({
        id: item.id,
        payload: {
          location: {
            ...item.location
          },
          name: item.name,
          notes: item.notes,
          packages: [
            {
              picture_number: item.packages[0].picture_number,
              service_id: item.packages[0].service_id,
              video_number: item.packages[0].video_number,
              video_duration: item.packages[0].video_duration
            }
          ],
          time: {
            duration: item.time.duration,
            from: item.time.from
          },
          total_price: item.total_price,
          total_revenue: item.total_revenue,
          photographer_id: item.photographer_id ? item.photographer_id : null
        }
      });

      const { statusCode, data } = editShootResource;
      if (statusCode === 200) {
        // If the selected photographer is different than the current shoot id, send a request to BE to change it
        this.nextLoading = false;
        this.showSuccessNotification(this.$t('NOTIFICATIONS.SHOOTS.EDIT', { id: item.id }));
        this.$router.push({ name: 'orders', query: { refetch: 'true' } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.book {
  $horizontal-padding: 171px;

  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  padding-top: 56px;

  &__email-sent {
    @include inter-font($size: 14px);
    align-items: center;
    display: flex;
  }

  &__footer {
    background-color: var(--secondary-bg-color);
    display: flex;
    height: $category-height;
    justify-content: space-between;
    padding: 16px $horizontal-padding;

    > button[data-type='back-button'] {
      margin-left: unset;
      margin-right: auto;
    }

    > button[data-type='next-button'] {
      margin-left: auto;
      margin-right: unset;
    }
  }

  &__right-button {
    margin-left: auto;
  }

  &__selectBusinessAccount {
    margin-top: 20px;
  }

  &__selectType {
    margin-top: 80px;
  }

  &__step {
    padding-top: 51.5px;
  }

  &__stepper {
    justify-self: center;
    overflow-y: auto;
    padding-right: 10px;
    width: 75%;
  }

  &__subtitle {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    font-weight: bold;
    margin: 0;
    text-align: left;
  }
}
</style>
