<template>
  <CategoryActionLayout :is-confirm-disabled="isConfirmDisabled" @confirm:action="handleConfirmChange" @go-back="$emit('go-back')">
    <section class="editCategory">
      <p class="editCategory__title">{{ $t('ADD_CATEGORY') }}</p>
      <div :class="['editCategory__name', { 'editCategory__name--has-border': !hasImage }]">
        <div class="editCategory__input">
          <BaseInput v-model="name" borderless :clearable="false" :placeholder="$t('WRITE_NAME')" />
        </div>
        <BaseImage v-if="hasImage" class="editCategory__image" :src="serviceImage" />
      </div>
      <BaseFileUploader class="editCategory__uploadFile ml-21" :label="$t('CHOOSE_CATEGORY_PICTURE')" @update:files="handlePictureSelection" />
    </section>
  </CategoryActionLayout>
</template>

<script>
// Api
import { MediaApi, ServiceApi } from '@api/index';

// Components
import CategoryActionLayout from '@components/package/CategoryActionLayout.vue';

// Base Components
import BaseFileUploader from '@base/BaseFileUploader.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseImage from '@base/BaseImage.vue';

/**
 * Component used to edit and createing new categories
 *
 * @displayName EditCategory
 */
export default {
  name: 'EditCategory',
  components: {
    BaseFileUploader,
    BaseImage,
    BaseInput,
    CategoryActionLayout
  },
  props: {
    /**
     * Use this prop to pass the category to be edited
     */
    service: { default: null, type: Object }
  },
  data() {
    return {
      blob: null,
      name: '',
      picture: null,
      serviceImage: '',
      tooltip: this.$t('COMING_SOON')
    };
  },
  computed: {
    /**
     * Set to true if an image is available to be shown
     */
    hasImage() {
      return this.picture || !this.isServiceEmpty;
    },
    /**
     * Returns true if service is empty
     */
    isServiceEmpty() {
      return Object.keys(this.service).length === 0;
    },
    /**
     * Returns true if the name has not being picked
     */
    isConfirmDisabled() {
      return !this.name;
    }
  },
  created() {
    if (this.service) {
      this.name = this.service.name;
      this.serviceImage = this.getServiceImage(this.service.id);
    }
  },
  methods: {
    /**
     * Method used to create a new service
     */
    async createService() {
      return await ServiceApi.create({ name: this.name });
    },
    /**
     * Method used to handle the confirm button click.
     * 1. if a service is passed in the props, update that sevice;
     * 2. otherwise create a new one
     */
    async handleConfirmChange() {
      if (!this.isServiceEmpty) {
        await this.updateService();
        await this.updateImage();
      } else {
        const serviceResource = await this.createService();

        if (serviceResource.statusCode === 200) await this.updateImage(serviceResource.data.service.id);
      }

      this.$emit('refresh:services');
    },
    /**
     * Method used to handle the selection of a new picture for the category
     */
    async handlePictureSelection($event) {
      this.picture = $event;

      this.blob = await this.zipFiles({ files: [this.picture] });

      await this.$nextTick();

      this.serviceImage = URL.createObjectURL($event);
    },
    /**
     * Method used to update service image
     */
    async updateImage(id = this.service.id) {
      if (!this.blob) return;

      const imageResource = await MediaApi.uploadServicePictures({ id, blob: this.blob });

      if (imageResource.statusCode === 200) this.$store.dispatch('notification/addSuccessNotification', 'Service image updated!');
    },
    /**
     * Method used to update a given sercice
     */
    async updateService() {
      await ServiceApi.update({ id: this.service.id, name: this.name });
    }
  }
};
</script>

<style lang="scss">
.editCategory {
  align-items: center;
  display: flex;
  @include responsive($max: md) {
    flex-direction: column;
  }

  .baseButton {
    .q-focus-helper {
      background-color: var(--secondary-bg-color);
      opacity: 0.1;
    }
  }

  .baseFileUploader {
    .baseButton__label {
      @include inter-font($size: 10px, $color: #fff);
    }
  }

  &__input {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__image {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__name {
    border-radius: 10px;
    height: 76px;
    position: relative;
    overflow: hidden;
    width: 240px;

    &--has-border {
      border: 1px dotted var(--terziary-text-color);
    }

    .baseInput {
      padding-bottom: 0;
      position: relative;
      width: 100%;
      z-index: 2;

      .q-field__control {
        input {
          color: var(--secondary-text-color) !important;
        }
      }
    }
    @include responsive($max: md) {
      margin-bottom: 20px;
    }
  }
  &__title {
    display: none;
    @include responsive($max: md) {
      display: block;
      color: var(--terziary-text-color);
    }
  }
  &__uploadFile {
    > .baseButton {
      background-color: var(--btn-upload-bg);
    }
  }
}
</style>
