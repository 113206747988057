<template>
  <BaseStepper class="businessBook" handle-navigation no-navigator :steps="steps">
    <template #header>
      <div class="businessBook__header">
        <span class="businessBook__title">{{ $t('BOOK_SHOOT') }}</span>
        <p v-if="step > 1 && type === $t('CLIENT_CUSTOM')" class="businessBook__pagination">{{ $t('STEP') }} {{ step - 1 }}/{{ steps.length - 1 }}</p>
      </div>
    </template>
    <template #SELECT_TYPE>
      <div class="businessBook__step">
        <p class="businessBook__subtitle">{{ $t('SELECT_BOOKING_TYPE') }}:</p>
        <ShootTypeCard class="businessBook__selectType" @update:type="handleTypeSelected" />
      </div>
    </template>
    <template #SELECT_CAT>
      <div class="businessBook__step">
        <StandardPackage @packages:updated="handlePackagesUpdate" />
      </div>
    </template>
    <template #SELECT_DATETIME>
      <LocationAndTimeSelection :type="type" class="businessBook__step" @date:skip="handleDateSkip" @update:datetime="handleUpdateDatetime" @update:location="handleUpdateLocation" />
    </template>
    <template #SUMMARY>
      <BookRecap class="businessBook__step" @update:notes="handleUpdateNotes" @update:shoot="handleUpdateShoot" />
    </template>
    <template #SELECT_COMMUNICATION>
      <div class="businessBook__step">
        <p class="businessBook__subtitle">{{ $t('SELECT_COMMUNICATION') }}</p>
        <SelectComunication class="businessBook__step" @type:selected="handleCommunicationClick" />
      </div>
    </template>
    <template #WHATSAPP>
      <div class="businessBook__step">
        <Whatsapp class="businessBook__step" />
      </div>
    </template>
    <template #TYPEFORM>
      <div id="form" class="full-height"></div>
    </template>
    <template #UPLOAD_BRIEF>
      <UploadBrief class="businessBook__step" @file:update="handleFileUpdate" />
    </template>
  </BaseStepper>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Base Components
import BaseStepper from '@base/BaseStepper.vue';

// Components
import BookRecap from '@components/shoot/book/BookRecap.vue';
import LocationAndTimeSelection from '@components/shoot/book/LocationAndTimeSelection.vue';
import SelectComunication from '@components/shoot/book/SelectComunication.vue';
import StandardPackage from '@components/shoot/book/StandardPackage.vue';
import UploadBrief from '@components/shoot/book/UploadBrief.vue';
import Whatsapp from '@components/shoot/book/Whatsapp.vue';

//Card type
import ShootTypeCard from '@/components/ShootTypeCard.vue';

import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

/**
 * Component used as view for book new shoot wizard
 *
 * @displayName Book
 */
export default {
  name: 'BusinessBook',
  components: {
    BaseStepper,
    BookRecap,
    LocationAndTimeSelection,
    ShootTypeCard,
    SelectComunication,
    StandardPackage,
    UploadBrief,
    Whatsapp
  },
  data() {
    return {
      communicationType: null,
      dateSkip: false,
      nextDisabled: true,
      steps: [
        {
          name: 'SELECT_COMMUNICATION'
        },
        {
          name: 'UPLOAD_BRIEF'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getBrief: 'bookShoot/getBrief',
      isSubClient: 'user/isSubClient',
      shoot: 'bookShoot/getShoot',
      step: 'stepper/getStep',
      user: 'user/getUser'
    }),
    /**
     * True if on last step
     */
    isStepperCompleted() {
      return this.step === this.steps.length;
    },
    type() {
      return this.shoot.type;
    }
  },
  watch: {
    step() {
      this.handleNextButton();
      this.validateStep();
    },
    isStepperCompleted() {
      this.$emit('stepper:completed', this.isStepperCompleted);
    },
    communicationType() {
      if (this.communicationType === this.$t('COMMUNICATION.WHATSAPP.TITLE')) {
        this.steps = [
          {
            name: 'SELECT_COMMUNICATION'
          },
          {
            name: 'WHATSAPP'
          }
        ];
      }

      if (this.communicationType === this.$t('COMMUNICATION.TYPEFORM.TITLE')) {
        this.steps = [
          {
            name: 'SELECT_COMMUNICATION'
          },
          {
            name: 'TYPEFORM'
          }
        ];
      }

      if (this.communicationType === this.$t('COMMUNICATION.BRIEF.TITLE')) {
        this.steps = [
          {
            name: 'SELECT_COMMUNICATION'
          },
          {
            name: 'UPLOAD_BRIEF'
          }
        ];
      }
    }
  },
  created() {
    const { query = {} } = this.$route;
    const { type = '' } = query;

    /**
     * If the type is redeem but the current shoot does not have any status
     * it means that the user refreshed the page and lost the store data. Redirect the user to the orders page.
     */
    if (type && type === 'redeem' && !this.shoot.status) {
      this.$router.push('/orders');
      return;
    }

    if (type === 'express' || type === 'redeem') {
      this.steps = [
        {
          name: 'SELECT_DATETIME'
        },
        {
          name: 'SUMMARY'
        }
      ];
    }

    if (type === 'express-pay') {
      this.steps = [
        {
          name: 'SUMMARY'
        }
      ];
    }
  },
  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      goToNextStep: 'stepper/goToNextStep',
      goToPrevStep: 'stepper/goToPrevStep',
      resetState: 'bookShoot/resetState',
      updateBrief: 'bookShoot/updateBrief',
      updateCompanyName: 'bookShoot/updateCompanyName',
      updateShoot: 'bookShoot/updateShoot'
    }),
    handleCommunicationClick($event) {
      this.communicationType = $event.titleCard;
      this.validateStep();
    },
    async handleNextButton() {
      await this.$nextTick();

      const whatsappCaseHidden = this.communicationType === this.$t('COMMUNICATION.WHATSAPP.TITLE') && this.step === this.steps.length;
      const expressHidden = this.type === 'express' && this.step === this.steps.length;
      const typeForm = this.communicationType === this.$t('COMMUNICATION.TYPEFORM.TITLE') && this.step === this.steps.length;

      if (typeForm) {
        const formElement = this.$el.querySelector('#form');
        if (formElement) createWidget('e3zxmJWw', { container: formElement });
      }

      this.$emit('hide-next', whatsappCaseHidden || expressHidden || typeForm);
    },
    handlePackagesUpdate($event) {
      const packages = [];
      let duration = 0;

      for (const _package of $event) {
        packages.push({
          name: _package.name,
          package_id: _package.id,
          price: _package.price,
          service_id: _package.service_id,
          duration: _package.duration,
          picture_number: _package.picture_number
        });

        duration += _package.duration * 60;
      }

      this.updateShoot({
        name: $event[0].name,
        packages,
        time: {
          duration
        }
      });

      this.validateStep();
    },
    handleDateSkip($event) {
      this.dateSkip = $event;

      this.validateStep();
    },
    handleFileUpdate($event) {
      this.updateBrief($event);

      this.validateStep();
    },
    handleTypeSelected($event) {
      this.updateShoot({
        type: $event
      });

      this.validateStep();
    },
    handleUpdateDatetime($event) {
      const { from, duration } = $event;

      let time = {
        duration: duration * 60
      };

      if (from && duration)
        time = {
          duration: duration * 60,
          from
        };

      this.updateShoot({
        time
      });

      this.validateStep();
    },
    handleUpdateLocation($event) {
      this.updateShoot({
        location: $event
      });

      this.validateStep();
    },
    /**
     * Method used to update the optional notes of the shoot
     */
    handleUpdateNotes($event) {
      this.updateShoot({
        notes: $event
      });
    },

    /**
     * Method used to update the shoot details
     */
    handleUpdateShoot($event) {
      const { value, type } = $event;

      this.updateShoot({
        [type]: value
      });

      this.validateStep();
    },

    /**** VALIDATIONS ****/

    /**
     * Check if the first step is valid and can move onto the next one
     * Return true if its invalid
     */
    firstStepValidation() {
      return Object.keys(this.shoot.type).length === 0;
    },
    /**
     * Check if the second step is valid and can move onto the next one
     * Return true if its invalid
     */
    secondStepValidation() {
      return this.shoot.packages.length === 0;
    },
    /**
     * Check if the third step is valid and can move onto the next one
     * Return true if its invalid
     */
    thirdStepValidation() {
      return Object.keys(this.shoot.location).length === 0 || !this.shoot.time.duration || !this.shoot.time.from;
    },
    /**
     * Check if the summary step is valid and can move onto the next one
     * Return true if its invalid
     */
    summaryStepValidation() {
      // On sub client outlet name and outlet code cannot be empty
      return this.isSubClient ? !this.shoot.outlet_name || !this.shoot.outlet_code || !this.shoot.poc_name || !this.shoot.poc_email || !this.shoot.poc_phone : false;
    },
    validateStep() {
      let validations;

      validations = {
        1: () => false,
        2: () => this.communicationType === null,
        3: () => false
      };

      const { query = {} } = this.$route;
      const { type = '' } = query;

      if (type === 'redeem') {
        validations = {
          1: this.thirdStepValidation,
          2: this.summaryStepValidation
        };
      }

      const validation = validations[this.step] === undefined ? false : validations[this.step]();
      this.$emit('validation', validation);
    }
  }
};
</script>

<style lang="scss" scoped>
.businessBook {
  &__clientName {
    align-items: flex-end;
    color: var(--secondary-text-color);
    display: flex;
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    font-style: italic;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__selectBusinessAccount {
    margin-top: 20px;
  }

  &__selectType {
    margin-top: 51.5px;
  }

  &__step {
    padding-top: 51.5px;
  }

  &__subtitle {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    margin: 0;
    text-align: left;
  }

  &__pagination {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(16px);
    margin: 0;
    text-align: left;
  }

  &__title {
    @include antonio-font($size: 20px, $color: var(--main-text-color));
  }
}
</style>
