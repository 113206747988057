<template>
  <div :data-type="type" class="baseTable">
    <q-infinite-scroll ref="infiniteScroll" @load="(index, done) => $emit('load-shoots', index, done)">
      <p v-if="items.length === 0">{{ $t('NO_DATA') }}</p>
      <template v-for="(item, index) in items" v-else>
        <div v-if="item" :key="index" class="baseTable__shoot" :class="{ baseTable__subfirst: hasSubClients(item) }">
          <template v-for="(field, id) in itemsFields">
            <div v-if="getName({ slot: field, item })" :key="`field_${id}`" class="baseTable__shoot-slot">
              <slot :name="getName({ slot: field, item })" v-bind:item="item" />
            </div>
          </template>
          <div v-if="$scopedSlots['row-actions'] !== undefined" style="margin-left: auto; margin-right: 8px">
            <slot name="row-actions" v-bind:item="item" />
          </div>
          <ButtonRightArrow v-if="!hideButton" class="baseTable__shoot-action" @click.native="$emit('item:open', item)" />
        </div>
        <slot v-if="hasSubClients(item)" name="sub-client" v-bind:item="item" />
      </template>
      <template v-slot:loading>
        <div class="row justify-center q-my-md">
          <q-spinner-dots color="primary" size="40px" />
        </div>
      </template>
    </q-infinite-scroll>
  </div>
</template>

<script>
// Common component
import ButtonRightArrow from '@common/ButtonRightArrow.vue';

/**
 * Component used to create a base table, with infinite scroll functionality
 */
export default {
  name: 'BaseTable',
  components: {
    ButtonRightArrow
  },
  props: {
    /**
     * Hide action button
     */
    hideButton: { default: false, type: Boolean },
    infiniteScrollDisabled: { default: false, type: Boolean },
    isClient: { default: false, type: Boolean },
    /**
     * Table items to be shown
     */
    items: { default: () => [], type: Array },
    /**
     * Columns of the tables
     */
    itemsFields: { default: () => [], type: Array },
    /**
     * Type of table (standard, borderless)
     */
    type: { default: 'standard', type: String }
  },
  mounted() {
    // Disable the infinite scroll if not necessary
    if (this.infiniteScrollDisabled) this.$refs.infiniteScroll.stop();
  },
  methods: {
    /**
     * Get slot name
     */
    getName({ slot: field, item }) {
      if (typeof field === 'string') return field;

      return field(item);
    },
    hasSubClients(itm) {
      return itm.subclients?.length > 0 && this.isClient && this.$scopedSlots['sub-client'] !== undefined;
    }
  }
};
</script>

<style lang="scss">
.baseTable {
  &[data-type='borderless'] {
    .baseTable__shoot-slot {
      border-right: none;
      flex: 1;
    }
  }

  &__shoot {
    align-items: center;
    background-color: var(--shoos-list-shoot-bg);
    border-radius: 10px;
    display: flex;
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    margin-bottom: 10px;
    padding: 10px;
    position: relative;
    text-align: left;
    &:last-child {
      margin-bottom: none;
    }
    @include responsive($max: md) {
      padding: 5px;
    }

    &-slot {
      border-right: 2px solid var(--main-bg-color);
      padding: 0 10px;
      width: auto;
      @include responsive($max: md) {
        padding: 0 3px;
        display: none;
        &:first-of-type,
        &:nth-of-type(2) {
          display: block;
        }
      }

      &:last-child {
        border-right: none;
      }
    }

    & ~ {
      margin-top: 10px;
    }
  }
  &__subfirst {
    border-radius: 10px 10px 0 0;
    margin-bottom: 0px;
  }
}
@include responsive($max: md) {
  .baseTable {
    &:first-child {
      margin-top: 60px;
    }
  }
}
</style>
