<template>
  <section class="customPackage">
    <div>
      <p class="customPackage__title">{{ $t('SELECT_CATEGORY') }}: *</p>
      <ShootSelectType @updated:categories="updateSelectedCategory" />
    </div>
    <div :style="{ display: 'flex', flexDirection: 'column', gap: '2em' }">
      <div v-if="selectedCategory && shoot.content === 'photography'">
        <p class="customPackage__title">{{ $t('SELECT_PICTURES_QUANTITY') }}:</p>
        <template>
          <SelectQuantityOfPictures v-model="pictureNumber" :category="selectedCategory.name ? selectedCategory.name : null" />
          <hr class="customPackage__hr" />
        </template>
      </div>
      <div v-if="selectedCategory && shoot.content === 'videography'">
        <p class="customPackage__title">{{ $t('Select video duration') }}:</p>
        <template>
          <SelectVideoDuration v-model="videoDuration" :unit="'Seconds'" />
          <hr class="customPackage__hr" />
        </template>
      </div>
      <div v-if="selectedCategory && shoot.content === 'videography'">
        <p class="customPackage__title">{{ $t('Select the quantity of videos') }}:</p>
        <template>
          <SelectQuanitityOfVideos v-model="videoNumber" :category="selectedCategory ? selectedCategory.name : null" />
          <hr class="customPackage__hr" />
        </template>
      </div>
    </div>

    <div>
      <div class="customPackage__uploadBrief">
        <p class="customPackage__title">{{ $t('UPLOAD_BRIEF') }}:</p>
        <div class="customPackage__upload">
          <BaseFileUploader acceptedFiles=".pdf" ref="fileUploader" @update:files="uploadFile" />
          <p>{{ selectedFile }}</p>
          <BaseIcon
            v-if="selectedFile"
            class="cardShoot__close ml-10"
            icon="close"
            stroke="#8F8F8F"
            :custom_style="{ height: '10px', width: '10px', display: 'inline', cursor: 'pointer' }"
            @click.native="removeUploadFile"
          />
        </div>
      </div>
      <RedeemablePackage v-if="allowRedeems" />
    </div>
  </section>
</template>

<script>
// Store
import { mapActions, mapGetters } from 'vuex';

// Base Components
import BaseFileUploader from '@base/BaseFileUploader.vue';
import BaseIcon from '@base/BaseIcon.vue';

// Components
import RedeemablePackage from './RedeemablePackage.vue';
import SelectQuantityOfPictures from './SelectQuantityOfPictures.vue';
import SelectQuanitityOfVideos from './SelectQuanitityOfVideos.vue';

import ShootSelectType from './ShootSelectType.vue';
import SelectVideoDuration from './SelectVideoDuration.vue';

/**
 * This component is used in the book a new shoot wizard for the creation of a custom package
 */
export default {
  name: 'CustomPackage',
  components: {
    BaseFileUploader,
    BaseIcon,
    RedeemablePackage,
    SelectQuantityOfPictures,
    ShootSelectType,
    SelectQuanitityOfVideos,
    SelectVideoDuration
  },
  props: {
    /**
     * If true, shoot could be redeemable, otherwise not
     */
    allowRedeems: { default: true, type: Boolean },
    /**
     * use to set the edit mode of shoot
     */
    editMode: { default: false, type: Boolean }
  },
  data() {
    return {
      blob: null,
      selectedCategory: null,
      selectedFile: '',
      pictureNumber: 0,
      videoNumber: 0,
      videoDuration: 0
    };
  },
  computed: {
    ...mapGetters({
      brief: 'bookShoot/getBrief',
      shoot: 'bookShoot/getShoot',
      selectedShoot: 'shoot/getSelectedShoot'
    }),
    getCurrentShoot() {
      const currentShoot = this.shoots.find(_shoot => _shoot.id === this.selectedShoot);
      return currentShoot || {};
    }
  },

  watch: {
    selectedCategory: {
      handler: function () {
        this.$emit('update:category', this.selectedCategory);
      }
    },
    videoNumber: {
      handler: function (newVal, oldVal) {
        this.$emit('update:videoQuantity', this.videoNumber);
      }
    },
    videoDuration: {
      handler: function () {
        this.$emit('update:videoDuration', this.videoDuration);
      }
    },
    pictureNumber: {
      handler: function (newVal, oldVal) {
        this.$emit('update:pictureQuantity', newVal);
      }
    }
  },
  async mounted() {
    if (this.editMode) {
      this.pictureNumber = this.shoot.packages[0].picture_number;
      this.videoDuration = this.shoot.packages[0].video_duration;
      this.videoNumber = this.shoot.packages[0].video_number;
    }

    if (this.brief) this.selectedFile = this.brief.name;
  },
  methods: {
    ...mapActions({
      updateBrief: 'bookShoot/updateBrief',
      updateShoot: 'bookShoot/updateShoot'
    }),
    /**
     *  set the loaded file as empty, overwriting the previously inserted one.
     *  update the uploaded file.
     */
    removeUploadFile() {
      this.selectedFile = '';
      this.$refs.fileUploader.reset();
      this.updateBrief();
    },

    /**
     * Triggers the opening of file select window
     */
    uploadFile($event) {
      this.selectedFile = $event.name;
      this.blob = $event;
      this.updateBrief(this.blob);
    },
    updateSelectedCategory($event) {
      this.videoDuration = this.shoot.packages[0] ? this.shoot.packages[0].video_duration : 0;
      this.videoNumber = this.shoot.packages[0] ? this.shoot.packages[0].video_number : 0;
      this.pictureNumber = this.shoot.packages[0] ? this.shoot.packages[0].picture_number : 0;
      this.selectedCategory = {
        id: $event[0].id,
        name: $event[0].name,
        picture_number: this.shoot.packages[0] ? this.shoot.packages[0].picture_number : $event[0].num_pictures,
        video_number: this.shoot.packages[0] ? this.shoot.packages[0].video_number : 0,
        video_duration: this.shoot.packages[0] ? this.shoot.packages[0].video_duration : 0
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.customPackage {
  column-gap: 62px;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));

  &__hr {
    background-color: var(--grey-hr);
    border: unset;
    height: 2px;
    margin: 0;
  }

  &__title {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    font-weight: bold;
    margin: 0;
    text-align: left;
  }
  &__uploadBrief {
    background: var(--terziary-btn-color);
    border-radius: 10px;
    margin-bottom: 50px;
    max-height: 120px;
    padding: 20px;
  }

  &__upload {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > p {
      @include inter-font($size: 12px, $color: var(--secondary-text-color));
      font-style: italic;
      font-weight: bold;
      margin: 0;
      text-align: right;
      width: 40%;
      word-break: break-all;
    }
  }
}
</style>
