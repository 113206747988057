<template>
  <section>
    <p>{{ $t('WHATSAPP_INFO') }}</p>
    <div>
      <BaseButton class="mr-10" :custom_style="{ 'background-color': '#25D366', color: '#fff' }" :label="$t('WHATSAPP')" @click.native="openWhatsapp" />
      <BaseButton :class="'baseButton'" :custom_style="{ 'background-color': 'rgb(254, 203, 48)' }" :label="$t('RETURN_TO_HOME')" @click.native="$router.push({ name: 'home' })" />
    </div>
  </section>
</template>

<script>
// Base components
import BaseButton from '@base/BaseButton.vue';

/**
 * This component will handle the whatsapp redirect
 */
export default {
  name: 'Whatsapp',
  components: {
    BaseButton
  },
  created() {
    this.openWhatsapp();
  }
};
</script>
