<template>
  <div class="redeemablePackage">
    <p class="redeemablePackage__title ma-0 text-left">{{ $t('PACKAGE_OPTIONS') }}:</p>
    <div class="redeemablePackage__body mt-15">
      <div class="redeemablePackage__toggle">
        <BaseToggle v-model="redeemable" class="full-width" label-position="left" style="{ 'margin-bottom': '7px'}">{{ $t('PACKAGE_REDEEMABLE') }}</BaseToggle>
      </div>
      <template v-if="redeemable">
        <hr data-theme="light" class="hr mb-19" />
        <div class="redeemablePackage__quantity-box">
          <div class="redeemablePackage__type">
            <p class="redeemablePackage__sub-title text-left">{{ $t('QUANTITY') }}</p>
            <div class="redeemablePackage__quantities">
              <div
                v-for="(item, index) in quantityBoxes"
                :key="index"
                :class="['redeemablePackage__quantity', { 'redeemablePackage__quantity--selected': selectedfixedQuantity === item }]"
                @click="selectQuantity(item)"
              >
                <p class="ma-0 text-left">{{ item }}</p>
              </div>
            </div>
          </div>
          <div v-if="selectedfixedQuantity === $t('FIXED_QUANTITY')" class="redeemablePackage__numberOfPackages">
            <p class="redeemablePackage__sub-title ma-0 text-left">{{ $t('NUMBER_OF_SHOOT') }}</p>
            <BaseNumberInput v-model="redeemCounter" class="redeemablePackage__number" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
// Store
import { mapActions, mapGetters } from 'vuex';

// Components
import BaseNumberInput from '@base/BaseNumberInput.vue';
import BaseToggle from '@base/BaseToggle.vue';

export default {
  name: 'RedeemablePackage',
  components: {
    BaseNumberInput,
    BaseToggle
  },

  data() {
    return {
      quantityBoxes: [this.$t('OPEN'), this.$t('FIXED_QUANTITY')],
      redeemable: false,
      redeemCounter: null,
      selectedfixedQuantity: ''
    };
  },
  computed: {
    ...mapGetters({
      shoot: 'bookShoot/getShoot'
    })
  },
  watch: {
    // If redeemable gets updated and setted to true, emit that the shoot is redeemable
    redeemable(value) {
      if (value) {
        this.selectedfixedQuantity = this.$t('OPEN');
        return;
      }

      this.selectedfixedQuantity = '';
    },
    // When the number of redeems change, emit the change
    redeemCounter(value) {
      this.updateShoot({
        redeemable_total: value
      });
    },
    // Based on the type of redeem (fixed or custom) init the redeemCounter
    selectedfixedQuantity(value) {
      if (!value) {
        this.redeemCounter = undefined;
        return;
      }

      this.redeemCounter = value === this.$t('OPEN') ? -1 : 0;
    }
  },
  async created() {
    const redeemTotal = this.shoot.redeemable_total;

    if (redeemTotal !== undefined) {
      this.redeemable = true;
      await this.$nextTick();
      this.selectedfixedQuantity = redeemTotal === -1 ? this.$t('OPEN') : this.$t('FIXED_QUANTITY');
      await this.$nextTick();
      this.redeemCounter = redeemTotal;
    }
  },
  methods: {
    ...mapActions({
      updateShoot: 'bookShoot/updateShoot'
    }),
    /**
     * use to save the index of the selcted index and title of element
     */
    selectQuantity(quantity_type) {
      this.selectedfixedQuantity = quantity_type;
    }
  }
};
</script>

<style lang="scss" scoped>
.redeemablePackage {
  &__body {
    display: flex;
    flex-direction: column;
  }

  &__quantities {
    display: flex;
    justify-content: space-between;
  }

  &__quantity {
    @include inter-font();
    align-items: center;
    background-color: var(--terziary-text-color);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 169px;

    &--selected {
      border: 3px solid var(--border-color) !important;
    }

    & ~ .redeemablePackage__quantity {
      margin-left: 15px;
    }
  }

  &__number {
    width: 169px !important;
  }

  &__numberOfPackages {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    > p {
      margin-right: 15px;
      width: 169px;
    }
  }

  &__sub-title {
    @include inter-font($size: 12px);
  }

  &__title {
    @include inter-font($size: 14px);
  }
}
</style>
