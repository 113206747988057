<template>
  <div class="baseRadioInput">
    <q-radio
      :value="value"
      :val="val"
      :name="name"
      :style="cssVars"
      :label="label || (!$slots['label'] ? `${val}` : '')"
      :class="['baseRadioInput__radio', { 'baseRadioInput--error': error }, { 'baseRadioInput__radio--no-tick': !showTick }]"
      @input="$emit('update:value', $event)"
    />
    <p class="baseRadioInput__errorMessage" v-if="error && errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioInput',
  model: {
    prop: 'value',
    event: 'update:value'
  },
  props: {
    /**
     * Checkbox color
     */
    colors: {
      default: () => ({
        bg: 'transparent',
        'bg-active': '#151515'
      }),
      type: Object,
      validator: value => {
        const keys = Object.keys(value);
        const availableKeys = ['bg', 'bg-active'];
        return keys.every(key => availableKeys.includes(key));
      }
    },
    error: { default: false, type: Boolean },
    errorMessage: {
      default: '',
      type: String
    },
    /**
     * The name of the radio inputs group
     */
    name: { default: 'options', type: String },
    /**
     * The value of the label to be shown
     */
    label: { default: '', type: String },
    /**
     * Set to true to show tick icon when checkbox true
     */
    showTick: { default: false, type: Boolean },
    /**
     * Use this prop to set the checkbox size
     */
    size: { default: 'medium', type: String },
    /**
     * Value of the checkbox
     */
    value: { default: '', type: [String, Object] },
    /**
     * Val
     */
    val: { default: '', type: [String, Object, Number] }
  },
  computed: {
    cssVars() {
      const keys = Object.keys(this.colors);
      const vars = {};

      for (const key of keys) {
        vars[`--${key}-color`] = this.colors[key];
      }

      const sizes = {
        medium: {
          '--radio-size': '20px',
          '--radio-active-size': '12px'
        },
        small: {
          '--radio-size': '14px',
          '--radio-active-size': '6px'
        }
      };

      if (this.size) {
        const keys = Object.keys(sizes[this.size]);

        for (const key of keys) {
          vars[key] = sizes[this.size][key];
        }
      }

      return vars;
    }
  }
};
</script>

<style lang="scss">
.baseRadioInput {
  text-align: left;

  &__radio {
    $target-width: calc((var(--radio-size) - 4px));
    $middle-active-position: calc((#{$target-width} - var(--radio-active-size)) / 2);

    margin-left: 0;

    > div:first-child {
      margin-left: 0;
      font-size: 20px;
    }
    .q-radio__label {
      color: var(--main-text-color);
      display: flex;
      font-family: $inter-regular;
      font-size: calculateRem(14px);
      margin-left: 14px;
    }

    svg {
      z-index: 2;
    }

    &--no-tick {
      svg {
        display: none;
      }
    }

    &[aria-checked='true'] {
      .q-radio__truthy {
        stroke: #151515;
      }

      .q-radio__inner {
        background-color: var(--bg-color);
        border-color: var(--bg-active-color);
        position: relative;

        &::after {
          background-color: var(--bg-active-color);
          content: '';
          height: var(--radio-active-size);
          left: $middle-active-position;
          position: absolute;
          top: $middle-active-position;
          width: var(--radio-active-size);
        }
      }
    }

    .q-radio__inner {
      background-color: var(--bg-color);
      border: 2px solid var(--bg-active-color);
      border-radius: 3px;
      height: var(--radio-size);
      left: 0;
      top: 0;
      width: var(--radio-size);
    }
  }
  &--error {
    .q-radio__inner {
      border-color: var(--q-color-negative) !important;
    }
  }
  &__errorMessage {
    font-size: calculateRem(11px);
    color: var(--q-color-negative);
    padding: 8px 12px 0;
  }
}
</style>
