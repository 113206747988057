<template>
  <div>
    <input v-if="uploadingStep === 1" type="file" ref="fileInput" @change="handleFileSelect" accept=".zip" id="raw-upload" class="file-input" hidden :disabled="isDisabled" />
    <label
      :for="uploadingStep === 1 && !isDisabled ? 'raw-upload' : ''"
      class="file-label"
      :class="{
        uploading: isUploading,
        disabled: isDisabled && uploadingStep === 1,
        'start-button': uploadingStep === 0
      }"
      @click="handleClick"
    >
      <q-icon :name="getIconName" class="file-icon" />
      <span class="file-text">{{ getLabelText }}</span>
    </label>
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from '@vue/composition-api';
import { ShootsApi } from '@api/index';

export default {
  name: 'RawsUploader',
  props: {
    shootId: {
      type: Number,
      required: true
    },
    updateStatusMutation: {
      required: true
    },
    uploadingStep: {
      type: Number,
      required: true,
      validator: value => [0, 1].includes(value)
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['start-upload'],

  setup(props, { root, emit }) {
    // State management
    const store = root.$store;
    const fileInput = ref(null);
    const isUploading = ref(false);
    const uploadProgress = ref(0);
    const currentPartNumber = ref(0);
    const totalParts = ref(0);
    const activeXHR = ref(null);

    // Constants
    const PART_SIZE = 10 * 1024 * 1024; // 10MB
    const MAX_FILE_SIZE = 50 * 1024 * 1024 * 1024; // 50GB
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 1000; // 1 second base delay

    // Computed properties
    const getIconName = computed(() => {
      if (props.uploadingStep === 0) return 'upload';
      if (isUploading.value) return 'hourglass_empty';
      if (props.isDisabled) return 'lock';
      return 'cloud_upload';
    });

    const getLabelText = computed(() => {
      if (props.uploadingStep === 0) return 'Start RAW Upload';
      if (isUploading.value) {
        return `Uploading: ${uploadProgress.value.toFixed(0)}% (Part ${currentPartNumber.value}/${totalParts.value})`;
      }
      if (props.isDisabled) return 'Complete the form to enable upload';
      return 'Upload RAW files (ZIP)';
    });

    // Utility functions
    const formatFileSize = bytes => {
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      if (bytes === 0) return '0 Bytes';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const validateFile = file => {
      if (!file.name.toLowerCase().endsWith('.zip')) {
        throw new Error('Please select a ZIP file');
      }
      if (file.size > MAX_FILE_SIZE) {
        throw new Error(`File size (${formatFileSize(file.size)}) exceeds ${formatFileSize(MAX_FILE_SIZE)}`);
      }
      if (file.size < PART_SIZE) {
        throw new Error(`File size must be at least ${formatFileSize(PART_SIZE)}`);
      }
    };

    const uploadPart = async (file, uploadId, partNumber, start, end, key) => {
      let attempt = 0;

      while (attempt < MAX_RETRIES) {
        try {
          const {
            data: { presignedUrl }
          } = await ShootsApi.getUploadPartUrl({
            id: props.shootId,
            uploadId,
            partNumber,
            key
          });

          const chunk = file.slice(start, end);

          const response = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            activeXHR.value = xhr;

            xhr.open('PUT', presignedUrl);
            xhr.setRequestHeader('Content-Type', 'application/octet-stream');

            xhr.upload.onprogress = event => {
              if (event.lengthComputable) {
                const partProgress = event.loaded / event.total;
                uploadProgress.value = ((currentPartNumber.value - 1 + partProgress) / totalParts.value) * 100;
              }
            };

            xhr.onload = () => {
              if (xhr.status === 200) {
                const etag = xhr.getResponseHeader('ETag');
                if (!etag) {
                  reject(new Error('No ETag received'));
                  return;
                }
                resolve({ PartNumber: partNumber, ETag: etag.replace(/"/g, '') });
              } else {
                reject(new Error(`Upload failed: ${xhr.status}`));
              }
            };

            xhr.onerror = () => reject(new Error('Network error'));
            xhr.ontimeout = () => reject(new Error('Upload timeout'));
            xhr.onabort = () => reject(new Error('Upload aborted'));

            xhr.timeout = 300000; // 5 minutes
            xhr.send(chunk);
          });

          return response;
        } catch (error) {
          attempt++;
          console.error(`Upload part ${partNumber} failed (attempt ${attempt}):`, error);

          if (attempt === MAX_RETRIES) {
            throw error;
          }

          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, attempt - 1)));
        }
      }
    };

    const handleClick = () => {
      if (props.uploadingStep === 0) {
        emit('start-upload');
      }
    };

    const handleFileSelect = async event => {
      if (props.uploadingStep !== 1 || props.isDisabled) return;

      const file = event.target.files[0];
      if (!file) return;

      let uploadId, key;
      const uploadedParts = [];

      try {
        validateFile(file);
        isUploading.value = true;
        uploadProgress.value = 0;
        currentPartNumber.value = 0;

        totalParts.value = Math.ceil(file.size / PART_SIZE);

        // Initialize upload
        const { data: initData } = await ShootsApi.initializeMultipartUpload({
          id: props.shootId
        });

        uploadId = initData.uploadId;
        key = initData.key;

        // Upload parts
        for (let partNumber = 1; partNumber <= totalParts.value; partNumber++) {
          currentPartNumber.value = partNumber;
          const start = (partNumber - 1) * PART_SIZE;
          const end = Math.min(start + PART_SIZE, file.size);

          const partResult = await uploadPart(file, uploadId, partNumber, start, end, key);
          uploadedParts.push(partResult);
        }

        // Complete upload
        await ShootsApi.completeMultipartUpload({
          id: props.shootId,
          uploadId,
          key,
          parts: uploadedParts.sort((a, b) => a.PartNumber - b.PartNumber)
        });

        await props.updateStatusMutation.mutate({
          id: props.shootId,
          payload: { targetStatus: 'uploaded' }
        });

        store.dispatch('notification/addSuccessNotification', 'Upload completed successfully');
      } catch (error) {
        console.error('Upload failed:', error);

        if (uploadId && key) {
          try {
            await ShootsApi.abortMultipartUpload({
              id: props.shootId,
              uploadId,
              key
            });
          } catch (abortError) {
            console.error('Failed to abort upload:', abortError);
          }
        }

        store.dispatch('notification/addFailureNotification', `Upload failed: ${error.message}`);
      } finally {
        isUploading.value = false;
        uploadProgress.value = 0;
        currentPartNumber.value = 0;
        totalParts.value = 0;
        activeXHR.value = null;
        if (fileInput.value) {
          fileInput.value.value = '';
        }
      }
    };

    // Cleanup
    onUnmounted(() => {
      if (activeXHR.value) {
        activeXHR.value.abort();
      }
      if (fileInput.value) {
        fileInput.value.value = '';
      }
    });

    return {
      fileInput,
      isUploading,
      getLabelText,
      getIconName,
      handleFileSelect,
      handleClick,
      uploadProgress
    };
  }
};
</script>

<style scoped>
.file-label {
  cursor: pointer;
  height: 44px;
  border-radius: 10px;
  display: flex;
  padding: 12px;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--main-btn-color);
  color: var(--main-text-color);
  transition: all 0.3s ease;
  width: fit-content;
  white-space: nowrap;
}

.file-label.uploading {
  cursor: not-allowed;
  opacity: 0.7;
}

.file-label.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: var(--disabled-bg-color, #e0e0e0) !important;
  color: var(--disabled-text-color, #666) !important;
  pointer-events: none;
}

.file-label.start-button {
  background-color: var(--main-btn-color);
}

.file-icon {
  margin-right: 10px;
  font-size: 1.2em;
  transition: all 0.3s ease;
}

.file-text {
  transition: all 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  display: none;
}
</style>
