// Api
import { UserApi } from '@api/index';

// Store
import { mapGetters } from 'vuex';

const photographersMixin = {
  data() {
    return {
      photographers: [],
      photographersCopy: []
    };
  },
  computed: {
    ...mapGetters({
      shoot: 'bookShoot/getShoot'
    }),
    selectedPhAccount: {
      get() {
        return this.photographers.find(user => this.shoot.photographer_id === user.id) || null;
      },
      set(value) {
        this.$emit('ph:selected', value);
      }
    }
  },
  methods: {
    /**
     * Method used to filter select options when the user is typing
     */
    filterOptions(value, update) {
      if (value === '') {
        update(() => {
          this.photographers = this.photographersCopy;
        });
        return;
      }
      update(() => {
        const needle = value.toLowerCase();
        this.photographers = this.photographersCopy.filter(v => v.name.toLowerCase().indexOf(needle) > -1 || `${v.id}` === `${needle}`);
      });
    },
    /**
     * Method used to retrieve photographers from db
     */
    async retrievePhotographers() {
      const photographers = await UserApi.searchUsers({
        payload: { filters: { role: 'photographer', statuses: ['active'] } }
      });
      const { data } = photographers;

      this.photographers = data ? data.users : [];
      this.photographersCopy = data ? data.users : [];
    }
  },
  created() {
    this.retrievePhotographers();
  }
};

export default photographersMixin;
