<template>
  <BaseButton class="packageCard__go-to" :icon="icon" :custom_style="buttonStyle" :icon_style="iconStyle" />
</template>

<script>
// Base components
import BaseButton from '@base/BaseButton.vue';

// Utils
import helpers from '@utils/helpers';

export default {
  name: 'ButtonRightArrow',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonStyle: {
        'background-color': helpers.getCssVariable('--main-btn-color'),
        'border-radius': '5px !important',
        height: '22px !important',
        padding: '0 !important',
        width: '22px !important'
      },
      iconStyle: {
        fill: helpers.getCssVariable('--main-text-color'),
        height: '20px',
        stroke: helpers.getCssVariable('--main-text-color'),
        width: '20px'
      }
    };
  },
  props: {
    icon: {
      type: String,
      default: 'arrow-right'
    }
  }
};
</script>
