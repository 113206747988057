<template>
  <section class="redeem">
    <BaseStepper class="redeem__content" handle-navigation no-navigator :steps="steps">
      <template #header>
        <div class="redeem__header">
          <span class="redeem__title">{{ $t('BOOK_SHOOT') }}</span>
          <p v-if="step > 1 && type === $t('CLIENT_CUSTOM')" class="redeem__pagination">{{ $t('STEP') }} {{ step - 1 }}/{{ steps.length - 1 }}</p>
        </div>
      </template>
      <template #SELECT_PACKAGE>
        <div class="redeem__results">
          <div class="row justify-center q-my-md" v-if="isLoading">
            <q-spinner-dots color="primary" size="150px" />
          </div>
          <div v-else>
            <PackagesView :pkgs="shoots" @open-pkg="show_navigator = true" />
            <ShootsNavigator :shoots="shoots" :show="show_navigator" @toggle="show_navigator = !show_navigator" />
          </div>
        </div>
      </template>
    </BaseStepper>
    <div class="redeem__footer">
      <BaseButton icon-on-right type="" disabled icon="angular-arrow" :label="$t('NEXT_STEP')" :icon_style="{ height: '23px', width: '16px' }" @click.native="() => {}" />
    </div>
  </section>
</template>
<script>
import { reactive, computed, toRefs } from '@vue/composition-api';
import { ShootsApi } from '@api/index';
import BaseStepper from '@base/BaseStepper.vue';
import BaseButton from '@base/BaseButton.vue';
import ShootsNavigator from '@components/shoot/ShootsNavigator.vue';
import PackagesView from '@components/PackagesView.vue';
import { useQuery } from '@tanstack/vue-query';
import { REDEEMABLE } from '@utils/shootsHelpers';
const fetchShoots = async page => {
  const payload = {
    filters: {
      statuses: [REDEEMABLE]
    },
    pagination: {
      offset: (page - 1) * 50,
      size: 50
    }
  };
  const response = await ShootsApi.searchShoots(payload);
  return {
    shoots: response.data.shoots,
    totalCount: response.data.total
  };
};

export default {
  name: 'RedeemShoot',
  components: {
    BaseButton,
    BaseStepper,
    ShootsNavigator,
    PackagesView
  },
  setup(props, { root }) {
    const store = root.$store;
    const state = reactive({
      show_navigator: false,
      steps: [{ name: 'SELECT_PACKAGE' }, {}, {}],
      page: 1
    });
    const {
      data: shootData,
      refetch,
      isLoading
    } = useQuery({
      queryKey: ['shoots', state.page],
      queryFn: () => fetchShoots(state.page),
      keepPreviousData: true
    });

    // Computed properties
    const shoots = computed(() => shootData.value?.shoots.filter(shoot => shoot.status === REDEEMABLE) || []);
    const totalCount = computed(() => shootData.value?.totalCount || 0);
    const totalPages = computed(() => Math.ceil(totalCount.value / 50)); // Assuming 50 items per page

    const step = computed(() => store.getters['stepper/getStep']);
    const isStepperCompleted = computed(() => step.value === state.steps.length);

    const pageUpdated = newPage => {
      state.page = newPage;
      refetch();
    };

    const prevPage = () => {
      state.page = Math.max(state.page - 1, 1);
      refetch();
    };

    const nextPage = () => {
      state.page += 1;
      refetch();
    };

    return {
      ...toRefs(state),
      step,
      shoots,
      isStepperCompleted,
      nextPage,
      prevPage,
      pageUpdated,
      totalPages,
      isLoading
    };
  }
};
</script>
<style lang="scss" scoped>
.redeem {
  // Variables for max-height calculations
  $footer-height: 76px;
  $header-height: 76px;
  $horizontal-padding: 171px;
  $padding-top: 56px;
  $redeem-header-height: 50px;

  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  padding-top: $padding-top;

  &__content {
    margin: 0 auto;
    width: 75%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    background-color: var(--secondary-bg-color);
    display: flex;
    height: $category-height;
    justify-content: flex-end;
    padding: 16px $horizontal-padding;
  }

  &__pagination {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(16px);
    margin: 0;
    text-align: left;
  }

  &__results {
    max-height: calc(100vh - $footer-height - $header-height - $padding-top - $redeem-header-height);
    overflow-y: auto;
    padding-bottom: 2rem;
  }

  &__title {
    @include antonio-font($size: 20px, $color: var(--main-text-color));
  }
}
</style>
