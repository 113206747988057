<template>
  <section>
    <template v-if="step === SHOOT_INFO">
      <CardShoot :shoot="shoot" show_attachments @close="$emit('close', $event)">
        <template #body>
          <CardShootDetail :shoot="shoot" show_attachments @close="$emit('close', $event)" />
        </template>
        <template #actions>
          <BaseButton :custom_style="{ color: '#F25050' }" :label="'Decline'" @click.native="handleDecline" />
          <BaseButton
            :disabled="status.percentage === 100"
            :loading="status.loading"
            :custom_style="accept_button"
            :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '3px', width: '17px' }"
            :label="$t('SHOOT.ACCEPT') | firstUppercase"
            icon="check"
            @click.native="handleAccept"
          />
        </template>
      </CardShoot>
    </template>
    <template v-if="step === DECLINE_CONFIRM">
      <CardShoot :shoot="shoot" show_attachments @close="$emit('close', $event)">
        <template #body>
          <h2 class="shootWizard__action-title" v-html="getDeclineTitle">
            {{ getDeclineTitle }}
          </h2>
          <p class="shootWizard__action">{{ $t('SHOOT.DECLINE_DETAIL') }}</p>
        </template>
        <template #actions>
          <BaseButton v-if="status.percentage !== 100" :label="$t('GO_BACK')" @click.native="goBack" />
          <BaseButton
            :custom_style="decline_button"
            :disabled="status.percentage === 100"
            :loading="status.loading"
            :icon_style="{ fill: 'transparent', height: '17px', stroke: '#fff', 'stroke-width': '2px', width: '17px' }"
            :label="declineLabel | firstUppercase"
            icon="trash"
            @click.native="decline"
          />
        </template>
      </CardShoot>
    </template>
    <template v-if="step === ACCEPT_CONFIRM">
      <CardShoot class="shootWizard__cardShoot" :shoot="shoot" show_attachments @close="$emit('close', $event)">
        <template #body>
          <h2 class="shootWizard__action-title" v-html="getAcceptTitle">{{ getAcceptTitle }}</h2>
          <p class="shootWizard__action">{{ $t('SHOOT.ACCEPT_DETAIL') }}</p>
          <p class="shootWizard__action__alert">{{ $t('SHOOT.ACCEPT_ALERT') }}</p>
        </template>
        <template #actions>
          <BaseButton v-if="status.percentage !== 100" :label="$t('GO_BACK')" @click.native="goBack" />
          <BaseButton
            :custom_style="accept_button"
            :disabled="status.percentage === 100"
            :loading="status.loading"
            :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '3px', width: '17px' }"
            :label="acceptLabel | firstUppercase"
            icon="check"
            @click.native="accept"
          />
        </template>
      </CardShoot>
    </template>
  </section>
</template>

<script>
// Vuex

// Composition API
import { ref, computed } from '@vue/composition-api';

// Api
import { ShootsApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';

// Components
import CardShoot from '@/components/CardShoot.vue';
import CardShootDetail from '@/components/CardShootDetail.vue';

// Helpers
import helpers from '@utils/helpers';
import { CONFIRMED } from '@utils/shootsHelpers';

import { useMutation, useQueryClient } from '@tanstack/vue-query';

export default {
  name: 'ShootWizard',
  components: {
    BaseButton,
    CardShoot,
    CardShootDetail
  },
  props: {
    shoot: { default: () => ({}), type: Object }
  },
  setup(props, { root, emit }) {
    const queryClient = useQueryClient();
    const store = root.$store;

    const status = ref({
      loading: false,
      percentage: 0
    });

    const STEPS = {
      ACCEPT_CONFIRM: 'ACCEPT_CONFIRM',
      DECLINE_CONFIRM: 'DECLINE_CONFIRM',
      SHOOT_INFO: 'SHOOT_INFO'
    };
    const step = ref(STEPS.SHOOT_INFO);

    const acceptLabel = computed(() => {
      const label = {
        false: root.$i18n.t('SHOOT.ACCEPT'),
        true: 'Accepted!'
      };
      return label[`${status.value.percentage === 100}`];
    });

    const decline_button = computed(() => ({
      'background-color': helpers.getCssVariable('--decline-btn-bg-color'),
      color: status.value.percentage === 100 ? helpers.getCssVariable('--decline-btn-bg-color') : '#fff',
      'margin-left': '15px'
    }));

    const declineLabel = computed(() => {
      const label = {
        false: root.$i18n.t('SHOOT.DECLINE'),
        true: 'Declined!'
      };
      return label[`${status.value.percentage === 100}`];
    });

    const getAcceptTitle = computed(() => {
      return root.$i18n.t('SHOOT.PH_ACTION', { action: root.$i18n.t('SHOOT.ACCEPT') });
    });

    const getDeclineTitle = computed(() => {
      return root.$i18n.t('SHOOT.PH_ACTION', { action: root.$i18n.t('SHOOT.DECLINE') });
    });

    // Mutations
    // Mutation to update the statusc
    const updateStatusMutation = useMutation({
      mutationFn: statusDetails => ShootsApi.updateStatus(statusDetails),
      onSuccess: updateStatus => {
        if (updateStatus.statusCode === 200) {
          queryClient.refetchQueries(['shoots']);
          store.dispatch('notification/addSuccessNotification', `Shoot #${props.shoot.id} has been updated `);
          status.value.loading = false;
          emit('toggle');
          emit('close');
        }
      },
      onError: () => {
        // Handle updating status error
        status.value.loading = false;
        emit('toggle');
        emit('close');
        store.dispatch('notification/addFailureNotification', 'Failed to update shoot ');
      }
    });
    const unassignPhotographerMutation = useMutation({
      mutationFn: shootId => ShootsApi.unassignPhotographer(shootId),
      onSuccess: response => {
        if (response.statusCode === 200) {
          store.dispatch('notification/addSuccessNotification', `Shoot ${props.shoot.id} has been declined`);
          queryClient.refetchQueries(['shoots']);
          emit('toggle');
          emit('close');
        }
      },
      onError: () => {
        status.value.loading = false;
        store.dispatch('notification/addFailureNotification', 'Failed to decline the shoot ');
        emit('toggle');
        emit('close');
      }
    });

    // Methods
    const accept = async () => {
      status.value.loading = true;
      const statusDetails = { id: props.shoot.id, payload: { targetStatus: CONFIRMED } };
      updateStatusMutation.mutate(statusDetails);
    };

    const decline = async () => {
      status.value.loading = true;
      unassignPhotographerMutation.mutate(props.shoot.id);
    };

    const goBack = () => {
      step.value = STEPS.SHOOT_INFO;
      status.value = { loading: false, percentage: 0 };
    };

    const handleAccept = () => {
      step.value = STEPS.ACCEPT_CONFIRM;
    };

    const handleDecline = () => {
      step.value = STEPS.DECLINE_CONFIRM;
    };

    return {
      ...STEPS,
      accept_button: {
        'background-color': helpers.getCssVariable('--main-btn-color'),
        'margin-left': '15px'
      },
      status,
      step,
      acceptLabel,
      decline_button,
      declineLabel,
      accept,
      decline,
      goBack,
      handleAccept,
      handleDecline,
      getDeclineTitle,
      getAcceptTitle
    };
  }
};
</script>

<style lang="scss" scoped>
.shootWizard {
  &__action {
    @include inter-font($size: 14px, $bolded: false, $color: var(--main-text-color));
    white-space: pre-wrap;
    &__alert {
      @include inter-font($size: 14px, $bolded: true, $color: var(--danger));
      bottom: 0;
      position: absolute;
    }
  }

  &__action-title {
    @include antonio-font($size: 23px, $uppercase: true, $color: var(--main-text-color));
    margin: 0;
  }
  &__cardShoot {
    position: relative;
  }
}
</style>
