<template>
  <section class="sendBrief">
    <div class="sendBrief__copy">
      <p class="sendBrief__title mb-22">{{ $t('UPLOAD_BRIEF_MANDATORY') }}:</p>
      <div class="sendBrief__description" v-html="$t('UPLOAD_BRIEF_DESCRIPTION')"></div>
    </div>
    <div class="sendBrief__pdf">
      <span class="align-middle justify-between mb-22">
        <p class="sendBrief__title mb-0">{{ $t('BRIEF_DOWNLOAD') }}</p>
      </span>
      <iframe class="sendBrief__preview" :src="`${require('@/assets/Flashy_Custom_Shoot_Brief.pdf')}`" type="application/pdf" />
    </div>
  </section>
</template>

<script>
// Axios
import { mapGetters } from 'vuex';

export default {
  name: 'UploadBrief',
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  }
};
</script>

<style lang="scss" scoped>
.sendBrief {
  display: flex;
  height: 100%;
  padding-bottom: 20px;

  > div {
    &:first-child {
      flex: 40%;
    }
  }

  &__copy {
    margin-right: 62px;
    width: 300px;
  }

  &__description {
    @include inter-font($size: 12px);
    text-align: left;
    white-space: pre-line;
  }

  &__pdf {
    display: flex;
    flex: 60%;
    flex-direction: column;
  }

  &__preview {
    border: unset;
    height: 100%;
    width: 100%;
  }

  &__title {
    @include inter-font($size: 14px, $color: var(--main-text-color));
    text-align: left;
  }
}
</style>
