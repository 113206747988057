<template>
  <div class="shootsTab">
    <template>
      <PackagesView v-if="toggleView === 'grid'" :pkgs="shoots" @open-pkg="show_navigator = true" :isLoading="loading" :isRefetching="refetching" />
      <ShootsTable
        v-if="toggleView === 'list'"
        ref="shootsTable"
        :shoots="shoots"
        :shootsFields="getShootsFields"
        :isLoading="loading"
        :isRefetching="refetching"
        :activeTab="activeTab"
        :getCategory="getCategory"
        :getPrice="getPrice"
        :getStatus="getStatus"
        :getStatusColor="getStatusColor"
        @open-pkg="show_navigator = true"
      />

      <ShootsNavigator :shoots="shoots" :show="show_navigator" @toggle="show_navigator = !show_navigator" />
    </template>
  </div>
</template>

<script>
// Utils
import helpers from '@utils/shootsHelpers';

// Store
import { mapGetters, mapMutations, mapState } from 'vuex';

// Components
import PackagesView from '@components/PackagesView.vue';
import ShootsNavigator from './ShootsNavigator.vue';
import ShootsTable from './ShootsTable.vue';

export default {
  name: 'ShootsTab',
  components: {
    PackagesView,
    ShootsNavigator,
    ShootsTable
  },
  props: {
    /**
     * object that contain the filtered payload
     */
    payloadF: { default: () => ({}), type: Object },
    /**
     * Active tab
     */
    activeTab: { default: '', type: String },
    /**
     * Set to true when shoots are getting loaded
     */
    loading: { default: false, type: Boolean },
    refetching: { default: false, type: Boolean },

    shoots: { default: () => [], type: Array }
  },
  data() {
    return {
      dates: {},
      shootId: '',
      show_navigator: false,
      viewCheck: null
    };
  },
  computed: {
    ...mapState({
      toggleViewCheck: 'shoot/getActiveView'
    }),
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isSubClient: 'user/isSubClient',
      isPhotographer: 'user/isPhotographer',
      isPhotographerInternal: 'user/isPhotographerInternal',
      services: 'services/getServices',
      toggleView: 'shoot/getActiveView'
    }),
    /*
     * Return the correct array of shoots, cause if isClient we don't have the shoot with status 'toBePayed' and with redeemable_counter = 0;
     * if we use the shoots array we got an error of the index of shoot
     */
    getCorrectShoots() {
      return this.isClient ? this.shoots.filter(item => item.status !== 'toBePayed' && item.redeemable_counter !== 0) : this.shoots;
    },
    /**
     * Retrieving columns for table
     */
    getShootsFields() {
      if (this.isEditor || this.isPhotographerInternal) return ['date', 'id', 'customer', 'location', 'category', 'status'];
      return ['date', 'id', 'customer', 'location', 'category', 'price', 'status'];
    }
  },
  watch: {
    /**
     * When query param gets updated, set to 'immediate true' to get the correct view on load.
     */
    '$route.query.view': {
      immediate: true,
      handler: function () {
        this.updateView(this.$route.query.view || 'grid');
        this.initialSelectedElement = this.toggleView;
        this.shootId = this.$route.query.id;
      }
    }
  },
  methods: {
    ...mapMutations({
      updateView: 'shoot/updateView'
    }),
    triggerExport() {
      this.$refs.shootsTable.exportToCSV(); // Assuming there is a ref="shootsTable" on the ShootsTable component in the template
    },
    /**
     * Returning category label for shoot list
     */
    getCategory(services) {
      if (services.length === 1) {
        const service = this.services.find(_service => _service.id === services[0].service_id) || {};
        return service.name;
      }
      return this.$tc('CATEGORY', services.length);
    },
    /**
     * Returning the right price based on the role
     */
    getPrice(shoot) {
      if (this.isPhotographer) return shoot.photographer_revenue;

      return shoot.price;
    },
    getStatus(status) {
      return helpers.mapStatus(status);
    },
    getStatusColor(status) {
      return helpers.getStatusColor(status);
    },
    /**
     * Method used to handle the selected Shoot
     */
    handleShow($event) {
      this.show_navigator = true;
      this.selectedShoot = $event;
    }
  }
};
</script>

<style lang="scss" scoped>
.ml-auto {
  margin-left: auto;
}
.shootsTab {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: inherit;
  position: relative;

  &__bg {
    &,
    &--mobile {
      border-radius: 2px;
      display: inline-block;
      height: 10px;
      margin-right: 5px;
      width: 10px;
    }
    &--mobile {
      display: none;
    }

    &[data-type='Canceled'] {
      background: var(--packages-trash-action);
    }
    &[data-type='Complete'] {
      background: var(--complete-box);
    }
    &[data-type='Confirmed'],
    &[data-type='Assigned'],
    &[data-type='Redeemable'] {
      background: var(--main-btn-color);
    }
    &[data-type='Pending'],
    &[data-type='Scheduled'] {
      background: var(--pending-box);
    }
    &[data-type='Ready for download'] {
      background: var(--ready-downlod-box);
    }
    &[data-type='Ready for editing'] {
      background: var(--confirm-box);
    }
  }

  &__filters {
    display: flex;
  }

  &__clear {
    @include inter-font($size: 14px);
    cursor: pointer;
    text-decoration: underline;
  }
  &__text {
    align-self: center;
    margin: 0 0 1em 0;
  }
}
@include responsive($max: md) {
  .shootsTab {
    &__bg {
      &--mobile {
        display: inline-block;
        vertical-align: middle;
      }
    }
    &__filters {
      margin-right: 0;
      width: 100%;
    }
    &__text {
      width: 80%;
      text-align: left;
    }
    .hideOnMobile {
      display: none;
    }
  }
}
</style>
