<template>
  <div class="items-center row">
    <BaseIcon v-if="icon" :custom_style="{ height: '15px', 'stroke-width': '2px', width: '15px' }" :icon="icon" />
    <p class="customLabel__label mb-0 ml-5">{{ label }}</p>
  </div>
</template>

<script>
// Base Components
import BaseIcon from '@base/BaseIcon.vue';

/**
 * Component used to create a custom label with icon and text
 *
 * @displayName CustomLabel
 */
export default {
  name: 'CustomLabel',
  components: {
    BaseIcon
  },
  props: {
    /**
     * Label icon
     */
    icon: { default: '', type: String },
    /**
     * Label text
     */
    label: { default: '', type: String }
  }
};
</script>

<style lang="scss" scoped>
.customLabel {
  &__label {
    @include inter-font($size: 14px, $bolded: true);
  }
}
</style>
