<template>
  <section :class="['bookRecap pb-20', { 'bookRecap--single-column': !isClient }, { 'bookRecap--3-columns': isRedeemable }]">
    <div class="bookRecap__left">
      <p class="bookRecap__title ma-0 mb-39">{{ $t('SUMMARY') }}</p>
      <div class="bookRecap__info">
        <CardShootInfo icon="calendar" :subtitles="[dateTime.time]" :title="dateTime.date" />
        <CardShootInfo :class="{ 'mt-50': isClient }" icon="map-pin" :subtitles="[`${$tc('CUSTOMER', 1)}: ${customerName}`]" :title="shoot.location.formatted_address" />
        <div class="mt-50">
          <CardShootInfo v-for="(pkg, index) of shoot.packages" :key="index" class="bookRecap__package" :icon="(index === 0 && 'tag') || ''" :subtitles="getSubtitles(pkg)" :title="getService(pkg)" />
        </div>
        <div v-if="!isSubClient" class="bookRecap__price mt-50">
          <CardShootInfo icon="dollar-sign" :title="`${getPrice()} ${$t('AED')}`" />
          <p v-if="isClient">{{ $t('EXPRESS_PAYMENT_INFO') }}</p>
        </div>
        <div v-if="brief" class="bookRecap__attach" @click="downloadBrief">
          <BaseIcon fill="transparent" icon="paperclip" stroke="#151515" :custom_style="{ height: '14px', 'stroke-width': '2px', width: '14px' }" />
          <span>{{ $tc('SEE_ATTACHMENT') }}</span>
        </div>
        <div v-if="!isRedeemable" class="bookRecap__notes">
          <p class="bookRecap__title mt-20 mb-0">{{ $t('NOTES_OPTIONAL') }}:</p>
          <BaseInput v-model="notes" class="bookRecap__notes mt-20 pb-0" type="textarea" :label="$t('NOTES_LABEL')" />
          <p v-if="(shoot.type = 'express')">{{ $t('UPDATE_NOTES') }}</p>
        </div>
        <div v-if="shoot.time.from && shoot.time.from !== undefined && allowPhSelection" class="bookRecap__selectph">
          <p class="bookRecap__title ma-0 mb-39">{{ $t('CHOOSE_PH') }}:</p>
          <BaseSelect
            v-model="selectedPhAccount"
            option-label="name"
            option-value="photographer_id"
            use-input
            :clearable="false"
            :label="$t('NAME_ID')"
            :options="photographers"
            :filterFn="filterOptions"
          />
        </div>
      </div>
    </div>
    <div v-if="isRedeemable" class="bookRecap__center">
      <div class="bookRecap__name">
        <p class="bookRecap__title">{{ $t('NAME_OF_SHOOT') }}:</p>
        <BaseInput v-model="shootName" :placeholder="$t('NAME')" :disabled="isClient || isSubClient" />
      </div>

      <div class="bookRecap__notes">
        <p class="bookRecap__title mt-20 mb-0">{{ $t('NOTES_OPTIONAL') }}:</p>
        <BaseInput v-model="notes" class="bookRecap__notes mt-20 pb-0" type="textarea" :label="$t('NOTES_LABEL')" />
        <p v-if="(shoot.type = 'express')" class="mt-5">{{ $t('UPDATE_NOTES') }}</p>
      </div>
    </div>
    <div v-if="(isRedeemable && isSubClient) || (isRedeemable && isClient && !isB2C)" class="bookRecap__outletInfo">
      <p class="bookRecap__title">{{ $t('OUTLET_CODE_LABEL') }}:</p>
      <BaseInput v-model="outletCode" />

      <p class="bookRecap__title mt-5">{{ $t('OUTLET_NAME_LABEL') }}:</p>
      <BaseInput v-model="outletName" />

      <p class="bookRecap__title">{{ $t('POC_NAME_LABEL') }}:</p>
      <BaseInput v-model="pocName" />

      <p class="bookRecap__title mt-5">{{ $t('POC_EMAIL_LABEL') }}:</p>
      <BaseInput v-model="pocEmail" type="email" :error="typeof validEmail === 'boolean' && !validEmail" :error-message="$t('FORM_VALIDATION.TEXT_NOT_VALID')" />

      <p class="bookRecap__title mt-5">{{ $t('POC_PHONE_LABEL') }}:</p>
      <BasePhoneNumber v-model="pocPhone" class="below" />
      <div class="q-field--error q-field--dense">
        <small v-if="typeof validPhone === 'boolean' && !validPhone" class="q-field__bottom float-left pl-14 pt-7"> {{ $t('FORM_VALIDATION.TEXT_NOT_VALID_PHONE') }}</small>
      </div>
    </div>
    <div v-if="isClient && !isRedeemable" class="bookRecap__payment">
      <form class="bookRecap__payment-form" id="payment-form" @submit="createPaymentMethod">
        <p class="bookRecap__payment-title">{{ $t('BILLING_INFORMATION') }}</p>
        <div class="bookRecap__form-group">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('PERSONAL_DATA.NAME') }} *</label>
            </span>
            <BaseInput v-model="clientData.name" />
          </div>
          <div class="bookRecap__form-element ml-10">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('PERSONAL_DATA.SURNAME') }} *</label>
            </span>
            <BaseInput v-model="clientData.surname" />
          </div>
        </div>
        <div class="bookRecap__form-element">
          <span class="justify-between full-width align-middle">
            <label>{{ $t('PERSONAL_DATA.TAX_ID') }} </label>
            <BaseIcon icon="info" class="ml-10" :custom_style="{ height: '14px', width: '14px' }" :tooltip="$t('CHECKOUT.TOOLTIP.TAX_ID')" />
          </span>
          <BaseInput v-model="clientData.tax_id" :error="!isTaxIdValid" :error-message="$t('CHECKOUT.ERROR.TAX_ID', { count: taxIdLength })" />
        </div>
        <p class="bookRecap__payment-title mt-45">{{ $t('BILLING_ADDRESS') }}</p>
        <div class="bookRecap__form-group">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('BILLING.COUNTRY') }} *</label>
            </span>
            <BaseSelectWithFilter :value="country" useInput :options="countryOptions" @update:value="handleCountrySelection" />
          </div>
          <div class="bookRecap__form-element ml-10">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('BILLING.CITY') }} * </label>
              <BaseIcon icon="info" class="ml-10" :custom_style="{ height: '14px', width: '14px' }" :tooltip="$t('CHECKOUT.TOOLTIP.CITY')" />
            </span>
            <BaseLocation v-model="clientData.billing_address.city" type="(cities)" :country="state.value" :key="`${state.value}_city`" />
          </div>
        </div>
        <div class="bookRecap__form-group mt-15">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('BILLING.POSTAL_CODE') }} *</label>
            </span>
            <BaseInput v-model="clientData.billing_address.postal_code" />
          </div>
        </div>
        <div class="bookRecap__form-group mt-15">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('BILLING.ADDRESS_1') }} * </label>
              <BaseIcon icon="info" class="ml-10" :custom_style="{ height: '14px', width: '14px' }" :tooltip="$t('CHECKOUT.TOOLTIP.ADDRESS_1')" />
            </span>
            <BaseInput v-model="clientData.billing_address.line1" />
          </div>
        </div>
        <div class="bookRecap__form-group mt-15">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('BILLING.ADDRESS_2') }} </label>
              <BaseIcon icon="info" class="ml-10" :custom_style="{ height: '14px', width: '14px' }" :tooltip="$t('CHECKOUT.TOOLTIP.ADDRESS_2')" />
            </span>
            <BaseInput v-model="clientData.billing_address.line2" />
          </div>
        </div>
        <p class="bookRecap__payment-title mt-30">{{ $t('PAYMENT_DETAILS') }}</p>
        <div class="bookRecap__form-element">
          <span class="justify-between full-width align-middle">
            <label>{{ $t('PAYMENT.NUMBER') }} *</label>
          </span>
          <div id="card-number-element" class="field"></div>
        </div>
        <div class="bookRecap__form-group mt-15">
          <div class="bookRecap__form-element">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('PAYMENT.EXPIRY') }} *</label>
            </span>
            <div id="card-expiry-element" class="field"></div>
          </div>
          <div class="bookRecap__form-element ml-10">
            <span class="justify-between full-width align-middle">
              <label>{{ $t('PAYMENT.CVC') }} *</label>
            </span>
            <div id="card-cvc-element" class="field"></div>
          </div>
        </div>
        <span class="bookRecap__mandatory-fields mb-10 mt-30">{{ $t('MANDATORY_FIELD') }}</span>
        <BaseButton icon="check" :custom_style="nextStepButtonStyle" :disabled="isDisabled" :loading="isButtonLoading" :label="$t('CONFIRM_AND_PAY')" html-type="submit" />
      </form>
    </div>
  </section>
</template>

<script>
// Api
import { OrderApi, ShootsApi } from '@api/index';

// Store
import { mapGetters, mapMutations } from 'vuex';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseLocation from '@base/BaseLocation.vue';
import BaseSelectWithFilter from '@base/BaseSelectWithFilter.vue';
import BaseSelect from '@base/BaseSelect.vue';
import BasePhoneNumber from '@base/BasePhoneNumber.vue';

// Components
import CardShootInfo from '@components/CardShootInfo.vue';

// Helpers
import countriesHelper from '@utils/countries/countries';
import helpers from '@/utils/helpers';
import { TO_PAY, REDEEMABLE } from '@utils/shootsHelpers';

// Mixins
import photographersMixin from '@utils/mixins/use-photographers.js';

/**
 * This component is used to show the summary of the previous steps in the book a new shoot wizard
 *
 * @displayName BookRecap
 */
export default {
  name: 'BookRecap',
  mixins: [photographersMixin],
  props: {
    type: { type: String }
  },
  components: {
    BaseButton,
    BaseIcon,
    BaseInput,
    BaseLocation,
    BaseSelect,
    BaseSelectWithFilter,
    BasePhoneNumber,
    CardShootInfo
  },

  data() {
    return {
      cardElement: null,
      clientData: {
        billing_address: {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: ''
        },
        name: '',
        surname: '',
        tax_id: ''
      },
      country: '',
      countryOptions: countriesHelper.getGroupedContries(),
      elements: null,
      isButtonLoading: false,
      state: '',
      stripe: null,
      stripeElementStyle: {
        base: {
          iconColor: this.getCssVariable('--main-text-color'),
          color: this.getCssVariable('--main-text-color'),
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: this.getCssVariable('--secondary-text-color')
          },
          '::placeholder': {
            color: this.getCssVariable('--secondary-text-color')
          }
        },
        invalid: {
          iconColor: this.getCssVariable('--danger'),
          color: this.getCssVariable('--danger')
        }
      },
      newTotalPrice: '',
      taxIdLength: null,
      // Card info
      cardElementCvc: null,
      cardElementExpiration: null,
      cardElementNumber: null
    };
  },
  computed: {
    ...mapGetters({
      brief: 'bookShoot/getBrief',
      validPhone: 'bookShoot/getValidPhone',
      validEmail: 'bookShoot/getValidEmail',
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isB2C: 'user/isB2C',
      isSubClient: 'user/isSubClient',
      services: 'services/getServices',
      shoot: 'bookShoot/getShoot',
      user: 'user/getUser'
    }),

    allowPhSelection() {
      // Available status
      // ASSIGNED = 'assigned';
      // CANCELED = 'canceled';
      // CONFIRMED = 'confirmed';
      // COMPLETED = 'completed';
      // READY = 'ready';
      // REDEEMABLE = 'redeemable';
      // SCHEDULED = 'scheduled';
      // TO_PAY = 'toBePayed';
      // TO_SCHEDULE = 'toSchedule';
      // UPLOADED = 'uploaded';

      if (this.type === 'express' && this.shoot.status === undefined) return false;
      return this.isAdmin && this.shoot.status !== TO_PAY;
    },
    /**
     * Returning customer name
     */
    customerName() {
      return this.user.name;
    },
    /**
     * Returning an object with the shoot date and time formatted for FE
     */
    dateTime() {
      return this.fromDateTimeToDateAndTimeRange({ from: this.shoot.time.from, to: this.shoot.time.to, duration: this.shoot.time.duration });
    },
    /**
     * Is button disabled
     */
    isDisabled() {
      const {
        billing_address: { city, country, line1, postal_code, state },
        name,
        surname
      } = this.clientData;
      return !city || !country || !line1 || !postal_code || !state || !name || !surname;
    },
    /**
     * Is current shoot redeemable?
     */
    isRedeemable() {
      return this.shoot && this.shoot.status === REDEEMABLE;
    },
    /**
     * Check if tax id value length is valid based on country
     */
    isTaxIdValid() {
      const taxId = this.clientData.tax_id;

      if (!this.country || taxId.length === 0) return true;

      // To be valid the tax is should be a number (!Number.isNaN) and should be a certain amount of digits.
      return taxId.length === this.taxIdLength && !Number.isNaN(+taxId);
    },
    totalPrice: {
      get() {
        return this.shoot.total_price || 0;
      },
      set(value) {
        this.$emit('update:total-price', value);
      }
    },
    /**
     * Style of the right button
     */
    nextStepButtonStyle() {
      return {
        'background-color': helpers.getCssVariable('--main-btn-color'),
        height: '44px'
      };
    },
    notes: {
      get() {
        return this.shoot.notes || null;
      },
      set(value) {
        this.$emit('update:notes', value);
      }
    },
    outletCode: {
      get() {
        return this.shoot.outlet_code || null;
      },
      set(value) {
        const payload = {
          type: 'outlet_code',
          value
        };
        this.$emit('update:shoot', payload);
      }
    },
    outletName: {
      get() {
        return this.shoot.outlet_name || null;
      },
      set(value) {
        const payload = {
          type: 'outlet_name',
          value
        };
        this.$emit('update:shoot', payload);
      }
    },
    pocName: {
      get() {
        return this.shoot.poc_name || null;
      },
      set(value) {
        const payload = {
          type: 'poc_name',
          value
        };
        this.$emit('update:shoot', payload);
      }
    },
    pocEmail: {
      get() {
        return this.shoot.poc_email || null;
      },
      set(value) {
        const payload = {
          type: 'poc_email',
          value
        };
        this.$emit('update:shoot', payload);
        this.validateEmailIfRedeem(value);
      }
    },
    pocPhone: {
      get() {
        return this.shoot.poc_phone || '';
      },
      set(value) {
        const payload = {
          type: 'poc_phone',
          value
        };
        this.$emit('update:shoot', payload);
        this.validatePhoneIfRedeem(value);
      }
    },
    selectedPhAccount: {
      get() {
        return this.photographers.find(user => this.shoot.photographer_id === user.photographer_id) || null;
      },
      set(value) {
        this.$emit('ph:selected', value);
      }
    },
    shootName: {
      get() {
        return this.shoot.name || null;
      },
      set(value) {
        const payload = {
          type: 'name',
          value
        };
        this.$emit('update:shoot', payload);
      }
    }
  },
  mounted() {
    if (this.isClient && this.shoot.status !== REDEEMABLE) {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.$store.getters['settings/getSettings'].stripe_public);
      this.elements = this.stripe.elements();

      this.cardElementNumber = this.elements.create('cardNumber', {
        showIcon: true,
        style: this.stripeElementStyle
      });

      this.cardElementExpiration = this.elements.create('cardExpiry', {
        style: this.stripeElementStyle
      });

      this.cardElementCvc = this.elements.create('cardCvc', {
        style: this.stripeElementStyle
      });

      this.cardElementNumber.mount('#card-number-element');
      this.cardElementExpiration.mount('#card-expiry-element');
      this.cardElementCvc.mount('#card-cvc-element');
    }
  },

  methods: {
    ...mapMutations({
      validatePhone: 'bookShoot/setValidPhone',
      validateEmail: 'bookShoot/setValidEmail'
    }),

    validatePhoneIfRedeem(val) {
      if ((this.isRedeemable && this.isSubClient) || (this.isRedeemable && this.isClient && !this.isB2C)) this.validatePhone(val);
    },

    validateEmailIfRedeem(val) {
      if ((this.isRedeemable && this.isSubClient) || (this.isRedeemable && this.isClient && !this.isB2c)) this.validateEmail(val);
    },
    /**
     * Method used to download brief
     */
    downloadBrief() {
      this.downloadFile({ file: this.brief, name: this.brief.name });
    },

    /**
     * Method used to get the price of the shoot
     */
    getPrice() {
      if (this.shoot.total_price > 0) return this.shoot.total_price;

      this.newTotalPrice = this.shoot.packages.map(element => Number(element.price)).reduce((a, b) => a + b);
      return this.newTotalPrice;
    },
    /**
     * Retrieve service name based on id
     */
    getService(pkg) {
      const service = this.services.find(service => service.id === pkg.service_id) || {};
      return service.name;
    },
    /**
     * Retrieve subtitles based on service
     */
    getSubtitles() {
      if (this.shoot.content === 'videography') {
        return this.getVideoSubtitles();
      }

      const subtitles = [];

      subtitles.push(this.$t('PACKAGE_NAME', { name: this.shoot.packages[0].name }));
      subtitles.push(this.$tc('PICTURE', this.shoot.packages[0].pictures_number));

      return subtitles;
    },
    getVideoSubtitles() {
      const subtitles = [];
      subtitles.push(this.$t('Video quantity: ' + this.shoot.video_number));
      subtitles.push(this.$t('Duration per video: ' + this.shoot.video_duration + ' seconds'));
      return subtitles;
    },
    handleCountrySelection($event) {
      this.country = $event;

      this.$set(this.clientData.billing_address, 'country', $event.value);
      this.$set(this.clientData.billing_address, 'state', $event.label);

      this.setTaxIdLength();
    },

    /**
     * Set the tax id length based on country
     */
    setTaxIdLength() {
      switch (this.country.value) {
        case 'AE':
          this.taxIdLength = 15;
          break;
      }
    },

    /***** STRIPE ******/
    async createPaymentMethod(event) {
      // We don't want to let default form submission happen here,
      // which would refresh the page.

      event.preventDefault();

      this.isButtonLoading = true;

      const paymentMethodPromise = this.stripe.createPaymentMethod({
        type: 'card',
        card: { ...this.cardElementNumber }
      });

      let shootPromise = null;
      if (this.shoot.id === undefined) shootPromise = ShootsApi.createShoot({ payload: this.shoot });

      const [stripePaymentMethod, shoot] = await Promise.all([paymentMethodPromise, shootPromise]);

      if ((this.shoot.id || shoot.statusCode === 200) && !stripePaymentMethod.error) {
        if (this.clientData.tax_id === '') delete this.clientData.tax_id;
        const userData = await OrderApi.synchronizeClientPayment(this.clientData);

        if (userData.statusCode === 200) {
          const shoot_id = this.shoot.id || shoot.data.shoot.id;
          const order_id = this.shoot.order_id || shoot.data.shoot.order_id;
          const notes = this.shoot.notes;

          this.stripePaymentMethodHandler({ payment_method_id: stripePaymentMethod.paymentMethod.id, order_id, shoot_id, notes });
          this.$store.dispatch('shoot/handleOpenSuccessAlert');
        }
      } else {
        if (stripePaymentMethod.error) {
          this.$store.dispatch('notification/addFailureNotification', stripePaymentMethod.error.message);
        }
        this.isButtonLoading = false;
      }
    },

    // Fetches a payment intent and captures the client secret
    async stripePaymentMethodHandler({ payment_method_id = undefined, payment_intent_id = undefined, order_id = undefined, shoot_id = undefined, notes }) {
      const checkout = await OrderApi.checkout({
        order_id,
        payload: {
          shoot_id: `${shoot_id}`,
          payment_intent_id,
          payment_method_id,
          notes
        }
      });

      const { data = {} } = checkout;
      const { status } = data;

      if (status === 'succeeded') {
        this.$router.push({ name: 'orders' });
      }

      if (status === 'requires_action') {
        this.isButtonLoading = false;
        const cardAction = await this.stripe.handleCardAction(data.payment_client_secret);

        if (cardAction.error) {
          this.$store.dispatch('error/addError', { message: cardAction.error.message });
          return;
        }
        this.stripePaymentMethodHandler({ payment_intent_id: cardAction.paymentIntent.id, order_id, shoot_id, notes });
      }
    }
  }
};
</script>

<style lang="scss">
.bookRecap {
  column-gap: 62px;
  display: grid;
  grid-template-columns: 4fr 6fr;

  &--single-column {
    grid-template-columns: auto;

    .bookRecap__info {
      grid-template-columns: 5fr 5fr;
    }
  }

  &--3-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &__form-element {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .baseInput,
    .baseSelect {
      width: 100%;
    }

    > span {
      margin-bottom: 10px;

      > label {
        @include inter-font($size: 14px);
        text-align: left;
      }
    }

    > div {
      align-items: center;
      border: 1px solid var(--form-fields-border-color);
      border-radius: 10px;
      display: flex;
      height: 35px;
      margin-top: 8px;
      padding: 0px 10px;
      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
  &__form-group {
    display: flex;
    justify-content: space-between;

    > .bookRecap__form-element {
      flex: 50%;
    }

    .baseLocation {
      background-color: transparent;
      height: 40px;
    }

    .baseInput,
    .baseSelect {
      width: 100%;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: auto;
  }
  &__mandatory-fields {
    @include inter-font($size: 12px);
    text-align: right;
  }
  &__notes {
    > p:nth-of-type(2) {
      font-size: calculateRem(11px);
      padding: 8px 12px 0;
    }
  }

  &__outletInfo {
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    height: fit-content;
    padding: calculateRem(19px) calculateRem(28px);
  }

  &__package {
    & ~ .bookRecap__package {
      margin-top: 25px;
    }
  }

  &__payment {
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    height: fit-content;
  }

  &__payment-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px;
    width: 100%;

    .baseButton__label {
      color: var(--main-text-color);
    }
  }

  &__payment-title {
    @include inter-font($size: 14px, $bolded: true);
    margin: 0;
    margin-bottom: 30px;
    text-align: left;
  }

  &__price {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    > p {
      color: var(--secondary-text-color);
      font-family: $inter-regular;
      font-size: calculateRem(11px);
      margin: 8px 0 0 27px;
      text-align: left;
    }
  }

  &__selectph {
    background-color: var(--secondary-bg-color);
    border-radius: calculateRem(10px);
    height: fit-content;
    padding: calculateRem(20px);
  }

  &__title {
    @include inter-font($size: 14px);
    text-align: left;
  }
}

.below {
  .vti__dropdown-list {
    top: auto;
    max-width: 280px;
  }
}
</style>
