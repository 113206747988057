<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <section class="bookSummary">
    <div>
      <p class="bookSummary__title ma-0 mb-28">{{ $t('SUMMARY') }}</p>
      <div v-if="shoot.redeemable_total !== undefined" class="bookSummary__redeemable">
        <BaseIcon icon="reuse" class="mr-15" :stroke="getCssVariable('--redeem-summary-txt')" :custom_style="{ height: '12px', width: '12px' }" />
        {{ $t('PACKAGE_REDEEMABLE') }}
        <span v-if="shoot.redeemable_total > 0">x {{ shoot.redeemable_total }}</span>
      </div>
      <CardShootInfo class="mb-40" icon="map-pin" :subtitles="[`${$tc('CUSTOMER', 1)}: ${companyName}`]" :title="shoot.location.formatted_address" />
      <CardShootInfo class="bookSummary__package" v-for="(pkg, index) of shoot.packages" :key="index" :icon="(index === 0 && 'tag') || ''" :subtitles="getSubtitles(pkg)" :title="getService(pkg)" />
      <div v-if="brief" class="bookSummary__attach">
        <BaseIcon fill="transparent" icon="paperclip" stroke="#151515" :custom_style="{ height: '14px', 'stroke-width': '2px', width: '14px' }" />
        <span @click="downloadBrief">{{ $tc('SEE_ATTACHMENT') }}</span>
      </div>
    </div>

    <div>
      <div class="bookSummary__shootName">
        <p class="bookSummary__title ma-0 mb-20">{{ $t('NAME_OF_SHOOT') }} {{ $t('OPTIONAL') }}:</p>
        <BaseInput v-model="shootName" :label="$t('NAME_OF_SHOOT')" />
      </div>
      <p class="bookSummary__title ma-0 mv-20">{{ $t('DEFINE_PRICE') + ' *' }}</p>
      <div class="bookSummary__price">
        <CardShootInfo icon="dollar-sign" :title="`${$t('CUSTOMER_PRICE')}(${$t('AED')})`" />
        <BaseNumberInput v-model="totalPrice" :class="['bookSummary__price-input', { 'bookSummary__price-input--borders': isError }]" :step="1" />
      </div>
      <hr class="bookSummary__hr" />
      <div class="bookSummary__price">
        <CardShootInfo icon="dollar-sign" :title="`${$t('PHOTOGRAPHER_PRICE')}(${$t('AED')})`" />
        <BaseNumberInput v-model="totalRevenue" :class="['bookSummary__price-input', { 'bookSummary__price-input--borders': isError }]" :step="1" />
      </div>
      <hr class="bookSummary__hr" />
    </div>

    <div data-position="right">
      <p class="bookSummary__title">{{ $t('FIRST_SHOOT') }}</p>
      <CardShootInfo icon="calendar" :subtitles="[dateTime.time]" :title="dateTime.date" />
      <p class="bookSummary__title mt-20 mb-0">{{ $t('NOTES_OPTIONAL') }}</p>
      <BaseInput v-model="notes" class="bookSummary__notes mt-20 mb-47 pb-0" type="textarea" :label="$t('NOTES_LABEL')" />
      <hr data-theme="extra-light" class="hr" />
      <div v-if="shoot.time.from && shoot.time.from !== undefined && allowPhSelection && (shoot.status === 'assigned' || shoot.status === 'confirmed')" class="bookSummary__selectph">
        <p class="bookSummary__title ma-0 mb-39">{{ $t('CHOOSE_PH') }}:</p>
        <BaseSelect
          v-model="selectedPhAccount"
          option-label="name"
          option-value="photographer_id"
          use-input
          :clearable="false"
          :label="$t('NAME_ID')"
          :options="photographers"
          :filterFn="filterOptions"
        />
      </div>
    </div>
    <p class="mt-20 text-left">{{ $t('MANDATORY_FIELD') }}</p>
  </section>
</template>

<script>
// Api
import { UserApi } from '@api/index';

// Store
import { mapGetters } from 'vuex';

// Base Components
import BaseIcon from '@base/BaseIcon.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseNumberInput from '@base/BaseNumberInput.vue';
import BaseSelect from '@base/BaseSelect.vue';

// Components
import CardShootInfo from '@components/CardShootInfo.vue';

// Mixins
import photographersMixin from '@utils/mixins/use-photographers.js';

/**
 * This component is used to show the summary of the previous steps in the book a new shoot wizard
 *
 * @displayName BookSummary
 */
export default {
  name: 'BookSummary',
  mixins: [photographersMixin],
  components: {
    BaseIcon,
    BaseInput,
    BaseNumberInput,
    BaseSelect,
    CardShootInfo
  },
  props: {
    /**
     * If true user could select a ph, otheriwse false (for example: edit mode)
     */
    allowPhSelection: { default: true, type: Boolean }
  },
  data() {
    return {
      isError: false
    };
  },
  watch: {
    totalRevenue() {
      if (this.totalPrice <= this.totalRevenue) {
        this.isError = true;
        this.$q.notify({ caption: this.$t('PHOTOGRAPHER_PRICE_ERROR'), color: 'positive', message: this.$t('ERROR_PRICE'), position: 'top-right', textColor: 'info' });
      } else this.isError = false;
    }
  },

  computed: {
    ...mapGetters({
      brief: 'bookShoot/getBrief',
      companyName: 'bookShoot/getCompanyName',
      services: 'services/getServices',
      shoot: 'bookShoot/getShoot'
    }),
    /**
     * Returning an object with the shoot date and time formatted for FE
     */
    dateTime() {
      return this.fromDateTimeToDateAndTimeRange({ from: this.shoot.time.from, duration: this.shoot.time.duration }) || '';
    },
    //use to set the name of the shoot and emmiting it to parent
    shootName: {
      get() {
        return this.shoot.name || '';
      },
      set(value) {
        this.$emit('update:shootName', value);
      }
    },
    totalPrice: {
      get() {
        return this.shoot.total_price || 0;
      },
      set(value) {
        this.$emit('update:total-price', value);
      }
    },
    totalRevenue: {
      get() {
        return this.shoot.total_revenue || 0;
      },
      set(value) {
        this.$emit('update:total-revenue', value);
      }
    },
    notes: {
      get() {
        return this.shoot.notes || null;
      },
      set(value) {
        this.$emit('update:notes', value);
      }
    }
  },
  methods: {
    /**
     * Method used to download brief
     */
    downloadBrief() {
      this.downloadFile({ file: this.brief, name: this.brief.name });
    },
    /**
     * Retrieve service name based on id
     */
    getService(pkg) {
      const service = this.services.find(service => service.id === Number(pkg.service_id)) || {};
      return service.name;
    },
    /**
     * Retrieve subtitles based on service
     */
    getSubtitles(pkg) {
      if (this.shoot.content === 'videography') {
        return this.getVideoSubtitles();
      }

      const subtitles = [];
      subtitles.push(this.$tc('PICTURE', pkg.picture_number));

      return subtitles;
    },
    getVideoSubtitles() {
      const subtitles = [];
      subtitles.push(this.$t('Video quantity: ' + this.shoot.packages[0].video_number));
      subtitles.push(this.$t('Duration per video: ' + this.shoot.packages[0].video_duration + ' seconds'));
      return subtitles;
    }
  }
};
</script>

<style lang="scss">
.bookSummary {
  column-gap: 62px;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  padding-bottom: 20px;

  &__attach {
    align-items: center;
    background-color: var(--secondary-bg-color);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-top: 36px;
    padding: 6px;

    span {
      font-family: $inter-regular;
      font-size: calculateRem(12px);
      font-style: italic;
      margin-left: 13px;
      text-decoration: underline;
    }
  }

  &__hr {
    background-color: var(--grey-hr);
    border: unset;
    height: 2px;
    margin-bottom: 40.5px;
    margin-top: 12.5px;
    width: 100%;
  }

  &__notes {
    max-height: 90px;
  }

  &__package {
    & ~ .bookSummary__package {
      margin-top: 25px;
    }
  }

  &__price {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__price-input {
    width: 115px !important;
    &--borders {
      border: 1px solid var(--danger);
      > input {
        color: var(--danger) !important;
      }
    }
  }

  &__redeemable {
    @include inter-font($size: 12px, $color: var(--redeem-summary-txt));
    align-items: center;
    background-color: var(--redeem-summary-bg);
    border-radius: 5px;
    display: flex;
    margin-bottom: 40px;
    padding: 2px 8px;

    > span {
      @include antonio-font($size: 14px, $color: var(--redeem-summary-txt), $uppercase: false);
      margin-left: auto;
    }
  }

  &__shootName {
    > label {
      width: 100%;
    }
  }

  &__title {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
    font-weight: bold;
    text-align: left;
  }

  > div {
    &[data-position='right'] {
      background-color: var(--secondary-bg-color);
      border-radius: 10px;
      padding: 20px;
    }
  }
}
.q-notification {
  border-radius: 10px;
  padding: 20px 30px;

  &__message {
    @include inter-font($size: 12px, $bolded: true, $color: var(--main-text-color));
  }
  &__caption {
    @include inter-font($size: 10px, $color: var(--main-text-color));
    max-width: 250px;
  }
}
</style>
