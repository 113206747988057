<template>
  <q-date
    v-model="date"
    :class="['baseCalendar__calendar', `baseCalendar__calendar--is-${size}`, { 'baseCalendar__calendar--is-disabled': disabled }]"
    mask="DD/MM/YYYY"
    color="yellow"
    :default-view="defaultView"
    :disable="disabled"
    :flat="flat"
    :options="options"
    :range="range"
    :title="$t('CALENDAR.DATE_SELECTION')"
    subtitle=""
    :locale="locale"
  >
    <template v-if="showButton">
      <div class="row items-center justify-end q-gutter-sm">
        <BaseButton :custom_style="{ button_style }" :label="$t('CANCEL')" @click.native="handleCancel" />
        <BaseButton :custom_style="{ ...button_style, ...confirmBtnStyle }" :disabled="!date" :label="$t('CONFIRM')" @click.native="handleConfirm" />
      </div>
    </template>
  </q-date>
</template>

<script>
// Base components
import BaseButton from '@base/BaseButton.vue';

// Utils
import helpers from '@utils/helpers';

import moment from 'moment';

export default {
  name: 'BaseCalendar',
  model: {
    event: 'new-date',
    prop: 'defaultDate'
  },
  components: {
    BaseButton
  },
  props: {
    /**
     * Use this prop to set the confirm and cancel buttons
     */
    showButton: { default: true, type: Boolean },
    /**
     * Use to pass a custom style for confirm button
     */
    confirmBtnStyle: { default: () => ({ 'background-color': helpers.getCssVariable('--main-btn-color') }), type: Object },
    /**
     * Use this prop to pass a default date
     */
    defaultDate: { default: '', type: String },
    /**
     * Set to Years or Months or Calendar to show a specific datepicker view by default
     */
    defaultView: { default: 'Calendar', type: String },
    /**
     * Set to true
     */
    disabled: { default: false, type: Boolean },
    /**
     * Set to true if want to remove the shadow
     */
    flat: { default: false, type: Boolean },
    icon: { default: false, type: Boolean },
    /**
     * Set the date
     */
    options: {
      default: () => {},
      type: [Array, Object, Function]
    },
    /**
     * Set to true if a range of data selection is needed
     */
    range: { default: false, type: Boolean },
    /**
     * Set to small, medium, large based
     */
    size: { default: '', type: String }
  },
  data() {
    return {
      button_style: { height: '28px' },
      date: this.defaultDate,
      previous_date: {}
    };
  },
  created() {
    if (this.date === moment.utc(this.date).format()) this.setDate();
  },

  computed: {
    /**
     * Retrieve days based on locale
     */
    days() {
      return [
        this.$t('CALENDAR.DAYS.SUNDAY'),
        this.$t('CALENDAR.DAYS.MONDAY'),
        this.$t('CALENDAR.DAYS.TUESDAY'),
        this.$t('CALENDAR.DAYS.WEDNESDAY'),
        this.$t('CALENDAR.DAYS.THURSDAY'),
        this.$t('CALENDAR.DAYS.FRIDAY'),
        this.$t('CALENDAR.DAYS.SATURDAY')
      ];
    },
    /**
     * Retrieve days of week short name version
     */
    daysShort() {
      return this.days.map(day => day.slice(0, 1));
    },
    /**
     * Retrive calendar locale
     */
    locale() {
      return {
        days: this.days,
        daysShort: this.daysShort,
        months: this.months,
        monthsShort: this.monthsShort,
        firstDayOfWeek: 0
      };
    },
    /**
     * Retrieve months based on locale
     */
    months() {
      return [
        this.$t('CALENDAR.MONTHS.JANUARY'),
        this.$t('CALENDAR.MONTHS.FEBRUARY'),
        this.$t('CALENDAR.MONTHS.MARCH'),
        this.$t('CALENDAR.MONTHS.APRIL'),
        this.$t('CALENDAR.MONTHS.MAY'),
        this.$t('CALENDAR.MONTHS.JUNE'),
        this.$t('CALENDAR.MONTHS.JULY'),
        this.$t('CALENDAR.MONTHS.AUGUST'),
        this.$t('CALENDAR.MONTHS.SEPTEMBER'),
        this.$t('CALENDAR.MONTHS.OCTOBER'),
        this.$t('CALENDAR.MONTHS.NOVEMBER'),
        this.$t('CALENDAR.MONTHS.DECEMBER')
      ];
    },
    /**
     * Retrieve months short name version
     */
    monthsShort() {
      return this.months.map(day => day.slice(0, 3));
    }
  },
  watch: {
    date() {
      if (!this.showButton) this.handleConfirm();
    }
  },
  methods: {
    handleCancel() {
      this.date = undefined;
      this.$emit('new-date', this.date);
    },
    handleConfirm() {
      if (!this.date) return;

      if (this.range) {
        this.handleRangeConfirm();
        return;
      }

      const [day, month, year] = this.date.split('/');
      const iso = moment.utc([year, month - 1, day]).format();

      this.$emit('new-date', iso);
    },
    handleFilterClosed() {
      this.date = '';
    },
    handleRangeConfirm() {
      if (typeof this.date === 'object') {
        let { from, to } = this.date;

        const [dayFrom, monthFrom, yearFrom] = from.split('/');
        const isoFrom = moment.utc([yearFrom, monthFrom - 1, dayFrom]).format();
        from = isoFrom;

        const [dayTo, monthTo, yearTo] = to.split('/');
        const isoTo = moment
          .utc([yearTo, monthTo - 1, dayTo])
          .endOf('day')
          .format();
        to = isoTo;

        this.$emit('new-date', { from, to });
      } else {
        const [day, month, year] = this.date.split('/');
        const from = moment.utc([year, month - 1, day]).format();
        const to = moment
          .utc([year, month - 1, day])
          .endOf('day')
          .format();

        this.$emit('new-date', { from: from, to: to });
      }
    },
    setDate() {
      const [year, month, day] = this.date.substr(0, 10).split('-');
      this.date = `${day}/${month}/${year}`;
      return this.date;
    }
  }
};
</script>

<style lang="scss">
.baseCalendar {
  &__calendar {
    border-radius: 10px;
    .baseButton__label {
      text-transform: uppercase;
      font-size: calculateRem(12px);
    }

    &--is-small {
      .q-date__calendar-item {
        .q-btn__wrapper {
          min-height: unset;

          * {
            line-height: 1;
          }
        }
      }

      .q-date__calendar-item > div,
      .q-date__calendar-item button {
        line-height: 18px;
        height: 20px;
        width: 20px;
      }
    }

    .q-date__calendar-item > div,
    .q-date__calendar-item button,
    .q-btn__content {
      color: var(--main-text-color) !important;
    }

    .q-date__calendar-days-container {
      min-height: unset;
    }

    .q-date__header {
      align-items: center;
      background-color: var(--calendar-header-bgcolor) !important;
      color: var(--calendar-header-color);
      display: flex;
      justify-content: center;
      height: 50px;

      > div:first-child {
        display: none;
      }

      > div {
        * {
          font-family: $inter_regular;
          font-size: calculateRem(10px);
          text-transform: uppercase;
        }
      }
    }

    .q-date__view {
      min-height: unset;

      .q-date__calendar-item,
      span.q-btn__content {
        font-family: $inter_regular;
        font-size: calculateRem(10px);
      }
    }
  }
}
</style>
