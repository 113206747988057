<template>
  <q-dialog v-model="isOpen" @hide="handleClose">
    <q-card class="baseAlert__card">
      <BaseIcon class="baseAlert__close" icon="close" @click="$emit('close-alert')" />
      <div class="baseAlert__content">
        <BaseIcon class="baseAlert__icon" :icon="icon" stroke="#151515" />
        <h4 class="baseAlert__title">{{ title }}</h4>
        <slot></slot>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import BaseIcon from '@components/base/BaseIcon';

export default {
  name: 'BaseAlert',
  components: {
    BaseIcon
  },
  props: {
    /**
     * The name of the alert's icon
     */
    icon: { type: String },
    /**
     * Set to true to show the dialog
     */
    show: { default: false, type: Boolean },
    /**
     * Title of the alert's content
     */
    title: { type: String }
  },
  data() {
    return {
      isOpen: false
    };
  },
  watch: {
    show: {
      handler(value) {
        this.isOpen = value;
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close-alert');
    }
  }
};
</script>
<style lang="scss" scoped>
.baseAlert {
  &__card {
    align-items: center;
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: calculateRem(557px);
    max-width: calculateRem(750px);
    padding: calculateRem(33px);
    position: relative;
  }

  &__content {
    max-width: 60%;
    padding: calculateRem(50px) 0;
    text-align: center;
  }

  &__close {
    cursor: pointer;
    height: calculateRem(15px);
    position: absolute;
    right: calculateRem(20px);
    top: calculateRem(20px);
    width: calculateRem(15px);
  }

  &__icon {
    height: calculateRem(51px);
    width: calculateRem(51px);
  }

  &__title {
    @include antonio-font($size: 23px, $uppercase: true);
    margin-bottom: calculateRem(20px);
  }
}
</style>
<style lang="scss">
.baseAlert {
  &__content {
    & p {
      @include inter-font($size: 14px, $bolded: false);
    }
  }
}
</style>
