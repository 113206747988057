<template>
  <div class="container">
    <div class="text-container">
      <p class="header">Payment Unsuccessful</p>
      <p class="text">We're sorry to inform you that your payment could not be processed at this time. Please try again or contact our support team for assistance.</p>

      <p class="header">Troubleshooting</p>
      <p class="text">If you encountered an issue during the payment process, please ensure your payment details are correct and up-to-date, or try using a different payment method.</p>

      <p class="header">Need Assistance?</p>
      <p class="text">Our support team is here to help. You can reach out to us anytime for further assistance regarding your payment or booking process.</p>
      <BaseButton icon="arrow-left" :icon_style="iconStyle" :custom_style="buttonStyle" :label="$t('Return to Booking')" @click.native="$router.replace({ name: 'flashyShoots' })" />
    </div>
    <div class="img-container">
      <img src="https://flashy-packages-pictures20220201104541386600000001.s3.eu-central-1.amazonaws.com/assets/credit-card.svg" alt="Credit card" />
    </div>
  </div>
</template>

<script>
import BaseButton from '../components/base/BaseButton.vue';
import helpers from '@utils/helpers';

export default {
  name: 'StripeError',
  components: {
    BaseButton
  },
  data() {
    return {
      buttonStyle: {
        'box-shadow': `0 3px 14px ${helpers.getCssVariable('--main-btn-color-shadow')}`,
        'background-color': helpers.getCssVariable('--main-btn-color'),
        color: helpers.getCssVariable('--main-text-color'),
        width: '300px'
      },
      iconStyle: {
        fill: helpers.getCssVariable('--main-text-color'),
        stroke: helpers.getCssVariable('--main-text-color')
      }
    };
  }
};
</script>
<style scoped lang="scss">
.container {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: row; /* Default layout is row */
  position: relative;
}
.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 50%;
}

.text-container {
  display: flex;
  flex-direction: column;
  /* background-color: lightgrey; */
  position: relative;
  padding: 5rem 4rem;
  margin-top: auto;
  margin-bottom: auto;
  flex: 50%;
}
.header {
  font-size: 30px;
  font-weight: bold;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
}

.text {
  font-size: 20px;
  font-weight: 200;
  color: #000; /* Black text */
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
}
@include responsive($max: mobileL) {
  .container {
    flex-direction: column !important;
    height: auto !important;
    padding: 24px;
  }
  .img-container {
    position: relative;
    flex: auto;
    display: none;
  }

  .text-container {
    flex: auto;
    margin: 0;
    padding: 0;
  }

  .text {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 16px !important;
  }
}
</style>
