<template>
  <div class="editPackage full-height">
    <section class="editPackage__body">
      <div class="editPackage__left-column">
        <BaseInput class="editPackage__title mb-30 pa-0" v-model="$v.newPackage.name.$model" borderless :clearable="false" />
        <CustomLabel class="mb-10" icon="tag" label="Category" />
        <BaseSelect :label="$t('SELECT_CATEGORY')" v-model="$v.newPackage.service_id.$model" option-label="name" option-value="id" class="pa-0" :options="servicesArr" />
        <CustomLabel class="mb-10 mt-30" icon="dollar-sign" :label="$t('CUSTOMER_PRICE')" />
        <BaseInput :placeholder="$t('SET_PRICE')" v-model="$v.newPackage.price.$model" class="pa-0" type="number" />
        <CustomLabel class="mb-10 mt-30" icon="dollar-sign" :label="$t('PHOTOGRAPHER_PRICE')" />
        <BaseInput :placeholder="$t('SET_PRICE')" v-model="$v.newPackage.photographer_revenue.$model" class="pa-0" type="number" />
        <CustomLabel class="mb-10 mt-30" icon="clock" :label="$t('DURATION')" />
        <BaseInput :placeholder="$t('HOURS')" v-model="$v.newPackage.duration.$model" :min="1" :step="0.5" class="pa-0" type="number" />
        <CustomLabel class="mb-10 mt-30" icon="hashtag" :label="$t('QUANTITY')" />
        <BaseInput :placeholder="$t('SHOOTS_NUMBER')" v-model="$v.newPackage.picture_number.$model" class="pa-0" type="number" />
      </div>
      <div class="editPackage__right-column ml-62">
        <BaseFileUploaderArea :label="$t('CHOOSE_FILE')" accepted-files=".zip, .jpeg, .png, .jpg" :default-files="pictures" multiple @file:update="pictures = $event" />
        <div class="editPackage__right-bottom mt-43">
          <div data-attribute="left">
            <CustomLabel :label="$t('DESCRIPTION')" />
            <BaseInput v-model="$v.newPackage.description.$model" type="textarea" :max-words="150" :placeholder="`${$t('ADD_DESCRIPTION')} (${$t('MAX_WORDS', { max: 150 })})`" />
          </div>
          <div data-attribute="right">
            <CustomLabel :label="$t('OTHER_LABEL_TAG')" />
            <div data-attribute="options">
              <BaseToggle v-model="newPackage.is_plus">
                <span class="editPackage__toggle-label">{{ $t('SET_PACKAGE_PLUS') }}</span>
              </BaseToggle>
              <BaseToggle :value="newPackage.status === 'hidden'" @toggled="handleToggle">
                <span class="editPackage__toggle-label">{{ $t('SET_PACKAGE_HIDDEN') }}</span>
              </BaseToggle>
              <q-btn-toggle
                v-model="newPackage.package_type"
                class="my-toggle"
                no-caps
                rounded
                toggle-color="primary"
                color="white"
                text-color="black"
                :options="[
                  { label: 'B2C', value: 'b2c' },
                  { label: 'B2B', value: 'b2b' }
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="editPackage__footer">
      <BaseButton :icon_style="{ height: '12px', width: '8px' }" icon="arrow-left" :label="$t('BACK')" @click.native="$emit('go-back')" />
      <BaseButton v-if="!isPackageEmpty" class="q-ml-auto mr-20" data-type="danger" icon="trash" :label="$t('DELETE')" @click.native="handleDelete" />
      <BaseButton data-type="default" icon="check" :disabled="isConfirmDisabled" :label="$t('CREATE')" :loading="isLoading" :percentage="loadingPercentage" @click.native="handleCreateClick" />
    </section>
  </div>
</template>

<script>
// Api
import { MediaApi, PackagesApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseFileUploaderArea from '@base/BaseFileUploaderArea.vue';
import BaseInput from '@base/BaseInput.vue';
import BaseSelect from '@base/BaseSelect.vue';
import BaseToggle from '@base/BaseToggle.vue';

// Common Components
import CustomLabel from '@common/CustomLabel.vue';

/**
 * Component used to edit and create a new category
 *
 * @displayName EditPackage
 */
export default {
  name: 'EditPackage',
  components: {
    BaseButton,
    BaseFileUploaderArea,
    BaseInput,
    BaseSelect,
    BaseToggle,
    CustomLabel
  },
  props: {
    /**
     * Package to be edited
     */
    service: { default: null, type: Object },
    /**
     * List of available services
     */
    servicesArr: { default: () => [], type: Array }
  },
  data() {
    return {
      isLoading: false,
      loadingPercentage: 0,
      newPackage: {
        service_id: undefined,
        photographer_revenue: undefined,
        price: undefined,
        duration: undefined,
        picture_number: undefined,
        description: undefined,
        is_plus: false,
        hide: false,
        name: 'Package name',
        package_type: 'b2b'
      },
      pictures: []
    };
  },
  validations: {
    newPackage: {
      name: {
        valid: value => {
          return value;
        }
      },
      service_id: {
        valid: value => {
          return value !== undefined && value !== '';
        }
      },
      photographer_revenue: {
        valid: (value, vm) => {
          return value !== undefined && +value > 0 && +value < +vm.price;
        }
      },
      price: {
        valid: value => {
          return value !== undefined && value > 0;
        }
      },
      description: {
        valid: value => {
          return value !== undefined && value !== '' && value.trim().split(/\s+/).length <= 150;
        }
      },
      duration: {
        valid: value => {
          return value !== undefined && value !== '' && value > 0;
        }
      },
      picture_number: {
        valid: value => {
          return value !== undefined && value !== '' && value > 0;
        }
      }
    }
  },
  watch: {
    /**
     * Show an error notification if the inserted photographer revenue is higher than the shoot price
     */
    photographerRevenue(value) {
      if (Number(value) >= Number(this.packagePrice) || this.packagePrice === undefined) {
        this.$q.notify({ caption: this.$t('PHOTOGRAPHER_PRICE_ERROR'), color: 'positive', message: this.$t('ERROR_PRICE'), position: 'top-right', textColor: 'info' });
      }
    },
    packagePrice(value) {
      if (Number(this.photographerRevenue) >= Number(value)) {
        this.$q.notify({ caption: this.$t('PHOTOGRAPHER_PRICE_ERROR'), color: 'positive', message: this.$t('ERROR_PRICE'), position: 'top-right', textColor: 'info' });
      }
    }
  },
  computed: {
    /**
     * Return true if the package is empty meaning that we are not editing but creating a new package
     */
    isPackageEmpty() {
      return Object.keys(this.service).length === 0;
    },
    /**
     * Check if the confirm button is disabled
     */
    isConfirmDisabled() {
      return this.$v.$invalid;
    },
    packagePrice() {
      return this.newPackage.price;
    },
    photographerRevenue() {
      return this.newPackage.photographer_revenue;
    }
  },
  created() {
    if (!this.isPackageEmpty) {
      this.newPackage = { ...this.newPackage, ...this.service };
      this.newPackage.service_id = this.servicesArr.find(service => service.id === this.service.service_id);
    }

    this.retrievePictures();
  },
  methods: {
    /**
     * Method used to create a new package
     */
    async createNewPackage() {
      const payload = { ...this.newPackage };
      payload.service_id = this.newPackage.service_id.id;

      const _package = await PackagesApi.create(payload);

      if (_package.statusCode === 200) {
        await this.uploadPictures(_package.data.pack.id);

        this.isLoading = false;
        this.$emit('packages:refresh');
        this.$emit('go-back');
      }
    },
    /**
     * Deleting package
     */
    async handleDelete() {
      const deletePackage = await PackagesApi.delete(this.newPackage.id);

      if (deletePackage.statusCode === 200) {
        this.$store.dispatch('notification/addSuccessNotification', this.$t('NOTIFICATIONS.PACKAGES.DELETE', { name: this.newPackage.name }));
        this.$emit('packages:refresh');
        this.$emit('go-back');
      }
    },
    /**
     * Method used to edit an existing package
     */
    async editPackage() {
      await this.uploadPictures();

      const payload = { ...this.newPackage };
      payload.service_id = this.newPackage.service_id.id;

      const _package = await PackagesApi.update({ id: this.newPackage.id, payload });

      if (_package.statusCode === 200) {
        this.$emit('packages:refresh');
        this.$emit('go-back');
      }
    },
    /**
     * Method used to call either the create package api or the edit package api
     */
    handleCreateClick() {
      this.isLoading = true;

      if (this.isPackageEmpty) {
        this.createNewPackage();
        return;
      }

      this.editPackage();
    },
    /**
     *
     * used to receive the emitted event and set toggle updating based on the newpackage status and value of newpackage hide
     */
    handleToggle($event) {
      this.$set(this.newPackage, 'status', $event ? 'hidden' : 'none');
      this.newPackage.hide = $event;
    },
    /**
     * Fetching all images
     */
    async retrievePictures() {
      const { num_pictures } = this.newPackage;

      const url = 'https://flashy-packages-pictures20220125084601854000000001.s3.eu-central-1.amazonaws.com/package';

      for (let i = 1; i <= num_pictures; i++) {
        const image = await fetch(`${url}/${this.newPackage.id}/${i}`);
        const blob = await image.blob();
        const file = new File([blob], `${this.newPackage.id}-${i}.png`, { type: 'image/png' });

        this.pictures.push(file);
      }
    },
    /**
     * Method used to upload example pictures
     */
    async uploadPictures(id = this.newPackage.id) {
      if (this.pictures.length === 0) return;

      const isZip = this.pictures.every(({ type }) => type.indexOf('image') === -1);
      let blob = null;

      if (!isZip) {
        blob = await this.zipFiles({ files: this.pictures });
      } else {
        blob = new Blob([this.pictures[0]]);
      }

      // Methods that calculates the percentage of the upload. Updates the percentage variable to be passed to the button component
      // so that a load bar gets shown inside the button
      const onUploadProgress = data => {
        const { loaded, total } = data;
        this.loadingPercentage = Math.round((loaded * 100) / total);
      };

      const picturesResource = await MediaApi.uploadPackagePictures({
        id,
        blob,
        onUploadProgress
      });

      if (picturesResource.statusCode === 200) return picturesResource.data.numUploadedPictures;

      return undefined;
    }
  }
};
</script>

<style lang="scss">
.editPackage {
  $horizontal-padding: 171px;
  $horizontal-padding-medium: 100px;
  $horizontal-padding-small: 70px;
  $horizontal-padding-mini: 20px;

  display: flex;
  flex-direction: column;

  &__body {
    background-color: var(--main-bg-color);
    display: flex;
    flex: 1;
    padding: 37px $horizontal-padding 49px $horizontal-padding;

    @include responsive($max: laptopM) {
      padding: 37px $horizontal-padding-small 49px $horizontal-padding-small;
    }
    @include responsive($max: tablet) {
      flex-direction: column;
      padding: 37px $horizontal-padding-medium 49px $horizontal-padding-medium;
    }
    @include responsive($max: md) {
      padding: 37px $horizontal-padding-mini 49px $horizontal-padding-mini;
    }
  }

  &__footer {
    align-items: center;
    background-color: var(--secondary-bg-color);
    display: flex;
    justify-content: space-between;
    min-height: 104px;
    padding: 0 $horizontal-padding;

    @include responsive($max: laptopM) {
      padding: 0 $horizontal-padding-small;
    }
    @include responsive($max: tablet) {
      padding: 0 $horizontal-padding-medium;
    }
    @include responsive($max: md) {
      padding: 0 $horizontal-padding-mini;
    }
  }

  &__left-column {
    flex: 40%;
    .q-field__focusable-action {
      display: none !important;
    }
  }

  &__right-bottom {
    display: flex;
    justify-content: space-between;

    > div[data-attribute='left'],
    > div[data-attribute='right'] {
      flex: 50%;
    }

    > div[data-attribute='right'] {
      margin-left: 42px;
    }

    > div[data-attribute='right'] > div[data-attribute='options'] {
      align-items: flex-start;
      background-color: var(--secondary-bg-color);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 150px;
      justify-content: center;
    }
  }

  &__right-column {
    flex: 60%;

    @include responsive($max: tablet) {
      flex: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }

    .baseFileUploaderArea {
      height: 278px;
      min-height: unset;
    }
    .baseToggle .q-toggle__inner {
      margin-left: 5px;
      margin-right: 20px;
    }
    .customLabel__label {
      margin-bottom: 14px;
    }
  }

  &__title {
    @include antonio-font($size: 20px);
    border-bottom: 2px solid var(--main-text-color);
    text-align: left;

    .q-field__control {
      padding: 0;
    }
  }
  .q-textarea.q-field--dense .q-field__control {
    max-height: 200px;
  }
  .q-textarea.q-field--dense .q-field__native {
    height: 150px;
    max-height: 196px !important;
  }

  &__toggle {
    align-items: center;
    display: flex;
  }

  &__toggle-label {
    @include inter-font($size: 14px);
  }
  .q-textarea.q-field--dense.q-field--labeled .q-field__control-container {
    overflow: auto;
  }

  .my-toggle {
    margin-left: 10px;
    margin-top: 5px;
  }
}
</style>
