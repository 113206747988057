import { render, staticRenderFns } from "./EditPackage.vue?vue&type=template&id=f84c6066&"
import script from "./EditPackage.vue?vue&type=script&lang=js&"
export * from "./EditPackage.vue?vue&type=script&lang=js&"
import style0 from "./EditPackage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QField from 'quasar/src/components/field/QField.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtnToggle,QField,QToggle});
