<template>
  <div class="shootIdInputSection">
    <div class="shootIdInputSection__columns">
      <div class="shootIdInputSection__col">
        <p class="shootIdInputSection__label">{{ $t('UPLOADING.SHOOT_ID_INPUT_LABEL') }}</p>
        <BaseInput clearable :value="shootIdFormInputs.insertedShootId" @update:value="$emit('update-inserted-shoot-id-input', $event)" />
      </div>
      <div class="shootIdInputSection__col" v-if="content === 'photography'">
        <p class="shootIdInputSection__label">{{ $t('UPLOADING.EXPOSURE_QUESTION') }}</p>
        <ul class="shootIdInputSection__answers">
          <li
            v-for="answer in answers"
            :key="answer"
            :class="['shootIdInputSection__answer', { 'shootIdInputSection__answer--active': shootIdFormInputs.activeAnswer === answer }]"
            @click="$emit('update-active-answer-input', answer)"
          >
            {{ $t(`UPLOADING.${answer.toUpperCase()}`) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="shootIdInputSection__checkbox">
      <div class="mb-30">
        <p>{{ $t('UPLOADING_WARNING') }}</p>
        <p v-if="this.content === 'photography'">{{ $t('ACCEPTED_FORMATS') }}</p>
        <p>{{ this.content === 'videography' ? $t('CONFIRM_UPLOAD_VIDEO') : $t('CONFIRM_UPLOAD_PHOTO') }}</p>
      </div>
      <BaseCheckbox
        :label="content === 'videography' ? $t('UPLOADING.CHECKBOX_LABEL_VIDEOGRAPHY') : $t('UPLOADING.CHECKBOX_LABEL')"
        :value="shootIdFormInputs.imagesMatchDeliveries"
        @update:value="$emit('update-checkbox-input', $event)"
      />
    </div>
  </div>
</template>
<script>
// Base components
import BaseInput from '@base/BaseInput.vue';
import BaseCheckbox from '@base/BaseCheckbox.vue';

export default {
  name: 'ShootIdInputSection',
  components: {
    BaseInput,
    BaseCheckbox
  },
  props: {
    /**
     * Use this prop to pass the object with the inputs values
     */
    shootIdFormInputs: {
      default() {
        return { insertedShootId: '', activeAnswer: '', imagesMatchDeliveries: false };
      },
      type: Object
    },
    content: { default: 'photography', type: String }
  },
  data() {
    return {
      answers: ['yes', 'no']
    };
  }
};
</script>
<style lang="scss" scoped>
.shootIdInputSection {
  &__answer {
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    @include inter-font(14px, false, var(--main-text-color));
    padding: calculateRem(10px) calculateRem(8px);
    text-transform: uppercase;

    &--active {
      background-color: var(--secondary-btn-color);
      color: var(--terziary-text-color);
    }
  }

  &__answers {
    list-style: none;
    padding: 0;
  }

  &__checkbox {
    position: absolute;
    bottom: 0;
    left: 0;
    > div {
      > p {
        @include inter-font($size: 12px, $color: var(--secondary-text-color));
        margin: 0;
      }
    }
  }

  &__col {
    width: 50%;

    &:not(:last-child) {
      padding-right: calculateRem(40px);
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    @include inter-font(14px);
    font-weight: 400;
    margin: 0 0 calculateRem(16px) 0;
  }
}
</style>
