<template>
  <div class="statusRecapCard">
    <section class="statusRecapCard__header">
      <div class="statusRecapCard__box" :style="`background-color: ${color}`"></div>
      <p class="statusRecapCard__title ma-0 ml-5">{{ status }}</p>
    </section>
    <p class="statusRecapCard__counter ma-0 mt-23">{{ counter }}</p>
  </div>
</template>

<script>
export default {
  name: 'StatusRecapCard',
  props: {
    /**
     * Color
     */
    color: { default: '', type: String },
    /**
     * Number of shoots of the status
     */
    counter: { default: null, type: Number },
    /**
     * Percentage (string with - or + if its decreasing or increasing)
     */
    percentage: { default: '', type: String },
    /**
     * Status label
     */
    status: { default: '', type: String }
  }
};
</script>

<style lang="scss" scoped>
.statusRecapCard {
  background-color: var(--status-card-bg);
  border-radius: 10px;
  height: 120px;
  min-width: 139px;
  padding: 15px;

  &__box {
    border-radius: 2px;
    height: 10px;
    width: 10px;
  }

  &__counter {
    @include inter-font($size: 25px, $bolded: true);
  }

  &__header {
    align-items: center;
    display: flex;
  }

  &__title {
    @include antonio-font($size: 12px);
    @include text-ellipsed;
  }
}
</style>
