<template>
  <div class="reschedule">
    <BaseCalendar class="reschedule__cal" :showButton="false" ref="calendar" flat size="small" :options="filterPastDate" @new-date="shootDate = $event" />
    <div class="reschedule__datetime">
      <div class="reschedule__element">
        <p>{{ $t('SELECT_TIME') }}</p>
        <p v-if="duration > 0">{{ `${hours + ' ' + $t('HOURS')}` }}</p>
        <BaseNumberInput v-else class="reschedule__hours-input" :min="1" :step="0.5" v-model="hours" />
      </div>

      <div class="reschedule__element mt-30">
        <p>{{ $t('FROM') }}</p>
        <BaseSelect v-model="time.from" :clearable="false" :options="getFromTimeStops" />
      </div>
      <div class="reschedule__element mt-30">
        <p>{{ $t('TO') }}</p>
        <p>{{ getTimeToStops }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from 'quasar';
import moment from 'moment';

// Base Components
import BaseCalendar from '@base/BaseCalendar.vue';
import BaseNumberInput from '@base/BaseNumberInput.vue';
import BaseSelect from '@base/BaseSelect.vue';

export default {
  name: 'Reschedule',
  components: {
    BaseCalendar,
    BaseNumberInput,
    BaseSelect
  },
  props: {
    /**
     * Shoot duration (minutes)
     */
    duration: { default: null, type: Number }
  },
  data() {
    return {
      hours: 1,
      shootDate: '',
      time: {
        from: '08:00 am',
        to: ''
      }
    };
  },
  computed: {
    /**
     * Returns the available time range for the from select
     */
    getFromTimeStops() {
      return this.getTimeStops({ start: '08:00', end: '17:45' });
    },
    /**
     * Function to get the final time of the shoot
     */
    getTimeToStops() {
      const startTime = moment(this.time.from, 'hh:mm a');
      const stopTime = startTime.add(this.hours, 'hours').format('hh:mm a');
      return stopTime;
    }
  },
  watch: {
    hours() {
      this.handeleDateTime();
    },
    shootDate() {
      this.handeleDateTime();
    },
    time: {
      deep: true,
      handler: function () {
        this.handeleDateTime();
      }
    }
  },
  created() {
    this.hours = this.duration / 60 || 1;
  },
  methods: {
    /**
     * This method check if a date is in the past, if so its not selectable
     */
    filterPastDate(_date) {
      return _date > date.formatDate(Date.now(), 'YYYY/MM/DD');
    },
    /**
     * Given a starting hour and an end hour, it returns an array of timeslot with 15 minutes range.
     * ex.: 08:00, 08:15, 08:30
     */
    getTimeStops({ end, start }) {
      var startTime = moment(start, 'HH:mm');
      var endTime = moment(end, 'HH:mm');

      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      var timeStops = [];

      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format('hh:mm a'));
        startTime.add(15, 'minutes');
      }
      return timeStops;
    },
    /**
     * Method used to build the datetime to be sent to BE
     */
    handeleDateTime() {
      if (!this.date) this.$emit('update:datetime', {});
      const [year, month, day] = this.shootDate.substr(0, 10).split('-');

      const formattedFromTime = moment(this.time.from, ['h:mm A']).format('HH:mm');

      const formattedToTime = moment(this.time.from, ['h:mm A']).add(this.hours, 'hours').format('HH:mm');

      const [hoursFrom, minutesFrom] = formattedFromTime.split(':');

      const dateTimeFrom = moment.utc([Number(year), Number(month) - 1, Number(day), Number(hoursFrom), Number(minutesFrom)]).format();
      const [hours, minutes] = formattedToTime.split(':');
      const dateTimeTo = moment.utc([Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes)]).format();

      if (moment(dateTimeFrom).isValid() && moment(dateTimeTo).isValid()) this.$emit('update:datetime', { from: dateTimeFrom, duration: this.hours });
    }
  }
};
</script>

<style lang="scss" scoped>
.reschedule {
  display: flex;
  min-width: 300px;
  @include responsive($max: md) {
    border-top: 1px solid var(--main-bg-color);
    flex-direction: column;
    justify-content: center;
    min-width: auto;
    padding-top: 30px;
  }

  &__cal {
    border: 1px solid var(--main-bg-color);
    @include responsive($max: md) {
      align-self: center;
    }
  }
  &__datetime {
    display: flex;
    flex-direction: column;
    margin-left: 70px;
    min-width: 300px;
    @include responsive($max: md) {
      margin-left: 0;
      margin-top: 30px;
      min-width: auto;
      padding: 0 30px;
    }
  }

  &__element {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > p {
      margin: 0;
    }

    > label {
      padding: 0;
    }
  }
  &__title {
    margin-top: 20px;
  }
}
</style>
