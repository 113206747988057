<template>
  <BaseStepper class="adminBook" handle-navigation no-navigator :steps="steps">
    <template #header>
      <div class="adminBook__header">
        <span class="adminBook__title">{{ isEditMode ? $t('SHOOT.EDITING_SHOOT', { id: shoot.id }) : $t('BOOK_SHOOT') }}</span>
        <span class="adminBook__clientName">{{ customerName }}</span>
        <p class="adminBook__pagination">{{ $t('STEP') }} {{ step }}/{{ steps.length }}</p>
      </div>
    </template>
    <template #SELECT_BUSINESS>
      <div class="adminBook__step">
        <p class="adminBook__subtitle">{{ $t('SELECT_BUSINESS_ACCOUNT') }}: *</p>
        <SelectBusinessAccount class="adminBook__selectBusinessAccount" @client:selected="handleClientSelected" />
        <p class="ma-0 mb-n5 absolute text-left" style="bottom: 5px">{{ $t('MANDATORY_FIELD') }}</p>
      </div>
    </template>
    <template #SELECT_TYPE>
      <div class="adminBook__step">
        <p class="adminBook__subtitle">{{ $t('SELECT_BOOKING_TYPE') }}: *</p>
        <ShootTypeCard class="adminBook__selectType" @update:type="handleTypeSelected" />
        <p class="mt-20 text-left">{{ $t('MANDATORY_FIELD') }}</p>
      </div>
    </template>
    <template #SELECT_CONTENT>
      <div class="adminBook__step">
        <p class="adminBook__subtitle">{{ $t('SELECT_BOOKING_TYPE') }}:</p>
        <ShootContentCard class="adminBook__selectType" @update:type="handleContentSelected" />
        <p class="mt-20 text-left">{{ $t('MANDATORY_FIELD') }}</p>
      </div>
    </template>
    <template #SELECT_CAT>
      <div class="adminBook__step">
        <StandardPackage v-if="type === 'express'" @packages:updated="handlePackagesUpdate" />
        <CustomPackage
          v-else
          :allow-redeems="!isEditMode"
          :editMode="isEditMode"
          :type="type"
          @update:videoQuantity="handleVideoQuantity"
          @update:videoDuration="handleVideoDuration"
          @update:pictureQuantity="handlePicturesQuantity"
          @update:category="handleCustomPackage"
          @disabled:next="nextDisabled = true"
        />
        <p class="ma-0 mb-n5 absolute text-left">{{ $t('MANDATORY_FIELD') }}</p>
      </div>
    </template>
    <template #SELECT_DATETIME>
      <LocationAndTimeSelection
        :editMode="isEditMode"
        :type="type"
        class="adminBook__step"
        @date:skip="handleDateSkip"
        @update:datetime="handleUpdateDatetime"
        @update:location="handleUpdateLocation"
      />
    </template>
    <template #SUMMARY>
      <BookSummary
        class="adminBook__step"
        :allowPhSelection="true"
        @ph:selected="handlePhSelected"
        @update:notes="handleUpdateNotes"
        @update:shootName="handleUpdateShootName"
        @update:total-price="handleUpdateTotalPrice"
        @update:total-revenue="handleUpdateTotalRevenue"
      />
    </template>
    <template #UPLOAD_BRIEF>
      <div>
        <UploadBrief class="adminBook__step" @file:update="handleFileUpdate" />
      </div>
    </template>
  </BaseStepper>
</template>

<script>
// Api
import { ShootsApi } from '@api/index';
// Vuex
import { mapActions, mapGetters } from 'vuex';

// Base Components
import BaseStepper from '@base/BaseStepper.vue';

// Components
import BookSummary from '@components/shoot/book/BookSummary.vue';
import CustomPackage from '@components/shoot/book/CustomPackage.vue';
import SelectBusinessAccount from '@components/shoot/book/SelectBusinessAccount.vue';
import StandardPackage from '@components/shoot/book/StandardPackage.vue';
import LocationAndTimeSelection from '@components/shoot/book/LocationAndTimeSelection.vue';
import UploadBrief from '@components/shoot/book/UploadBrief.vue';
import ShootContentCard from '../../components/ShootContentCard.vue';

// Helpers
import helpers from '@/utils/helpers';

const BRIEF = 'brief';
const EDIT_CUSTOM = 'edit-custom';
const EDIT_EXPRESS = 'edit-express';
const EXPRESS = 'express';

// Card type
import ShootTypeCard from '@/components/ShootTypeCard.vue';

/**
 * Component used as view for book new shoot wizard
 *
 * @displayName Book
 */
export default {
  name: 'Book',
  components: {
    BaseStepper,
    BookSummary,
    CustomPackage,
    LocationAndTimeSelection,
    SelectBusinessAccount,
    ShootTypeCard,
    StandardPackage,
    UploadBrief,
    ShootContentCard
  },
  data() {
    return {
      dateSkip: false,
      nextDisabled: true,
      queryType: '',
      steps: [
        {
          name: 'SELECT_BUSINESS'
        },
        {
          name: 'SELECT_TYPE'
        }
      ],
      content() {
        return this.shoot.content;
      }
    };
  },
  computed: {
    ...mapGetters({
      brief: 'bookShoot/getBrief',
      customerName: 'bookShoot/getCompanyName',
      shoot: 'bookShoot/getShoot',
      shootReedemableTotal: 'bookShoot/getShootReedemable',
      step: 'stepper/getStep'
    }),
    /**
     * Check if it's in edit mode
     */
    isEditMode() {
      return this.queryType === EDIT_CUSTOM || this.queryType === EDIT_EXPRESS;
    },
    /**
     * True if on last step
     */
    isStepperCompleted() {
      return this.step === this.steps.length;
    },
    /**
     * Style of the right button
     */
    nextStepButtonStyle() {
      return {
        'background-color': helpers.getCssVariable('--main-btn-color'),
        height: '44px'
      };
    },
    /**
     * Right button action icon
     */
    rightButtonIcon() {
      const icon = {
        true: '',
        false: 'angular-arrow'
      };

      return icon[`${this.isStepperCompleted}`];
    },
    /**
     * Right button action label
     */
    rightButtonLabel() {
      const label = {
        true: 'Confirm',
        false: 'Next step'
      };

      return label[`${this.isStepperCompleted}`];
    },
    /**
     * Return the type of shoot
     */
    type() {
      return this.shoot.type;
    }
  },
  watch: {
    isStepperCompleted() {
      this.$emit('stepper:completed', this.isStepperCompleted);
    },
    step() {
      this.validateStep();
    },
    shootReedemableTotal() {
      this.validateStep();
    },
    /**
     * Watching the shoot type to set steps
     */
    type() {
      if (this.type === 'express') {
        this.updateShoot({
          content: 'photography'
        });
        this.steps = [
          {
            name: 'SELECT_BUSINESS'
          },
          {
            name: 'SELECT_TYPE'
          },
          {
            name: 'SELECT_CAT'
          },
          {
            name: 'SELECT_DATETIME'
          },
          {
            name: 'SUMMARY'
          }
        ];
      }
      if (this.type === 'custom') {
        this.updateShoot({
          content: ''
        });
        this.steps = [
          {
            name: 'SELECT_BUSINESS'
          },
          {
            name: 'SELECT_TYPE'
          },
          {
            name: 'SELECT_CONTENT'
          },
          {
            name: 'SELECT_CAT'
          },
          {
            name: 'SELECT_DATETIME'
          },
          {
            name: 'SUMMARY'
          }
        ];
      }
      if (this.type === 'brief') {
        this.steps = [
          {
            name: 'SELECT_BUSINESS'
          },
          {
            name: 'SELECT_TYPE'
          },
          {
            name: 'UPLOAD_BRIEF'
          }
        ];
      }
    }
  },
  async created() {
    const { query = {} } = this.$route;
    const { type = '' } = query;
    this.queryType = type;
    this.$emit('update:query', type);

    if (type === EDIT_CUSTOM) {
      this.steps = [
        {
          name: 'SELECT_CAT'
        },
        {
          name: 'SELECT_DATETIME'
        },
        {
          name: 'SUMMARY'
        }
      ];
    }
    if (type === EDIT_EXPRESS) {
      this.steps = [
        {
          name: 'SELECT_DATETIME'
        },
        {
          name: 'SUMMARY'
        }
      ];
    }
  },

  beforeDestroy() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      goToNextStep: 'stepper/goToNextStep',
      goToPrevStep: 'stepper/goToPrevStep',
      resetState: 'bookShoot/resetState',
      updateBrief: 'bookShoot/updateBrief',
      updateCompanyName: 'bookShoot/updateCompanyName',
      updateShoot: 'bookShoot/updateShoot'
    }),
    async createShoot() {
      const shoot = await ShootsApi.createShoot({ payload: this.shoot });

      if (shoot.statusCode === 200 && this.brief) {
        const blob = await new Blob([this.brief]);

        ShootsApi.uploadBrief({ id: shoot.data.shoot.id, blob });
      }
      this.$router.push({ name: 'orders', query: { refetch: 'true' } });
    },
    handleClientSelected($event) {
      if (!$event) return;
      this.updateCompanyName($event.company_name);
      this.updateShoot({
        client_id: $event.client_id
      });

      this.validateStep();
    },
    handlePhSelected($event) {
      this.updateShoot({
        photographer_id: $event.id
      });

      this.validateStep();
    },
    /**
     * Method used to update the optional notes of the shoot
     */
    handleUpdateNotes($event) {
      this.updateShoot({
        notes: $event
      });
    },
    /**
     * Method used to update the name of the shoot
     */
    handleUpdateShootName($event) {
      this.updateShoot({
        name: $event
      });

      this.validateStep();
    },
    handleUpdateTotalPrice($event) {
      this.updateShoot({
        total_price: $event
      });

      this.validateStep();
    },
    /**
     * used to receive the emitted event and update the total revenue;
     */
    handleUpdateTotalRevenue($event) {
      this.updateShoot({
        total_revenue: $event
      });

      this.validateStep();
    },
    handleCustomPackage($event) {
      const packages = [
        {
          service_id: $event.id,
          picture_number: $event.picture_number ? $event.picture_number : 0,
          video_number: $event.video_number ? $event.video_number : 0,
          video_duration: $event.video_duration ? $event.video_duration : 0
        }
      ];
      this.updateShoot({
        packages
      });

      this.validateStep();
    },
    handleVideoQuantity($event) {
      this.updateShoot({
        packages: [{ ...this.shoot.packages[0], video_number: $event }]
      });

      this.validateStep();
    },
    handleVideoDuration($event) {
      this.updateShoot({
        packages: [{ ...this.shoot.packages[0], video_duration: $event }]
      });

      this.validateStep();
    },
    handlePicturesQuantity($event) {
      this.updateShoot({
        packages: [{ ...this.shoot.packages[0], picture_number: $event }]
      });

      this.validateStep();
    },
    handleDateSkip($event) {
      this.dateSkip = $event;

      this.validateStep();
    },
    /**
     * manages the file updates
     * receives the file from the component, updates it and validates the step
     */
    handleFileUpdate($event) {
      this.updateBrief($event);
      this.validateStep();
    },
    handleNextStep() {
      if (this.isStepperCompleted) {
        this.createShoot();
        return;
      }

      this.nextDisabled = true;
      this.goToNextStep();
    },
    /**
     * manages the packages updates
     * saves in array packages the elements get by cycling the $event and updates the new shoot
     */
    handlePackagesUpdate($event) {
      const packages = [];

      for (const _package of $event) {
        packages.push({
          name: _package.name,
          package_id: _package.id,
          photographer_revenue: _package.photographer_revenue,
          price: _package.price,
          service_id: _package.service_id,
          duration: _package.duration,
          picture_number: _package.picture_number
        });
      }

      this.updateShoot({
        packages
      });
      this.validateStep();
    },
    handleTypeSelected($event) {
      this.updateShoot({
        type: $event
      });

      this.validateStep();
    },
    handleContentSelected($event) {
      this.updateShoot({
        content: $event
      });
      this.validateStep();
    },
    handleUpdateDatetime($event) {
      const { from, duration } = $event;
      let time = {
        duration: duration * 60
      };

      if (from && duration)
        time = {
          duration: duration * 60,
          from
        };

      this.updateShoot({
        time
      });

      this.validateStep();
    },
    /**
     * Method used to update the shoot duration
     */
    handleUpdateDuration($event) {
      this.updateShoot({
        time: {
          duration: $event
        }
      });
    },
    handleUpdateLocation($event) {
      this.updateShoot({
        location: $event
      });

      this.validateStep();
    },

    /**** VALIDATIONS ****/

    /**
     * Check if the first step is valid and can move onto the next one
     * Return true if its invalid
     */
    firstStepValidation() {
      return this.shoot.client_id === undefined || this.shoot.client_id === null;
    },
    /**
     * Check if the first step is valid and can move onto the next one
     * Return true if its invalid
     */
    sixthStepValidation() {
      return this.shoot.total_price === 0 || this.shoot.total_revenue === 0 || this.shoot.total_revenue >= this.shoot.total_price;
    },
    /**
     * Check if the fourth step is valid and can move onto the next one
     * Return true if its invalid
     */
    fifthStepValidation() {
      // Check if the redeemable total is not zero and not null
      const isRedeemable = this.shoot.redeemable_total !== null && this.shoot.redeemable_total !== undefined;

      // If the shoot is redeemable, the 'from' time is not required
      if (isRedeemable) {
        return Object.keys(this.shoot.location).length === 0 || !this.shoot.time.duration;
      }

      // If the shoot is not redeemable, the 'from' time is required
      return Object.keys(this.shoot.location).length === 0 || !this.shoot.time.duration || !this.shoot.time.from;
    },
    /**
     * Check if the second step is valid and can move onto the next one
     * Return true if its invalid
     */
    secondStepValidation() {
      return Object.keys(this.shoot.type).length === 0;
    },
    /**
     *     /**
     * Check if the third step is valid and can move onto the next one
     * Return true if its invalid
     */
    thirdStepValidation() {
      return Object.keys(this.shoot.content).length === 0;
    },
    /**
     * Check if the third step is valid and can move onto the next one
     * Return true if its invalid
     */
    fourthStepValidation() {
      if (this.type === 'express') return this.shoot.packages.length === 0;

      const values = Object.values(this.shoot.packages);
      const picturesNumber = values.map(value => value.picture_number);
      const picturesNumberSum = picturesNumber.reduce((accumulator, val) => accumulator + val, 0);
      return (
        this.shootReedemableTotal === 0 ||
        values.length === 0 ||
        (values.some(value => value.picture_number < 1) && this.shoot.content === 'photography') ||
        (picturesNumberSum < 1 && this.shoot.content === 'photography') ||
        (this.shoot.content === 'videography' && this.shoot.packages[0].video_number < 1) ||
        (this.shoot.content === 'videography' && this.shoot.packages[0].video_duration < 1)
      );
    },
    validateStep() {
      let validations = {
        1: this.firstStepValidation,
        2: this.secondStepValidation,
        3: this.thirdStepValidation,
        4: this.fourthStepValidation,
        5: this.fifthStepValidation,
        6: this.sixthStepValidation
      };

      if (this.type === BRIEF) {
        validations = {
          1: () => false,
          2: () => null,
          3: () => this.brief === null
        };
      }
      if (this.type === EXPRESS) {
        validations = {
          1: this.firstStepValidation,
          2: this.secondStepValidation,
          3: this.fourthStepValidation,
          4: this.fifthStepValidation,
          5: this.fifthStepValidation
        };
      }
      if (this.queryType === EDIT_EXPRESS) {
        validations = {
          1: this.fourthStepValidation
        };
      }
      if (this.queryType === EDIT_CUSTOM) {
        validations = {
          1: this.fourthStepValidation,
          2: this.fifthStepValidation,
          3: this.sixthStepValidation
        };
      }

      const validation = validations[this.step] === undefined ? false : validations[this.step]();
      this.$emit('validation', validation);
    }
  }
};
</script>

<style lang="scss" scoped>
.adminBook {
  &__clientName {
    align-items: flex-end;
    color: var(--secondary-text-color);
    display: flex;
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    font-style: italic;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__selectBusinessAccount {
    margin-top: 20px;
  }

  &__selectType {
    margin-top: 51.5px;
  }

  &__step {
    height: 100%;
    padding-top: 51.5px;
  }

  &__subtitle {
    @include inter-font($size: 12px, $color: var(--main-text-color));
    margin: 0;
    text-align: left;
  }

  &__pagination {
    @include inter-font($size: 16px, $color: var(--main-text-color));
    margin: 0;
    text-align: left;
  }

  &__title {
    @include antonio-font($size: 20px, $color: var(--main-text-color));
  }
}
</style>
