<template>
  <div class="baseFileUploader">
    <BaseTooltip v-if="tooltip" :tooltip="tooltip" :target="true" />
    <input type="file" ref="input" :accept="acceptedFiles" style="display: none" :multiple="multiple" @change="handleFileChange" />
    <BaseButton
      icon="upload"
      :custom_style="custom_style"
      :disabled="disabled"
      :icon_style="icon_style"
      :label="label"
      :loading="loading"
      :percentage="percentage"
      :type="type"
      @click.native="uploadFile"
    />
  </div>
</template>

<script>
// Base Components
import BaseButton from '@base/BaseButton.vue';
import BaseTooltip from '@base/BaseTooltip.vue';

// Utils
import helpers from '@utils/helpers';

export default {
  name: 'BaseFileUploader',
  components: {
    BaseButton,
    BaseTooltip
  },
  props: {
    /**
     * List of accepted files types
     */
    acceptedFiles: { default: '', type: String },
    /**
     * Button custom style
     */
    custom_style: {
      default: () => ({}),
      type: Object
    },
    /**
     * If true, disabled the button
     */
    disabled: { default: false, type: Boolean },
    /**
     * Set to true to handle the click action outside this component
     */
    handleActionOutside: { default: false, type: Boolean },
    /**
     * Icon custom style
     */
    icon_style: { default: () => ({ stroke: helpers.getCssVariable('--terziary-text-color') }), type: Object },
    /**
     * Pass a custom label for the button if needed
     */
    label: {
      default: function () {
        return this.$t('UPLOAD');
      },
      type: String
    },
    /**
     * Set to true if loading while button action gets performed
     */
    loading: { default: false, type: Boolean },
    /**
     * Loading percentage
     */
    percentage: { default: 0, type: Number },
    /**
     * Set to true if need to upload more then just one file
     */
    multiple: { default: false, type: Boolean },
    /**
     * Use this prop to set the button tooltip text
     */
    tooltip: { default: '', type: String },
    /**
     * Button type
     */
    type: { default: 'dark', type: String }
  },
  data() {
    return {
      selectedFile: null
    };
  },
  methods: {
    /**
     * This method will take the file that has been selected (if exists)
     *
     * @param {Object} $event - The native event of file input change
     */
    handleFileChange($event) {
      const { target = {} } = $event;
      const { files = [] } = target;

      if (files.length > 0) {
        if (this.multiple) {
          this.selectedFile = files;
        } else {
          this.selectedFile = files[0];
        }
      }

      this.$emit('update:files', this.selectedFile);
    },
    /**
     * Resetting the file uploader status
     */
    reset() {
      this.selectedFile = null;
      this.$refs.input.value = '';
    },
    /**
     * Triggers the opening of file select window
     */
    uploadFile() {
      if (this.handleActionOutside) {
        this.$emit('click');
        return;
      }
      this.$refs.input.click();
    }
  }
};
</script>
