<template>
  <section>
    <div class="shootTypeCard">
      <!-- I do a loop 'for' for every element contained in the typesCards array.
        On click active the function selectCard , and checks if the index of the selected element corresponds to the one previously saved, than adds the selected class my div -->
      <template v-for="(typeCard, index) in typesCards">
        <div v-if="typeCard.enabled" :key="index" @click="selectCard(index)" :class="['shootTypeCard__card', { 'shootTypeCard__card--selected': selectedCardIndex === index }]">
          <h6 class="shootTypeCard__title">{{ typeCard.titleCard }}</h6>
          <p class="shootTypeCard__description">{{ typeCard.descriptionCard }}</p>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
// Store
import { mapGetters } from 'vuex';

export default {
  name: 'ShootTypeCard',

  data() {
    return {
      selectedCardIndex: null
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      shoot: 'bookShoot/getShoot'
    }),
    typesCards() {
      return [
        {
          titleCard: this.$t('EXPRESS'),
          descriptionCard: this.$t('STATUS_CARD_DESCRIPTION'),
          value: 'express',
          enabled: this.isClient || this.isAdmin
        },
        {
          titleCard: this.$t('CUSTOM'),
          descriptionCard: this.$t('CUSTOM_CARD_DESCRIPTION'),
          value: 'custom',
          enabled: this.isAdmin
        },
        {
          titleCard: this.$t('CLIENT_CUSTOM'),
          descriptionCard: this.$t('CLIENT_CUSTOM_DESCRIPTION'),
          value: 'client_custom',
          enabled: this.isClient
        },
        {
          titleCard: this.$t('ONLY_BRIEF'),
          descriptionCard: this.$t('ONLY_BRIEF_CARD_DESCRIPTION'),
          value: 'brief',
          enabled: false
        }
      ];
    }
  },
  created() {
    if (this.shoot.type) this.selectedCardIndex = this.typesCards.findIndex(card => card.value === this.shoot.type);
  },
  methods: {
    // saves the index of the selected item in the SelectedCardIndex property
    selectCard(index) {
      this.selectedCardIndex = index;

      this.$emit('update:type', this.typesCards[index].value);
    }
  }
};
</script>

<style lang="scss" scoped>
.shootTypeCard {
  align-items: center;
  display: flex;

  &__card {
    background-color: var(--terziary-text-color);
    border: 3px solid transparent;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    font-family: $inter-regular;
    font-weight: 400;
    height: 200px;
    padding: 0 20px;
    text-align: center;
    width: 300px;

    & ~ .shootTypeCard__card {
      margin-left: 63px;
    }

    &--selected {
      border: 3px solid var(--border-color) !important;
    }
  }
  &__title {
    font-family: $inter-regular;
    font-size: calculateRem(16px);
    margin: 0;
    padding-top: 60px;
  }
  &__description {
    font-family: $inter-regular;
    font-size: calculateRem(12px);
  }
}
</style>
