<template>
  <BaseContentBlock v-if="uploadingStep === 1" class="picturesUpload__content" :title="$t('UPLOADING.CHECK_TITLE')"
    ><ShootIdInputSection
      :shoot-id-form-inputs="shootIdFormInputs"
      @update-active-answer-input="$emit('update-shoot-form-inputs', { param: 'activeAnswer', value: $event })"
      @update-checkbox-input="$emit('update-shoot-form-inputs', { param: 'imagesMatchDeliveries', value: $event })"
      @update-inserted-shoot-id-input="$emit('update-shoot-form-inputs', { param: 'insertedShootId', value: $event })"
      :content="content"
    />
  </BaseContentBlock>
  <BaseContentBlock v-else-if="uploadingStep === 2" class="picturesUpload__content" :title="$t('UPLOADING.UPLOADING')" :subtitle="$t('UPLOADING.WAIT')"
    ><BaseCircularProgress :value="progressValue" class="picturesUpload__progress" />
  </BaseContentBlock>
  <BaseContentBlock v-else class="picturesUpload__content" :title="$t('UPLOADING.UPLOADING')" :subtitle="$t('UPLOADING.SUCCESS')"
    ><BaseSuccessUpload class="picturesUpload__successUpload" :filename="uploadedFilename" />
  </BaseContentBlock>
</template>
<script>
// Base Components
import BaseContentBlock from '@base/BaseContentBlock.vue';
import BaseCircularProgress from '@base/BaseCircularProgress.vue';
import BaseSuccessUpload from '@base/BaseSuccessUpload.vue';

// Components
import ShootIdInputSection from '@components/photographer/ShootIdInputSection.vue';

export default {
  name: 'PicturesUpload',
  components: {
    BaseCircularProgress,
    BaseContentBlock,
    BaseSuccessUpload,
    ShootIdInputSection
  },
  props: {
    /**
     * Use this prop to pass the value of the progress of uploading pictures
     */
    progressValue: { default: 0, type: Number },
    /**
     * Use this prop to pass shoot id form inputs
     */
    shootIdFormInputs: {
      default() {
        return {
          activeAnswer: '',
          imagesMatchDeliveries: false,
          insertedShootId: ''
        };
      },
      type: Object
    },
    /**
     * Use this prop to pass the number of the current uploading step
     */
    uploadingStep: { default: 0, type: Number },
    /**
     * Use this prop to pass the name of the uploaded file
     */
    uploadedFilename: { default: '', type: String },
    content: { default: 'photography', type: String }
  }
};
</script>
<style lang="scss" scoped>
.picturesUpload {
  &__content {
    height: 100%;
    position: relative;
    width: calculateRem(670px);
  }

  &__progress {
    margin-top: calculateRem(32px);
  }

  &__successUpload {
    left: 50%;
    padding-top: calculateRem(59px);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
