import moment from 'moment';
import { statuses } from '@utils/shootsHelpers';

/**
 * Method used to formrat the shoots report to be displayed in the graph
 */
export const formatShootReportData = ({ dates, reports }) => {
  // Returning graph colors based on the status
  const colors = statuses.map(({ color }) => color);
  const labels = [];
  const series = [];

  if (reports.length === 0) {
    return {
      labels,
      colors,
      series
    };
  }

  // Retrieving the selected datepicker range dates
  const { from, to } = dates;
  // Cloning the starting date, we will use this to loop through the dates
  let now = moment(from.substr(0, 10)).clone();

  /**
   * For every day between from and we need to retrieve the reports
   */
  while (now.isSameOrBefore(moment(to.substr(0, 10)))) {
    // Pushing graph x-axis label for every date
    labels.push(`${now.format('ddd')}, ${now.format('DD')}`);

    // Formatting the current looped date to match the dates that backend returns in the report api
    const formattedCompleteDate = now.format('YYYY-MM-DD');

    // Retrieving the status counters for the current lopped date
    const dayCounters = reports.find(item => item[formattedCompleteDate] !== undefined) || {};

    // For every status, retrieving the counters. This data will be used in the series to be displayed in the graph
    for (const status of statuses) {
      let statusData = 0;
      const { label, value } = status;
      const counters = dayCounters[formattedCompleteDate] || {};

      for (const _value of value) {
        statusData += counters[_value] ? counters[_value].total : 0;
      }

      statusData = statusData > 0 ? statusData : null;

      const _series = series.find(serie => serie.name === label);

      if (_series) {
        _series.data.push(statusData);
        continue;
      }

      series.push({
        name: label,
        type: 'column',
        data: [statusData]
      });
    }

    now.add(1, 'days');
  }

  return {
    labels,
    colors,
    series
  };
};
