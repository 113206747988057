<template>
  <div class="categoryHandler">
    <BaseCheckbox v-show="!showCategoryMenu" :value="isSelected" :colors="{ bg: 'transaprent', 'bg-active': '#fff' }" @update:value="handleUpdateCheckbox">
      <template #label>
        <p class="categoryHandler__label">{{ category.name }}</p>
      </template>
    </BaseCheckbox>
    <div v-show="showCategoryMenu" class="categoryHandler__category-actions row">
      <div
        v-for="(action, index) of actions"
        :key="index"
        class="categoryHandler__category-action"
        :style="{ 'background-color': action.bg || getCssVariable('--category-action-bg'), color: action.color || getCssVariable('--main-text-color') }"
        @click="handleAction(action.value)"
      >
        <BaseIcon :custom_style="{ height: '17px', 'stroke-width': '2px', width: '17px' }" :icon="action.icon" :stroke="action.color" />
        <p>{{ action.label }}</p>
      </div>
    </div>
    <div class="categoryHandler__category-menu" @click="showCategoryMenu = !showCategoryMenu">
      <BaseIcon :custom_style="{ height: '18px', width: '20px' }" stroke="#fff" :icon="showCategoryMenu ? 'close' : 'more-horizontal'" />
    </div>
    <q-dialog ref="dialog">
      <q-card class="categoryHandler__card">
        <q-card-section class="row items-center pa-0">
          <div class="categoryHandler__card-title">{{ category.name }}</div>
          <q-space />
          <BaseIcon icon="close" v-close-popup />
        </q-card-section>

        <q-card-section class="pa-0 mt-18">
          <p class="categoryHandler__delete-title">{{ $t('DELETE_CATEGORY.TITLE') }}</p>
          <p class="categoryHandler__delete-description">{{ $t('DELETE_CATEGORY.DESCRIPTION') }}</p>
          <BaseIcon icon="shuffle" :custom_style="{ display: 'inline', height: '17px', 'margin-left': '3px', 'vertical-align': 'middle', width: '17px' }" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Api
import { ServiceApi } from '@api/index';

// Base Components
import BaseCheckbox from '@base/BaseCheckbox.vue';
import BaseIcon from '@base/BaseIcon.vue';

const DELETE = 'DELETE';
const EDIT = 'EDIT';
const MOVE = 'MOVE';
/**
 * Component used to handle some actions on category like:
 * 1. editing;
 * 2. moving;
 * 3. deleting;
 *
 * @displayName CategoryHandler
 */
export default {
  name: 'CategoryHandler',
  components: {
    BaseCheckbox,
    BaseIcon
  },
  props: {
    // Category in exam
    category: { default: () => ({}), type: Object },
    // List of packages
    packages: { default: () => [], type: Array }
  },
  data() {
    return {
      actions: [
        {
          icon: 'edit',
          label: 'Edit',
          value: EDIT
        },
        {
          icon: 'shuffle',
          label: 'Move',
          value: MOVE
        },
        {
          bg: this.getCssVariable('--category-delete-bg'),
          color: '#fff',
          icon: 'trash',
          label: 'Delete',
          value: DELETE
        }
      ],
      isSelected: false,
      selectedServices: [],
      showCategoryMenu: false
    };
  },
  methods: {
    /**
     * Method used to handle the delete category action.
     * If the service is not used in any package, delete it, otherwise show warning dialog
     */
    async deleteCategory() {
      // Searching if there are some package using the category we are about to delete
      const isCategoryUsed = this.packages.some(category => category.service_id === this.category.id);

      if (isCategoryUsed) {
        this.$refs.dialog.toggle();
        return;
      }

      const serviceDelete = await ServiceApi.delete(this.category.id);

      if (serviceDelete.statusCode === 200) {
        this.$emit('refresh:services');
        this.$store.dispatch('notification/addSuccessNotification', this.$t('NOTIFICATIONS.SERVICES.DELETE', { name: this.category.name }));
      }
    },
    /**
     * Method used to emit the event for editing a service
     */
    editCategory() {
      this.$emit('category:edit', this.category);
    },
    handleMoveCategoryAction() {
      this.$emit('category:move', this.category);
    },
    handleUpdateCheckbox($event) {
      this.$emit('category:selected', this.category);
      this.isSelected = $event;
    },
    /**
     * Method used to handle the click on one of the actions.
     * It will trigger some method based on the action value
     */
    handleAction(value) {
      const actionsCallback = {
        [DELETE]: () => this.deleteCategory(),
        [EDIT]: () => this.editCategory(),
        [MOVE]: () => this.handleMoveCategoryAction()
      };

      actionsCallback[value]();
    }
  }
};
</script>

<style lang="scss">
$category-padding: 24px;

.categoryHandler {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.33);
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0 $category-padding;
  position: relative;
  z-index: 2;

  &__card {
    border-radius: 10px;
    min-width: 670px;
    padding: 20px 36px 25px 40px;
    @include responsive($max: md) {
      height: 100%;
      max-height: 100% !important;
      min-width: 100%;
    }
  }

  &__card-title {
    @include inter-font($size: 14px);
    font-weight: 800;
  }

  &__category-action {
    $size: 50px;

    align-items: center;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: $size;
    justify-content: center;
    width: $size;

    > p {
      @include inter-font($color: inherit, $size: 10px);
      font-weight: 800;
      margin: 0;
      margin-top: 4px;
    }

    & ~ .categoryHandler__category-action {
      margin-left: 8px;
    }
  }

  &__category-menu {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.33);
    border-radius: 50%;
    display: flex;
    height: 23px;
    justify-content: center;
    margin-left: auto;
    width: 23px;
  }

  &__delete-description {
    display: inline;
    @include inter-font($size: 12px);
    white-space: pre-wrap;
  }

  &__delete-title {
    @include antonio-font($size: 23px);
  }

  &__label {
    @include antonio-font($color: #fff, $size: 14px);
    margin: 0;
  }
}
.q-dialog__inner--minimized {
  @include responsive($max: md) {
    padding: 0;
  }
}
</style>
