<template>
  <div class="baseFileUploaderArea" @dragover="dragover" @drop="drop">
    <template v-if="filelist.length === 0">
      <BaseFileUploader :accepted-files="acceptedFiles" :label="label" :multiple="multiple" @update:files="filelist = multiple ? Object.values($event) : [$event]" />
      <label for="assetsFieldHandle" class="mt-10 block cursor-pointer">
        <div>{{ $t('DRAG_AND_DROP') }}</div>
      </label>
    </template>
    <div v-else :data-multiple="`${multiple && filelist.length > 1}`" class="baseFileUploaderArea__files mt-4">
      <div v-for="(file, index) in filelist" :key="index" class="baseFileUploaderArea__file">
        <div class="baseFileUploaderArea__remove">
          <BaseIcon icon="close" stroke="#fff" :custom_style="{ height: '14px', width: '14px' }" @click.native="removeFile(index)" />
        </div>
        <img :src="getImage(file)" height="54" width="97" />
        <span v-if="file.name" class="baseFileUploaderArea__file-info align-middle mt-10">
          <span class="baseFileUploaderArea__filename"> {{ file.name }}</span>
          <div class="baseFileUploaderArea__check ml-10">
            <BaseIcon icon="check" stroke="#fff" :custom_style="{ height: '10px', 'min-width': '11px' }" />
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Base Components
import BaseFileUploader from './BaseFileUploader.vue';
import BaseIcon from './BaseIcon.vue';

/**
 * File uploader with drag and drop area
 */
export default {
  name: 'BaseFileUploaderArea',
  components: {
    BaseFileUploader,
    BaseIcon
  },
  props: {
    /**
     * List of accepted files
     */
    acceptedFiles: { default: '.pdf', type: String },
    /**
     * Default files
     */
    defaultFiles: { default: () => [], type: Array },
    /**
     * Pass a custom label for the button if needed
     */
    label: {
      default: '',
      type: String
    },
    /**
     * Set to true to allow mulitple file upload
     */
    multiple: { default: false, type: Boolean }
  },
  data: function () {
    return {
      filelist: [] // Store our uploaded files
    };
  },
  watch: {
    /**
     * When filelist gets updated, emit the new value
     */
    filelist() {
      this.$emit('file:update', this.filelist);
    }
  },
  created() {
    this.filelist = this.defaultFiles;
  },
  methods: {
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();

      for (const file of event.dataTransfer.files) {
        const splittedName = file.name.split('.') || [];

        const fileType = splittedName.reverse()[0] || '';

        // If file is of the accepted types add it
        if (this.acceptedFiles.replace(/\s/g, '').split(',').indexOf(`.${fileType}`) !== -1) {
          this.filelist = this.multiple ? [...this.filelist, file] : [file];
        }
      }
    },
    /**
     * Return the file image preview
     */
    getImage(file) {
      // Extracting file extension
      const fileExtension = file.type.split('/').reverse()[0];

      if (file.type && file.type.indexOf('image') === -1) return this.requireMedia(`/svg/${fileExtension}.svg`);

      return URL.createObjectURL(file);
    },
    /**
     * Removing file
     */
    removeFile(index) {
      this.filelist.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.baseFileUploaderArea {
  align-items: center;
  border-radius: 10px;
  border: 3px dotted var(--fua-border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  max-width: calculateRem(600px);
  min-height: 344px;
  width: 100%;

  &__check {
    align-items: center;
    background-color: var(--file-uploaded-checkbox);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 14px;
    width: 14px;
  }

  &__file {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;

    > img {
      object-fit: cover;
    }

    &:hover {
      .baseFileUploaderArea__remove {
        display: flex;
      }
    }
  }

  &__file-info {
    width: 100px;
  }

  &__filename {
    @include text-ellipsed;
  }

  &__files {
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    width: 100%;

    &[data-multiple='false'] {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &[data-multiple='true'] {
      column-gap: 5px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 100px);
      row-gap: 10px;
    }
  }

  &__remove {
    align-items: center;
    background-color: var(--danger);
    border-radius: 50%;
    display: none;
    height: 14px;
    justify-content: center;
    position: absolute;
    right: -6px;
    top: -6px;
    width: 14px;
  }
}
</style>
