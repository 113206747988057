<template>
  <div class="baseSuccessUpload">
    <BaseIcon class="baseSuccessUpload__zip" icon="icon-zip" :custom_style="{ height: '4.4375rem', width: '3.3125rem', 'box-shadow': '0 2px 15px var(--card-shadow-color)' }"></BaseIcon>
    <div class="baseSuccessUpload__file">
      <p class="baseSuccessUpload__filename">{{ filename }}</p>
      <BaseIcon icon="icon-GreenCheck" :custom_style="{ height: '1.0625rem', width: '1.0625rem' }"></BaseIcon>
    </div>
  </div>
</template>

<script>
// Base components
import BaseIcon from '@base/BaseIcon.vue';

/**
 * This component shows the file upload success icon
 *
 * @displayName BaseSuccessUpload
 */
export default {
  name: 'BaseSuccessUpload',
  components: {
    BaseIcon
  },
  props: {
    /**
     * Icon custom style
     */
    filename: { default: 'Test_filename.txt', required: true }
  }
};
</script>

<style lang="scss" scoped>
.baseSuccessUpload {
  align-items: center;
  background: radial-gradient(var(--main-bg-color), #fff);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;

  &__file {
    display: inline-flex;
    margin-top: calculateRem(21px);
  }

  &__filename {
    @include inter-font(12px);
    font-style: italic;
    margin-right: 0.5rem;
  }
}
</style>
