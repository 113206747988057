import helpers from './helpers';

/**
 * Method used to retrieve numbers from a string
 * @param {String} str
 * @returns {Array} array of string numbers with their indexes
 */
const getStringNumbers = str => {
  const arr = str.split(' ');
  return arr
    .map((el, index) => {
      const num = el.match(/(\d+)/);
      return num ? { index: index, num: Number(num[0]) } : null;
    })
    .filter(_num => _num);
};

/**
 * Method used to separate string by its numbers
 * @param {String} str
 * @returns {Array} array of strings. Example: "My 5 bedroom house." will return ["my", "bedroom house." ]
 */
const getStringStrings = str => {
  const arr = str.split(' ');
  const strings = arr.map(el => {
    const num = el.match(/(\d+)/);
    return num ? '&' : el.toLowerCase();
  });

  const joinedStrings = strings.join(' ');

  const splittedByNumbers = joinedStrings
    .split('&')
    .filter(el => el)
    .map(el => el.trim());
  return splittedByNumbers;
};

/**
 * Method used to sort the packages by their names. It sorts the packages alphabetically
 * with the special attention to numbers and special words that can change the elements' position
 * @param {Array} packages
 * @returns {Array} sorted packages array
 */
export const sortPackagesByName = packages => {
  if (packages.length === 0) return [];
  // 1. Add the necessary keys to each element of the array
  const arrWithNewKeys = [...packages].map(item => {
    const nameStrings = getStringStrings(item.name);
    const nameNumbers = getStringNumbers(item.name);
    const firstNumber = nameNumbers.find(_num => _num.index === 0);
    const secondNumber = nameNumbers.find(_num => _num.index !== 0);

    return {
      ...item,
      firstStr: nameStrings.length > 0 ? nameStrings[0] : '',
      firstNr: firstNumber ? firstNumber.num : 0,
      secondNr: secondNumber ? secondNumber.num : 0
    };
  });

  // 2. Group first strings and add the respective rating to each group
  const firstStrings = arrWithNewKeys.map(el => el.firstStr);
  const unique1stStrings = [...new Set(firstStrings)];
  const unique1stStringsWithRating = unique1stStrings
    .sort()
    .reverse()
    .map((str, index) => {
      return {
        value: str,
        rating: index + 1
      };
    });

  // 3. Group first numbers and add the respective rating to each group
  const firstNumbers = arrWithNewKeys.map(el => el.firstNr);
  const unique1stNumbers = [...new Set(firstNumbers)];
  const unique1stNumbersWithRating = unique1stNumbers
    .filter(_num => _num !== 0)
    .sort((a, b) => {
      return a - b;
    })
    .reverse()
    .map((num, index) => {
      return {
        value: num,
        rating: index + 1
      };
    });

  // 3. Group second numbers and add the respective rating to each group
  const secondNumbers = arrWithNewKeys.map(el => el.secondNr);
  const unique2ndNumbers = [...new Set(secondNumbers)];
  const unique2ndNumbersWithRating = unique2ndNumbers
    .filter(_num => _num !== 0)
    .sort((a, b) => {
      return a - b;
    })
    .reverse()
    .map((num, index) => {
      return {
        value: num,
        rating: index + 1
      };
    });

  // 4. Calculate rating for each element of the array
  arrWithNewKeys.forEach(el => {
    const foundStr = unique1stStringsWithRating.find(_el => _el.value === el.firstStr);
    const alphabeticRating = foundStr ? foundStr.rating : 0;

    const found1stNum = unique1stNumbersWithRating.find(_el => _el.value === el.firstNr);
    const firstNumRating = found1stNum ? found1stNum.rating : 0;

    const found2ndNum = unique2ndNumbersWithRating.find(_el => _el.value === el.secondNr);
    const secondNumRating = found2ndNum ? found2ndNum.rating : 0;

    // Special words ratings
    const packageWordRating = el.firstStr.startsWith('package') ? -30 : 0;
    const studioWordRating = el.firstStr.startsWith('studio') ? 100 : 0;
    const plusRating = el.is_plus ? 0.5 : 1;

    el.rating = alphabeticRating * 3 + firstNumRating * 0.5 + secondNumRating * 2 + packageWordRating + studioWordRating + plusRating;
  });

  // 5. Sort the array by rating
  const sortedArr = helpers.sortArrayItemsByKey({ arr: arrWithNewKeys, key: 'rating', reverse: true });

  // 6. Remove unnecessary keys
  sortedArr.forEach(el => {
    delete el.firstStr;
    delete el.firstNr;
    delete el.secondNr;
    delete el.rating;
  });

  return sortedArr;
};
