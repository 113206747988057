<template>
  <carousel-3d class="packagesCarousel__carousel" :count="items.length + 1" :display="display" :perspective="perspective" :space="space" :width="width" :border="0">
    <slide :key="0" :index="0" class="packagesCarousel__slide packagesCarousel__slide-0">
      <template slot-scope="{ isCurrent }">
        <div :class="['packagesCarousel__new-package', { 'packagesCarousel__slide--is-faded': !isCurrent }]" @click="$emit('show-edit-package')">
          <BaseIcon :custom_style="{ height: '24px', width: '24px' }" icon="plus" />
        </div>
      </template>
    </slide>
    <slide v-for="(item, index) in items" :index="index + 1" :key="item.id" class="packagesCarousel__slide">
      <template slot-scope="{ isCurrent }">
        <PackageCard
          :class="{ 'packagesCarousel__slide--is-faded': !isCurrent }"
          :pkg="item"
          :key="item.id"
          :isActive="isCurrent"
          @package:delete="$emit('handel-package-delete', item)"
          @package:update="$emit('handlePackageUpdate', item)"
        />
      </template>
    </slide>
  </carousel-3d>
</template>

<script>
// Base components
import BaseIcon from '@base/BaseIcon.vue';

// Carousel library
import { Carousel3d, Slide } from 'vue-carousel-3d';

// Components
import PackageCard from '@/components/package/PackageCard.vue';

/**
 * This component will handle the packages carousel shown on mobile
 *
 * @displayName PackagesCarousel
 */
export default {
  name: 'PackagesCarousel',
  components: {
    BaseIcon,
    Carousel3d,
    PackageCard,
    Slide
  },
  props: {
    /**
     * Number of items displayed
     */
    display: { default: 3, type: Number },
    /**
     * List of carousel items
     */
    items: { default: () => [], type: Array },
    /**
     * Defines the perspective of the cards. Higher means more rotate
     */
    perspective: { default: 0, type: Number },
    /**
     * Space between displayed cards
     */
    space: { default: 100, type: Number },
    /**
     * Cards width
     */
    width: { default: 255, type: Number }
  }
};
</script>

<style lang="scss" scoped>
.packagesCarousel {
  &__carousel {
    display: none;
  }

  &__new-package {
    align-items: center;
    border: 1px dotted var(--new-package-border);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__slide {
    background-color: transparent;
    transition: transform 500ms ease 0s, opacity 500ms ease 50ms, visibility 500ms ease 0s !important;

    &--is-faded {
      opacity: 0.8;
      transition: transform 500ms ease 0s, opacity 500ms ease 50ms, visibility 500ms ease 0s !important;
    }
  }

  &__slide-0 {
    &.current {
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
    }
  }

  &__packages {
    $gap: 20px;

    column-gap: $gap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(188px, 1fr));
    row-gap: $gap;
  }
}

@include responsive($max: mobileL) {
  .packagesCarousel {
    &__carousel {
      display: block;
    }
    &__carousel,
    &__carousel .carousel-3d-slider,
    &__slide {
      height: 300px !important;
    }

    &__packages {
      display: none;
    }
  }
}
</style>
