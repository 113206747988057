<template>
  <BaseCard class="packageCard">
    <template #header>
      <div class="packageCard__cover" :style="{ backgroundColor: content === 'videography' ? '#f250505e' : 'rgba(0,0,0,.1)' }">
        <div class="packageCard__image" :style="{ backgroundImage: `url(${bgImage})` }"></div>
        <div class="packageCard__overlay">
          <div v-if="!isRedeemable" class="packageCard__topInfo">
            <div class="packageCard__dates">
              <span><BaseIcon icon="calendar" :custom_style="{ height: '13px', width: '13px', marginRight: '0.3rem' }" />{{ dateTime.date || $t('DEFINE_DATE') }}</span>
              <span><BaseIcon icon="clock" :custom_style="{ height: '13px', width: '13px', marginRight: '0.3rem' }" />{{ dateTime.time || '---' }}</span>
              <div v-if="isPhotographer && isConfirmed && getMissingTime" class="packageCard__chip">{{ getMissingTime }}</div>
              <div v-if="isAdmin && isToPay" class="packageCard__chip" data-type="info">{{ $t('TO_PAY') }}</div>
            </div>
            <div class="packageCard__pictures">
              <span :style="pkg.picture_number > 9999 ? { fontSize: '0.8rem' } : {}">
                {{ pkg.content === 'videography' ? pkg.video_number : pkg.picture_number }}
              </span>
              <span v-if="pkg.content === 'photography'">{{ $tc('IMAGES', pkg.picture_number) }}</span>
              <span v-if="pkg.content === 'videography' && pkg.video_number > 1"> {{ $t('VIDEOS') }}</span>
              <span v-if="pkg.content === 'videography' && pkg.video_number <= 1">{{ $t('VIDEO') }}</span>
            </div>
          </div>
          <div v-else class="packageCard__redeem">
            <div class="align-middle justify-between">
              <p>{{ $t('REDEEMABLE_PACKAGE') }}</p>
              <BaseIcon icon="reuse" :custom_style="{ height: '12px', width: '12px' }" />
            </div>
            <p data-type="counter">{{ getRedeemableText }}</p>
          </div>

          <div class="packageCard__package">
            <p>
              #{{ pkg.id }}<span v-if="pkg.outlet_code && !isPhotographer">| {{ pkg.outlet_code }}</span
              ><span v-if="pkg.outlet_name && !isPhotographer">| {{ pkg.outlet_name }}</span>
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="packageCard__info">
        <span class="packageCard__name">{{ packageName }} </span>
        <span class="packageCard__category">{{ $tc('CATEGORY') + ': ' + getCategory(pkg.services) }}</span>
        <span v-if="isAdmin || isClient" class="packageCard__photographer">{{ $t('PACKAGE_PHOTOGRAPHER', { photographer: pkg.photographer_name || $t('STATUSES.PENDING') }) }}</span>
        <span class="packageCard__photographer">{{ $tc('Content') + ': ' + pkg.content }}</span>
        <span v-if="isPhotographer || isAdmin" class="packageCard__customer">{{ $t('PACKAGE_CUSTOMER', { customer: pkg.customer_name }) }}</span>
      </div>
    </template>
    <template #footer>
      <div class="packageCard__footer">
        <div v-if="isClient || isAdmin" class="packageCard__status"><span :style="{ backgroundColor: statusColor }"></span>{{ getCorrectStatus }}</div>
        <ButtonRightArrow :icon="isRedeemable ? 'plus' : 'arrow-right'" class="packageCard__go-to" />
      </div>
    </template>
  </BaseCard>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base components
import BaseCard from '@base/BaseCard.vue';
import BaseIcon from '@base/BaseIcon.vue';

// Common components
import ButtonRightArrow from '@common/ButtonRightArrow.vue';

// Utils
import helpers, { CONFIRMED, REDEEMABLE, TO_PAY, UPLOADED } from '@utils/shootsHelpers';
import moment from 'moment';

/**
 * Component used to show the available packages info
 *
 * @displayName PackageCard
 */
export default {
  name: 'PackageCard',
  components: {
    BaseCard,
    BaseIcon,
    ButtonRightArrow
  },
  props: {
    /**
     * The package we want to show the info
     */
    pkg: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isPhotographer: 'user/isPhotographer',
      services: 'services/getServices',
      user: 'user/getUser'
    }),
    bgImage() {
      return this.getServiceImage(this.serviceId);
    },
    /**
     * Returning an object with the shoot date and time formatted for FE
     */
    dateTime() {
      if (!this.pkg.datetime) return {};

      return this.fromDateTimeToDateAndTimeRange({ from: this.pkg.datetime, duration: this.pkg.duration });
    },
    getCorrectStatus() {
      if (!this.isUploaded || !this.isClient) return this.status;

      return this.$t('STATUSES.BEING_EDITED');
    },
    /**
     * use to get the correct text to show if the shoot is near
     */
    getMissingTime() {
      const pkgTwoDaysMissing = moment(this.pkg.datetime).subtract(2, 'days').format('YYYY MM DD');
      const pkgOneDaysMissing = moment(this.pkg.datetime).subtract(1, 'days').format('YYYY MM DD');

      const { onGoing, daysCount } = this.checkDateTimeBetween({ from: this.pkg.datetime, duration: this.pkg.duration, daysMissing: [pkgTwoDaysMissing, pkgOneDaysMissing] });

      let dateToCompare = '';

      if (onGoing) return this.$t('ON_GOING');
      if (daysCount.length > 0) {
        daysCount.forEach(day => {
          dateToCompare = day;
        });
      }
      if (pkgTwoDaysMissing === dateToCompare) return this.$t('MISSING_HOURS', { hours: '48' });
      if (pkgOneDaysMissing === dateToCompare) return this.$t('MISSING_HOURS', { hours: '24' });

      return '';
    },
    /**
     * used to get the text of the redeemable shoot, if is fixed quantity selcted or not.
     */
    getRedeemableText() {
      if (this.pkg.redeemable_total > 0) return this.$tc('REDEEMABLE_PACKAGE_AVAILABLE', this.pkg.redeemable_counter);
      return this.$t('UNLIMITED_SHOOTS');
    },
    /**
     * Check if the shoot is confirmed
     */
    isConfirmed() {
      return this.pkg.status === CONFIRMED;
    },
    /**
     * Check if the shoot is redeemable
     */
    isRedeemable() {
      return this.pkg.status === REDEEMABLE;
    },
    /**
     * Check if the shoot status is to pay
     */
    isToPay() {
      return this.pkg.status === TO_PAY;
    },
    isUploaded() {
      return this.pkg.status === UPLOADED;
    },
    packageName() {
      return this.pkg.name || this.$t('PACKAGE_NAME_TITLE');
    },
    service() {
      return this.pkg.services[0] || {};
    },
    serviceId() {
      if (this.pkg.services.length > 1 || this.pkg.services.length === 0) {
        return;
      }
      return this.service.service_id;
    },
    status() {
      return helpers.mapStatus(this.pkg.status);
    },
    content() {
      return this.pkg.content;
    },
    statusColor() {
      return helpers.getStatusColor(this.pkg.status);
    }
  },
  methods: {
    getTime(isoString) {
      const dateIndex = isoString.indexOf('T') + 1;
      const timeIndex = isoString.indexOf('Z');
      const time = isoString.slice(dateIndex, timeIndex).slice(0, 5);

      return time;
    },

    /**
     * Returning category label for shoot list
     */
    getCategory(services) {
      if (services.length === 1) {
        const service = this.services.find(_service => _service.id === services[0].service_id) || {};
        return service.name;
      }
      return this.$tc('CATEGORY', services.length);
    }
  }
};
</script>

<style lang="scss" scoped>
.videoPackageCard {
  background-color: var(--main-btn-color) !important;
}
.packageCard {
  color: var(--main-text-color);
  $padding: calculateRem(12px);
  height: auto;
  min-height: calculateRem(319px);
  padding-bottom: calculateRem(46px);
  width: calculateRem(250px);

  &__chip {
    @include inter-font($size: 9px, $bolded: true, $color: var(--terziary-text-color));
    background-color: var(--reject-user-text);
    border-radius: 5px;
    box-shadow: 0px 0px 6px 2px #f250505e;
    padding: 0px 4px;
    position: absolute;
    right: 10px;
    top: 7px;

    &[data-type='info'] {
      background-color: var(--main-btn-color);
      box-shadow: unset;
      color: var(--main-text-color);
    }
  }

  &__cover {
    border-radius: 10px 10px 0px 0px;
    height: calculateRem(127px);
    position: relative;
    overflow: hidden;
  }

  &__image {
    background-position: center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__dates,
  &__redeem {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: calculateRem(8px) calculateRem(12px);
    width: 100%;
  }

  &__dates {
    align-items: flex-start;
    background-color: var(--card-bg-color);
    min-height: calculateRem(52px);
    opacity: 0.95;
    position: relative;

    > span {
      @include inter-font($size: 10px, $bolded: true);
      align-items: flex-start;
      display: inline-flex;
      text-align: left;

      &:first-child {
        margin-bottom: calculateRem(8px);
      }
    }
  }

  &__footer {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: $padding;
    position: absolute;
    text-align: left;
    width: 100%;

    &:after {
      background-color: var(--light-grey-hr);
      content: '';
      display: block;
      height: 2px;
      left: $padding;
      position: absolute;
      top: 0;
      width: calc(100% - 2 * $padding);
    }
  }

  &__go-to {
    flex-shrink: 0;
    margin-left: auto;
  }

  &__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: auto;
    padding: $padding;
    text-align: left;

    span ~ span {
      margin-top: 2.5px;
    }
  }

  &__name {
    @include antonio-font($size: 14px);
    margin-bottom: calculateRem(8px);
  }

  &__overlay {
    background: var(--pkg-bg-overlay);
    height: 100%;
    left: 0;
    padding: $padding;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__category,
  &__status,
  &__photographer,
  &__customer {
    @include inter-font($size: 12px, $bolded: false);
  }

  &__category:first-letter {
    text-transform: uppercase;
  }

  &__photographer,
  &__customer,
  &__status {
    width: 100%;
    @include text-ellipsed;
  }
  &__topInfo {
    display: flex;
  }
  &__package {
    @include inter-font($size: 10px, $color: var(--terziary-text-color));
    bottom: 0;
    font-weight: 500;
    left: 0;
    padding: 0.75rem;
    position: absolute;
    text-align: left;
    > p {
      margin: 0;
      &:first-of-type {
        font-size: calculateRem(12px);
      }

      > span {
        padding-left: 3px;
      }
    }
  }
  &__photographer {
    text-transform: capitalize;
  }
  &__category {
    text-transform: capitalize;
  }

  &__pictures {
    align-items: center;
    background-color: rgba(var(--dark-bg), 0.65);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: calculateRem(8px);
    padding: calculateRem(8px) calculateRem(10px);
    & > span {
      @include inter-font($size: 10px, $bolded: false, $color: var(--txt-on-dark-bg));
      line-height: 1.1;
    }
    & > span:first-of-type {
      font-size: calculateRem(18px);
      font-weight: 700;
      line-height: 1.1;
      max-width: calculateRem(46px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__redeem {
    background-color: var(--redeem-card-bg);

    p {
      @include inter-font($bolded: true, $size: 10px);
      margin: 0;
      text-align: left;

      &[data-type='counter'] {
        font-weight: normal;
      }
    }
  }

  &__status {
    > span {
      border-radius: 2px;
      display: inline-block;
      height: calculateRem(10px);
      margin-right: calculateRem(10px);
      width: calculateRem(10px);
    }
  }
}

@include responsive($max: mobileL) {
  .packageCard {
    width: auto;
  }
}
</style>
