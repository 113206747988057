<template>
  <section :disabled="disabled">
    <BaseDropdown class="baseFilter" anchor="bottom left" self="top left" @hide="toggledDropdown" @show="toggledDropdown(true)">
      <template #trigger>
        <div ref="trigger" :class="['baseFilter__tag', { 'baseFilter__tag--is-active': is_toggled }]">
          <BaseIcon v-if="icon" :custom_style="{ height: '13px', width: '12px', 'stroke-width': '2px' }" :icon="icon" />
          <span class="baseFilter__label" :class="{ 'ml-0': !icon }">{{ filter_label }}</span>
          <BaseIcon :custom_style="arrow_icon_style" :class="['baseFilter__arrow', { 'baseFilter__arrow--is-expanded': is_toggled }]" fill="#151515" icon="arrow-filter" />
        </div>
      </template>
      <template v-if="!disabled" #menu>
        <div class="baseFilter__slot" ref="slot" :style="{ 'min-width': `${$refs.trigger && $refs.trigger.getBoundingClientRect().width}px` }">
          <slot />
        </div>
      </template>
    </BaseDropdown>
    <div v-if="is_toggled && !disabled" class="mb-30" :style="{ height }"></div>
  </section>
</template>

<script>
// Base Components
import BaseDropdown from '@base/BaseDropdown.vue';
import BaseIcon from '@base/BaseIcon.vue';

/**
 * Component used to handle the filters
 *
 * @displayName BaseFilter
 */
export default {
  name: 'BaseFilter',
  components: {
    BaseDropdown,
    BaseIcon
  },
  props: {
    /**
     * Set disabled filter
     */
    disabled: { default: false, type: Boolean },
    /**
     * Label of the filter
     */
    filter_label: { default: '', type: String },
    /**
     * Icon to be shown on the left side of the icon
     */
    icon: { default: '', type: String }
  },
  data() {
    return {
      arrow_icon_style: { height: '7px', width: '10px' },
      height: '0px',
      is_toggled: false
    };
  },
  methods: {
    async toggledDropdown(isToggled) {
      await this.$nextTick();
      if (this.disabled) isToggled = false;
      else this.is_toggled = isToggled;

      let height = 0;

      if (isToggled) height = this.$refs.slot.getBoundingClientRect().height;

      this.height = `${height}px`;
    }
  }
};
</script>

<style lang="scss">
.baseFilter {
  display: table;

  &__arrow {
    margin-left: 5px;
    margin-top: 5px;
    transition: transform 0.4s ease-in-out;

    &--is-expanded {
      transform: rotate(180deg);
    }
  }

  &__label {
    color: var(--main-text-color);
    font-family: $inter-regular;
    font-size: calculateRem(14px);
    margin-left: calculateRem(11px);
  }

  &__slot {
    background-color: transparent;
    @include responsive($max: md) {
      max-width: 290px;
    }
  }

  &__tag {
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 44px;
    margin-right: 10px;
    padding: 8px;
    width: fit-content;

    &:hover,
    &--is-active {
      background-color: var(--tag-hover-color);
    }
  }
}

@include responsive($max: md) {
  .baseFilter {
    &__label {
      display: none;
    }

    &__tag {
      background-color: var(--tag-hover-color);
      height: 40px;
      padding: 10px;
      width: 50px;
    }
  }
}
.ml-0 {
  margin-left: 0;
}
</style>
