<template>
  <section class="baseTabs">
    <q-tabs v-model="selected_tab" class="baseTabs__tabs" align="left" indicator-color="transparent" :breakpoint="200" :style="`background-color: ${tabs_color}`">
      <q-tab :ripple="false" v-for="(tab, index) in tabs" :key="index" :name="tab.value" :icon="tab.icon" class="baseTabs__tab" no-caps>
        <span class="baseTabs__tab-label">{{ tab.label }}</span>
        <hr :class="['baseTabs__tab-underline', { 'baseTabs__tab-underline--is-active': selected_tab === tab.value }]" />
      </q-tab>
      <slot name="filters" />
    </q-tabs>
    <q-tab-panels v-model="selected_tab" class="baseTabs__tab-panels">
      <q-tab-panel v-for="(tab, index) in tabs" :key="index" :name="tab.value" class="baseTabs__tab-panel">
        <slot :name="tab.value" />
      </q-tab-panel>
    </q-tab-panels>
  </section>
</template>

<script>
/**
 * Base components for tabs
 *
 * @displayName BaseTabs
 */
export default {
  name: 'BaseTabs',
  props: {
    /**
     * Active tab
     */
    activeTabIndex: { default: null, type: Number },
    /**
     * Color of the tabs bar
     */
    tabs_color: { default: '', type: String },
    /**
     * List of tabs
     */
    tabs: { default: () => [], type: Array, required: true }
  },
  data() {
    return {
      /**
       * Selected tab
       */
      selected_tab: ''
    };
  },
  created() {
    this.selected_tab = this.activeTabIndex ? this.tabs[this.activeTabIndex].value : this.tabs[0].value;
  },
  watch: {
    selected_tab(val, oldval) {
      if (oldval !== '') this.$emit('changed:tab', this.selected_tab);
    }
  }
};
</script>

<style lang="scss">
.baseTabs {
  $horizontal_padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__tab {
    height: 68px;
    padding: 0;

    ~ .baseTabs__tab {
      margin-left: 18px;
    }
  }

  &__tab-label {
    font-family: $antonio-bold;
    font-size: calculateRem(20px);
  }

  &__tab-panel {
    background-color: var(--main-bg-color);
    padding-left: $horizontal_padding;
    padding-right: $horizontal_padding;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &__tab-panels {
    background-color: var(--main-bg-color);
  }

  &__tab-underline {
    background-color: var(--active-tab-txt-color);
    margin: 0px;
    opacity: 0;
    width: 100%;

    &--is-active {
      opacity: 1;
    }
  }

  &__tabs {
    background-color: var(--main-tabs-color);
    color: var(--tab-txt-color);
    height: 68px;
    padding-left: $horizontal_padding;
    padding-right: $horizontal_padding;

    .q-tab--active {
      color: var(--secondary-bg-color);
    }
  }

  .q-tab-panels {
    height: 100%;
  }
}
body.desktop .q-focus-helper,
body.desktop .q-focus-helper::after,
body.desktop .q-focus-helper::before {
  border-radius: 0;
  height: auto;
  left: auto;
  opacity: 0;
  pointer-events: none;
  position: unset;
  transition: none;
  top: auto;
  width: auto;
}

@include responsive($max: 'md') {
  .baseTabs {
    $horizontal_padding: 16px;
    &__tabs {
      z-index: unset;
    }

    &__tab-label {
      font-size: calculateRem(16px);
    }

    &__tab-panel {
      padding-left: $horizontal_padding;
      padding-right: $horizontal_padding;
      width: 100%;
    }

    &__tabs {
      padding: 0 #{$horizontal_padding};

      .q-tabs__content {
        justify-content: unset;
      }
    }
  }
}

@include responsive($min: 'md', $max: 'tablet') {
  .baseTabs {
    $horizontal_padding: 83px;

    &__tab-panel {
      padding-left: $horizontal_padding;
      padding-right: $horizontal_padding;
      width: 100%;
    }

    &__tabs {
      padding: 0 #{$horizontal_padding};
      z-index: unset;
    }
  }
}
</style>
