<template>
  <section class="moveCategory">
    <BaseButton class="moveCategory__button moveCategory__backButton" icon="angular-arrow" :label="$t('BACK')" @click.native="$emit('go-back')" />
    <section class="moveCategory__content">
      <CategoryCard :service="service" />
      <section class="moveCategory__middle">
        <div class="moveCategory__hl"></div>
        <p>{{ $t('REPLACE_CATEGORY') }}</p>
        <div class="moveCategory__hl"></div>
      </section>
      <CategorySelect @category:selected="newService = $event" />
    </section>
    <BaseButton
      class="moveCategory__button"
      :custom_style="{ 'background-color': getCssVariable('--replace-button-color'), color: getCssVariable('--main-text-color') }"
      :disabled="disable"
      :label="$t('Confirm')"
      @click.native="reassignService"
    />
  </section>
</template>

<script>
// Axios
import { ServiceApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';

// Components
import CategoryCard from '@components/package/CategoryCard.vue';
import CategorySelect from '@components/package/CategorySelect.vue';

/**
 * Component used to replace a category with another one
 *
 * @displayName MoveCategory
 */
export default {
  name: 'MoveCategory',
  components: {
    BaseButton,
    CategoryCard,
    CategorySelect
  },
  props: {
    /**
     * Service to be moved
     */
    service: { default: () => ({}), type: Object }
  },
  data() {
    return {
      disable: this.isConfirmDisabled,
      newService: {}
    };
  },
  computed: {
    /**
     * Check if the confirm button has to be disabled
     */
    isConfirmDisabled() {
      return Object.keys(this.newService).length === 0;
    }
  },
  methods: {
    /**
     * Method used to reassign a service from one to another
     */
    async reassignService() {
      const reassign = await ServiceApi.reassign({
        from_id: this.service.id,
        to_id: this.newService.id
      });
      this.disable = !this.disable;

      if (reassign.statusCode === 200) {
        this.$emit('packages:refresh');
        this.$q.notify({ icon: 'icon-GreenCheck', color: 'positive', textColor: 'info', position: 'top-right', message: this.$t('REASSIGN_SERVICE_COMPLETE') });
      }
    }
  }
};
</script>

<style lang="scss">
.moveCategory {
  align-items: flex-start;
  display: flex;
  height: fit-content;
  justify-content: space-between;
  padding-right: 171px;
  width: 100%;
  @include responsive($max: md) {
    flex-direction: column;
    padding: 0;
  }

  &__button {
    background-color: var(--btn-upload-bg);
    margin-top: 18px;
    min-width: fit-content;
    @include responsive($max: md) {
      bottom: 30px;
      position: absolute;
      right: 30px;
    }
    .q-btn.disabled {
      background-color: var(--btn-upload-bg) !important;
    }

    .baseIcon {
      stroke: #fff;
      transform: rotate(180deg);
    }

    .baseButton__label {
      @include inter-font($size: 14px);
    }
  }

  &__backButton {
    .baseButton__label {
      color: #fff;
    }

    @include responsive($max: md) {
      left: 30px;
      right: auto;
    }
  }

  &__content {
    align-items: flex-start;
    display: flex;
    margin: 0 60px;

    > p {
      @include inter-font($size: 12px, $color: #fff);
      margin: 0 7px;
    }
    @include responsive($max: md) {
      flex-direction: column;
      margin: 0 auto;
    }
  }

  &__hl {
    background-color: #979797;
    height: 1px;
    width: 20px;
    @include responsive($max: md) {
      display: none;
    }
  }

  &__middle {
    align-items: center;
    display: flex;
    margin-top: 18px;

    > p {
      @include inter-font($size: 12px, $color: #fff);
      margin: 0;
    }
    @include responsive($max: md) {
      margin: 18px auto;
    }
  }

  &__title {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: 2;
    width: 100%;

    > p {
      @include antonio-font($size: 14px, $color: #fff);
      margin: 0;
    }
  }
  .ml-21 {
    @include responsive($max: md) {
      margin-left: 0;
    }
  }
}
</style>
