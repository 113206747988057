<template>
  <div class="welcome">
    <section class="welcome__overlay"></section>
    <section class="welcome__content">
      <h1 class="welcome__title">{{ $t('USER_WELCOME', { name }) }}</h1>
      <p class="welcome__subtitle">{{ getSubtitle }}</p>

      <!-- TEMPORARY COMMENTING THIS DUE TO MISSING BE API 
        <div class="welcome__stats">
            <div class="welcome__stat">
            <div class="welcome__stat-row">
                <div>Upcoming shoots</div>
                <div>0</div>
            </div>
            <div class="welcome__stat-row">
                <p>Pending</p>
                <p>Confirmed</p>
            </div>
            </div>
        </div>
      -->
    </section>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

export default {
  name: 'Welcome',
  computed: {
    ...mapGetters({
      isClient: 'user/isClient',
      isPhotographer: 'user/isPhotographer',
      user: 'user/getUser'
    }),
    /**
     * Retrieving subtitle based on user role
     */
    getSubtitle() {
      if (this.isClient) return this.$t('CLIENT_WELCOME_MESSAGE');

      if (this.isPhotographer) return this.$t('PH_WELCOME_MESSAGE');

      return '';
    },
    /**
     * Getting user name
     */
    name() {
      return this.user && this.user.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.welcome {
  background-image: url('../assets/welcome_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 100px 207px 128px 207px;
  position: relative;
  width: 100%;
  @include responsive($max: '880px') {
    padding: 40px;
  }

  &__content {
    color: var(--terziary-text-color);
    height: 100%;
    position: relative;
    z-index: 2;
  }

  &__overlay {
    background: linear-gradient(rgba(64, 64, 64, 0.5), rgba(0, 0, 0, 0.75));
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__subtitle,
  &__title {
    margin: 0;
    text-align: left;
  }

  &__stat {
    border-left: 2px solid #fff;
    padding: 0 14px;
  }

  &__stat-row {
    display: flex;

    & > div {
      @include antonio-font($size: 18px, $uppercase: true, $color: 'inherit');
      align-items: center;
      background-color: var(--stat-bg-color);
      border-radius: 10px;
      display: flex;
      height: 35px;
      justify-content: center;
      padding: 5px 8px;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        @include inter-font($size: 12px, $color: var(--stat-text-color));
        width: 35px;
      }
    }

    & > p {
      @include inter-font($size: 12px, $color: var(--terziary-text-color));

      &:first-child {
        margin-right: 30px;
      }

      &:last-child {
      }
    }
  }

  &__stats {
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &__subtitle {
    @include inter-font($size: 16px, $color: 'inherit');
  }

  &__title {
    @include antonio-font($size: 60px, $color: 'inherit');
    @include responsive($max: '880px') {
      @include antonio-font($size: 25px, $color: 'inherit');
    }
  }
}
</style>
