<template>
  <div class="notifications">
    <p class="notifications__title">{{ $t('MANAGE_NOTIFICATIONS') }}</p>
    <section class="justify-between">
      <div class="notifications__roles">
        <div v-for="role of roles" :key="role.value" :class="['notifications__option', { 'notifications__option--is-selected': selectedRole === role.value }]" @click="selectedRole = role.value">
          <q-radio dense v-model="selectedRole" :val="role.value" class="mr-10" color="black" size="36px" />
          {{ role.label | firstUppercase }}
        </div>
      </div>
      <hr />
      <div class="notifications__list">
        <q-expansion-item v-for="notification of notifications" :key="notification.id" header-class="notifications__expansion" expand-icon="none" @input="handlePanelToggle(notification.id)">
          <template #header>
            <p class="notifications__label">{{ notification.description }}</p>
            <div class="notifications__header-actions align-middle">
              <BaseIcon
                :class="['notifications__icon', { 'notifications__icon--is-opened': opened.includes(notification.id) }]"
                :custom_style="{ height: '9px', width: '12px' }"
                icon="angular-arrow"
              />
              <BaseToggle :value="notification.value" @toggled="updateNotification({ notification })" />
            </div>
          </template>
          <div v-for="child of notification.children" :key="`child_${child.id}`" class="notifications__child">
            <p class="notifications__label">{{ child.description }}</p>
            <BaseToggle :value="child.value" @toggled="updateNotification({ notification: child, parent: notification })" />
          </div>
        </q-expansion-item>
      </div>
    </section>
  </div>
</template>

<script>
// Api
import { SettingsApi } from '@api/index';

// Base components
import BaseIcon from '@base/BaseIcon.vue';
import BaseToggle from '@base/BaseToggle.vue';

const ADMIN = 'ADMIN';
const CLIENT = 'CLIENT';
const PHOTOGRAPHER = 'PHOTOGRAPHER';

/**
 * Component used to handle notifications settings (Available only for admin)
 */
export default {
  name: 'Notifications',
  components: {
    BaseIcon,
    BaseToggle
  },
  data() {
    return {
      allSettings: {},
      opened: [],
      roles: [
        { label: this.$t('ROLES.PHOTOGRAPHER'), value: PHOTOGRAPHER },
        { label: this.$t('ROLES.CLIENT'), value: CLIENT },
        { label: this.$t('ROLES.ADMIN'), value: ADMIN }
      ],
      selectedRole: PHOTOGRAPHER
    };
  },
  computed: {
    /**
     * Retrieving the list of notifications based on the role selected
     */
    notifications() {
      return this.allSettings[this.selectedRole.toLowerCase()] || [];
    }
  },
  async created() {
    // Retrieving notifications
    const notificationsResource = await SettingsApi.getNotifications();

    if (notificationsResource.statusCode === 200) this.allSettings = notificationsResource.data;
  },
  methods: {
    /**
     * Update the array of opened panels
     */
    handlePanelToggle(id) {
      this.opened = this.updateArray({ array: this.opened, element: id });
    },
    /**
     * Updating the notifiation value
     */
    async updateNotification({ notification, parent = null }) {
      const { children, id, value } = notification;

      notification.value = !value;

      SettingsApi.updateNotification({ id, status: notification.value });

      /**
       * If notification has to be turned off (meaning its value is true right now) and its child of another one,
       * check if any other notification is active and udpdate also the father
       */
      if (parent) {
        const areAllSiblingsDisabled = parent.children.every(({ value }) => value === false);

        parent.value = !areAllSiblingsDisabled;

        return;
      }

      /**
       * If the notification does not have a parent, it means its the parent of others so, activate all if the notification gets activated
       */
      if (!parent) {
        for (const child of children) {
          child.value = notification.value;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.notifications {
  height: 100%;
  padding: 80px 172px;
  @include responsive($max: md) {
    padding: 50px 20px;
  }

  > section {
    $margin-top: 44px;
    height: calc(100% - #{$margin-top});
    margin-top: $margin-top;
    @include responsive($max: md) {
      flex-direction: column;
      height: fit-content;
      margin-top: 30px;
      width: 100%;
    }
    > hr {
      display: none;
      @include responsive($max: md) {
        color: var(--hr-bg-color);
        display: block;
        margin: 40px 0;
        width: 100%;
      }
    }
  }
  &__child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
  }

  &__expansion {
    align-items: center;
    background-color: var(--expansion-bg);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .q-expansion-item ~ .q-expansion-item {
    margin-top: 5px;
  }

  .q-focus-helper {
    order: 1;
  }

  &__header-actions {
    order: 2;
  }

  &__icon {
    transform: rotate(-90deg);

    &--is-opened {
      transform: rotate(90deg);
    }
  }

  &__label {
    @include inter-font($size: 12px);
    margin: 0;
    order: 0;
  }

  &__list,
  &__roles {
    flex: 50%;
    overflow-y: auto;
    @include responsive($max: md) {
      overflow-y: unset;
    }
  }
  &__option {
    @include inter-font($size: 12px);
    border: 1px solid var(--option-border);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 45px;
    min-width: 300px;
    padding: 13px 20px;
    text-align: left;
    width: 300px;

    &--is-selected {
      background-color: var(--option-selected-bg);
      color: var(--option-selected-text);
    }

    & ~ .notifications__option {
      margin-top: 15px;
    }

    .q-radio svg {
      background-color: var(--main-bg-color);
      border-radius: 50%;
      color: var(--option-selected-bg);
    }
    @include responsive($max: md) {
      min-width: unset;
      width: auto;
    }
  }

  &__title {
    @include inter-font($size: 16px, $bolded: true);
    text-align: left;
  }
}
</style>
