<template>
  <div>
    <q-dialog content-class="shootsNavigator__dialog" :value="show" @input="handleToggleShootDetails">
      <q-carousel v-model="current_shoot" arrows class="shootsNavigator__carousel" padding swipeable :navigation="false">
        <q-carousel-slide v-for="shoot in shoots" :key="shoot.id" :name="shoot.id">
          <div class="shootsNavigator__slide">
            <CardShoot v-if="showCardShoots" :showRawsPreview="showRawsPreview" :hideShootId="getHideInfo" :hide-shoot-id="uploadingStep === 1" :shoot="shoot" @close="handleToggleShootDetails">
              <template #body>
                <CardShootDetail v-if="isDefaultView" :shoot="shoot" show_attachments @update:photograper="handlePhotographer" />
                <PicturesUpload
                  v-else-if="isUploadingView"
                  :uploadingStep="uploadingStep"
                  :shootIdFormInputs="shootIdFormInputs"
                  :progressValue="progressValue"
                  :uploadedFilename="uploadedFilename"
                  @update-shoot-form-inputs="updateShootFormInputs"
                  :content="getShoot.content"
                />
                <CancelEditShootDialog v-else-if="isContactView" :selectedType="actionType" @shootId:update="shootId = $event" />
                <Reschedule v-else-if="isScheduleView" :duration="shoot.duration" @update:datetime="handleReschedule" />
                <!-- Handle Accept Shoot -->
                <h2 class="action-title" v-if="isAcceptConfirmView">{{ getAcceptTitle }}</h2>
                <p class="action" v-if="isAcceptConfirmView">{{ $t(getAcceptDetail) }}</p>
                <!-- Handle Decline Shoot -->
                <h2 class="action-title" v-if="isDeclineConfirmView">
                  {{ getDeclineTitle }}
                </h2>
                <p class="action" v-if="isDeclineConfirmView">{{ getDeclineDetail }}</p>
              </template>
              <template #actions>
                <BaseButton
                  class="shootsNavigator__backButton"
                  v-if="actionsComponent.includes('BACK') || actionType !== '' || isAcceptConfirmView || isDeclineConfirmView"
                  :label="$t('GO_BACK')"
                  @click.native="handleGoBack"
                />
                <BaseButton
                  v-if="actionsComponent.includes('WHATSAPP')"
                  icon="message-circle"
                  type="default"
                  :label="$t('WHATSAPP')"
                  @click.native="openWhatsapp"
                  :custom_style="{ margin: ' 0 20px' }"
                />
                <BaseButton v-if="actionsComponent.includes('EMAIL')" icon="mail" type="default" :label="$t('PERSONAL_DATA.EMAIL')" @click.native="openEmail" />
                <BaseButton
                  v-if="actionsComponent.includes('CONFIRM') && (isScheduleView || (picturesLoaded && uploadingStep === 3))"
                  icon="check"
                  :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                  :disabled="isConfirmDisabled"
                  :loading="isLoading"
                  :label="$t('CONFIRM')"
                  type="default"
                  @click.native="handleConfirm"
                />
                <BaseButton
                  v-if="actionsComponent.includes('ACCEPT') && isAcceptConfirmView"
                  :disabled="status.percentage === 100"
                  :loading="status.loading"
                  :custom_style="accept_button"
                  :icon_style="{ fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' }"
                  :label="$t('SHOOT.ACCEPT') | firstUppercase"
                  icon="check"
                  @click.native="handleAccept"
                />
                <BaseButton
                  v-if="actionsComponent.includes('DECLINE') && isDeclineConfirmView"
                  :disabled="status.percentage === 100"
                  :loading="status.loading"
                  :custom_style="isDefaultView ? { color: '#F25050' } : decline_button"
                  :icon_style="{ fill: 'transparent', height: '17px', stroke: '#f1f1f1', 'stroke-width': '2px', width: '17px' }"
                  :label="declineLabel | firstUppercase"
                  icon="close"
                  @click.native="handleDecline"
                />
                <ActionsButton
                  v-if="isDefaultView"
                  :downloadProcessedPhotos="downloadProcessedPhotos"
                  :downloadRawPhotos="downloadRawPhotos"
                  :actionsComponent="actionsComponent"
                  :handleEditShoot="handleEditShoot"
                  :handleCancelShoot="handleCancelShoot"
                  :handleDeclineShoot="handleDecline"
                  :handleAcceptShoot="handleAccept"
                  :handleAssignPhotographer="handleAssignPhotographer"
                  :selectedPhotographer="selectedAssignPhotographer"
                  :assignLabel="getAssignLabel"
                  :isDownloadDisabled="isDownloadDisabled"
                  :previewRawImages="handleShowRawsPreview"
                  :handleTriggerRawsPreview="handleTriggerRawsPreview"
                  :shoot="getShoot"
                  :isLoading="isLoading"
                />

                <BaseButton v-if="actionsComponent.includes('SCHEDULE')" :icon_style="iconStyle" icon="calendar" :label="getRescheduleLabel" type="default" @click.native="setScheduleAction(shoot)" />

                <RawsUploader
                  v-if="actionsComponent.includes('UPLOAD')"
                  :shootId="getShoot.id"
                  :updateStatusMutation="updateStatusMutation"
                  :uploadingStep="uploadingStep"
                  :isDisabled="isDisabledUpload"
                  @start-upload="startRawsUpload"
                />

                <ContentUploader v-if="actionsComponent.includes('UPLOAD_PROCESSED')" :shootId="getShoot.id" :content="getShoot.content" :updateStatusMutation="updateStatusMutation" />

                <BaseButton
                  v-if="actionsComponent.includes('PERFORM_EDIT') || actionsComponent.includes('PERFORM_CANCEL')"
                  :icon="getActionIcon"
                  :custom_style="{ 'min-width': '90px' }"
                  :icon_style="{ fill: 'transparent', height: '14px', stroke: '#fff', 'stroke-width': '2px', width: '14px' }"
                  :disabled="getActionDisabled"
                  :label="getActionLabel"
                  :loading="isCancelling"
                  :type="getActionTypeButton"
                  @click.native="getActionFunction(shoot)"
                />
              </template>
            </CardShoot>
            <ShootWizard v-if="showShootWizard" :shoot="shoot" @close="handleToggleShootDetails" />
          </div>
        </q-carousel-slide>
      </q-carousel>
    </q-dialog>
    <DownloadManager :updateStatusMutation="updateStatusMutation" :userRole="user.role" />
  </div>
</template>

<script>
// Tanstack query and the composition api
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { computed, reactive, toRefs, ref } from '@vue/composition-api';
import text from '@/i18n';

// Api
import { ShootsApi } from '@api/index';

// Base Components
import BaseButton from '@base/BaseButton.vue';
import ContentUploader from '@base/ContentUploader.vue';
import RawsUploader from '@base/RawsUploader.vue';

// Components
import CardShoot from '@/components/CardShoot.vue';
import CancelEditShootDialog from './CancelEditShootDialog.vue';
import CardShootDetail from '@/components/CardShootDetail.vue';
import PicturesUpload from '@components/photographer/PicturesUpload.vue';
import Reschedule from './Reschedule.vue';
import ShootWizard from '@/components/ShootWizard.vue';
import DownloadManager from '@/components/shoot/DownloadManager.vue';

// Utils
import helpers, { ASSIGNED, CANCELED, CONFIRMED, COMPLETED, READY, REDEEMABLE, SCHEDULED, TO_PAY, TO_SCHEDULE, UPLOADED } from '@utils/shootsHelpers';
import { default as globalHelpers } from '@utils/helpers';
// Mixins
import checkBreakpoint from '@utils/mixins/check-breakpoint.js';
import ActionsButton from '../ActionsButton.vue';

// View constants
const VIEW = {
  CONTACT: 'CONTACT',
  DEFAULT: 'DEFAULT',
  SCHEDULE: 'SCHEDULE',
  UPLOAD: 'UPLOAD',
  ACCEPT_CONFIRM: 'ACCEPT_CONFIRM',
  DECLINE_CONFIRM: 'DECLINE_CONFIRM'
};

const defaultData = () => ({
  // Constants
  CONTACT: VIEW.CONTACT,
  DEFAULT: VIEW.DEFAULT,
  SCHEDULE: VIEW.SCHEDULE,
  UPLOAD: VIEW.UPLOAD,
  ACCEPT_CONFIRM: VIEW.ACCEPT_CONFIRM,
  DECLINE_CONFIRM: VIEW.DECLINE_CONFIRM,
  SHOOT_INFO: VIEW.SHOOT_INFO,
  actionType: '',
  uploadProcessedLabel: undefined,
  cancelLabelCustomStyle: {
    color: globalHelpers.getCssVariable('--packages-trash-action'),
    width: '44px',
    background: 'rgba(255, 99, 97, 0.05)',
    'margin-right': '5px'
  },
  iconStyle: { fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' },
  currentView: VIEW.DEFAULT,
  showRawsPreview: false,
  showActions: false,
  isCancelling: false,
  isLoading: false,
  isLoadingProcessed: false,
  isLoadingRawsSelection: false,
  isUploadingFile: false,
  newSchedule: {
    address: {},
    datetime: {}
  },
  processedPercentage: 0,
  selectedAssignPhotographer: null,
  shootIdFormInputs: {
    activeAnswer: '',
    imagesMatchDeliveries: false,
    insertedShootId: ''
  },
  showContactForm: false,
  shootId: null,
  picturesUploaded: false,
  uploadingStep: 0,
  uploadedFilename: '',
  picturesLoaded: false,
  progressValue: 0,
  decline_button: {
    'background-color': globalHelpers.getCssVariable('--decline-btn-bg-color'),
    'margin-left': '15px',
    color: '#fff'
  },
  accept_button: {
    'background-color': globalHelpers.getCssVariable('--main-btn-color'),
    'margin-left': '15px'
  },
  status: {
    loading: false,
    percentage: 0
  }
});

export default {
  name: 'ShootsNavigator',
  mixins: [checkBreakpoint],
  components: {
    BaseButton,
    CancelEditShootDialog,
    CardShoot,
    CardShootDetail,
    PicturesUpload,
    Reschedule,
    ShootWizard,
    ActionsButton,
    ContentUploader,
    RawsUploader,
    DownloadManager
  },
  props: {
    /**
     * Use this prop to show/hide the dialog
     */
    show: { default: false, type: Boolean },
    /**
     * Use this prop to pass the shoots
     */
    shoots: {
      default: () => [],
      type: Array
    }
  },

  setup(props, { emit, root }) {
    const store = root.$store;
    const router = root.$router;
    const queryClient = useQueryClient();

    const state = reactive({
      // Constants
      CONTACT: VIEW.CONTACT,
      DEFAULT: VIEW.DEFAULT,
      SCHEDULE: VIEW.SCHEDULE,
      UPLOAD: VIEW.UPLOAD,
      ACCEPT_CONFIRM: VIEW.ACCEPT_CONFIRM,
      DECLINE_CONFIRM: VIEW.DECLINE_CONFIRM,
      SHOOT_INFO: VIEW.SHOOT_INFO,
      actionType: '',
      uploadProcessedLabel: undefined,
      cancelLabelCustomStyle: {
        color: globalHelpers.getCssVariable('--packages-trash-action'),
        width: '44px',
        background: 'rgba(255, 99, 97, 0.05)',
        'margin-right': '5px'
      },
      iconStyle: { fill: 'transparent', height: '17px', stroke: '#151515', 'stroke-width': '2px', width: '17px' },
      currentView: VIEW.DEFAULT,
      showRawsPreview: false,
      showActions: false,
      isCancelling: false,
      isLoading: false,
      isLoadingProcessed: false,
      isLoadingRawsSelection: false,
      isUploadingFile: false,
      newSchedule: {
        address: {},
        datetime: {}
      },
      processedPercentage: 0,
      selectedAssignPhotographer: null,
      shootIdFormInputs: {
        activeAnswer: '',
        imagesMatchDeliveries: false,
        insertedShootId: ''
      },
      showContactForm: false,
      shootId: null,
      picturesUploaded: false,
      uploadingStep: 0,
      uploadedFilename: '',
      picturesLoaded: false,
      progressValue: 0,
      decline_button: {
        'background-color': globalHelpers.getCssVariable('--decline-btn-bg-color'),
        'margin-left': '15px',
        color: '#fff'
      },
      accept_button: {
        'background-color': globalHelpers.getCssVariable('--main-btn-color'),
        'margin-left': '15px'
      },
      status: {
        loading: false,
        percentage: 0
      }
    });

    // Computed properties
    const isAdmin = computed(() => store.getters['user/isAdmin']);
    const isClient = computed(() => store.getters['user/isClient']);
    const user = computed(() => store.getters['user/getUser']);
    const isEditor = computed(() => store.getters['user/isEditor']);
    const isPhotographer = computed(() => store.getters['user/isPhotographer']);
    const selectedShoot = computed(() => store.getters['shoot/getSelectedShoot']);
    const current_shoot = computed({
      get: () => selectedShoot.value,
      set: pkgId => setSelectedShoot(pkgId)
    });

    const getShoot = computed(() => {
      const currentShoot = props.shoots.find(_shoot => _shoot.id === selectedShoot.value);
      return currentShoot || {};
    });

    const actionsComponent = computed(() => {
      if (isAdmin.value) return adminActions();
      if (isClient.value) {
        if (user.value.subClientRole === 'viewer') return viewerActions();
        else return clientActions();
      }
      if (isEditor.value) return editorActions();
      if (isPhotographer.value) return photographerActions();

      return [];
    });

    const setSelectedShoot = payload => {
      store.dispatch('shoot/saveOpenedShoot', payload);
    };

    const showSuccessNotification = message => {
      store.dispatch('notification/addSuccessNotification', message);
    };

    const showFailureNotification = message => {
      store.dispatch('notification/addFailureNotification', message);
    };

    const updateBookShoot = payload => {
      store.dispatch('bookShoot/updateShoot', payload);
    };

    const getAssignLabel = computed(() => {
      // return checkBreakpoint({ upperBoundary: 543 }) ? text.t('ASSIGN') : getShoot.value.content === 'videography' ? text.t('ASSIGN_VI') : text.t('ASSIGN_PH');
      return 'Assign a photographer';
    });

    const isDownloadDisabled = computed(() => {
      return isClient.value && getShoot.value.status !== READY && getShoot.value.status !== COMPLETED;
    });

    const getActionIcon = computed(() => {
      return state.actionType === 'edit' ? 'pencil' : 'trash';
    });

    const getActionDisabled = computed(() => {
      return isDisableEdit.value || false;
    });

    const getActionLabel = computed(() => {
      return state.actionType === 'edit' ? text.t('EDIT') : text.t('CANCEL');
    });

    const getActionTypeButton = computed(() => {
      return state.actionType === 'edit' ? 'dark' : 'danger';
    });

    const getHideInfo = computed(() => {
      return state.actionType === 'edit' ? true : false;
    });

    const getRescheduleLabel = computed(() => {
      return isRedeemable.value ? text.t('REDEEM') : text.t('SELECT_DATE_TIME');
    });

    const getShootAddress = computed(() => {
      return getShoot.value.address;
    });

    const isContactView = computed(() => {
      return state.currentView === state.CONTACT;
    });

    const isAcceptConfirmView = computed(() => {
      return state.currentView === state.ACCEPT_CONFIRM;
    });

    const isDeclineConfirmView = computed(() => {
      return state.currentView === state.DECLINE_CONFIRM;
    });

    const isDefaultView = computed(() => {
      return state.currentView === state.DEFAULT;
    });

    const isScheduleView = computed(() => {
      return state.currentView === state.SCHEDULE;
    });

    const isRedeemable = computed(() => {
      return getShoot.value.status === REDEEMABLE;
    });

    const isDisabledUpload = computed(() => {
      const isActiveAnswerSelected = state.shootIdFormInputs.activeAnswer !== '' || getShoot.value.content === 'videography';
      const isInsertedShootIdCorrect = state.shootIdFormInputs.insertedShootId === `${getShoot.value.id}`;

      return state.currentView === state.UPLOAD && (!isActiveAnswerSelected || !isInsertedShootIdCorrect || !state.shootIdFormInputs.imagesMatchDeliveries);
    });

    const isDisableEdit = computed(() => {
      return state.actionType === 'edit' && state.shootId !== `${getShoot.value.id}`;
    });

    const isUploadingView = computed(() => {
      return state.currentView === state.UPLOAD;
    });

    const isConfirmDisabled = computed(() => {
      if (isScheduleView.value) {
        return Object.keys(state.newSchedule.datetime).length === 0;
      }

      return false;
    });

    const showActionButton = computed(() => {
      return (isAdmin.value && state.actionType === 'edit') || (isAdmin.value && state.actionType === 'cancel');
    });

    const showCardShoots = computed(() => {
      return !helpers.showWizard(getShoot.value);
    });

    const showShootWizard = computed(() => {
      return helpers.showWizard(getShoot.value);
    });

    const acceptLabel = computed(() => {
      const label = {
        false: text.t('SHOOT.ACCEPT'),
        true: 'Accepted!'
      };

      return label[`${state.status.percentage === 100}`];
    });

    const declineLabel = computed(() => {
      const label = {
        false: isAdmin.value ? text.t('Reassign') : text.t('SHOOT.DECLINE'),
        true: 'Declined!'
      };

      return label[`${state.status.percentage === 100}`];
    });

    const getAcceptTitle = computed(() => {
      return `Are you sure you want to confirm the assignment of the ${getShoot.value.content === 'videography' ? 'videographer' : 'photographer'} ?`;
    });

    const getAcceptDetail = computed(() => {
      return `Confirming the assignment of the ${getShoot.value.content === 'videography' ? 'videographer' : 'photographer'} #${
        getShoot.value.photographer_id
      } to this shoot will change its status from 'Assigned' to 'Confirmed'. Are you sure you want to proceed?`;
    });

    const getDeclineTitle = computed(() => {
      return 'Are you sure you want to reassign the photographer?';
    });

    const getDeclineDetail = computed(() => {
      let currentStatus = getShoot.value.status === 'confirmed' ? 'Confirmed' : 'Assigned';
      return `Canceling the assignment of the photographer #${getShoot.value.photographer_id} to this shoot will change its status from '${currentStatus}' to 'Pending'. Are you sure you want to proceed?`;
    });
    // Mutation to update the statusc
    const updateStatusMutation = useMutation({
      mutationFn: statusDetails => ShootsApi.updateStatus(statusDetails),
      onSuccess: updateStatus => {
        if (updateStatus.statusCode === 200) {
          queryClient.refetchQueries(['shoots']);
          store.dispatch('notification/addSuccessNotification', `Shoot #${getShoot.value.id} has been updated `);
          handleToggleShootDetails();
          // other success handling
        }
      },
      onError: error => {
        // Handle updating status error
        state.isLoading = false;
        state.status.loading = false;
        state.isCancelling = false;
        console.error('Error updating shoot status:', error);
      }
    });

    // Mutation for scheduling the shoot
    const scheduleShootMutation = useMutation({
      mutationFn: shootDetails => ShootsApi.scheduleShoot(shootDetails),
      onSuccess: (response, shootDetails) => {
        if (response.statusCode === 200) {
          const targetStatus = shootDetails.photographer_id ? ASSIGNED : SCHEDULED;
          const statusDetails = { id: shootDetails.id, payload: { targetStatus } };
          updateStatusMutation.mutate(statusDetails);
        }
      },
      onError: error => {
        // Handle scheduling shoot error
        state.isLoading = false;
        console.error('Error scheduling shoot:', error);
      }
    });

    const assignPhotographerMutation = useMutation({
      mutationFn: ({ shoot_id, photographer_id }) => ShootsApi.assignPhotographer({ shoot_id, photographer_id }),

      onSuccess: (response, { shoot_id }) => {
        if (response.statusCode === 200) {
          // Log success and proceed to update the status

          const targetStatus = ASSIGNED;
          const statusDetails = { id: shoot_id, payload: { targetStatus } };

          // Here you would call the updateStatus mutation, similar to how you've done it in the onSuccess of scheduleShootMutation
          updateStatusMutation.mutate(statusDetails);
        }
      },
      onError: (error, { shoot_id }) => {
        // Handle the error state, set loading to false, and log the error
        state.isLoading = false;
        console.error(`Error assigning photographer for shoot ID ${shoot_id}:`, error);
      }
    });
    const unassignPhotographerMutation = useMutation({
      mutationFn: shootId => ShootsApi.unassignPhotographer(shootId),
      onSuccess: response => {
        if (response.statusCode === 200) {
          store.dispatch('notification/addSuccessNotification', 'The photographer has been unassigned');
          queryClient.refetchQueries(['shoots']);
          handleToggleShootDetails();
        }
      },
      onError: (error, shootId) => {
        // Handle the error state
        state.isCancelling = false;
        state.status.loading = false;
        console.error(`Error unassigning photographer for shoot ID ${shootId}:`, error);
      }
    });

    // UploadRawMutation will be used in the component where you want to handle file uploads
    // Upload Raws Mutation with progress tracking
    const uploadRawsMutation = useMutation({
      mutationFn: fileData => {
        // The mutationFn will call your API method
        return ShootsApi.uploadRaw({
          id: fileData.id,
          blob: fileData.blob,
          // Directly use the onUploadProgress from the argument
          onUploadProgress: event => {
            const { loaded, total } = event;
            state.progressValue = Math.round((loaded * 100) / total);
          }
        });
      },
      onMutate: async fileData => {
        // You might want to reset the progress or do some actions before the upload starts
        state.isUploadingFile = true;
        state.uploadedFilename = fileData.blob.name;
        state.uploadingStep = 2;
      },
      onSuccess: () => {
        // Handle success
        state.picturesLoaded = true;
        state.uploadingStep = 3;
        showSuccessNotification('File uploaded successfully');
      },
      onError: error => {
        // Handle error
        console.error('Failed to upload file:', error);
        store.dispatch('notification/addFailureNotification', 'Failed to upload file');
      },
      onSettled: () => {
        // Always run whether an error or success after mutation is done
        state.isUploadingFile = false;
      }
    });

    // Upload Processed Images Mutation
    const uploadRawsPreviewMutation = useMutation({
      mutationFn: fileData => {
        // Choose the API function based on conditions
        const apiMethod = ShootsApi.uploadRawsPreview;

        return apiMethod({
          id: fileData.id,
          blob: fileData.blob,
          onUploadProgress: event => {
            const { loaded, total } = event;
            state.processedPercentage = Math.round((loaded * 100) / total);
          }
        });
      },
      onMutate: async () => {
        // Reset progress or set initial upload state
        state.isLoadingProcessed = true;
        state.processedPercentage = 0; // Reset progress
      },
      onSuccess: async (response, variables) => {
        // Check if the file upload was successful
        showSuccessNotification(text.t('NOTIFICATIONS.SHOOTS.UPDATE', { id: getShoot.value.id }));
        state.isLoadingProcessed = false;
      },
      onError: error => {
        // Handle error
        console.error('Failed to upload file:', error);
        store.dispatch('notification/addFailureNotification', 'Failed to upload content');
      }
    });

    const triggerRawsPreviewMutation = useMutation({
      mutationFn: payload => ShootsApi.updateRawsPreview(payload),
      onSuccess: (data, variables) => {
        if (data.statusCode === 200) {
          queryClient.refetchQueries(['shoots']);
          store.dispatch('notification/addSuccessNotification', `Raws selection updated for Shoot #${variables.id}`);
          handleToggleShootDetails(); // Trigger UI changes if needed
        }
      },
      onError: error => {
        console.error('Error updating raws_preview:', error);
        store.dispatch('notification/addFailureNotification', 'Failed to update RAWs preview');
      }
    });

    const downloadRawsMutation = useMutation({
      mutationFn: downloadDetails => ShootsApi.downloadRaw(downloadDetails),
      onMutate: () => {
        state.isLoading = true;
      },
      onSuccess: (_, downloadDetails) => {
        // Assuming that the download API call returns a success status without a body
        store.dispatch('notification/addSuccessNotification', `Raw photos for Shoot #${downloadDetails.id} are being downloaded.`);
        state.isLoading = false;
        handleToggleShootDetails();
      },
      onError: (error, downloadDetails) => {
        console.error(`Error downloading raw photos for Shoot #${downloadDetails.id}:`, error);
        store.dispatch('notification/addFailureNotification', `Failed to download raw photos for Shoot #${downloadDetails.id}`);
        state.isLoading = false;
        handleToggleShootDetails();
      }
    });
    // methods
    function adminActions() {
      // Show the action buttons only if the contact form is not displayed
      if (isContactView.value) return ['BACK', 'PERFORM_CANCEL', 'PERFORM_EDIT'];

      const actions = {
        [ASSIGNED]: ['CANCEL', 'EDIT', 'ACCEPT', 'DECLINE', 'TRIGGER_PREVIEW', 'TRIGGER_RAWS_PREVIEW'],
        [CANCELED]: [],
        [CONFIRMED]: ['CANCEL', 'EDIT', 'UPLOAD', 'CONFIRM', 'TRIGGER_PREVIEW', 'TRIGGER_RAWS_PREVIEW', 'DECLINE'],
        [COMPLETED]: ['DOWNLOAD', 'UPLOAD_PROCESSED', 'DOWNLOAD_RAWS', 'TRIGGER_PREVIEW'],
        [READY]: getShoot.value.content === 'photography' ? ['DOWNLOAD', 'DOWNLOAD_RAWS', 'UPLOAD_PROCESSED', 'TRIGGER_PREVIEW'] : ['DOWNLOAD', 'UPLOAD_PROCESSED'],
        [REDEEMABLE]: isScheduleView.value ? ['BACK', 'CONFIRM'] : ['SCHEDULE'],
        [SCHEDULED]: ['CANCEL', 'ASSIGN_PH', 'EDIT', 'TRIGGER_PREVIEW', 'TRIGGER_RAWS_PREVIEW'],
        [UPLOADED]: ['DOWNLOAD_RAWS', 'UPLOAD_PROCESSED', 'EDIT', 'TRIGGER_PREVIEW', 'TRIGGER_RAWS_PREVIEW', 'UPLOAD_RAWS_PREVIEW'],
        [TO_SCHEDULE]: isScheduleView.value ? ['BACK', 'CONFIRM'] : ['SCHEDULE'],
        [TO_PAY]: getShoot.value.datetime ? ['EDIT'] : isScheduleView.value ? ['BACK', 'CONFIRM'] : ['SCHEDULE']
      };

      return actions[getShoot.value.status] || [];
    }

    function clientActions() {
      let newShootActions = [];

      // Show the action buttons only if the contact form is not displayed
      if (!isContactView.value) newShootActions = ['CANCEL', 'EDIT', 'DOWNLOAD'];
      if (isContactView.value) newShootActions = ['BACK', 'WHATSAPP', 'EMAIL'];

      const actions = {
        [ASSIGNED]: newShootActions,
        [CONFIRMED]: newShootActions,
        [UPLOADED]: ['PREVIEW_RAWS'],
        [COMPLETED]: [],
        [READY]: [],
        [REDEEMABLE]: isScheduleView.value ? ['BACK', 'CONFIRM'] : ['SCHEDULE'],
        [SCHEDULED]: newShootActions
      };

      if (getShoot.value.status === COMPLETED || getShoot.value.status === READY) {
        if (isDownloadDisabled.value === false) {
          actions[COMPLETED].push('DOWNLOAD');
          actions[READY].push('DOWNLOAD');
        }
      }

      return actions[getShoot.value.status] || [];
    }

    function viewerActions() {
      const actions = {
        [ASSIGNED]: [],
        [CONFIRMED]: [],
        [UPLOADED]: [],
        [COMPLETED]: [],
        [READY]: [],
        [REDEEMABLE]: [],
        [SCHEDULED]: []
      };

      if (getShoot.value.status === COMPLETED || getShoot.value.status === READY) {
        if (isDownloadDisabled.value === false) {
          actions[COMPLETED].push('DOWNLOAD');
          actions[READY].push('DOWNLOAD');
        }
      }

      return actions[getShoot.value.status] || [];
    }

    function photographerActions() {
      let confirmedActions = [];

      switch (state.uploadingStep) {
        case 0:
          confirmedActions = ['UPLOAD', 'CANCEL'];
          break;
        case 1:
          confirmedActions = ['BACK', 'UPLOAD'];
          break;
        case 2:
          confirmedActions = ['CONFIRM'];
          break;
        case 3:
          confirmedActions = ['BACK', 'CONFIRM'];
          break;
      }

      const actions = {
        [ASSIGNED]: ['ACCEPT', 'DECLINE'],
        [CONFIRMED]: confirmedActions,
        [CANCELED]: [],
        [COMPLETED]: [],
        [READY]: [],
        [UPLOADED]: []
      };
      return actions[getShoot.value.status] || [];
    }

    function editorActions() {
      const actions = {
        [UPLOADED]: ['DOWNLOAD_RAWS']
      };

      return actions[getShoot.value.status] || [];
    }

    function handleShowRawsPreview() {
      state.showRawsPreview = !state.showRawsPreview;
    }
    // Define the resetData function using defaultData
    function resetData() {
      Object.assign(state, defaultData());
    }

    // Define the handleToggleShootDetails function
    function handleToggleShootDetails() {
      emit('toggle');
      resetData();
    }

    /**
     * Method used to confirm uploading pictures and update the shoot's status
     */
    async function confirmPicturesUpload() {
      state.isLoading = true;
      // Change shoot status to uploaded
      const statusDetails = { id: getShoot.value.id, payload: { targetStatus: UPLOADED } };
      // Call mutate with additional callbacks
      updateStatusMutation.mutate(statusDetails, {
        onSuccess: () => {
          // Logic for when the mutation is successful specifically for this operation
          state.isLoading = false;
          state.picturesUploaded = true;
          const notificationMessage = getShoot.value.content === 'videography' ? `Shoot #${getShoot.value.id} draft uploaded successfully` : `Shoot #${getShoot.value.id} RAWs uploaded successfully`;
          store.dispatch('notification/addSuccessNotification', notificationMessage);
        },
        onError: error => {
          // Logic for when the mutation encounters an error specifically for this operation
          state.isLoading = false;
          state.picturesUploaded = false;
          store.dispatch('notification/addFailureNotification', `Error uploading shoot #${getShoot.value.id}`);
        }
      });
    }

    function editShoot(item) {
      const { type } = item;

      const shoot = {
        datetime: item.datetime,
        id: item.id,
        packages: [
          {
            service_id: item.services[0].service_id,
            picture_number: item.services[0].picture_number,
            video_number: item.video_number,
            video_duration: item.video_duration
          }
        ],
        is_brief_uploaded: item.is_brief_uploaded,
        location: getShootAddress,
        photographer_id: item.photographer_id || null,
        picture_number: item.picture_number,
        name: item.name,
        notes: item.notes || undefined,
        services: item.services,
        status: item.status,
        total_price: Number(item.price),
        total_revenue: Number(item.photographer_revenue),
        content: item.content,
        time: {
          from: item.from || undefined,
          duration: item.duration
        },
        type: type
      };
      updateBookShoot(shoot);
      router.push({ name: 'bookShoot', query: { type: `${'edit-'}${type}` } });
    }

    /**
     * use to set the correct function of action button
     */
    function getActionFunction(element) {
      state.actionType === 'edit' ? editShoot(element) : adminCancelShoot(element);
    }

    /**
     * This method handles the click on the confirm button performing different action based on the current view
     */
    function handleConfirm() {
      if (isScheduleView.value) {
        scheduleShoot();
        return;
      }

      confirmPicturesUpload();
    }

    // Accept shoot on behalf of the photographer
    async function accept() {
      state.status.loading = true;
      const statusDetails = { id: getShoot.value.id, payload: { targetStatus: CONFIRMED } };
      updateStatusMutation.mutate(statusDetails);
    }
    function handleAccept() {
      if (isDefaultView.value) {
        state.currentView = VIEW.ACCEPT_CONFIRM;
        return;
      }
      accept();
    }

    /**
     * Method used to decline a shoot
     */
    async function decline() {
      state.status.loading = true;
      unassignPhotographerMutation.mutate(getShoot.value.id);
    }

    function handleDecline() {
      if (isDefaultView.value) {
        state.currentView = VIEW.DECLINE_CONFIRM;
        return;
      }
      decline();
    }

    function goBack() {
      state.currentView = VIEW.DEFAULT;
      state.status = {
        loading: false,
        percentage: 0
      };
    }

    function handlePhotographer($event) {
      state.selectedAssignPhotographer = $event;
    }
    /**
     * Setting the shoot new schedule time (date and time)
     */
    function handleReschedule($event) {
      state.newSchedule.datetime = $event;
    }

    // Upload Raw Images
    function handleUploadRaws($event) {
      const blob = new Blob([$event]); // Ensure that this Blob construction is correct for your file data
      const fileData = { id: getShoot.value.id, blob };
      // Use the uploadRawMutation.mutate function to start the mutation
      uploadRawsMutation.mutate(fileData); // No need for the second argument
    }

    /**
     * Upload the processed images
     */

    async function handleUploadRawsPreview(selectedFile) {
      // Directly assign the selected file since we're no longer dealing with multiple files
      const file = selectedFile;

      if (!file) {
        console.error('No file selected.');
        return;
      }

      // Since `file` is already a Blob, you can skip creating a new Blob and use it directly
      const fileData = {
        id: getShoot.value.id,
        blob: file
      };

      // Now mutate with the correct file data
      uploadRawsPreviewMutation.mutate(fileData);
    }

    async function handleUpdateRawsPreview($event) {
      try {
        state.isLoadingRawsSelection = true; // Reset the loading state in both success and failure scenarios

        const blob = new Blob($event);

        const onUploadProgress = data => {
          const { loaded, total } = data;
          state.processedPercentage = Math.round((loaded * 100) / total);
        };

        const uploadProcessedResource = await ShootsApi.uploadRawsSelectionImages({ id: getShoot.value.id, blob, onUploadProgress });

        if (uploadProcessedResource.statusCode === 200) {
          if (uploadProcessedResource.statusCode === 200) {
            showSuccessNotification(text.t('NOTIFICATIONS.SHOOTS.UPDATE', { id: getShoot.value.id }));
          } else {
            store.dispatch('notification/addFailureNotification', 'Failed to upload preview raws');
          }
        } else {
          store.dispatch('notification/addFailureNotification', 'Failed to upload preview raws');
        }
      } catch (error) {
        console.error('An error occurred:', error); // Log the error for debugging
        store.dispatch('notification/addFailureNotification', 'Failed to upload preview raws');
      } finally {
        state.isLoadingRawsSelection = false; // Reset the loading state in both success and failure scenarios
        handleToggleShootDetails();
      }
    }

    function setScheduleAction(item) {
      if (item.status === REDEEMABLE) {
        redeem(item);
        return;
      }

      state.currentView = VIEW.SCHEDULE;
    }

    function redeem(item) {
      const shoot = {
        packages: item.services.map(service => ({ service_id: service.service_id, pictures_number: service.picture_number, name: service.package.name, package_id: service.package.id })),
        photographer_id: null,
        client_id: null,
        is_brief_uploaded: item.is_brief_uploaded,
        is_payed: true,
        location: getShootAddress.value,
        name: item.name,
        notes: item.notes || undefined,
        redeemable_shoot_id: getShoot.value.id,
        redeemable_total: item.redeemable_total,
        redeemable_counter: item.redeemable_counter,
        status: item.status,
        total_price: item.price,
        time: {
          duration: item.duration
        },
        type: 'redeem',
        content: item.content,
        video_number: item.video_number,
        video_duration: item.video_duration
      };

      updateBookShoot(shoot);
      router.push({ name: 'bookShoot', query: { type: 'redeem' } });
    }

    // Shoot level images_preview trigger
    async function handleTriggerRawsPreview() {
      triggerRawsPreviewMutation.mutate({
        id: getShoot.value.id,
        payload: {
          rawsPreview: !getShoot.value.raws_preview
        }
      });
    }

    /**
     * Method used to reset the uploading data
     */
    function resetUploadingData() {
      state.picturesLoaded = false;
      state.picturesUploaded = false;
      state.progressValue = 0;
      state.shootIdFormInputs = {
        activeAnswer: '',
        imagesMatchDeliveries: false,
        insertedShootId: ''
      };
      state.uploadedFilename = '';
      state.uploadingStep = 0;
    }
    /**
     * Method used to show the form with the questions regarding the uploaded files.
     * When the form is completed, the user can upload the pictures
     */
    function startRawsUpload() {
      state.currentView = VIEW.UPLOAD;
      state.uploadingStep = 1;
    }

    /**
     * Method used to handle the edit action of a shoot using the type.
     */
    async function handleEditShoot(item) {
      if (isClient.value || (isAdmin.value && item === 'edit')) {
        state.currentView = VIEW.CONTACT;
        state.actionType = item;
        return;
      }
    }
    /**
     * Method used to handle the cancel of a shoot using the type.
     * if is photographer call this.photographerCancelShoot
     */
    function handleCancelShoot(item) {
      if (isPhotographer.value) {
        photographerCancelShoot();
        return;
      }
      if (isClient.value || (isAdmin.value && item === 'cancel')) {
        state.currentView = VIEW.CONTACT;
        state.actionType = item;
        return;
      }
      resetUploadingData();
    }
    /**
     * Method used to cancel a shoot if logged used is a photographer
     */
    async function photographerCancelShoot() {
      state.isCancelling = true;
      unassignPhotographerMutation.mutate(getShoot.value.id);
    }

    /**
     * Method used to cancel a shoot. Once the cancel has been completed, a notification is displayed and the navigator closed
     */

    async function adminCancelShoot() {
      state.isCancelling = true;
      const statusDetails = { id: getShoot.value.id, payload: { targetStatus: CANCELED } };
      updateStatusMutation.mutate(statusDetails);
    }

    function handleGoBack() {
      if (isScheduleView.value || (isUploadingView.value && state.uploadingStep === 1)) {
        state.currentView = VIEW.DEFAULT;
        state.uploadingStep = 0;
        return;
      }

      if (isContactView.value) {
        state.currentView = VIEW.DEFAULT;
        state.actionType = '';
        return;
      }
      if (isAcceptConfirmView.value || isDeclineConfirmView.value) {
        state.currentView = VIEW.DEFAULT;
        state.actionType = '';
        return;
      }

      state.uploadingStep = state.uploadingStep !== 3 ? state.uploadingStep - 1 : state.uploadingStep - 2;
    }

    async function downloadProcessedPhotos() {
      if (isDownloadDisabled.value) return;

      try {
        // Start the download job
        await ShootsApi.enqueueDownloadJob({
          id: getShoot.value.id
        });

        // Add download to the queue
        store.dispatch('downloads/addDownload', {
          shootId: getShoot.value.id,
          shootName: getShoot.value.name // or however you get the shoot name
        });

        // Hide the main dialog
        emit('toggle');

        showSuccessNotification('Download job started');
      } catch (error) {
        console.error('Failed to start download:', error);
        showFailureNotification('Failed to start the download job');
      }
    }
    async function downloadRawPhotos() {
      if (state.isDownloadDisabled) return;

      // Only admins and editors can download raw photos
      if (isAdmin.value || isEditor.value) {
        downloadRawsMutation.mutate({ id: getShoot.value.id });
      }
    }

    async function handleAssignPhotographer() {
      // Set the loading state
      state.isLoading = true;

      // Prepare the details for the photographer assignment
      const assignmentDetails = {
        shoot_id: getShoot.value.id,
        photographer_id: state.selectedAssignPhotographer.id
      };
      assignPhotographerMutation.mutate(assignmentDetails);
    }
    /**
     * Method used to update the shoot id form inputs
     */
    function updateShootFormInputs({ param, value }) {
      state.shootIdFormInputs[param] = value;
    }

    /**
     * Method used to update a shoot by setting the schedule datetime
     * It will:
     * 1 - update the shoot schedule time;
     * 2 - update the shoot status;
     * 3 - update the FE shoot informations;
     */
    function scheduleShoot() {
      // Set the loading state
      state.isLoading = true;

      // Prepare the payload for scheduling the shoot
      const shootDetails = {
        id: getShoot.value.id,
        payload: {
          datetime: state.newSchedule.datetime.from
        }
      };
      // Use the mutate method from the scheduleShootMutation
      scheduleShootMutation.mutate(shootDetails);
    }

    return {
      ...toRefs(state),
      getShoot,
      isAdmin,
      isEditor,
      isPhotographer,
      isClient,
      selectedShoot,
      getAssignLabel,
      isDownloadDisabled,
      getActionIcon,
      getActionDisabled,
      getActionLabel,
      getActionTypeButton,
      getHideInfo,
      getRescheduleLabel,
      getShootAddress,
      isContactView,
      isAcceptConfirmView,
      isDeclineConfirmView,
      isDefaultView,
      isDisabledUpload,
      isDisableEdit,
      isUploadingView,
      isConfirmDisabled,
      showActionButton,
      showCardShoots,
      showShootWizard,
      acceptLabel,
      declineLabel,
      getAcceptTitle,
      getAcceptDetail,
      getDeclineTitle,
      getDeclineDetail,
      adminActions,
      clientActions,
      photographerActions,
      editorActions,
      handleShowRawsPreview,
      confirmPicturesUpload,
      handleToggleShootDetails,
      editShoot,
      getActionFunction,
      handleConfirm,
      accept,
      handleAccept,
      decline,
      handleDecline,
      goBack,
      handlePhotographer,
      handleReschedule,
      handleUploadRaws,
      handleUploadRawsPreview,
      handleUpdateRawsPreview,
      setScheduleAction,
      redeem,
      handleTriggerRawsPreview,
      resetUploadingData,
      startRawsUpload,
      handleEditShoot,
      handleCancelShoot,
      photographerCancelShoot,
      adminCancelShoot,
      handleGoBack,
      downloadProcessedPhotos,
      downloadRawPhotos,
      handleAssignPhotographer,
      scheduleShoot,
      updateShootFormInputs,
      actionsComponent,
      setSelectedShoot,
      showFailureNotification,
      current_shoot,
      isScheduleView,
      updateStatusMutation,
      user
    };
  }
};
</script>

<style lang="scss">
.action {
  @include inter-font($size: 14px, $bolded: false, $color: var(--main-text-color));
  white-space: pre-wrap;
}

.alert {
  @include inter-font($size: 14px, $bolded: true, $color: var(--danger));
  bottom: 0;
  position: absolute;
}

.action-title {
  @include antonio-font($size: 23px, $uppercase: true, $color: var(--main-text-color));
  margin: 0;
}

@include responsive($max: md) {
  .shootsNavigator {
    &__actions {
      height: 76px;
    }

    &__carousel {
      border-radius: 0;
      height: 100vh !important;
      max-height: unset !important;
      width: 100vw !important;

      .q-carousel__slide {
        padding: 0px !important;
      }

      .q-carousel__prev-arrow--horizontal,
      .q-carousel__next-arrow--horizontal {
        display: none;
      }
    }

    &__backButton {
      display: none;
    }

    &__dialog {
      .q-dialog__inner {
        padding: 0;
      }
    }

    &__slide {
      .baseCard {
        border-radius: 0;
        height: 100svh !important;
        padding: 16px !important;
      }
    }
  }
}

@include responsive($min: md, $max: tablet) {
  .shootsNavigator {
    &__carousel {
      width: 100vw;

      .q-carousel__slide {
        padding-left: 30px !important;
        padding-right: 30px !important;
      }

      .q-carousel__prev-arrow--horizontal {
        left: 0;
      }

      .q-carousel__next-arrow--horizontal {
        right: 0;
      }
    }

    &__dialog {
      .q-dialog__inner {
        padding: 0;
      }
    }
  }
}

.shootsNavigator {
  &__carousel {
    background-color: transparent;
    box-shadow: unset !important;
    height: unset;
    max-width: unset !important;
    width: unset;
  }

  &__uploadText {
    margin: auto 10px;

    > p {
      margin: 0;
    }
  }
}
</style>
