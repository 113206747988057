<template>
  <section class="adminHomepage">
    <h1 class="adminHomepage__title">{{ $t('WELCOME_BACK') }}</h1>
    <p class="adminHomepage__subtitle">{{ $t('CHECK_WEEK_PROGRESS') }}</p>
    <div class="adminHomepage__data">
      <section class="adminHomepage__left">
        <section class="adminHomepage__donuts">
          <div class="adminHomepage__donut-wrapper">
            <BaseDonut width="200" type="donut" :colors="colors" :labels="labels" :series="totalSeries" />
            <div class="adminHomepage__donut-data">
              <p>{{ $t('TOTAL') }}</p>
              <span>
                <strong>{{ totalShoots }}</strong> {{ $tc('SHOOT.SHOOT', totalShoots) }}
              </span>
              <span>
                <!-- <strong>{{ photosNumber }}</strong> {{ $tc('PHOTO', photosNumber) }} -->
              </span>
            </div>
          </div>
          <div class="adminHomepage__donut-wrapper mt-89">
            <BaseDonut width="200" type="donut" :colors="colors" :labels="labels" :series="averageSeries" />
            <div class="adminHomepage__donut-data">
              <p>{{ $t('DAILY_AVERAGE') }}</p>
              <span>
                <strong>{{ Math.ceil(totalShoots / 7, 2) }}</strong> {{ $tc('SHOOT.SHOOT', totalShoots / 7) }}
              </span>
              <span>
                <!-- <strong>{{ Math.ceil(photosNumber / 7, 2) }}</strong> {{ $tc('PHOTO', photosNumber / 7) }} -->
              </span>
            </div>
          </div>
        </section>
      </section>
      <section class="adminHomepage__middle mh-20">
        <BaseColumnLineChart :colors="colors" :labels="labels" :series="series" />
      </section>
      <section class="adminHomepage__right">
        <ShootsFilter group="UPCOMING" />
        <ShootsFilter class="mt-45" group="IN_PROGRESS" />
        <ShootsFilter class="mt-45" group="COMPLETED" />
      </section>
    </div>
  </section>
</template>

<script>
// Api
import { ReportApi } from '@api/index';

// Components

// Base Components
import BaseColumnLineChart from '@base/BaseColumnLineChart.vue';
import BaseDonut from '@base/BaseDonut.vue';

// Components
import ShootsFilter from '@components/shoot/ShootsFilter.vue';

// Utils
import helpers from '@/utils/helpers';
import { UPCOMING_SHOOT, statuses } from '@/utils/shootsHelpers';
import { formatShootReportData } from '@utils/report';

/**
 * Admin homepage
 */
export default {
  name: 'AdminHomepage',
  components: {
    BaseColumnLineChart,
    BaseDonut,
    ShootsFilter
  },
  data() {
    return {
      UPCOMING_SHOOT: UPCOMING_SHOOT,
      colors: [],
      labels: [],
      series: [],
      payload: {
        filters: {
          time: {}
        }
      },
      options: {
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        stroke: {
          show: false
        }
      },
      reports: []
    };
  },
  computed: {
    averageSeries() {
      const _averageSeries = [];

      for (const serie of this.totalSeries) {
        _averageSeries.push(Math.ceil(serie / 7));
      }

      return _averageSeries;
    },
    totalSeries() {
      const mappedCounters = this.series.map(({ data }) => data);
      const _series = [];

      for (const counter of mappedCounters) {
        const shootsByStatus = counter.reduce((partialSum, a) => partialSum + Number(a), 0);
        _series.push(shootsByStatus);
      }

      return _series;
    },
    totalShoots() {
      let _total = 0;

      for (const report of this.reports) {
        const { shoots = {} } = report[Object.keys(report)[0]];
        const { total = 1 } = shoots;

        _total += total;
      }

      return _total;
    }
  },
  created() {
    // Retrieving current week range (mon-sun dates) to inizialize the dates of the date picker
    const { weekFirstDay, weekLastDay } = helpers.getLastWeekRange();

    this.payload.filters.time = {
      from: weekFirstDay,
      to: weekLastDay
    };

    this.retrieveShootsReport();

    this.options.colors = statuses.map(({ color }) => color);
    this.options.labels = statuses.map(({ value }) => value);
  },
  methods: {
    /**
     * Retrieving shoots report
     */
    async retrieveShootsReport() {
      const shootsReport = await ReportApi.shootsReport(this.payload);

      if (shootsReport.statusCode === 200) {
        this.reports = shootsReport.data.reports;
      }

      const { labels, colors, series } = formatShootReportData({
        dates: this.payload.filters.time,
        reports: this.reports
      });

      this.colors = colors;
      this.labels = labels;
      this.series = series;
    }
  }
};
</script>

<style lang="scss" scoped>
.adminHomepage {
  background-color: var(--admin-hp-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 43px 30px 70px 43px;

  > section {
    background: linear-gradient(180deg, rgba(64, 64, 64, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    height: 100%;
    width: 100%;
  }

  &__data {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__donuts {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-top: 36px;
  }

  &__donut-data {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 34px;

    > p {
      @include antonio-font($size: 18px, $color: #fff);
      margin: 0;
      text-align: left;
    }

    > span {
      @include inter-font($size: 16px, $color: #fff);
      text-align: left;
    }
  }

  &__donut-wrapper {
    align-items: center;
    display: flex;
  }

  &__left {
    display: flex;
    flex: 25%;
    flex-direction: column;
  }

  &__middle {
    flex: 45%;
  }

  &__right {
    flex: 30%;
  }

  &__shoots {
    display: flex;
    flex: 1;
    max-height: calc(100vh - #{$header-height});
    padding: 41px 31px 65px 92px;
    > div {
      flex: 50%;
    }
  }

  &__shoots-detail {
    margin-top: 27px;
    text-align: left;
    > div {
      margin-right: 10px;
    }

    strong {
      margin: 0 5px;
    }

    > div {
      color: var(--main-text-color);

      > span:last-child {
        text-transform: lowercase;
      }
    }
  }

  &__subtitle {
    @include inter-font($size: 14px, $color: #fff);
    margin: 0;
    margin-top: 8px;
    text-align: left;
  }

  &__title {
    @include antonio-font($size: 30px, $color: #fff);
    line-height: unset;
    margin: 0;
    text-align: left;
  }
}

@include responsive($max: '880px') {
  .adminHomepage {
    height: fit-content;
    flex-direction: column;
    padding: 20px;

    &__data {
      flex-direction: column;
      margin-bottom: 38px;
    }
    &__middle {
      margin: 50px 0;
    }

    &__shoots {
      padding: 41px 31px;
      flex-direction: column;
    }
  }
}
</style>
